import React from "react";

const ImageHolder = (props) => {
  // Function to generate a color based on the string (name)
  function generateColorFromName(name) {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const R = (hash & 0xff0000) >> 16;
    const G = (hash & 0x00ff00) >> 8;
    const B = hash & 0x0000ff;
    return `rgb(${R}, ${G}, ${B})`;
  }

  // Get the color for the name or fallback
  const backgroundColor = props?.name
    ? generateColorFromName(props.name)
    : "rgb(100, 100, 100)";

  return props?.image ? (
    <img
      className={props?.className ? props?.className : ""}
      src={
        props.page === "post"
          ? props.image
          : process.env.REACT_APP_MEDIA + props?.image
      }
      alt="profile"
    />
  ) : (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="customize-profile-img caps-text"
    >
      {props?.name?.slice(0, 1)}
    </div>
  );
};

export default ImageHolder;
