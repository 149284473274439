import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { followUnfollowFriends, getGuestProfile, getMyFeedData, updateYourProfile } from "../../store/users";
import wIcon3 from "../../include/images/w-icon-3.svg";
import introImage from "../../include/images/contact-intro-img.jpg";
import emailIcon from "../../include/images/e-mail-icon.svg";
import locationIcon from "../../include/images/location-icon.svg";
import { getPublicPost, likeUnlikePost } from "../../store/post";
import contactHero from "../../include/images/contact-hero.png";
import LoadingBar from "react-top-loading-bar";
import { contactUs } from "../../store/misc";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { getProfile } from "../../utils/localStorageServices";

//Enivronment Press corp Page
const ContactUs = (props) => {
  var ref = useRef(null);
  const profile = getProfile();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    message: "",
    organization: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (profile?.profile) {
      setFormData({
        firstName: profile.profile.firstName,
        lastName: profile.profile.lastName,
        emailAddress: profile.profile.email,
        organization: profile.profile.organizationName,
        message:"",
        phoneNumber:"",
      });
    }
  }, []);

  // Regex for email and phone validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{10}$/;

  // Validate input fields
  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "firstName":
        if (!value.trim()) error = "First Name is required.";
        break;
      case "lastName":
        if (!value.trim()) error = "Last Name is required.";
        break;
      case "emailAddress":
        if (!value.trim()) {
          error = "Email Address is required.";
        } else if (!emailRegex.test(value)) {
          error = "Invalid Email Address.";
        }
        break;
      // case "phoneNumber":
      //   if (!value.trim()) {
      //     error = "Phone Number is required.";
      //   } else if (!phoneRegex.test(value)) {
      //     error = "Phone Number must be 10 digits.";
      //   }
      //   break;
      case "message":
        if (!value.trim()) error = "Message is required.";
        break;
      default:
        break;
    }

    setFormErrors((prev) => ({ ...prev, [name]: error }));
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    validateField(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = { ...formErrors };
    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      let error = "";
      switch (key) {
        case "firstName":
          if (!value.trim()) error = "First Name is required.";
          break;
        case "lastName":
          if (!value.trim()) error = "Last Name is required.";
          break;
        case "emailAddress":
          if (!value.trim()) {
            error = "Email Address is required.";
          } else if (!emailRegex.test(value)) {
            error = "Invalid Email Address.";
          }
          break;
        case "message":
          if (!value.trim()) error = "Message is required.";
          break;
        default:
          break;
      }
      errors[key] = error;
    });

    setFormErrors(errors);
    const hasErrors = Object.values(errors).some((error) => error);

    const { firstName, lastName, emailAddress, message, phoneNumber, organization } = formData;

    if (!firstName || !lastName || !emailAddress || !message) {
      toast(
        <AlertError message="Please fill in all mandatory fields: First Name, Last Name, Email Address, and Message." />,
      );
      return;
    }

    if (hasErrors) {
      toast(<AlertError message="Please fix the errors in the form." />);
      return;
    }
    const payload = {
      firstName,
      lastName,
      email: emailAddress,
      phoneNumber,
      message,
      organization,
    };
    props.contactUs(payload, (res) => {
      if (res.status === 200) {
        toast(<AlertSuccess message="Thank you for reaching out! We'll get back to you shortly." />);
        setFormData({
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          message: "",
          organization: "",
        });
        setFormErrors({
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          message: "",
          organization: "",
        });
      } else {
        toast(<AlertError message="Something Went Wrong" />);
      }
    });
  };

  return (
    <PageLayout>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0}/>
      <div className="inner-hero-container">
        <img className="inner-hero-bg" src={contactHero} />
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="inner-header">
                <h3>Get in Touch</h3>
                <p>Want to get in touch? We’d love to hear from you. Here’s how you can reach us.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Banner */}
      <div className="content-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="contact-banner">
                <div className="contact-banner-left">
                  <ul className="cb-list">
                    <li className="cb-item">
                      <div className="cb-box">
                        <div className="cb-box-icon">
                          <img src={locationIcon} alt="" />
                        </div>
                        <div className="cb-box-text">
                          <h6>Headquarters</h6>
                          <p>PO Box G-3 Aspen, CO 81611</p>
                        </div>
                      </div>
                    </li>
                    <li className="cb-item">
                      <div className="cb-box">
                        <div className="cb-box-icon">
                          <img src={emailIcon} alt="" />
                        </div>
                        <div className="cb-box-text d-flex flex-column">
                          <h6>Contact Us</h6>
                          <a href="mailto:info@environmentalInclusion.com">info@environmentalInclusion.com</a>
                          <a href="mailto:hr@environmentalinclusion.com">hr@environmentalinclusion.com</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="contact-banner-right">
                  <ul className="cb-list">
                    <li className="cb-item">
                      <div className="cb-box">
                        <div className="cb-box-icon">
                          <img src={emailIcon} alt="" />
                        </div>
                        <div className="cb-box-text">
                          <h6>Intern Program</h6>
                          <a href="mailto:internprogram@environmentalinclusion.com">
                            internprogram@environmentalinclusion.com
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="cb-item">
                      <div className="cb-box">
                        <div className="cb-box-icon">
                          <img src={emailIcon} alt="" />
                        </div>
                        <div className="cb-box-text">
                          <h6>Environmental Press Corp</h6>
                          <a href="mailto:info@environmentalpresscorp.com">info@environmentalpresscorp.com</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Intro and Form */}
      <div className="content-container pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4">
              <div className="contact-intro-wrapper h-100">
                <div className="contact-intro h-100">
                  <div className="contact-intro-img">
                    <img src={introImage} alt="Ricardo Lopez" />
                  </div>
                  <div className="ci-about text-center">
                    <h6>Ricardo Lopez</h6>
                    <p>Director of Communications</p>
                  </div>
                  <hr />
                  <div className="ci-mail justify-content-center justify-content-xl-start">
                    <div className="ci-mail-icon">
                      <img src={emailIcon} alt="" />
                    </div>
                    <div className="ci-mail-text">
                      <h6>Environmental Press Corp</h6>
                      <a href="mailto:info@environmentalpresscorp.com">info@environmentalpresscorp.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-xl-8">
              <div className="contact-content-holder h-100">
                <div className="contact-head-row">
                  <h2>Contact Form</h2>
                </div>
                <form className="contact-form-row-group" onSubmit={handleSubmit}>
                  <div className="form-floating-row d-flex flex-wrap">
                    <div className="ff-column">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                        <label htmlFor="firstName">First Name</label>
                        {formErrors.firstName && <span className="text-danger">{formErrors.firstName}</span>}
                      </div>
                    </div>
                    <div className="ff-column">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        <label htmlFor="lastName">Last Name</label>
                        {formErrors.lastName && <span className="text-danger">{formErrors.lastName}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="form-floating-row d-flex flex-wrap">
                    <div className="ff-column">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="emailAddress"
                          name="emailAddress"
                          placeholder="Email Address"
                          value={formData.emailAddress}
                          onChange={handleChange}
                        />
                        <label htmlFor="emailAddress">Email Address</label>
                        {formErrors.emailAddress && <span className="text-danger">{formErrors.emailAddress}</span>}
                      </div>
                    </div>
                    <div className="ff-column">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="tel"
                          className="form-control"
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                        />
                        <label htmlFor="phoneNumber">Phone Number</label>
                        {/* {formErrors.phoneNumber && <span className="text-danger">{formErrors.phoneNumber}</span>} */}
                      </div>
                    </div>
                  </div>
                  <div className="form-floating-row d-flex flex-wrap">
                    <div className="ff-column">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          id="organization"
                          name="organization"
                          placeholder="Organization"
                          value={formData.organization}
                          onChange={handleChange}
                        />
                        <label htmlFor="lastName">Organization</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-floating-row d-flex flex-wrap">
                    <div className="ff-column">
                      <div className="form-floating custom-form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Type something here...."
                          id="message"
                          name="message"
                          style={{ height: "135px" }}
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                        {formErrors.message && <span className="text-danger">{formErrors.message}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="cf-form-btn d-flex align-items-center justify-content-end">
                    <button type="submit" className="btn btn-default btn-lg">
                      SEND
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  contactUs: (data, callback) => {
    dispatch(contactUs(data, callback));
  },
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ContactUs));
