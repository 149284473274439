import React from "react";

import { useState } from "react";
import StepOne from "../../components/forgotPassword/stepOne";
import VerifyOtpForgetPassword from "../../components/forgotPassword/verifyEmail";
import Layout from "../../components/forgotPassword/layout";
import { connect } from "react-redux";
import { forgotPassword } from "../../store/users";
import LoadingBar from "react-top-loading-bar";
import { useRef } from "react";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import ResetPassword from "../../components/forgotPassword/resetPassword";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";

const ForgotPassword = (props) => {
  var ref = useRef();
  const [step, setStep] = useState("1");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [forgetPasId, setForgetPasId] = useState(null)
  const handleSendCode = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    props?.forgotPassword({ email }, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
        ref?.current?.complete();
        setStep("2");
        toast(
          <AlertSuccess
            message={
              res?.data?.message
                ? res?.data?.data?.message
                : "Code Sent Successfully"
            }
          />
        );
      } else {
        setLoading(false);
        ref?.current?.complete();
        toast(
          <AlertError
            message={
              res?.data?.message ? res?.data?.message : "Something went wrong"
            }
          />
        );
      }
    });
  };
  Useauthredirect();
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0}/>

      {step == "1" && (
        <Layout>
          <StepOne
            step={step}
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            handleSendCode={handleSendCode}
            loading={loading}
          />
        </Layout>
      )}
      {step == "2" && (
        <Layout>
          {" "}
          <VerifyOtpForgetPassword
            step={step}
            setStep={setStep}
            email={email}
            setForgetPasId={setForgetPasId}
          />
        </Layout>
      )}

      {step == "3" && <ResetPassword forgetPasId={forgetPasId} />}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (data, callback) => dispatch(forgotPassword(data, callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
