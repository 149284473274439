import React from "react";

const SmallScreenPaginations = ({ setPage, page, totalPages }) => {
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) setPage(newPage);
  };

  return (
    <div className="table-pagination d-flex w-100 justify-content-center mt-0">
      {/* Previous Button */}
      <div className="page-item">
        <a
          className={page === 1 ? "page-link disabled _cursor" : "page-link _cursor"}
          aria-label="Previous"
          onClick={() => handlePageChange(page - 1)}
        >
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
            <path d="M7 13L1 7L7 1" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </a>
      </div>

      {/* Page Numbers */}
      <ul className="pagination d-flex justify-content-start">
        {/* Current Page */}
        <li className="page-item active">
          <a className="page-link _cursor">{page}</a>
        </li>
        {/* Next Page */}
        {page < totalPages && (
          <li className="page-item">
            <a className="page-link _cursor" style={{backgroundColor:"#fff" ,color:"black"}} onClick={() => handlePageChange(page + 1)}>
              {page + 1}
            </a>
          </li>
        )}
      </ul>

      {/* Next Button */}
      <div className="page-item">
        <a
          className={page === totalPages ? "page-link disabled _cursor" : "page-link _cursor"}
          aria-label="Next"
          onClick={() => handlePageChange(page + 1)}
        >
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
            <path d="M1 13L7 7L1 1" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default SmallScreenPaginations;
