import React, { useEffect, useRef } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import Cover from "../../layout/cover/cover";
import close from "../../include/images/close.svg";
import PageInfoSidebar from "../../components/common/pageInfoSidebar/pageInfoSidebar";
import NearToExtinct from "../../components/common/nearToExtinctCard/nearToExtinct";
import { regionListings } from "../../store/region";
import { getBlogs, blogsListings } from "../../store/blog";
import { newsListings, getNews } from "../../store/news";
import {
  sideBarApisListings,
  getBLogCategoryList,
  getNewsCategoryList,
  getRegionList,
  getSpeciesList,
  getZooList,
  getOrganizationList,
  getEnvironmentalistList,
  getWetMarketList,
  getSpeciesCategoryList,
  getScienceAndEducationCategory,
} from "../../store/sidebarApis";
import { zooListings, getZoo, getAllZoo } from "../../store/zoo";
import { connect } from "react-redux";
import { useState } from "react";
import { getSpeciesCategory, speciesListings, getSpecies } from "../../store/species";
import { getMisc, getSetting } from "../../store/misc";
import { Link } from "react-router-dom";
import App from "../../common/maps/map";
import IndexFeed from "../../pages/feed/feed.jsx";
import SpeciesListCard from "../../components/common/listingCard/speciesListCard";
import Skeleton from "react-loading-skeleton";
import FilterSideBar from "../../components/common/pageInfoSidebar/filterInfoSidebar";
import { resolve } from "joi-browser";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import PostListCard from "../../components/common/postHome/postListCard";
import arjunPic from "../../include/images/arjun.jpg";
import envPressCorp from "../../include/images/envPressCorp.png";
import volunteerImage from "../../include/images/volunteerImage.png";
import sponsorImage from "../../include/images/sposorshipImage.png";
import { getFeaturedSponsors, getSubscription } from "../../store/subscription.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { getProfile } from "../../utils/localStorageServices.js";
import SpeciesCategoryCard from "../../components/common/listingCard/speciesCategoryCard.jsx";

const IndexRegion = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  const [USRegion, setUSRegion] = useState([]);
  const [otherRegion, setOtherRegion] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [filterRecordCount, setFilterRecordCount] = useState(0);
  const [order, setOrder] = useState("desc");
  const [sort, setSort] = useState("createdAt");
  const [speciesList, setSpeciesList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [environmentalistList, setEnvironmentalistList] = useState([]);
  const [zooAndParksList, setZooAndParksList] = useState([]);
  const [wetmarketList, setWetMarketList] = useState([]);
  const history = useHistory();
  const profile = getProfile();
  // for blog
  const [blogCategory, setBlogCategory] = useState([]);
  const [newsCategory, setNewsCategory] = useState([]);
  const [speciesCategories, setSpeciesCategories] = useState([]);
  const [scienceAndEducationCategory, setScienceAndEducationCategory] = useState([]);

  //map Data
  const [mapRegionData, setMapRegionData] = useState([]);
  const [mapZooData, setMapZooData] = useState([]);
  const [selectedMap, setSelectedMap] = useState("");
  const [showAllZooMap, setShowAllZooMap] = useState(false);
  const [selectSpeciesCategory, setSelectedSpeciesCategory] = useState();
  const [selectSpeciesCategoryName, setSelectedSpeciesCategoryName] = useState();
  const [selectFilterCategoryName, setSelectedFilterCategoryName] = useState();
  const [selectMapData, setSelectMapData] = useState(null);
  const [showAllMap, setShowAllMap] = useState(true);
  const [showMapFilter, setShowmapFilter] = useState(false);
  const mapViewPointRef = useRef(null);

  const regionsList = props.sideBarApisListings && props.sideBarApisListings.regionList;
  const speciesCategory = props.speciesList && props.speciesList.speciesCategory;
  const speciesCat = speciesCategories.map((category) => category.species);
  const speciesItem = props.speciesList && props.speciesList.species;

  const setting = props.misc && props.misc.setting && props.misc.setting.data && props.misc.setting.data.homePageText;
  const sponsorFeature =
    props.getSubscription && props.getSubscription.sponsorFeature && props.getSubscription.sponsorFeature.data;

  Useauthredirect();
  //For Dropdowns
  useEffect(() => {
    //For Regions Dropdown
    if (regionsList && regionsList.data) {
      const usRegions =
        regionsList.data && regionsList.data.length > 0 && regionsList.data.filter((item) => item.country === "US");
      const otherRegions =
        regionsList.data && regionsList.data.length > 0 && regionsList.data.filter((item) => item.country !== "US");
      setUSRegion(usRegions);
      setOtherRegion(otherRegions);
    }
  }, [regionsList]);

  useEffect(() => {
    setLoadingMain(true);
    window.scrollTo(0, 0);

    const fetchOrganizationList = () => {
      return new Promise((resolve, reject) => {
        if (
          props.sideBarApisListings &&
          props.sideBarApisListings.organizationList &&
          props.sideBarApisListings.organizationList.data
        ) {
          const orgList =
            props.sideBarApisListings.organizationList.data &&
            props.sideBarApisListings.organizationList.data.length > 0 &&
            props.sideBarApisListings.organizationList.data.filter((item) => item.name);
          setOrganizationList(orgList);
          resolve();
        } else {
          props.getOrganizationList({}, (res) => {
            if (res && res.status === 200) {
              const orgList = res.data.data && res.data.data.length > 0 && res.data.data.filter((item) => item.name);
              setOrganizationList(orgList);
              resolve();
            } else {
              reject();
            }
          });
        }
      });
    };

    // const fetchBlogCategoryList = () => {
    //   if (props.sideBarApisListings && props.sideBarApisListings.blogCategoryList && props.sideBarApisListings.blogCategoryList.data) {
    //     const blogCategories =
    //       props.sideBarApisListings.blogCategoryList.data &&
    //       props.sideBarApisListings.blogCategoryList.data.length > 0 &&
    //       props.sideBarApisListings.blogCategoryList.data.filter((item) => item.name);
    //     setBlogCategory(blogCategories);
    //   } else {
    //     return props.getBLogCategoryList().then((res) => {
    //       if (res && res.status === 200) {
    //         const blogCategories =
    //           res.data.data &&
    //           res.data.data.length > 0 &&
    //           res.data.data.filter((item) => item.name);
    //         setBlogCategory(blogCategories);
    //       }
    //     });
    //   }
    // };

    // const fetchNewsCategoryList = () => {
    //   if (props.sideBarApisListings && props.sideBarApisListings.newsCategoryList && props.sideBarApisListings.newsCategoryList.data) {
    //     const newsList =
    //       props.sideBarApisListings.newsCategoryList.data &&
    //       props.sideBarApisListings.newsCategoryList.data.length > 0 &&
    //       props.sideBarApisListings.newsCategoryList.data.filter((item) => item.name);
    //     setNewsCategory(newsList);
    //   } else {
    //     return props.getNewsCategoryList().then((res) => {
    //       if (res && res.status === 200) {
    //         const newsCategories =
    //           res.data.data &&
    //           res.data.data.length > 0 &&
    //           res.data.data.filter((item) => item.name);
    //         setNewsCategory(newsCategories);
    //       }
    //     });
    //   }
    // };
    const fetchSpeciesList = () => {
      return new Promise((resolve, reject) => {
        if (
          props.sideBarApisListings &&
          props.sideBarApisListings.speciesList &&
          props.sideBarApisListings.speciesList.data
        ) {
          const blogCategories =
            props.sideBarApisListings.speciesList.data &&
            props.sideBarApisListings.speciesList.data.length > 0 &&
            props.sideBarApisListings.speciesList.data.filter((item) => item.name);
          setSpeciesList(blogCategories);
          resolve();
        } else {
          props.getSpeciesList({}, (res) => {
            if (res && res.status === 200) {
              const species = res.data.data && res.data.data.length > 0 && res.data.data.filter((item) => item.name);
              setSpeciesList(species);
              resolve();
            } else {
              reject();
            }
          });
        }
      });
    };
    const fetchZooList = () => {
      return new Promise((resolve, reject) => {
        if (props.sideBarApisListings && props.sideBarApisListings.zoo && props.sideBarApisListings.zoo.data) {
          const blogCategories =
            props.sideBarApisListings.zoo.data &&
            props.sideBarApisListings.zoo.data.length > 0 &&
            props.sideBarApisListings.zoo.data.filter((item) => item.name);
          setZooAndParksList(blogCategories);
          resolve();
        } else {
          props.getZooList({}, (res) => {
            if (res && res.status === 200) {
              const blogCategories =
                res.data.data && res.data.data.length > 0 && res.data.data.filter((item) => item.name);
              setZooAndParksList(blogCategories);
              resolve();
            } else {
              reject();
            }
          });
        }
      });
    };
    const fetchEnvironMentalList = () => {
      return new Promise((resolve, reject) => {
        if (
          props.sideBarApisListings &&
          props.sideBarApisListings.environmentalist &&
          props.sideBarApisListings.environmentalist.data
        ) {
          const blogCategories =
            props.sideBarApisListings.environmentalist.data &&
            props.sideBarApisListings.environmentalist.data.length > 0 &&
            props.sideBarApisListings.environmentalist.data.filter((item) => item.name);
          setEnvironmentalistList(blogCategories);
          resolve();
        } else {
          props.getEnvironmentalistList({}, (res) => {
            if (res && res.status === 200) {
              const blogCategories =
                res.data.data && res.data.data.length > 0 && res.data.data.filter((item) => item.name);
              setEnvironmentalistList(blogCategories);
              resolve();
            } else {
              reject();
            }
          });
        }
      });
    };
    const fetchSpeciesCategoryList = () => {
      return new Promise((resolve, reject) => {
        if (
          props.sideBarApisListings &&
          props.sideBarApisListings.speciesCategory &&
          props.sideBarApisListings.speciesCategory.data
        ) {
          const blogCategories =
            props.sideBarApisListings.speciesCategory.data &&
            props.sideBarApisListings.speciesCategory.data.length > 0 &&
            props.sideBarApisListings.speciesCategory.data.filter((item) => item.name);
          setSpeciesCategories(blogCategories);
          resolve();
        } else {
          props.getSpeciesCategoryList({}, (res) => {
            if (res && res.status === 200) {
              const blogCategories =
                res.data.data && res.data.data.length > 0 && res.data.data.filter((item) => item.name);
              setSpeciesCategories(blogCategories);
              resolve();
            } else {
              reject();
            }
          });
        }
      });
    };
    const fetchWetMarketList = () => {
      return new Promise((resolve, reject) => {
        if (
          props.sideBarApisListings &&
          props.sideBarApisListings.wetMarket &&
          props.sideBarApisListings.wetMarket.data
        ) {
          const blogCategories =
            props.sideBarApisListings.wetMarket.data &&
            props.sideBarApisListings.wetMarket.data.length > 0 &&
            props.sideBarApisListings.wetMarket.data.filter((item) => item.name);
          setWetMarketList(blogCategories);
          resolve();
        } else {
          props.getWetMarketList({}, (res) => {
            if (res && res.status === 200) {
              const blogCategories =
                res.data.data && res.data.data.length > 0 && res.data.data.filter((item) => item.name);
              setWetMarketList(blogCategories);
              resolve();
            } else {
              reject();
            }
          });
        }
      });
    };
    const fetchSceinceList = () => {
      return new Promise((resolve, reject) => {
        if (
          props.sideBarApisListings &&
          props.sideBarApisListings.scienceAndEducationCategory &&
          props.sideBarApisListings.scienceAndEducationCategory.data
        ) {
          const blogCategories =
            props.sideBarApisListings.scienceAndEducationCategory.data &&
            props.sideBarApisListings.scienceAndEducationCategory.data.length > 0 &&
            props.sideBarApisListings.scienceAndEducationCategory.data.filter((item) => item.name);
          setScienceAndEducationCategory(blogCategories);
          resolve();
        } else {
          props.getScienceAndEducationCategory({}, (res) => {
            if (res && res.status === 200) {
              const blogCategories =
                res.data.data && res.data.data.length > 0 && res.data.data.filter((item) => item.name);
              setScienceAndEducationCategory(blogCategories);
              resolve();
            } else {
              reject();
            }
          });
        }
      });
    };
    //For Species Category and Setting

    const fetchSpeciesCategory = () => {
      return new Promise((resolve, reject) => {
        if (speciesCategory && !speciesCategory.data) {
          props.getSpeciesCategory({}, (res) => {
            if (res && res.status === 200) {
            }
          });
          resolve();
        } else {
          reject();
        }
      });
    };
    const fetchSettings = () => {
      return new Promise((resolve, reject) => {
        if (!setting) {
          props.getSetting((res) => {
            if (res && res.status === 200) {
            }
          });
          resolve();
        } else {
          reject();
        }
      });
    };
    const fetchFeaturedSponsor = () => {
      return new Promise((resolve, reject) => {
        if (!sponsorFeature) {
          props.getFeaturedSponsors((res) => {
            if (res && res.status === 200) {
            }
          });
          resolve();
        } else {
          reject();
        }
      });
    };
    Promise.all([
      fetchOrganizationList(),
      fetchSpeciesList(),
      fetchZooList(),
      fetchEnvironMentalList(),
      fetchSpeciesCategoryList(),
      fetchWetMarketList(),
      fetchSceinceList(),
      fetchSpeciesCategory(),
      fetchSettings(),
      fetchFeaturedSponsor(),
    ])
      .then(() => {
        setLoadingMain(false);
      })
      .catch(() => {
        setLoadingMain(false);
      });
  }, []);

  // for news
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   const payload = {
  //     sort,
  //     order,
  //   };
  //   props.getNews(payload, (res) => {
  //     if (res && res.status === 200) {
  //     }
  //   });
  // }, [sort, order]);

  //For Blogs
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   const payload = {
  //     sort,
  //     order,
  //   };
  //   props.getBlogs(payload, (res) => {
  //     if (res && res.status === 200) {
  //     }
  //   });
  // }, [sort, order]);

  //For zoos
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   props.getZoo({}, (res) => {
  //     if (res && res.status === 200) {

  //     }
  //   });
  // }, []);

  useEffect(() => {
    const categories = selectSpeciesCategory;
    const payload = {
      sort,
      order,
    };
    if (categories) {
      payload.categories = categories;
    }
    props.getSpecies(payload, (res) => {
      if (res && res.status === 200) {
      }
    });
  }, [sort, order, selectSpeciesCategory]);

  useEffect(() => {
    const handleScroll = () => {
      if (mapViewPointRef.current) {
        const rect = mapViewPointRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;

        // Only set to false when it goes out of the viewport
        if (!isVisible) {
          setShowmapFilter(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [mapViewPointRef, setShowmapFilter]);

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add("filter-open");
    } else {
      document.body.classList.remove("filter-open");
    }
  }, [showMenu]);

  const combinedData = [
    ...([].concat(...speciesCat) || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "endangered-species",
      image: item?.coverImage?.small,
    })),
    ...(organizationList || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      image: item?.coverImage?.small,
      section: "organizations",
    })),
    ...(environmentalistList || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "environmentalists",
      image: item?.coverImage?.small,
    })),
    ...(zooAndParksList || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "zoos-and-wildlife-reserves",
      image: item?.coverImage?.small,
    })),
    ...(wetmarketList || [])?.map((item) => ({
      id: item?._id,
      name: item?.name,
      coordinates: item?.geoLocation,
      slug: item?.slug,
      section: "war-on-the-environment-threats",
      image: item?.coverImage?.small,
    })),
  ];
  const getMapData = () => {
    return [
      ...(selectMapData || [])?.map((item) => ({
        id: item?._id,
        name: item?.name,
        coordinates: item?.geoLocation,
        slug: item?.slug,
        section: item.section,
        image: item?.coverImage?.small,
      })),
    ];
  };
  return (
    <>
      <PageLayout>
        <main id="main">
          <div class="content-container" style={{ paddingTop: "30px" }}>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="ei-category-container d-flex flex-wrap">
                    <div class={`ei-category-left ${loadingMain ? "" : "home-page-left"}`}>
                      <div class="filter-header-mobile d-block d-lg-none">
                        <h6>Browse </h6>
                        <div class="close-btn">
                          <img src={close} alt="" onClick={() => setShowMenu(false)} />
                        </div>
                      </div>

                      <PageInfoSidebar
                        page={page}
                        totalCount={totalCount}
                        filterRecordCount={filterRecordCount}
                        setFilterRecordCount={setFilterRecordCount}
                        regions={regionsList.data}
                        setPage={setPage}
                        USRegions={USRegion}
                        otherRegion={otherRegion}
                        blogCategories={blogCategory}
                        newsCategory={newsCategory}
                        setShowMenu={setShowMenu}
                        showMenu={showMenu}
                        speciesCategories={speciesCategories}
                        zooAndParksList={zooAndParksList}
                        organizationList={organizationList}
                        environmentalistList={environmentalistList}
                        wetmarketList={wetmarketList}
                        scienceAndEducationCategory={scienceAndEducationCategory}
                        selectSpeciesCategory={selectSpeciesCategory}
                        setSelectedSpeciesCategory={setSelectedSpeciesCategory}
                        setSelectedSpeciesCategoryName={setSelectedSpeciesCategoryName}
                        selectSpeciesCategoryName={selectSpeciesCategoryName}
                        sponsorFeature={sponsorFeature}
                        loading={loadingMain}
                      />
                      {showMapFilter && (
                        <FilterSideBar
                          page={page}
                          totalCount={totalCount}
                          filterRecordCount={filterRecordCount}
                          setFilterRecordCount={setFilterRecordCount}
                          setPage={setPage}
                          USRegions={USRegion}
                          otherRegion={otherRegion}
                          blogCategories={blogCategory}
                          newsCategory={newsCategory}
                          setShowMenu={setShowMenu}
                          showMenu={showMenu}
                          speciesCategories={speciesCategories}
                          zooAndParksList={zooAndParksList}
                          organizationList={organizationList}
                          environmentalistList={environmentalistList}
                          wetmarketList={wetmarketList}
                          scienceAndEducationCategory={scienceAndEducationCategory}
                          selectSpeciesCategory={selectSpeciesCategory}
                          setSelectedSpeciesCategory={setSelectedSpeciesCategory}
                          setSelectedSpeciesCategoryName={setSelectedFilterCategoryName}
                          selectSpeciesCategoryName={selectFilterCategoryName}
                          setSelectMapData={setSelectMapData}
                          selectMapData={selectMapData}
                          loading={loadingMain}
                          setShowAllMap={setShowAllMap}
                          combinedData={combinedData}
                          setShowmapFilter={setShowmapFilter}
                        />
                      )}
                      <div class="filter-footer-mob-btn d-block d-lg-none">
                        {/* <Link
                        class="btn btn-dark-green btn-block"
                        onClick={() => setShowMenu(!showMenu)}
                      >
                        Apply
                      </Link> */}
                      </div>
                    </div>

                    <div class="ei-category-right home-page-body">
                      {/* <Cover intro={setting} loading={loadingMain} /> */}
                      {!loadingMain ? (
                        <div className="home-page-banner" dangerouslySetInnerHTML={{ __html: setting }} />
                      ) : (
                        <div className="mb-3">
                          <Skeleton borderRadius={"10px"} width={"100%"} height={"60px"} />
                          <Skeleton borderRadius={"10px"} className="mt-3 mb-3" width={"100%"} height={"80px"} />
                        </div>
                      )}
                      <div className="d-flex d-lg-none">
                        {!loadingMain ? (
                          <div
                            className="env-press-container position-relative mb-3 cursor-pointer"
                            onClick={() => {
                              history.push("/environmental-press-corp");
                            }}
                          >
                            <img
                              src={envPressCorp}
                              alt="Environmental Press Corp Background"
                              className="env-press-image"
                            />
                            <div className="env-press-overlay text-center text-white">
                              <h4 className="env-press-heading">
                                Environmental <br />
                                <span className="env-press-subheading">Press Corp</span>
                              </h4>
                              <p className="env-press-description">
                                Championing Environmental Justice and Giving Voice to Endangered Animals
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="env-press-container position-relative mb-3 cursor-pointer">
                            <Skeleton borderRadius={"10px"} width={"100%"} height={"100px"} />
                          </div>
                        )}
                        {!loadingMain ? (
                          <Link
                            className="env-press-container position-relative mb-3 cursor-pointer"
                            to={
                              profile?.profile
                                ? { pathname: "/intern-report", state: "home" }
                                : { pathname: "/intern-report" }
                            }
                          >
                            <img
                              src={volunteerImage}
                              alt="Environmental Press Corp Background"
                              className="env-press-image"
                            />
                            <div className="env-press-overlay sponsor-press-overlay text-center text-white">
                              <h4 className="mb-2">
                                <span className="env-press-subheading">INTERN/VOLUNTEERS</span>
                              </h4>
                            </div>
                          </Link>
                        ) : (
                          <div className="env-press-container position-relative mb-3 cursor-pointer">
                            <Skeleton borderRadius={"10px"} width={"100%"} height={"100px"} />
                          </div>
                        )}
                        {!loadingMain ? (
                          <Link
                            className="env-press-container position-relative mb-3 cursor-pointer"
                            to={profile?.profile ? { pathname: "/addsponsor", state: "home" } : { pathname: "/login" }}
                          >
                            <img
                              src={sponsorImage}
                              alt="Environmental Press Corp Background"
                              className="env-press-image"
                            />
                            <div className="env-press-overlay  sponsor-press-overlay text-center text-white">
                              <h4 className="mb-2">
                                <span className="env-press-subheading">SPONSORSHIP </span>
                              </h4>
                            </div>
                          </Link>
                        ) : (
                          <div className="env-press-container position-relative mb-3 cursor-pointer">
                            <Skeleton borderRadius={"10px"} width={"100%"} height={"100px"} />
                          </div>
                        )}
                      </div>
                      <div class="ei-category-details">
                        <div class="ei-heading">
                          {loadingMain ? (
                            <Skeleton borderRadius={"10px"} width={"100%"} height={40} />
                          ) : (
                            <h4>Endangered Species</h4>
                          )}
                        </div>
                        <ul class="ei-grid-list d-flex flex-wrap">
                          {loadingMain
                            ? [1, 2, 3, 4, 5, 6, 7, 8].map((item, idx) => (
                                <SpeciesCategoryCard
                                  key={idx}
                                  img={item?.coverImage?.medium}
                                  img1={item?.coverImage?.large}
                                  name={item.name}
                                  // page={"species-listing-page"}
                                  id={item._id}
                                  loading={loadingMain}
                                  slug={item?.slug}
                                />
                              ))
                            : speciesCategory &&
                              speciesCategory.data &&
                              speciesCategory.data.length > 0 &&
                              speciesCategory.data.map(
                                (item, idx) =>
                                  idx < 12 && (
                                    <SpeciesCategoryCard
                                      key={idx}
                                      img1={item?.coverImage?.large}
                                      img={item?.coverImage?.medium}
                                      name={item.name}
                                      page={"home"}
                                      id={item._id}
                                      loading={loadingMain}
                                      slug={item?.slug}
                                    />
                                  ),
                              )}
                        </ul>
                        {/* {loadingMain ? (
                          <div class="ei-heading mt-60" >
                            <Skeleton borderRadius={"10px"} width={"100%"} height={40} />
                          </div>
                        ) : ( */}
                        <div class="ei-heading mt-60 d-flex space-between">
                          <h4>Posts</h4>
                          <Link to={"/add-post"} class="btn btn-default pt-2">
                            Add Post
                          </Link>
                        </div>
                        {/* )} */}
                        <div class="gallery-map">
                          <IndexFeed />
                        </div>
                        {/* {loadingMain ? (
                          <div class="mt-60">
                            {" "}
                            <Skeleton borderRadius={"10px"} height={"50vh"} />
                          </div>
                        ) : (
                          <NearToExtinct speciesCategory={speciesCategory} />
                        )} */}
                        {/* {loadingMain ? (
                          <Skeleton borderRadius={"10px"} class="mt-60" height={"100vh"} />
                        ) : (
                          <>
                            <div class="ei-heading mt-60 d-flex space-between">
                              <h4>Regions</h4>
                              <p
                                className="map-switch"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                  if (selectedMap === "US") {
                                    setSelectedMap("loading");
                                    setTimeout(() => setSelectedMap(""), 900);
                                  } else {
                                    setSelectedMap("loading");
                                    setTimeout(() => setSelectedMap("US"), 900);
                                  }
                                }}
                              >
                                Switch to {selectedMap === "US" ? "All " : "US"} region map
                              </p>
                            </div>
                            <div class={`ei-map-box ${selectedMap === "loading" ? "ei-map-box-skeleton" : ""}`}>
                              <App data={mapRegionData} selectedMap={selectedMap} setSelectedMap={setSelectedMap} />
                            </div>
                          </>
                        )} */}
                        {/* {newsList && newsList.length > 0 && (
                          <div class="news-grid-container mt-60">
                            <div class="ei-heading d-flex justify-content-between align-items-center">
                              <h4>Latest News</h4>{" "}
                              <Link to="/news" class="btn-link">
                                View All
                              </Link>
                            </div>
                            <ul class="news-list d-flex flex-wrap">
                              {loadingMain
                                ? [1, 2, 3].map((item) => (
                                    <ListingNewsOnRegion
                                      follow={"Follow"}
                                      name={item.name}
                                      description={item.description}
                                      createAt={item?.createAt}
                                      page={"news-listing-page"}
                                      id={item._id}
                                      category={item.newsCategoryName}
                                      loading={loadingMain}
                                    />
                                  ))
                                : newsList &&
                                  newsList.length > 0 &&
                                  newsList.map(
                                    (item, idx) =>
                                      idx < 3 && (
                                        <ListingNewsOnRegion
                                          follow={"Follow"}
                                          name={item.name}
                                          description={item.description}
                                          createdAt={item?.createdAt}
                                          page={"news-listing-page"}
                                          id={item._id}
                                          categories={item.categories}
                                          loading={loadingMain}
                                        />
                                      )
                                  )}
                            </ul>
                          </div>
                        )} */}
                        {/* {loadingMain ? (
                          <div class="zoo-report-container mt-60">
                            {" "}
                            <Skeleton borderRadius={"10px"} height={"100vh"} />
                          </div>
                        ) : (
                          <>
                            {combinedData && combinedData.length > 0 && (
                              <div class="zoo-report-container  mt-60">
                                <div class="ei-heading d-flex space-between">
                                  <h4>Explore more on the map below</h4>
                                  <p
                                    className="map-switch"
                                    style={{ marginRight: "5px" }}
                                    onClick={() => setShowmapFilter(!showMapFilter)}
                                  >
                                    {showMapFilter ? "Hide map filters" : "Show map filters"}
                                  </p>
                                </div>
                                <div class="zoo-report-box d-flex flex-wrap" ref={mapViewPointRef}>
                                  <div class="zrb-right zrb-right-map-home">
                                    <div class="zoo-map-image" style={{ width: "100%" }}>
                                      <App data={showAllMap ? combinedData : getMapData()} mapType="zoo" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )} */}
                        {/* {blogList && blogList.length > 0 && (
                          <div class="blog-list-container mt-60">
                            <div class="ei-heading d-flex justify-content-between align-items-center">
                              <h4>Blogs</h4>{" "}
                              <Link to="/blog" class="btn-link">
                                View All
                              </Link>
                            </div>

                            <ul class="blog-list d-flex flex-wrap">
                              {loadingMain
                                ? [1, 2, 3].map((item) => (
                                    <ListBlogsOnRegion
                                      img={item?.coverImage?.medium}
                                      name={item.name}
                                      description={item?.description}
                                      page={"blog-listing-page"}
                                      id={item._id}
                                      createdAt={item?.createdAt}
                                      loading={loadingMain}
                                      blogCategoryName={
                                        item?.categories?.categories[0]
                                      }
                                    />
                                  ))
                                : blogList &&
                                  blogList.length > 0 &&
                                  blogList.map(
                                    (item, idx) =>
                                      idx < 3 && (
                                        <ListBlogsOnRegion
                                          img={item?.coverImage?.medium}
                                          name={item.name}
                                          createdAt={item?.createdAt}
                                          description={item?.description}
                                          page={"blog-listing-page"}
                                          id={item._id}
                                          categories={item.categories}
                                        />
                                      )
                                  )}
                            </ul>
                          </div>
                        )} */}
                      </div>
                    </div>
                    <div class="ei-category-right overflow-scroll home-page-right">
                      {!loadingMain ? (
                        <div
                          className="env-press-container position-relative mb-3 cursor-pointer"
                          onClick={() => {
                            history.push("/environmental-press-corp");
                          }}
                        >
                          <img
                            src={envPressCorp}
                            alt="Environmental Press Corp Background"
                            className="env-press-image"
                          />
                          <div className="env-press-overlay text-center text-white">
                            <h4 className="env-press-heading">
                              Environmental <br />
                              <span className="env-press-subheading">Press Corp</span>
                            </h4>
                            <p className="env-press-description">
                              Championing Environmental Justice and Giving Voice to Endangered Animals
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="env-press-container position-relative mb-3 cursor-pointer">
                          <Skeleton borderRadius={"10px"} width={"100%"} height={"200px"} />
                        </div>
                      )}
                      {!loadingMain ? (
                        <Link
                          className="env-press-container position-relative mb-3 cursor-pointer"
                          to={
                            profile?.profile
                              ? { pathname: "/intern-report", state: "home" }
                              : { pathname: "/intern-report" }
                          }
                        >
                          <img
                            src={volunteerImage}
                            alt="Environmental Press Corp Background"
                            className="env-press-image"
                          />
                          <div className="env-press-overlay sponsor-press-overlay text-center text-white">
                            <h4 className="mb-2">
                              <span className="env-press-subheading">INTERN/VOLUNTEERS</span>
                            </h4>
                          </div>
                        </Link>
                      ) : (
                        <div className="env-press-container position-relative mb-3 cursor-pointer">
                          <Skeleton borderRadius={"10px"} width={"100%"} height={"200px"} />
                        </div>
                      )}
                      {!loadingMain ? (
                        <Link
                          className="env-press-container position-relative mb-3 cursor-pointer"
                          to={profile?.profile ? { pathname: "/addsponsor", state: "home" } : { pathname: "/login" }}
                        >
                          <img
                            src={sponsorImage}
                            alt="Environmental Press Corp Background"
                            className="env-press-image"
                          />
                          <div className="env-press-overlay  sponsor-press-overlay text-center text-white">
                            <h4 className="mb-2">
                              <span className="env-press-subheading">SPONSORSHIP </span>
                            </h4>
                          </div>
                        </Link>
                      ) : (
                        <div className="env-press-container position-relative mb-3 cursor-pointer">
                          <Skeleton borderRadius={"10px"} width={"100%"} height={"200px"} />
                        </div>
                      )}
                      {loadingMain ? (
                        <Skeleton borderRadius={"10px"} height={"100vh"} />
                      ) : (
                        <div class="ei-green-label-block position-sticky d-none d-lg-block">
                          <div class="ei-g-l-title">Near to Extinction</div>
                          <div class="ei-g-l-row">
                            <ul class="ei-g-l-list">
                              {speciesCategory &&
                                speciesCategory.data &&
                                speciesCategory.data.length > 0 &&
                                speciesCategory.data.map((item, i) => {
                                  const isValidExtinctionPercentage =
                                    item.extinctionPercentage !== null &&
                                    item.extinctionPercentage !== undefined &&
                                    item.extinctionPercentage !== "" &&
                                    parseFloat(item.extinctionPercentage) !== 0;

                                  if (isValidExtinctionPercentage) {
                                    return (
                                      <li className="ei-g-l-item" key={i}>
                                        <div className="ei-g-l-box w-100 h-100 d-flex">
                                          <div className="ei-g-l-heading flex-grow-1">
                                            <Link
                                              to={{
                                                pathname: `/endangered-species`,
                                                id: item?._id,
                                                name: item.name,
                                              }}
                                              className="hover-link-near-extinct text-capitalize"
                                            >
                                              {item.name}
                                            </Link>
                                          </div>
                                          <div className="ei-g-l-number flex-shrink-0">
                                            {item.extinctionPercentage}%
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </PageLayout>
      {!loadingMain && (
        <div class="browse-category-mob d-block d-lg-none">
          <Link class="btn btn-block btn-dark-green" onClick={() => setShowMenu(!showMenu)}>
            Browse
          </Link>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  getBLogCategoryList: (params, callback) => dispatch(getBLogCategoryList(params, callback)),
  getSpeciesCategory: (params, callback) => dispatch(getSpeciesCategory(params, callback)),
  getSetting: (callback) => dispatch(getSetting(callback)),
  getNewsCategoryList: (params, callback) => dispatch(getNewsCategoryList(params, callback)),
  getNews: (params, callback) => dispatch(getNews(params, callback)),
  getBlogs: (params, callback) => dispatch(getBlogs(params, callback)),
  getZoo: (params, callback) => dispatch(getZoo(params, callback)),
  // getAllZoo: (callback) => dispatch(getAllZoo(callback)),
  getSpeciesList: (params, callback) => dispatch(getSpeciesList(params, callback)),
  getSpecies: (params, callback) => dispatch(getSpecies(params, callback)),
  getAllZoo: (params, callback) => dispatch(getAllZoo(params, callback)),
  getSpeciesCategoryList: (params, callback) => dispatch(getSpeciesCategoryList(params, callback)),
  getZooList: (params, callback) => dispatch(getZooList(params, callback)),
  getOrganizationList: (params, callback) => dispatch(getOrganizationList(params, callback)),
  getEnvironmentalistList: (params, callback) => dispatch(getEnvironmentalistList(params, callback)),
  getWetMarketList: (params, callback) => dispatch(getWetMarketList(params, callback)),
  getScienceAndEducationCategory: (params, callback) => dispatch(getScienceAndEducationCategory(params, callback)),
  getFeaturedSponsors: (callback) => dispatch(getFeaturedSponsors(callback)),
});

const mapStateToProps = (state) => ({
  regionLists: regionListings(state),
  speciesList: speciesListings(state),
  blogsListings: blogsListings(state),
  newsListings: newsListings(state),
  zooListings: zooListings(state),
  sideBarApisListings: sideBarApisListings(state),
  misc: getMisc(state),
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(IndexRegion));
