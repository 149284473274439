import React, { useEffect, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import close from "../../include/images/close.svg";
import { getProfileInfo, getUser, getUsersPreferences, updateYourProfile } from "../../store/users";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import rawImage from "../../include/images/row-icon-0.png";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import RightFeedSidebar from "../../components/common/pageInfoSidebar/rightFeedSidebar";
import LeftSideBar from "../../components/common/pageInfoSidebar/leftSideBar";
import editIcon from "../../include/images/edit-2.svg";
import { getMisc, getCountry, getUsState, getUsCitiesDropdown } from "../../store/misc";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Storage, Auth } from "aws-amplify";
import Select from "react-select";
import Joi from "joi";
import { validateErrorMessage } from "../../common/validation/validation";
import { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import AlertSuccess from "../../common/alerts/alertSuccess";
import Skeleton from "react-loading-skeleton";
import { getProfile, setProfile } from "../../utils/localStorageServices";
import ProfileLayout from "../../layout/profileLayout/profileLayout";
import { Cropper } from "react-cropper";
import organization from "../../components/environmentalistDetail/organization";
import banner from "../../include/images/banner-hero.jpg";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const IndividualSustainabilityDetails = (props) => {
  var ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showRightMenu, setShowRightMenu] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  const [newProfilePic, setNewProfilePic] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [key, setKey] = useState(0);
  const profile = getProfile();
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [imageForBanner, setImageForBanner] = useState(null); // For Profile Picture cropping
  const [imageForOrganization, setImageForOrganization] = useState(null); // For Organization Logo cropping
  const [cropper, setCropper] = useState(null);
  const [coverImage, setCoverImage] = useState(
    profile?.profile?.bannerPicture?.original
      ? process.env.REACT_APP_MEDIA + profile?.profile?.individualBannerPicture?.original
      : profile?.profile?.coverPicture
      ? process.env.REACT_APP_MEDIA + profile?.profile?.coverPicture?.original
      : banner,
  );
  const [userData, setUserData] = useState({
    tagLine: profile?.profile?.individualTagLine,
    description: profile?.profile?.individualDescription,
    bannerPicture: profile?.profile?.individualBannerPicture,
    organizationLogo: profile?.profile?.profilePicture,
  });
  const [image, setImage] = useState(null);

  const handleCrop = () => {
    if (cropper) {
      const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
      const croppedImageBlob = dataURLToBlob(croppedImageDataUrl);
      const croppedImageFile = new File([croppedImageBlob], "banner-image.png", { type: "image/png" });
      const updatedData = { ...userData, bannerPicture: URL.createObjectURL(croppedImageBlob) };
      setUserData(updatedData);
      setCoverImage(URL.createObjectURL(croppedImageBlob));
      setImage(null);
      setImageForBanner(croppedImageFile);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const onSaveHandler = async () => {
    setLoadingData(true);
    ref?.current?.continuousStart();

    const uploadFile = async (file, type) => {
      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name.split(".").pop();

      if (fSize > 25) {
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        setLoadingData(false);
        return null;
      }

      if (!["image/jpeg", "image/jpg", "image/png", "image/webp"].includes(fType)) {
        toast(<AlertError message="Invalid format. Allowed formats are jpg, jpeg, png, and webp." />);
        setLoadingData(false);
        return null;
      }

      const fileName = `${uuidv4()}.${ext}`;

      try {
        const result = await Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            toast(<AlertError message="Something went wrong with the upload." />);
            setLoadingData(false);
          },
        });
        return `public/${result.key}`;
      } catch (error) {
        toast(<AlertError message="Something went wrong with the upload." />);
        setLoadingData(false);
        return null;
      }
    };

    let bannerImageUrl = userData?.bannerPicture;
    let organizationLogoUrl = userData?.organizationLogo;

    if (imageForBanner) {
      bannerImageUrl = await uploadFile(imageForBanner, "profilePicture");
      if (!bannerImageUrl) return;
    }

    if (newLogo) {
      organizationLogoUrl = await uploadFile(newLogo, "organizationLogo");
      if (!organizationLogoUrl) return;
    }

    const payload = {
      individualTagLine: userData?.tagLine,
      individualDescription: userData?.description,
    };
    if (bannerImageUrl) {
      payload.individualBannerPicture = bannerImageUrl;
    }

    if (organizationLogoUrl) {
      payload.profilePicture = organizationLogoUrl;
    }
    props.updateYourProfile(payload, (res) => {
      if (res && res.status === 200) {
        props.getProfileInfo((res) => {
          if (res && res.status === 200) {
            setUserProfile(res?.data?.data);
            setNewProfilePic(null);
            setNewLogo(null);

            const data = {
              ...userData,
              bannerImage: res?.data?.data?.individualBannerPicture?.original,
              description: res?.data?.data?.individualDescription,
              tagLine: res?.data?.data?.individualTagLine,
              organizationLogo :res?.data?.data?.profilePicture,
            };
            setUserData(data);
            setLoadingData(false);
            ref?.current?.complete();
            toast(<AlertSuccess message="Information Saved" />);
          }
        });
      } else {
        setLoadingData(false);
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
      }
    });
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (type === "organizationLogo") {
          setImageForOrganization(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropOrganizationLogo = () => {
    if (cropper) {
      const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
      const croppedImageBlob = dataURLToBlob(croppedImageDataUrl);
      const croppedImageFile = new File([croppedImageBlob], "organization-logo.png", { type: "image/png" });

      const updatedData = { ...userData, organizationLogo: URL.createObjectURL(croppedImageBlob) };
      setUserData(updatedData);
      setImageForOrganization(null);
      setNewLogo(croppedImageFile);
    }
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([uintArray], { type: mimeString });
  };
  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result); // Set the image for cropping
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <ProfileLayout page={"editProfile"}>
      {loadingMain || loadingData ? (
        <div class="panel-main-content d-flex flex-column">
          {" "}
          <Skeleton borderRadius={"10px"} height={"100vh"} />{" "}
        </div>
      ) : (
        <div class="panel-main-content d-flex flex-column">
          <div class="panel-head">
            <h1>Update Sustainability page details</h1>
          </div>
          <div class="panel-body flex-grow-1">
            {loadingMain ? (
              <Skeleton borderRadius={"10px"} height={200} width={"100%"} />
            ) : (
              <div className="wpw-content-img-box">
                {image ? (
                  <div className="cropper-overlay">
                    <div className="cropper-container">
                      <Cropper
                        src={image}
                        style={{ height: "70vh", width: "100%" }}
                        aspectRatio={16 / 9}
                        guides={true}
                        viewMode={1}
                        autoCropArea={1}
                        background={false}
                        responsive={true}
                        checkOrientation={false}
                        onInitialized={(instance) => setCropper(instance)}
                      />
                      <div className="cropper-actions">
                        <button onClick={handleCrop} className="btn btn-default h-40">
                          Crop and Save
                        </button>
                        <button onClick={() => setImage(null)} className="btn btn-secondary h-40">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <img src={coverImage} alt="profile cover" className="rounded-3" />
                    <div onClick={triggerFileInput} className="edit-btn species-detailsedit-btn cursor-pointer">
                      <img src={editIcon} alt="edit icon" />
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleFileChange1}
                    />
                  </>
                )}
              </div>
            )}
            <div class="form-floating-row d-block d-sm-flex flex-wrap mt-3">
              <div className="ff-column w-25">
                <div className="ff-label-box">Profile Picture</div>
                <div className="ff-image-box d-flex">
                  {imageForOrganization ? (
                    <div className="cropper-overlay">
                      <div className="cropper-container">
                        <Cropper
                          src={imageForOrganization}
                          style={{ height: "70vh", width: "100%" }}
                          aspectRatio={1}
                          guides={true}
                          viewMode={1}
                          autoCropArea={1}
                          background={false}
                          responsive={true}
                          checkOrientation={false}
                          onInitialized={(instance) => setCropper(instance)}
                        />
                        <div className="cropper-actions">
                          <button onClick={handleCropOrganizationLogo} className="btn btn-default h-40">
                            Crop
                          </button>
                          <button onClick={() => setImageForOrganization(null)} className="btn btn-secondary h-40">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {userData?.organizationLogo ? (
                        <img
                          src={
                            newLogo
                              ? userData?.organizationLogo
                              : process.env.REACT_APP_MEDIA + userData?.organizationLogo?.small
                          }
                          alt="Organization Logo"
                        />
                      ) : (
                        <></>
                      )}
                      {(userData?.organizationLogo || imageForOrganization) && (
                        <button
                          type="button"
                          className="ff-close-img"
                          onClick={() => {
                            fileInputRef1.current?.click();
                          }}
                        >
                          <img src={editIcon} alt="edit icon" />
                        </button>
                      )}
                    </>
                  )}
                  <input
                    ref={fileInputRef1}
                    type="file"
                    className="form-control"
                    id="organizationLogo"
                    name="organizationLogo"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "organizationLogo")}
                  />
                  {!userData?.organizationLogo && !imageForOrganization && (
                    <button type="button" className={"ff-update-link mt-auto position-relative"}>
                      <input
                        type="file"
                        className="form-control"
                        id="fileInput"
                        accept=".jpg, .jpeg, .png, .webp"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => setImageForOrganization(reader.result);
                          }
                        }}
                      />
                      Upload
                    </button>
                  )}
                </div>
              </div>

              <div className="edit-pfl-modal" style={{ marginTop: "10%" }}>
                <div className="wpw-content-head-btn me-3">
                  <Link className="btn btn-default btn-block" to="/profile">
                    View Profile
                  </Link>
                </div>
              </div>
            </div>
            {/*<div
              class={
                error && error.organizationName
                  ? "is-invalid form-floating-row d-flex flex-wrap"
                  : "form-floating-row d-flex flex-wrap"
              }
            >
              <div class="ff-column">
                <div class="form-floating custom-form-floating">
                  <input
                    type="text"
                    class={
                      error && error.organizationName
                        ? "is-invalid form-control text-capitalize"
                        : "form-control text-capitalize"
                    }
                    id="firstName"
                    name="firstName"
                    value={userData?.organizationName}
                    placeholder="Organization Name"
                    onChange={(e) => {
                      const data = { ...userData };
                      data.organizationName = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                      setUserData(data);
                    }}
                  />
                  <label for="firstName">Organization Name</label>
                </div>
                {error && error?.organizationName ? <p className="invalid">{error?.organizationName}</p> : ""}
              </div>
            </div> */}

            <div class="form-floating-row d-flex flex-wrap">
              <div class="ff-column">
                <div class="form-floating custom-form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    id="floatingTextarea2"
                    style={{ height: "115px" }}
                    value={userData.description}
                    name="description"
                    onChange={(e) => {
                      const data = { ...userData };
                      data.description = e.target.value;
                      setUserData(data);
                    }}
                  />
                  <label for="floatingTextarea2">Description</label>
                </div>
              </div>
            </div>
            <div class="form-floating-row d-flex flex-wrap">
              <div class="ff-column">
                <div class="form-floating custom-form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Title Tagline"
                    id="floatingTextarea2"
                    style={{ height: "115px" }}
                    value={userData.tagLine}
                    name="Title Tagline"
                    onChange={(e) => {
                      const data = { ...userData };
                      data.tagLine = e.target.value;
                      setUserData(data);
                    }}
                  />
                  <label for="floatingTextarea2">Title Tagline</label>
                </div>
              </div>
            </div>
            <div class="p-p-btn d-flex align-items-center justify-content-end">
              <a href="javascript:void(0);" class="btn btn-default btn-sm" onClick={() => onSaveHandler()}>
                Save
              </a>
            </div>
          </div>
        </div>
      )}
    </ProfileLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCountry: (callback) => dispatch(getCountry(callback)),
  getUsState: (callback) => dispatch(getUsState(callback)),
  getUsCitiesDropdown: (data, callback) => dispatch(getUsCitiesDropdown(data, callback)),
  updateYourProfile: (data, callback) => dispatch(updateYourProfile(data, callback)),
  getProfileInfo: (callback) => dispatch(getProfileInfo(callback)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(IndividualSustainabilityDetails));
