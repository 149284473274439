import React, { useEffect, useRef, useState } from "react";
import {
  getMyPostData,
  getUsersPreferences,
  getUser,
  deleteMyPost,
  updateYourProfile,
  getProfileInfo,
  getSponsorUser,
  getTransaction,
  getstoryUser,
} from "../../store/users";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import profileImage from "../../include/images/profile-w-img.jpg";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import location from "../../include/images/w-page-link-0.svg";
import link from "../../include/images/w-page-link-1.svg";
import AlertSuccess from "../../common/alerts/alertSuccess";
import Feeds from "../../components/feed/feeds";
import ProfileLayout from "../../layout/profileLayout/profileLayout";
import LoadingBar from "react-top-loading-bar";
import Media from "../../components/feed/media";
import { getLiked, getMedia, getPosts } from "../../store/post";
import Skeleton from "react-loading-skeleton";
import organizationSvg from "../../include/images/organization.svg";
import emailsvg from "../../include/images/email.svg";
import banner from "../../include/images/banner-hero.jpg";
import { getProfile, setProfile } from "../../utils/localStorageServices";
import editIcon from "../../include/images/edit-2.svg";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Auth, Storage } from "aws-amplify";
import { Dropdown } from "react-bootstrap";
import SponsoredSpecies from "../../components/feed/sponsorList";
import Transaction from "../../components/feed/transaction";
import {
  updateTransaction,
  updateTransactionStory,
  updateTransactionStoryStripe,
  updateTransactionStripe,
} from "../../store/subscription";
import DeletePost from "../../common/alerts/deleteReason";
import CorporateSponsor from "../../common/alerts/sponsorAlert";
import sustainabilityIcon from "../../include/images/sustainbility.svg";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import SponsorGreetings from "../../components/common/congratsModal/greetingsModal";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import ImageHolder from "../../common/customStyles/imageHolder";
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
const UserProfile = (props) => {
  var ref = useRef(null);
  const [loadingMain, setLoadingMain] = useState(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [sort, setSort] = useState("createdAt");
  const [tab, setTab] = useState("post");
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [loadingLike, setLoadingLike] = useState(false);
  const fileInputRef = useRef(null);
  const profile = getProfile();
  const [coverImage, setCoverImage] = useState(
    profile?.profile?.coverPicture?.original
      ? process.env.REACT_APP_MEDIA + profile?.profile?.coverPicture?.original
      : banner,
  );
  const myPostData = props.getUser && props.getUser.myPost && props.getUser.myPost.data;
  const userProfile = props?.getUser?.profileInfo?.data;
  const mediaData = props?.getPosts?.media;
  const likeData = props?.getPosts?.likes?.data;
  const currentLocation = useLocation();
  const [showSponsorAlert, setSponsorAlert] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [image, setImage] = useState(null);
  const [showGreeting, setShowGreeting] = useState(false);
  const [showStoryGreeting, setShowStoryGreeting] = useState(false);
  const isMobile = useCheckMobileScreen();
  useEffect(() => {
    setCoverImage(
      userProfile?.coverPicture?.original ? process.env.REACT_APP_MEDIA + userProfile.coverPicture?.original : banner,
    );
  }, [userProfile]);
  useEffect(() => {
    setLoadingMain(true);
    const payload = {
      keyword: "",
      page: page,
      sort,
      order,
    };
    props.getMyPostData(payload, (res) => {
      if (res && res.status === 200) {
        setPage(1);
        setLoadingMain(false);
      } else {
        setLoadingMain(false);
      }
    });
  }, [sort, order, page]);

  useEffect(() => {
    if (tab == "media") {
      setLoadingMedia(true);
      props?.getMedia((res) => {
        if (res && res.status == 200) {
          setLoadingMedia(false);
        } else {
          setTab("post");
          setLoadingMedia(false);
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  }, [tab]);

  useEffect(() => {
    if (tab == "like") {
      setLoadingLike(true);
      props?.getLiked((res) => {
        if (res && res.status == 200) {
          setLoadingLike(false);
        } else {
          setTab("post");
          setLoadingLike(false);
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  }, [tab]);
  useEffect(() => {
    if (tab == "sponsor") {
      setLoadingLike(true);
      props?.getSponsor((res) => {
        if (res && res.status == 200) {
          setLoadingLike(false);
        } else {
          setTab("post");
          setLoadingLike(false);
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
      props.getstoryUser((res) => {
        if (res.status == 200) {
        } else {
        }
      });
    }
  }, [tab]);
  useEffect(() => {
    if (tab == "transactions") {
      setLoadingLike(true);
      props?.getTransaction({ page: 1 }, (res) => {
        if (res && res.status == 200) {
          setLoadingLike(false);
        } else {
          setTab("post");
          setLoadingLike(false);
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  }, [tab]);
  useEffect(() => {
    const queryParams = new URLSearchParams(currentLocation.search);
    const subscription_id = queryParams.get("subscription_id");
    const baToken = queryParams.get("ba_token");
    const token = queryParams.get("token");
    const type = queryParams.get("type");
    const cancel = queryParams.get("cancel");
    const paymentMode = queryParams.get("pay");
    const sessionId = JSON.parse(sessionStorage.getItem("session_id") || null);
    const tempSelectSpecies = JSON.parse(sessionStorage.getItem("tempSelectSpecies") || "[]");

    if ((subscription_id && token) || sessionId) {
      const params = {
        subscription_id,
        speciesId: [...tempSelectSpecies],
        token,
      };

      const apiCallback = (res) => {
        if (res && res.status === 200) {
          if (cancel) {
            toast(<AlertError message={res?.data?.data} />);
            return;
          }
          if (type === "individual" || type === "corporate") {
            if (!profile?.profile?.organizationName && !profile?.profile?.organizationLogo) {
              setSponsorAlert(true);
            }
            setShowGreeting(true);
          } else {
            setShowStoryGreeting(true);
          }
          sessionStorage.removeItem("session_id");
          sessionStorage.removeItem("tempSelectSpecies");
        } else {
          console.log("Error updating transaction", res);
        }
      };

      if (type === "individual" || type === "corporate") {
        params.isCompany = type === "corporate";
        if (paymentMode == "stripe") {
          const payload = {
            session_id: sessionId,
          };
          props.updateTransactionStripe(payload, apiCallback);
        } else {
          props.updateTransaction(params, apiCallback);
        }
      } else {
        if (paymentMode == "stripe") {
          const payload = {
            session_id: sessionId,
          };
          props.updateTransactionStoryStripe(payload, apiCallback);
        } else {
          params.speciesId = tempSelectSpecies[0];
          props.updateTransactionStory(params, apiCallback);
        }
      }
    }
  }, [currentLocation]);

  const deleteHandler = (item) => {
    ref?.current?.continuousStart();
    setLoadingMain(true);
    const id = item?._id;
    props.deleteMyPost(id, (res) => {
      if (res && res.status === 200) {
        const payload = {
          keyword: "",
          page: page,
          sort,
          order,
        };
        props.getMyPostData(payload, (res) => {
          if (res && res.status === 200) {
            ref?.current?.complete();
            setLoadingMain(false);
            toast(<AlertSuccess message={"Post Deleted Successfully"} />);
          }
        });
      } else {
        ref?.current?.complete();
        setLoadingMain(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleProfile = () => {
    const payload = {
      isPrivate: !userProfile.isPrivate,
    };
    props.updateYourProfile(payload, (res) => {
      if (res && res.status === 200) {
        props.getProfileInfo((res) => {
          if (res && res.status === 200) {
            toast(<AlertSuccess message="Profile updated successfully." />);
            ref?.current?.complete();
            let tempData = { ...profile };
            tempData.profile = res?.data?.data;
            setProfile(tempData);
          } else {
            toast(<AlertError message="Something went wrong during the upload." />);
          }
        });
      } else {
        toast(<AlertError message="Something went wrong during the upload." />);
        ref?.current?.complete();
      }
    });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result); // Set the image for cropping
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = () => {
    if (cropper) {
      const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
      const croppedImageBlob = dataURLToBlob(croppedImageDataUrl);
      const croppedImageFile = new File([croppedImageBlob], "cropped-image.png", { type: "image/png" });

      setCoverImage(URL.createObjectURL(croppedImageBlob));
      updateBanner(croppedImageFile);
      setImage(null);
    }
  };
  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([uintArray], { type: mimeString });
  };

  const updateBanner = (file) => {
    if (!file) return;

    ref?.current?.continuousStart();

    const ext = file.name.split(".").pop();
    const fSize = Math.round(file.size / 1048576); // Size in MB
    const fType = file.type;

    if (fSize > 25) {
      toast(<AlertError message="Size exceeds the maximum allowable size of 25MB." />);
      ref?.current?.complete();
      return;
    }

    if (!["image/jpeg", "image/jpg", "image/png", "image/webp"].includes(fType)) {
      toast(<AlertError message="Invalid format. Acceptable formats are jpg, jpeg, png, and webp." />);
      ref?.current?.complete();
      return;
    }

    const fileName = uuidv4() + "." + ext;

    // Upload to storage
    Storage.put(fileName, file, {
      completeCallback: () => {},
      progressCallback: (progress) => {},
      errorCallback: (err) => {
        toast(<AlertError message={"Error occurred during file upload."} />);
        ref?.current?.complete();
      },
    })
      .then((result) => {
        const payload = {
          coverPicture: "public/" + result.key,
        };
        props.updateYourProfile(payload, (res) => {
          if (res && res.status === 200) {
            ref?.current?.complete();
            toast(<AlertSuccess message="Cover image updated successfully." />);
          } else {
            ref?.current?.complete();
            toast(<AlertError message={res?.data?.message || "Failed to update cover image."} />);
          }
        });
      })
      .catch((err) => {
        toast(<AlertError message="Something went wrong during the upload." />);
        ref?.current?.complete();
      });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <ProfileLayout page={"profile"} loading={!userProfile}>
        <div class="panel-main-content d-flex flex-column">
          <div class="panel-body flex-grow-1">
            <div class="widget-profile-block">
              <div class="widget-profile-wrapper">
                {!userProfile ? <Skeleton borderRadius={"10px"} height={40} /> : <div class="wpw-title">Profile</div>}
                <div class="wpw-content-row">
                  {loadingMain ? (
                    <Skeleton borderRadius={"10px"} height={200} width={"100%"} className="lh-lg mt-2" />
                  ) : (
                    <div className="wpw-content-img-box">
                      {image ? (
                        <div className="cropper-overlay">
                          <div className="cropper-container">
                            <Cropper
                              src={image}
                              style={{ height: "70vh", width: "100%" }}
                              aspectRatio={16 / 9}
                              guides={true}
                              viewMode={1}
                              autoCropArea={1}
                              background={false}
                              responsive={true}
                              checkOrientation={false}
                              onInitialized={(instance) => setCropper(instance)}
                            />
                            <div className="cropper-actions">
                              <button onClick={handleCrop} className="btn btn-default h-40">
                                Crop and Save
                              </button>
                              <button onClick={() => setImage(null)} className="btn btn-secondary h-40">
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        userProfile && (
                          <>
                            <img src={coverImage} alt="profile cover" className="banner-image" />
                            <div onClick={triggerFileInput} className="edit-btn species-detailsedit-btn cursor-pointer">
                              <img src={editIcon} alt="edit icon" />
                            </div>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={handleFileChange}
                            />
                          </>
                        )
                      )}
                    </div>
                  )}
                  <div class="wpw-content" alt="profile w1 img">
                    <div
                      className={`wpw-content-head d-flex flex-wrap align-items-center justify-content-between position-relative`}
                    >
                      {loadingMain ? (
                        <div class="wpw-content-head-icon d-flex  align-items-center justify-content-center">
                          <Skeleton borderRadius={"10px"} height={110} width={100} />
                        </div>
                      ) : (
                        <>
                          <div class="wpw-content-head-icon d-flex  align-items-center justify-content-center">
                            {userProfile?.profilePicture?.original ? (
                              <img
                                src={
                                  userProfile?.profilePicture?.original
                                    ? process.env.REACT_APP_MEDIA + userProfile?.profilePicture?.original
                                    : ""
                                }
                                alt="profile w img"
                              />
                            ) : (
                              <ImageHolder image={null} name={userProfile?.firstName?.slice(0, 1)} />
                            )}
                          </div>
                          <div class="col-dropdown-menu mt-1 user-dropdown">
                            <Dropdown className="dropdown">
                              <Dropdown.Toggle
                                className="d-flex align-items-center"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#fff",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  border: "none",
                                  paddingLeft: "10px",
                                }}
                                id="dropdown-basic"
                              >
                                <div className="dropdown-toggle-inside d-flex align-items-center">
                                  <div className="dl-icon d-flex flex-wrap align-items-center justify-content-center">
                                    {userProfile?.isPrivate ? (
                                      <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M12.6667 7.83337H3.33333C2.59695 7.83337 2 8.43033 2 9.16671V13.8334C2 14.5698 2.59695 15.1667 3.33333 15.1667H12.6667C13.403 15.1667 14 14.5698 14 13.8334V9.16671C14 8.43033 13.403 7.83337 12.6667 7.83337Z"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M4.66699 7.83337V5.16671C4.66699 4.28265 5.01818 3.43481 5.6433 2.80968C6.26842 2.18456 7.11627 1.83337 8.00033 1.83337C8.88438 1.83337 9.73223 2.18456 10.3573 2.80968C10.9825 3.43481 11.3337 4.28265 11.3337 5.16671V7.83337"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.99967 15.1667C11.6816 15.1667 14.6663 12.1819 14.6663 8.50004C14.6663 4.81814 11.6816 1.83337 7.99967 1.83337C4.31778 1.83337 1.33301 4.81814 1.33301 8.50004C1.33301 12.1819 4.31778 15.1667 7.99967 15.1667Z"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M1.33301 8.5H14.6663"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.99967 1.83337C9.66719 3.65894 10.6148 6.02806 10.6663 8.50004C10.6148 10.972 9.66719 13.3411 7.99967 15.1667C6.33215 13.3411 5.38451 10.972 5.33301 8.50004C5.38451 6.02806 6.33215 3.65894 7.99967 1.83337Z"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )}
                                  </div>
                                  <div className="dl-text" style={{ color: "#47ad1d" }}>
                                    {userProfile?.isPrivate ? "Private" : "Public"}
                                  </div>
                                  <div className="chevron-down-wrap d-flex align-items-center justify-content-center">
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4 6L8 10L12 6"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu min-w-auto">
                                <Dropdown.Item
                                  className="dropdown-item"
                                  onClick={() => {
                                    if (userProfile?.isPrivate) {
                                      handleProfile();
                                    }
                                  }}
                                  eventKey={"1"}
                                >
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-link w-100 h-100 d-flex flex-wrap align-items-center"
                                  >
                                    <div className="dl-icon d-flex flex-wrap align-items-center justify-content-center">
                                      <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.99967 15.1667C11.6816 15.1667 14.6663 12.1819 14.6663 8.50004C14.6663 4.81814 11.6816 1.83337 7.99967 1.83337C4.31778 1.83337 1.33301 4.81814 1.33301 8.50004C1.33301 12.1819 4.31778 15.1667 7.99967 15.1667Z"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M1.33301 8.5H14.6663"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.99967 1.83337C9.66719 3.65894 10.6148 6.02806 10.6663 8.50004C10.6148 10.972 9.66719 13.3411 7.99967 15.1667C6.33215 13.3411 5.38451 10.972 5.33301 8.50004C5.38451 6.02806 6.33215 3.65894 7.99967 1.83337Z"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div className="dl-text" style={{ color: "#47ad1d" }}>
                                      Public
                                    </div>
                                  </a>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="dropdown-item"
                                  onClick={() => {
                                    if (!userProfile?.isPrivate) {
                                      handleProfile();
                                    }
                                  }}
                                  eventKey={"2"}
                                >
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-link w-100 h-100 d-flex flex-wrap align-items-center"
                                  >
                                    <div className="dl-icon d-flex flex-wrap align-items-center justify-content-center">
                                      <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M12.6667 7.83337H3.33333C2.59695 7.83337 2 8.43033 2 9.16671V13.8334C2 14.5698 2.59695 15.1667 3.33333 15.1667H12.6667C13.403 15.1667 14 14.5698 14 13.8334V9.16671C14 8.43033 13.403 7.83337 12.6667 7.83337Z"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M4.66699 7.83337V5.16671C4.66699 4.28265 5.01818 3.43481 5.6433 2.80968C6.26842 2.18456 7.11627 1.83337 8.00033 1.83337C8.88438 1.83337 9.73223 2.18456 10.3573 2.80968C10.9825 3.43481 11.3337 4.28265 11.3337 5.16671V7.83337"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div className="dl-text" style={{ color: "#47ad1d" }}>
                                      Private
                                    </div>
                                  </a>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </>
                      )}
                      {/* {!userProfile ? (
                        <Skeleton borderRadius={"10px"} height={30} width={300} />
                      ) : (
                        <div className="d-flex align-items-center">
                          <div className="wpw-content-head-btn mr-3">
                            <Link className="btn btn-default btn-block" to="/edit-profile">
                              Edit Profile
                            </Link>
                          </div>
                        </div>
                      )} */}
                    </div>
                    <div class="wpw-content-body position-relative">
                      <div class="w-body-content">
                        {!userProfile ? (
                          <div className="loading-skeleton">
                            <Skeleton borderRadius={"10px"} height={30} width={200} />
                          </div>
                        ) : (
                          <div className="d-flex align-items-center justify-content-between">
                            <h4 className="caps-text">
                              {userProfile?.firstName} {userProfile?.lastName}
                            </h4>
                            <Link className="btn btn-link mb-3 fw-normal" to="/edit-profile">
                              <u>Edit Profile</u>
                            </Link>
                          </div>
                        )}
                        <div className="d-block d-md-flex justify-content-between">
                          <div>
                            <div class="w-page-link-list ">
                              {!userProfile ? (
                                <div class="w-page-link-item">
                                  <div className="loading-skeleton">
                                    <Skeleton borderRadius={"10px"} height={20} width={100} />
                                  </div>
                                </div>
                              ) : (
                                userProfile?.location && (
                                  <div class="w-page-link-item">
                                    <div className="w-page-link-box w-100 h-100 d-flex flex-wrap align-items-center">
                                      <div className="w-page-link-icon">
                                        <img src={location} alt="w page link" />
                                      </div>
                                      <div className="w-page-link text-capitalize">
                                        {userProfile?.location?.stateName && userProfile?.location?.countryName
                                          ? `${userProfile.location.stateName}, ${userProfile.location.countryName}`
                                          : userProfile?.location?.countryName || ""}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              {!userProfile ? (
                                <div class="w-page-link-item">
                                  <div className="loading-skeleton">
                                    <Skeleton borderRadius={"10px"} height={20} width={100} />
                                  </div>
                                </div>
                              ) : (
                                userProfile?.email && (
                                  <div class="w-page-link-item">
                                    <div class="w-page-link-box w-100 h-100 d-flex flex-wrap align-items-center">
                                      <div class="w-page-link-icon">
                                        <img src={emailsvg} alt="w page link" />
                                      </div>
                                      <div class="w-page-link">
                                        <a href="javascript:void(0);" target="_blank">
                                          {userProfile && userProfile?.email}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              {!userProfile ? (
                                <div class="w-page-link-item">
                                  <div className="loading-skeleton">
                                    <Skeleton borderRadius={"10px"} height={20} width={100} />
                                  </div>
                                </div>
                              ) : (
                                userProfile?.organizationName && (
                                  <div class="w-page-link-item">
                                    <div class="w-page-link-box w-100 h-100 d-flex flex-wrap align-items-center">
                                      <div class="w-page-link-icon">
                                        <img src={organizationSvg} alt="w page link" />
                                      </div>
                                      <div class="w-page-link text-capitalize">
                                        {userProfile && userProfile?.organizationName
                                          ? userProfile?.organizationName
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              {!userProfile ? (
                                <div class="w-page-link-item">
                                  <div className="loading-skeleton">
                                    <Skeleton borderRadius={"10px"} height={20} width={100} />
                                  </div>
                                </div>
                              ) : (
                                userProfile?.website && (
                                  <div class="w-page-link-item">
                                    <div class="w-page-link-box w-100 h-100 d-flex flex-wrap align-items-center">
                                      <div class="w-page-link-icon">
                                        <img src={link} alt="w page link" />
                                      </div>
                                      <div class="w-page-link">
                                        <a href="javascript:void(0);" target="_blank">
                                          {userProfile && userProfile?.website}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            <div class="ww-text-list d-flex flex-wrap align-items-center">
                              <div class="ww-text-item">
                                <div class="ww-text-box">
                                  <span>{userProfile?.followingCount}</span>
                                  <Link to={`${userProfile?.slug}/followers`}>Following</Link>
                                </div>
                              </div>
                              <div class="ww-text-item">
                                <div class="ww-text-box">
                                  <span>{userProfile?.followerCount}</span>
                                  <Link to={`${userProfile?.slug}/followers`}>Followers</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          {userProfile && (
                            <div style={isMobile ? {} : { width: "35%" }}>
                              <div class="wpw-content-head-btn mt-2">
                                <Link
                                  to={{ pathname: "/addsponsor", state: "profile" }}
                                  class="btn btn-outline-green h-40 pt-10 w-100"
                                >
                                  Sponsor
                                </Link>
                              </div>
                              <div class="wpw-content-head-btn mt-2">
                                <Link
                                  to={{ pathname: "/story", state: "profile" }}
                                  class="btn btn-dark-green w-100 h-40 pt-10"
                                >
                                  Create A Story
                                </Link>
                              </div>
                              <div className="wpw-content-head-btn mt-2">
                                <Link
                                  to={profile?.profile ? "/add-post" : "/login"}
                                  className="btn btn-default h-40 pt-10 w-100"
                                >
                                  Add Post/Media
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="sustainability-container mt-3">
                          {!userProfile ? (
                            <div className="w-page-link-item">
                              <div className="loading-skeleton">
                                <Skeleton borderRadius={"10px"} height={20} width={100} />
                              </div>
                            </div>
                          ) : (
                            <>
                              {(() => {
                                const profileData = profile?.profile;
                                const isCorporateComplete =
                                  profileData?.corporateSponsoredSpecies?.length > 0 ||
                                  profileData?.individualSponsoredSpecies?.length > 0;
                                const isBothSponsor =
                                  profileData?.corporateSponsoredSpecies?.length > 0 &&
                                  profileData?.individualSponsoredSpecies?.length > 0;
                                if (isCorporateComplete) {
                                  return (
                                    <div className="w-page-link-item p-0">
                                      <div className="w-page-link-box w-100 h-100 d-flex align-items-center">
                                        <div className="w-page-link-icon" style={{ width: "30px" }}>
                                          <img src={sustainabilityIcon} alt="w page link" />
                                        </div>
                                        <div className="w-page-link w-auto">
                                          Update content on your sustainability page from{" "}
                                          <Link
                                            className="btn-link edit-btn-link fw-normal"
                                            to="/sustainability-details"
                                          >
                                            here
                                          </Link>
                                          .
                                        </div>
                                        {profileData?.corporateSponsoredSpecies?.length > 0 && (
                                          <a
                                            href={`/sustainability/${profileData?.slug}?isCompany=corporate`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-default"
                                          >
                                            {isBothSponsor ? "View Corporate Page" : "View Sustainability Page"}
                                          </a>
                                        )}
                                        {profileData?.individualSponsoredSpecies?.length > 0 && (
                                          <a
                                            href={`/sustainability/${profileData?.slug}?isCompany=individual`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-default"
                                          >
                                            {isBothSponsor ? "View Individual Page" : "View Sustainability Page"}
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className="w-page-link-item">
                                      <div className="w-page-link-box w-100 h-100 d-flex align-items-center ">
                                        <div className="w-page-link-icon" style={{ width: "30px" }}>
                                          <img src={sustainabilityIcon} alt="w page link" />
                                        </div>
                                        <div className="w-page-link w-auto">
                                          Sponsor species and get a sustainability page for yourself or your
                                          organisation.
                                        </div>
                                        <a
                                          href={`/sustainability/${profileData?.slug}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn btn-default"
                                        >
                                          Sample Sustainability Page
                                        </a>
                                      </div>
                                    </div>
                                  );
                                }
                              })()}
                            </>
                          )}
                        </div>
                      </div>
                      <div class="profile-page-link d-flex flex-wrap align-items-center justify-content-between">
                        <div class={`p-p-item ${tab == "post" ? "active" : ""}`}>
                          <a href="javascript:void(0);" class="p-p-box" onClick={() => setTab("post")}>
                            Posts
                          </a>
                        </div>
                        <div class={`p-p-item ${tab == "media" ? "active" : ""}`}>
                          <a href="javascript:void(0);" class="p-p-box" onClick={() => setTab("media")}>
                            Media
                          </a>
                        </div>
                        <div class={`p-p-item ${tab == "sponsor" ? "active" : ""}`}>
                          <a href="javascript:void(0);" class="p-p-box" onClick={() => setTab("sponsor")}>
                            Species Sponsored
                          </a>
                        </div>
                        <div class={`p-p-item ${tab == "transactions" ? "active" : ""}`}>
                          <a href="javascript:void(0);" class="p-p-box" onClick={() => setTab("transactions")}>
                            Transactions
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(tab == "post" || tab == "like") && (
              <Feeds
                myPostData={tab == "like" ? likeData : myPostData}
                userProfile={userProfile}
                deleteHandler={deleteHandler}
                likedPosts={tab}
                page="profile"
              />
            )}
            {tab == "media" && <Media mediaData={mediaData} loading={loadingMedia} />}
            {tab == "sponsor" && <SponsoredSpecies mediaData={null} loading={loadingLike} />}
            {tab == "transactions" && <Transaction mediaData={null} loading={loadingLike} />}
          </div>
        </div>
        <CorporateSponsor
          show={showSponsorAlert}
          onHide={() => {
            setSponsorAlert(!showSponsorAlert);
          }}
          loading={loadingMain}
          page="profile"
        />
        <SponsorGreetings
          show={showGreeting}
          onHide={() => {
            setShowGreeting(!showGreeting);
          }}
          data="Thank you for sponsoring this species! Your generous contribution helps protect and preserve endangered species.
                We truly appreciate your support!"
          loading={loadingMain}
        />
        <SponsorGreetings
          show={showStoryGreeting}
          onHide={() => {
            setShowStoryGreeting(!showStoryGreeting);
          }}
          data="Thank you for supporting a story that highlights this species! Your contribution is essential in protecting and preserving endangered species, and we deeply appreciate your help in sharing their stories."
          loading={loadingMain}
        />
      </ProfileLayout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMyPostData: (params, callback) => dispatch(getMyPostData(params, callback)),
  getUsersPreferences: (params, callback) => dispatch(getUsersPreferences(params, callback)),
  deleteMyPost: (params, callback) => dispatch(deleteMyPost(params, callback)),
  getMedia: (callback) => dispatch(getMedia(callback)),
  getLiked: (callback) => dispatch(getLiked(callback)),
  updateYourProfile: (data, callback) => dispatch(updateYourProfile(data, callback)),
  getProfileInfo: (callback) => dispatch(getProfileInfo(callback)),
  getSponsor: (params, callback) => dispatch(getSponsorUser(params, callback)),
  getstoryUser: (param, callback) => {
    dispatch(getstoryUser(param, callback));
  },
  getTransaction: (params, callback) => dispatch(getTransaction(params, callback)),
  updateTransaction: (params, callback) => dispatch(updateTransaction(params, callback)),
  updateTransactionStory: (params, callback) => dispatch(updateTransactionStory(params, callback)),
  updateTransactionStripe: (params, callback) => dispatch(updateTransactionStripe(params, callback)),
  updateTransactionStoryStripe: (params, callback) => dispatch(updateTransactionStoryStripe(params, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
  getPosts: getPosts(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserProfile));
