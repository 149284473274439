import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import close from "../../../include/images/close.svg";
import successIcon from "../../../include/images/check-icon.ec9704e601f898137e1324568528cfa2.svg";
import close1 from "../../../include/images/x-close-24x24.svg";

//Delete Modal for Post which Reason Textarea
const SponsorGreetings = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup "
      show={props.show}
      onHide={() => props.onHide(null)}
      enforceFocus={true}
      dialogClassName="modal-400w"
      backdropClassName={"delete-backdrop"}
      centered
    >
      <div
        className="w-100 ms-auto d-block d-lg-none d-flex align-items-center justify-content-end "
        onClick={() => props.onHide(null)}
      >
        <img src={close} alt="" className="custom-close-btn cursor-pointer" />
      </div>
      <div
        className="signup-modal-close ms-auto d-lg-block d-sm-none"
        style={{ position: "absolute", right: "-40px", top: "0px" }}
        data-bs-dismiss="modal"
        onClick={props.onHide}
      >
        <img src={close1} alt="x close icon" />
      </div>
      <div className="w-100 d-flex align-items-center justify-content-center custom-success-icon">
        <img src={successIcon} alt="" />
      </div>
      <Modal.Body>
        <div className="fw-medium">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-content-holder pt-4 mx-auto">
                  {/* <div className="login-head-row text-center">
                    <h1>Thank You!</h1>
                  </div> */}
                  <div className="form-group register-user-form fw-normal text-center w-100">
                    <h6 className="fw-normal">{props.data}</h6>
                  </div>

                  {/* <div className="login-form-btn d-flex align-items-center justify-content-center">
                    <Link className="btn-link" onClick={() => props.onHide(null)}>
                      Back to Species Page
                    </Link>
                    <div className="btn-link">
                      <Link to="/">Back to Home</Link>
                    </div> 
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SponsorGreetings;
