import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"; // Correct location import
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { updateYourProfile } from "../../store/users"; // Assuming the action exists
import { getSpeciesDetails } from "../../store/species";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import defaultImage from "../../include/images/default_image_species.svg";
import { createsponsor, createStripeSponsor, getSubscription } from "../../store/subscription";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Auth, Storage } from "aws-amplify";
import LoadingBar from "react-top-loading-bar";
import SpeciesAndCategoryEditModal from "../../components/common/feedEditModals/speciesAndCategoryEditModal";
import Sponsor from "../../components/speciesDetailsPages/sponsor";
import SponsorView from "./sponsorView";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getProfile } from "../../utils/localStorageServices";
import SponsorSpeciesModal from "../../components/common/subscription/sponsorSpeciesModal";
import { getSpeciesList, sideBarApisListings } from "../../store/sidebarApis";
import SponsorsList from "./sponsorsList";
import PaymentModal from "../../components/common/subscription/paymentModal";
import paypalIcon from "../../include/images/paypal.svg";
import stripeIcon from "../../include/images/stripeIcon.svg";
import sampleImg1 from "../../include/images/page-example-img1.jpg";
import sampleImg2 from "../../include/images/page-example-img2.jpg";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const AddSponsor = (props) => {
  const location = useLocation(); // To access location.state
  const sampleSustainability = "/aviation-AI.pdf";
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    logo: null,
    sponsorType: "individual",
  });
  const ref = useRef();
  const [id, setId] = useState(undefined);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [follow, setFollow] = useState(false);
  const [method, setMethod] = useState(null);
  const [speciesData, setSpeciesData] = useState(null);
  const [speciesCatData, setSpeciesCatData] = useState(null);
  const [displayName, setDisPlayName] = useState("");
  const [tempSelectSpeciesName, setTempSelectSpeciesName] = useState([]);
  const [showSpeciesAndCategories, setshowSpeciesAndCategories] = useState(false);
  const [tempSelectSpeciesCategory, setTempSelectSpeciesCategory] = useState([]);
  const [tempSelectSpecies, setTempSelectSpecies] = useState([]);

  const [tempSelectSpeciesName1, setTempSelectSpeciesName1] = useState([]);
  const [showSpeciesAndCategories1, setshowSpeciesAndCategories1] = useState(false);
  const [tempSelectSpeciesCategory1, setTempSelectSpeciesCategory1] = useState([]);
  const [tempSelectSpecies1, setTempSelectSpecies1] = useState([]);

  const [tempSelectSpeciesName2, setTempSelectSpeciesName2] = useState([]);
  const [showSpeciesAndCategories2, setshowSpeciesAndCategories2] = useState(false);
  const [tempSelectSpeciesCategory2, setTempSelectSpeciesCategory2] = useState([]);
  const [tempSelectSpecies2, setTempSelectSpecies2] = useState([]);

  const [tempSelectSpeciesName3, setTempSelectSpeciesName3] = useState([]);
  const [showSpeciesAndCategories3, setshowSpeciesAndCategories3] = useState(false);
  const [tempSelectSpeciesCategory3, setTempSelectSpeciesCategory3] = useState([]);
  const [tempSelectSpecies3, setTempSelectSpecies3] = useState([]);
  const [showPaymentModal, setshowPaymentModal] = useState(false);

  const profile = getProfile();
  const speciesDropdownDataList = props?.sideBarApisListings?.speciesList?.data;

  const pathSegments = window.location.pathname.split("/");
  const isSponsorWithContent = pathSegments[1] === "addsponsor" && pathSegments[2]?.trim();
  useEffect(() => {
    if (location?.state?.id) {
      setId(location?.state?.id);
      const speciesId = location.state.id;
      fetchSpeciesData(speciesId);
    } else {
      setLoadingDetail(true);
      props.getSpeciesDetails(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status === 200) {
          setLoadingDetail(false);
          ref?.current?.complete();
          setId(res?.data?.data?._id);
          setSpeciesData(res?.data?.data);
        }
      });
    }
  }, [location]);

  const fetchSpeciesData = (speciesId) => {
    setLoadingDetail(true);
    ref?.current?.continuousStart();
    props.getSpeciesDetails(speciesId, (res) => {
      if (res && res.status === 200) {
        setLoadingDetail(false);
        ref?.current?.complete();
        setSpeciesData(res?.data?.data);
      }
    });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file (logo) upload
  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, logo: e.target.files[0] }));
  };
  const handleSponsorTypeChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      sponsorType: value,
    }));
  };
  const toggleSpeciesAndCategories = () => {
    setshowSpeciesAndCategories(!showSpeciesAndCategories);
  };
  const toggleSpeciesAndCategories1 = () => {
    setshowSpeciesAndCategories1(!showSpeciesAndCategories1);
  };
  const toggleSpeciesAndCategories2 = () => {
    setshowSpeciesAndCategories2(!showSpeciesAndCategories2);
  };
  const toggleSpeciesAndCategories3 = () => {
    setshowSpeciesAndCategories3(!showSpeciesAndCategories3);
  };
  const handleSubmit = (type) => {
    setLoadingDetail(true);
    togglePaymentModal();
    ref?.current?.continuousStart();
    if (method === "corporate" && id) {
      const speciesCounts = [tempSelectSpecies1?.length];
      if (speciesCounts.some((count) => count > 1)) {
        ref?.current?.complete();
        toast(<AlertError message="Each species dropdown should contain only one selected species." />);
        return;
      }
    } else if (
      method === "individual" &&
      id === undefined &&
      (!tempSelectSpecies1 || tempSelectSpecies1.length !== 1)
    ) {
      ref?.current?.complete();
      toast(<AlertError message="Individual sponsors must select exactly one species." />);
      return;
    }

    if (location?.state?.id || isSponsorWithContent) {
      const species = [tempSelectSpecies1[0]].filter(Boolean);
      if (species.length > 0) {
        sessionStorage.setItem("tempSelectSpecies", JSON.stringify(species));
      }
    } else {
      if (method === "corporate") {
        const species = [tempSelectSpecies2[0]].filter(Boolean);
        if (species.length > 0) {
          sessionStorage.setItem("tempSelectSpecies", JSON.stringify(species));
        }
      } else {
        if (tempSelectSpecies1.length > 0) {
          const species = tempSelectSpecies1.filter(Boolean);
          if (species.length > 0) {
            sessionStorage.setItem("tempSelectSpecies", JSON.stringify(species));
          }
        }
      }
    }

    const species = id ? [id] : JSON.parse(sessionStorage.getItem("tempSelectSpecies")) || [];
    let payload;
    if (type == "paypal") {
      payload = {
        speciesId:
          method === "corporate" && id
            ? [id].filter(Boolean)
            : method === "corporate" && !id
            ? [tempSelectSpecies2[0]].filter(Boolean)
            : method === "individual" && !id
            ? tempSelectSpecies1
            : [id],
        isCompany: method === "corporate",
        return_url: id
          ? `${window.location.origin}/endangered-species/${speciesData?.slug}?type=${method}`
          : `${window.location.origin}/profile?type=${method}`,
        cancel_url: id
          ? `${window.location.origin}/endangered-species/${speciesData?.slug}?type=${method}&cancel=true`
          : `${window.location.origin}/profile?type=${method}&cancel=true`,
      };
    } else {
      payload = {
        speciesId:
          method === "corporate" && id
            ? [id].filter(Boolean)
            : method === "corporate" && !id
            ? [tempSelectSpecies2[0]].filter(Boolean)
            : method === "individual" && !id
            ? tempSelectSpecies1
            : [id],
        isCompany: method === "corporate",
        return_url: id
          ? `${window.location.origin}/endangered-species/${speciesData?.slug}?type=${method}&pay=stripe`
          : `${window.location.origin}/profile?type=${method}&pay=stripe`,
        cancel_url: id
          ? `${window.location.origin}/endangered-species/${speciesData?.slug}?type=${method}&cancel=true&pay=stripe`
          : `${window.location.origin}/profile?type=${method}&cancel=true&pay=stripe`,
      };
    }

    if (method == "corporate" && formData?.description) {
      payload.description = formData.description;
    }
    if (type == "paypal") {
      props
        .createsponsor(payload, (res) => {
          if (res && res.status === 200) {
            setLoadingDetail(false);
            const redirectUrl = res.data.data?.url;
            if (redirectUrl) {
              window.location.href = redirectUrl;
            } else {
              toast(<AlertError message="No redirection URL provided" />);
            }
          } else {
            setLoadingDetail(false);
            toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
          }
        })
        .catch((err) => {
          setLoadingDetail(false);
          toast(<AlertError message="Something Went Wrong" />);
        });
    } else {
      props
        .createStripeSponsor(payload, (res) => {
          if (res && res.status === 200) {
            setLoadingDetail(false);
            const redirectUrl = res.data.data?.session?.url;
            const sessionId = res.data.data?.session?.id;
            sessionStorage.setItem("session_id", JSON.stringify(sessionId));
            if (redirectUrl) {
              window.location.href = redirectUrl;
            } else {
              toast(<AlertError message="No redirection URL provided" />);
            }
          } else {
            setLoadingDetail(false);
            toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
          }
        })
        .catch((err) => {
          setLoadingDetail(false);
          toast(<AlertError message="Something Went Wrong" />);
        });
    }
  };

  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  const handleImageOpen = (e) => {
    e.preventDefault();
    window.open(sampleSustainability, "_blank", "noopener,noreferrer");
  };
  const togglePaymentModal = () => {
    setshowPaymentModal(!showPaymentModal);
  };
  return (
    <PageLayout>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <div class="content-container be-sponcer-container">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <div class="panel-head mb-0">
                {(location?.state?.id || isSponsorWithContent) && (
                  <div class="breadcrumb-row d-flex flex-wrap">
                    <div class="breadcrumb-box">
                      <Link to="/endangered-species">Endangered Species</Link>
                    </div>
                    <Link to={`/endangered-species/${speciesData?.slug}`} class="breadcrumb-box text-capitalize">
                      {speciesData?.displayCommonName || speciesData?.scientificName}
                    </Link>
                  </div>
                )}
                {!isSponsorWithContent && (
                  <div class="breadcrumb-row d-flex flex-wrap">
                    <div class="breadcrumb-box text-capitalize">
                      {location.state == "profile" ? (
                        <Link to="/profile">{location.state}</Link>
                      ) : location.state == "Environmental Press Corp" ? (
                        <Link to="/environmental-press-corp">{location.state}</Link>
                      ) : (
                        <Link to="/">Home</Link>
                      )}
                    </div>
                    <div class="breadcrumb-box">Add Sponsor</div>
                  </div>
                )}
              </div>
              <div class="be-sponcer-content pe-0 pe-lg-5">
                {(location?.state?.id || isSponsorWithContent) && (
                  <div className="be-sponcer-detail d-flex">
                    <div className="bsd-left">
                      <div class="bsd-left-img">
                        {speciesData?.coverImage?.original ? (
                          <img src={process.env.REACT_APP_MEDIA + speciesData?.coverImage?.original} alt="" />
                        ) : (
                          <img src={getDefaultImage(speciesData?.speciesCategoryName)} alt="" />
                        )}
                      </div>
                    </div>
                    <div class="bsd-center">
                      <div class="bsd-text text-capitalize">
                        <h6>{speciesData?.dangerLevelName}</h6>
                        <h4 className="text-capitalize">
                          {speciesData?.displayCommonName || speciesData?.scientificName}
                        </h4>
                      </div>
                      <ul className="bsd-origin-list d-flex flex-wrap">
                        {speciesData?.regions.slice(0, 3).map((region, index) => (
                          <li key={index}>
                            <div class="bsd-origin-box">
                              {region.countryName === "United States" && region.state
                                ? `${region.state}, ${region.countryName}`
                                : region.countryName}
                              {index < Math.min(speciesData?.regions.length, 3) - 1 ? ", " : ""}
                            </div>
                          </li>
                        ))}
                        {speciesData?.regions.length > 3 && (
                          <div className="bsd-origin-box">+{speciesData.regions.length - 3}</div>
                        )}
                      </ul>
                    </div>
                    <div class="bsd-right ms-auto">
                      <div class="bsd-right-img">
                        <img src={process.env.REACT_APP_MEDIA + speciesData?.dangerLevelImage?.small} alt=""></img>
                      </div>
                    </div>
                  </div>
                )}
                <div class="be-sponcer-header">
                  <h4>Sponsor an Endangered Species: Make a Difference Today</h4>
                  <p>
                    Join out mission to protect endangered species by becoming a sponsor. Your Sponsorship directly
                    contributes to vital conservation efforts, helping to preserve habitats, protect animals from
                    poaching, and support research and breeding programs. ?By sponsoring a species, you’ll play a key in
                    Ensuring the survival of some of the planet’s most vulnerable wildlife.
                  </p>
                </div>
                <div class="be-sponcer-body">
                  <h4>Why Sponsor?</h4>
                  <ul class="bsb-list">
                    <li class="psb-item">
                      <div class="bsb-box">
                        <h5>Conservation Impact:</h5>
                        <p>Your sponsorship helps fund essential projects aimed at protecting endangered species.</p>
                      </div>
                    </li>
                    <li class="psb-item">
                      <div class="bsb-box">
                        <h5>Personal Connection:</h5>
                        <p>Receive regular updates, photos, and stories about the species you sponsor.</p>
                      </div>
                    </li>
                    <li class="psb-item">
                      <div class="bsb-box">
                        <h5>Support Research:</h5>
                        <p>
                          Enable critical studies that aim to understand and combat the threat facing endangered
                          species.
                        </p>
                      </div>
                    </li>
                    <li class="psb-item">
                      <div class="bsb-box">
                        <h5>Cost is $150 per month per animal</h5>
                        <p>
                          This will give you a dedicated sustainability page where we can give you monthly reports on
                          the progress of your animals conservation efforts.
                        </p>
                      </div>
                    </li>
                    <li class="psb-item">
                      <div class="bsb-box">
                        <h5>Together, We Can Project Wildlife</h5>
                        <p>
                          Your support can help create a future where endangered species thrive in their natural
                          habitats. Take the first step today by sponsoring an endangered species and be part of the
                          global effort to preserve biodiversity for generations to come
                        </p>
                      </div>
                    </li>
                  </ul>
                  <div class="bsb-btn">
                    <Link
                      to={{
                        pathname: "/sustainability-details",
                        state: {
                          data: location?.state?.id
                            ? {
                                _id: speciesData?._id,
                                displayCommonName: speciesData?.displayCommonName,
                                coverImage: speciesData?.coverImage,
                                speciesCategoryName: speciesData?.speciesCategoryName,
                              }
                            : {
                                _id: tempSelectSpeciesName2[0]?._id,
                                displayCommonName: tempSelectSpeciesName2[0]?.displayCommonName,
                                coverImage: tempSelectSpeciesName2[0]?.coverImage,
                                speciesCategoryName: tempSelectSpeciesName2[0]?.speciesCategoryName,
                              },
                        },
                      }}
                      class={`btn btn-default h-40 pt-10 ${
                        tempSelectSpecies2.length > 0 || location?.state?.id ? "" : "disabled"
                      }`}
                    >
                      Build Your Own Sustainabillity Page
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-lg-4">
              <div class="sponcer-species-select">
                <div class="sss-upper">
                  <h5>SPONSOR NOW</h5>
                  <Link
                    to={{
                      pathname: "/sustainability-details",
                      state: {
                        data: location?.state?.id
                          ? {
                              _id: speciesData?._id,
                              displayCommonName: speciesData?.displayCommonName,
                              coverImage: speciesData?.coverImage,
                              speciesCategoryName: speciesData?.speciesCategoryName,
                            }
                          : {
                              _id: tempSelectSpeciesName2[0]?._id,
                              displayCommonName: tempSelectSpeciesName2[0]?.displayCommonName,
                              coverImage: tempSelectSpeciesName2[0]?.coverImage,
                              speciesCategoryName: tempSelectSpeciesName2[0]?.speciesCategoryName,
                            },
                      },
                    }}
                    class={`btn btn-default ${tempSelectSpecies2.length > 0 || location?.state?.id ? "" : "disabled"}`}
                  >
                    Next
                  </Link>
                </div>
                <div class="sss-lower">
                  <form class="rf-form-row-group">
                    {location?.state?.id || isSponsorWithContent ? (
                      <div class="form-floating custom-form-floating has-select-button">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={speciesData?.displayCommonName || displayName}
                          readOnly
                        />
                        <button type="button" class="select-button-box">
                          Selected
                        </button>
                      </div>
                    ) : (
                      <div class="form-floating custom-form-floating has-select-button">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={
                            tempSelectSpeciesName2.length > 0
                              ? tempSelectSpeciesName2[0]?.displayCommonName
                              : "Select Species 1"
                          }
                          readOnly
                          onClick={() => {
                            toggleSpeciesAndCategories2();
                          }}
                        />
                        {tempSelectSpeciesName2?.length > 0 ? (
                          <button
                            type="button"
                            className="select-button-box"
                            onClick={() => {
                              toggleSpeciesAndCategories2();
                            }}
                          >
                            Change
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="select-button-box"
                            onClick={() => toggleSpeciesAndCategories2()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div class="sponcer-page-example-content">
                <div class="spec-img">
                  <img src={sampleImg1} alt="" />
                </div>
                <div class="spec-btn1">
                  <a
                    href="/sample-sustainability-page"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-default w-100 h-40 pt-10"
                  >
                    Corporate Example
                  </a>
                </div>
              </div>
              <div class="sponcer-page-example-content">
                <div class="spec-img">
                  <img src={sampleImg2} alt="" />
                </div>
                <div class="spec-btn1">
                  <a
                    href="/sample-individual-sustainability-page"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-default w-100 h-40 pt-10"
                  >
                    Individual Example
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SponsorSpeciesModal
          show={showSpeciesAndCategories}
          onHide={toggleSpeciesAndCategories}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory}
          tempSelectSpecies={tempSelectSpecies}
          setTempSelectSpecies={setTempSelectSpecies}
          tempSelectSpeciesName={tempSelectSpeciesName}
          setTempSelectSpeciesName={setTempSelectSpeciesName}
          isPost={true}
          postType={props?.postType}
        />
        <SponsorSpeciesModal
          show={showSpeciesAndCategories1}
          onHide={toggleSpeciesAndCategories1}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory1}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory1}
          tempSelectSpecies={tempSelectSpecies1}
          setTempSelectSpecies={setTempSelectSpecies1}
          tempSelectSpeciesName={tempSelectSpeciesName1}
          setTempSelectSpeciesName={setTempSelectSpeciesName1}
          isPost={true}
          postType={props?.postType}
        />
        <SponsorSpeciesModal
          show={showSpeciesAndCategories2}
          onHide={toggleSpeciesAndCategories2}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory2}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory2}
          tempSelectSpecies={tempSelectSpecies2}
          setTempSelectSpecies={setTempSelectSpecies2}
          tempSelectSpeciesName={tempSelectSpeciesName2}
          setTempSelectSpeciesName={setTempSelectSpeciesName2}
          isPost={true}
          postType={props?.postType}
        />
        <SponsorSpeciesModal
          show={showSpeciesAndCategories3}
          onHide={toggleSpeciesAndCategories3}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory3}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory3}
          tempSelectSpecies={tempSelectSpecies3}
          setTempSelectSpecies={setTempSelectSpecies3}
          tempSelectSpeciesName={tempSelectSpeciesName3}
          setTempSelectSpeciesName={setTempSelectSpeciesName3}
          isPost={true}
          postType={props?.postType}
        />
      </div>
      {/* {(location?.state?.id || isSponsorWithContent) && <SponsorView id={id} section="species" />} */}
      <SponsorsList id={id} section="species" />
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
  getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
  createsponsor: (data, callback) => {
    dispatch(createsponsor(data, callback));
  },
  createStripeSponsor: (data, callback) => {
    dispatch(createStripeSponsor(data, callback));
  },
  getSpeciesList: (params, callback) => {
    dispatch(getSpeciesList(params, callback));
  },
});
const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
  sideBarApisListings: sideBarApisListings(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AddSponsor));
