import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import searchClose from "../../../include/images/x-close-24x24.svg";
import { useRef } from "react";
import { connect } from "react-redux";
import { postReports, sendCommentDM, sendPostDM } from "../../../store/post";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import { getUser, getUsersDropdownData } from "../../../store/users";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import { getProfile } from "../../../utils/localStorageServices";
import close1 from "../../../include/images/close-24x24.svg";
import close from "../../../include/images/x-close-24x24.svg";
import ImageHolder from "../../../common/customStyles/imageHolder";
import noRecord from "../../../include/images/nrb-img.svg";
import searchIcon from "../../../include/images/searchIcon.svg";
const ChatUsers = (props) => {
  var ref = useRef(null);
  const [textSearched, setTextSearched] = useState();
  const [userDropdown, setUserDropdown] = useState(null);
  const [sentUsers, setSentUsers] = useState([]);
  const profile = getProfile();
  let postId = props.selectedItem?._id;
  useEffect(() => {
    if (profile?.profile) {
      if (props?.getUser?.userDropdownData?.data) {
        setUserDropdown(props?.getUser?.userDropdownData?.data);
      } else {
        props.getUsersDropdownData({ keyword: "" }, (res) => {
          if (res && res?.status === 200) {
            setUserDropdown(res?.data?.data);
          } else {
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      }
    }
  }, []);
  useEffect(() => {
    if (textSearched) {
      const payload = {
        keyword: textSearched ? textSearched : "",
      };
      props.getUsersDropdownData(payload, (res) => {
        if (res && res.status === 200) {
          setUserDropdown(res?.data?.data);
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, [textSearched]);
  const handleSendRequest = (userId) => {
    if (!sentUsers.includes(userId)) {
      let payload = {
        users: [userId],
      };
      if (props?.comments) {
        props.sendCommentDM(postId, payload, (res) => {
          if (res && res.status === 200) {
            setSentUsers([...sentUsers, userId]);
          } else {
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        props.sendPostDM(postId, payload, (res) => {
          if (res && res.status === 200) {
            setSentUsers([...sentUsers, userId]);
          } else {
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      }
    } else {
      toast(<AlertError message={"Already Sent!"} />);
    }
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <Modal
        className="modal fade new-chat-modal font-family-poppins"
        id="reportPostModal"
        dialogClassName="modal-dialog modal-dialog-centered"
        contentClassName="modal-content"
        show={props?.show}
        onHide={() => props?.onHide()}
      >
        <div class="new-chat-modal-content position-relative d-flex gap-2">
          <div class="new-chat-modal-left">
            <div class="new-chat-modal-upper">
              <h4>Add New Chat</h4>
              <div class="chat-search-pannel">
                <span class="form-control-feedback">
                  <img src={searchIcon} alt="" />
                </span>
                <input
                  type="text"
                  class="chat-search-input"
                  placeholder="Search"
                  value={textSearched}
                  onChange={(e) => setTextSearched(e.target.value)}
                />
              </div>
              <div class="search-label"></div>
            </div>
            <Modal.Body className="new-chat-modal-lower">
              <div class="search-member-list">
                {userDropdown ? (
                  userDropdown.map((user) => (
                    <div
                      class="search-member-item"
                      onClick={() => {
                        props.setSelectUser(user);
                        props.onHide();
                      }}
                      key={user?._id}
                    >
                      <div class="search-member-box w-100 h-100 d-flex">
                        <div class="search-member-img flex-shrink-0">
                          {user?.profilePicture?.original ? (
                            <img
                              src={
                                user?.profilePicture?.original
                                  ? process.env.REACT_APP_MEDIA + user?.profilePicture?.original
                                  : ""
                              }
                              alt={user?.firstName}
                            />
                          ) : (
                            <ImageHolder image={null} name={user?.firstName?.slice(0, 1)} />
                          )}
                        </div>
                        <div class="search-member-content flex-grow-1 text-capitalize">{`${user?.firstName} ${user?.lastName}`}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="no-records-container d-flex align-items-center justify-content-center mt-4"
                    style={{ height: "400px" }}
                  >
                    <div className="no-records-box text-center">
                      <div className="nrb-image">
                        <img src={noRecord} alt="no records" />
                      </div>
                      <h6>No Users Found</h6>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
          </div>
          <div class="new-chat-modal-right">
            <button onClick={() => props?.onHide()} type="button" class="close-modal" data-bs-dismiss="modal">
              <img src={close} alt="" />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  postReports: (id, parmas, callback) => dispatch(postReports(id, parmas, callback)),
  getUsersDropdownData: (params, callback) => dispatch(getUsersDropdownData(params, callback)),
  sendPostDM: (param, data, callback) => dispatch(sendPostDM(param, data, callback)),
  sendCommentDM: (param, data, callback) => dispatch(sendCommentDM(param, data, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ChatUsers));
