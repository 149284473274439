import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/x-close-24x24.svg";
import Mclose from "../../../include/images/close-24x24.svg";
import { getSpeciesCategoryList, sideBarApisListings, getSpeciesList } from "../../../store/sidebarApis";
import { getUser, updateYourProfile, getUsersPreferences } from "../../../store/users";
import { connect } from "react-redux";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import { useRef } from "react";
import NextButton from "../../../common/form/nextButton";
import paypalIcon from "../../../include/images/paypal.svg";
import stripeIcon from "../../../include/images/stripeIcon.svg";
import { createsponsor, createStripeSponsor } from "../../../store/subscription";

const PaymentModal = (props) => {
  var ref = useRef();
  const isMobile = useCheckMobileScreen();

  const totalPages =
    (props?.sideBarApisListings?.speciesList && props?.sideBarApisListings?.speciesList?.totalCount) || 0;

  const dataPerPage =
    (props?.sideBarApisListings?.speciesList && props?.sideBarApisListings?.speciesList?.recordsPerPage) || 0;

  const noOfPage = Math.ceil(totalPages / dataPerPage) || 0;
  return (
    <Modal
      className={`modal fade signup-modal font-family-poppins `}
      id="speciesModal"
      show={props.show}
      onHide={() => props.onHide()}
      style={{ marginTop: "4%" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="signup-modal-parent d-flex flex-wrap">
            <div className="signup-modal-box">
              <div className="signup-modal-header">
                <div className="smh-insider position-relative d-flex justify-content-between">
                  <div className="modal_close-box d-lg-none ">
                    <div
                      className={`signup-modal-close ms-auto ${isMobile ? "mt-5" : ""}`}
                      data-bs-dismiss="modal"
                      onClick={() => props.onHide()}
                    >
                      <img src={Mclose} alt="x close icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="signup-modal-body pt-0">
                <div className="signup-modal-title fw-medium">Select Payment Method</div>
                <div className="d-blck" style={{width:"300px"}}>
                  {/* PayPal Button */}
                  <div className="payment-btn d-flex align-items-center mb-3 cursor-pointer" onClick={() => props.handleSubmit("paypal")}>
                    <img
                      src={paypalIcon}
                      alt="PayPal Icon"
                      className="me-2"
                      style={{ width: "24px", height: "24px" }}
                    />
                    <div>Pay via PayPal</div>
                  </div>
                  {/* Stripe Button */}
                  <div className="payment-btn d-flex align-items-center cursor-pointer" onClick={() => props.handleSubmit("stripe")}>
                    <img
                      src={stripeIcon}
                      alt="Stripe Icon"
                      className="me-2"
                      style={{ width: "24px", height: "24px" }}
                    />
                    <div>Pay via Stripe</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="signup-modal-close-box d-none d-lg-block">
              <div className="signup-modal-close ms-auto" data-bs-dismiss="modal" onClick={() => props.onHide()}>
                <img src={close} alt="x close icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSpeciesCategoryList: (params, callback) => dispatch(getSpeciesCategoryList(params, callback)),
  getSpeciesList: (params, callback) => {
    dispatch(getSpeciesList(params, callback));
  },
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
  createsponsor: (data, callback) => {
    dispatch(createsponsor(data, callback));
  },
  createStripeSponsor: (data, callback) => {
    dispatch(createStripeSponsor(data, callback));
  },
  getUsersPreferences: (params, callback) => dispatch(getUsersPreferences(params, callback)),
});

const mapStateToProps = (state) => ({
  sideBarApisListings: sideBarApisListings(state),
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PaymentModal));
