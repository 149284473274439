import React, { useEffect, useRef, useState } from "react";
import logo from "../../include/images/logo.svg";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUser, userLogin } from "../../store/users";
import { connect } from "react-redux";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import eyeImg from "../../include/images/eye-d.svg";
import eyeOff from "../../include/images/eye-d-off.svg";

import LoadingBar from "react-top-loading-bar";
import NextButton from "../../common/form/nextButton";
import { siteAccess } from "../../store/misc";
const Auth = (props) => {
  var ref = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const sendLogin = () => {
    ref && ref.current && ref.current.continuousStart();
    const value = { ...loginData };
    setLoading(true);
    const payload = {
      userName: value.userName ? value.userName : "",
      password: value && value.password ? value.password : "",
    };

    props.siteAccess(payload, (res) => {
      if (res.status === 200) {
        localStorage.setItem("visitedBefore", "true");
        history.push("/");
        setLoading(false);
        ref && ref.current && ref.current.complete();
      } else {
        setLoading(false);
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0}/>
      <div class="page-outer-wrapper font-family-poppins grey-bg">
        <header id="header">
          <nav class="navbar navbar-expand-xl">
            <div class="container">
              <div class="nav-inside d-flex align-items-center justify-content-center">
                <div class="navbar-brand">
                  <Link to="/">
                    <img src={logo} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div class="login-container fw-medium">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="login-content-holder mx-auto">
                  <div class="login-head-row">
                    <h5>Enter Credentials to Access This Site</h5>
                  </div>
                  <div class="login-form-row-group">
                    <div class="form-floating-row d-flex flex-wrap">
                      <div class="ff-column">
                        <div class="form-floating custom-form-floating">
                          <input
                            type="text"
                            class={"form-control"}
                            id="username"
                            placeholder="Username"
                            value={loginData && loginData?.userName ? loginData.userName : ""}
                            onChange={(e) => {
                              const data = { ...loginData };
                              data.userName = e.target.value;
                              setLoginData(data);
                            }}
                          />
                          <label for="username">Username</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-floating-row d-flex flex-wrap">
                      <div class="ff-column">
                        <div class={"form-floating custom-form-floating"}>
                          <input
                            type={showPassword ? "text" : "password"}
                            class={"form-control"}
                            id="floatingPassword"
                            placeholder="Password"
                            value={loginData && loginData?.password ? loginData.password : ""}
                            onChange={(e) => {
                              const data = { ...loginData };
                              data.password = e.target.value;
                              setLoginData(data);
                            }}
                          />
                          <label for="floatingPassword">Password</label>

                          {showPassword ? (
                            <button
                              type="button"
                              class="show-password-btn"
                              data-target="floatingPassword"
                              onClick={toggleShowPassword}
                            >
                              <img src={eyeImg} alt="eye d" />
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="show-password-btn"
                              data-target="floatingPassword"
                              onClick={toggleShowPassword}
                            >
                              <img src={eyeOff} alt="eye d" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="login-form-btn d-flex align-items-center justify-content-end mt-2">
                      <NextButton
                        handleSubmit={sendLogin}
                        loading={loading}
                        classData={"btn btn-default"}
                        label="Enter"
                      />
                    </div>
                    <div className="login-link">
                      <Link to="/">Back to Home</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  siteAccess: (data, callback) => dispatch(siteAccess(data, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Auth));
