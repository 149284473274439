import React, { useEffect, useState } from "react";
// import "./bootstrap.min.css";
// import "./fontawesome.css";
// import "./style.module.css";
import logo from "./images/logo.svg";
import vallourecHero from "./images/aviation-ai-bg.jpg";
import individualBg from "./images/individual-hero-bg.png";
import species1 from "./images/black-footed.jpg";
import species2 from "./images/turtle.jpg";
import species3 from "./images/aberdare-cisticola.jpg";
import union from "./images/union.svg";
import union1 from "./images/endengeredIcon.png";
import logoBw from "./images/logo-bw.svg";
import avaitionAIcon from "./images/icon-img-1.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import icon1 from "../include/images/icon1_1.jpg"
import postImg1 from "../include/images/post-image-1.jpg";
import postImg2 from "../include/images/post-image-2.jpg";
import wIcon from "../include/images/w-icon-1.svg";
import wIcon3 from "../include/images/w-icon-3.svg";
import wIcon5 from "../include/images/w-icon-5.svg";
import wIcon2 from "../include/images/w-icon-2.svg";
import wIcon4 from "../include/images/w-icon-4.svg";
import liked from "../include/images/fill-heart-icon.svg";

const SampleIndSustainability = () => {
  const [buttonColor, setButtonColor] = useState("#47AD1D");
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .btn-current-color, .btn-current-color:focus {
        color: #fff !important;
        background-color: ${buttonColor};
        border: 1px solid ${buttonColor};
      }
    `;
    document.head.appendChild(style);

    // Cleanup on unmount
    return () => {
      document.head.removeChild(style);
    };
  }, [buttonColor]);
  return (
    <div className="page-holder-wrapper bg-gray min-vh-100" style={{ marginTop: "-6.2%" }}>
      <div className="individual-hero-container">
        <div
          className="individual-hero-bg"
          style={{
            backgroundImage: `url(${individualBg})`,
          }}
        ></div>
        <div class="container add-index">
          <div class="row">
            <div class="col-lg-12">
              <div class="individual-hero-content d-flex">
                <div class="ihc-left">
                  <div class="ihc-left-img">
                    <img src={avaitionAIcon} alt="" />
                  </div>
                </div>
                <div class="ihc-right">
                  <div class="ihc-head">
                    <h3>Suelen Rodrigues</h3>
                  </div>
                  <div class="ihc-body">
                    <div class="ihc-body-left">
                      <p>
                        Welcome to the Suelen Rodrigues Sustainability page where we sponsor endagered animals and the
                        conservation efforts by the scientists, biologists, journalists, conservation organizations, and
                        non-profits to save these species from extinction.
                      </p>
                      <div class="ihc-body-btn d-flex">
                        <a href="javascript:void(0);" class="btn btn-current-color" style={{ color: "#47AD1D" }}>
                          <span>Follow</span>
                        </a>
                        <a href="javascript:void(0);" class="btn btn-current-color" style={{ color: "#47AD1D" }}>
                          <span>Pack Friend Request</span>
                        </a>
                      </div>
                    </div>
                    <div class="ihc-body-right">
                      <div class="ihc-body-right-box">
                        <div class="ihc-body-right-img">
                          <img src={species1} alt="" />
                        </div>
                        <div class="ihc-ihc-body-right-text">
                          <p>Black-footed ferret</p>
                        </div>
                      </div>
                      <div class="ihc-body-right-box">
                        <div class="ihc-body-right-img">
                          <img src={species2} alt="" />
                        </div>
                        <div class="ihc-ihc-body-right-text">
                          <p>
                            Ridley <br />
                            turtle
                          </p>
                        </div>
                      </div>
                      <div class="ihc-body-right-box">
                        <div class="ihc-body-right-img">
                          <img src={species3} alt="" />
                        </div>
                        <div class="ihc-ihc-body-right-text">
                          <p>African Penguin</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sustainability-content-container sponsored-species-container pb-0 large-padd">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-title">
                <h2>Sponsored Species</h2>
              </div>
              <div class="spacies-widget-row">
                <div class="species-content1 d-flex align-items-center">
                  <div class="sc-left">
                    <div class="sc-left-img">
                      <img src={species1} alt="" />
                    </div>
                  </div>
                  <div class="sc-center">
                    <div class="sc-text">
                      <h4>Black-footed ferret</h4>
                      <p>Mustela Nigripes</p>
                    </div>
                    <ul class="sc-origin-list d-flex flex-wrap">
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>Arizona, USA </p>
                        </div>
                      </li>
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>Serengeti, Tanzania</p>
                        </div>
                      </li>
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>Galápagos Islands, Ecuador</p>
                        </div>
                      </li>
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>+5</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="sc-right ms-auto">
                    <div class="sc-right-img">
                      <img src={union1} alt="" />
                    </div>
                    <p>Endangered</p>
                  </div>
                </div>
                <div class="species-widget-content">
                  <ul class="swc-list">
                    <li class="swc-item">
                      <div class="swc-box d-flex flex-column">
                        <div class="swc-box-header">
                          <h4>The Black-Footed Ferret: A Conservation Success Story</h4>
                          <div class="dropend ms-auto"></div>
                        </div>
                        <div class="swc-box-body flex-grow-1">
                          <p>
                            The black-footed ferret (Mustela Nigripes) is one of North America’s most elusive and
                            endengared creatures. With its slender body, masked face, and distinctive black paws, this
                            carnivorous mammal is trully unique
                          </p>
                          <div class="swc-box-btn mt-auto">
                            <a href="javascript:void(0);" class="btn-read-more" style={{ color: "#47AD1D" }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="swc-item">
                      <div class="swc-box d-flex flex-column">
                        <div class="swc-box-header">
                          <h4>Why the Black-Footed Ferret Needs Our Protection</h4>
                          <div class="dropend ms-auto"></div>
                        </div>
                        <div class="swc-box-body flex-grow-1">
                          <p>
                            The black-footed ferret is one of North America’s most endengared mammals, and while
                            conservation efforts have made significant strides, the species still faces many challenges.
                          </p>
                          <div class="swc-box-btn mt-auto">
                            <a href="javascript:void(0);" class="btn-read-more" style={{ color: "#47AD1D" }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="swc-item">
                      <div class="swc-box d-flex flex-column">
                        <div class="swc-box-header">
                          <h4>How the Black-Footed Ferret Help Us Understand Ecosystem Health</h4>
                          <div class="dropend ms-auto"></div>
                        </div>
                        <div class="swc-box-body flex-grow-1">
                          <p>
                            When we think of endengered species, it´s easy to focus on the animals themselves. But the
                            light of the blackfooted ferret (Mustela nigriepes) offers a powerful reminder that
                            protecting an individual species is also about...
                          </p>
                          <div class="swc-box-btn mt-auto">
                            <a href="javascript:void(0);" class="btn-read-more" style={{ color: "#47AD1D" }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="swc-btn">
                    <a href="javascript:void(0);" class="btn btn-current-color btn-block" style={{ color: "#47AD1D" ,minWidth:"100%" }}>
                      <span>View More Post</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="spacies-widget-row">
                <div class="species-content1 d-flex align-items-center">
                  <div class="sc-left">
                    <div class="sc-left-img">
                      <img src={species2} alt="" />
                    </div>
                  </div>
                  <div class="sc-center">
                    <div class="sc-text">
                      <h4>Ridley turtle</h4>
                      <p>Lepidochelys </p>
                    </div>
                    <ul class="sc-origin-list d-flex flex-wrap">
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>Virginia, USA</p>
                        </div>
                      </li>
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>Serengeti, Tanzania</p>
                        </div>
                      </li>
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>Galápagos Islands, Ecuador</p>
                        </div>
                      </li>
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>+5</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="sc-right ms-auto">
                    <div class="sc-right-img">
                      <img src={union} alt="" />
                    </div>
                    <p>Critically Endangered</p>
                  </div>
                </div>
                <div class="species-widget-content">
                  <ul class="swc-list">
                    <li class="swc-item">
                      <div class="swc-box d-flex flex-column">
                        <div class="swc-box-header">
                          <h4>The Fascinating World of the Ridley Sea Turtle: A Tiny Survivor of the Oceans</h4>
                        </div>
                        <div class="swc-box-body flex-grow-1">
                          <p>
                            The Ridley sea turtel, one of the smallest and most endangered of sea turtles, is a
                            remarkable creature with an incredible survival story.
                          </p>
                          <div class="swc-box-btn mt-auto">
                            <a href="javascript:void(0);" class="btn-read-more" style={{ color: "#47AD1D" }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="swc-item">
                      <div class="swc-box d-flex flex-column">
                        <div class="swc-box-header">
                          <h4>Why the Kemps Ridley Turtle Is on the Brink of Extinction</h4>
                        </div>
                        <div class="swc-box-body flex-grow-1">
                          <p>
                            The Kemp’s Ridley turtle, scientifically known as Lepidochelys kempii, is one of the rarest
                            and most critically endangered sea turtle on the planet.
                          </p>
                          <div class="swc-box-btn mt-auto">
                            <a href="javascript:void(0);" class="btn-read-more" style={{ color: "#47AD1D" }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="swc-item">
                      <div class="swc-box d-flex flex-column">
                        <div class="swc-box-header">
                          <h4>The Olive Ridley Turtle: A Lesser-Known Hero of the Ocean</h4>
                        </div>
                        <div class="swc-box-body flex-grow-1">
                          <p>
                            While much attention has been given to the critically endangered Kemp’s Ridley turtle, the
                            Olive Ridley turtle (lepidochelys olivacea) also deserves our focus and protection.
                          </p>
                          <div class="swc-box-btn mt-auto">
                            <a href="javascript:void(0);" class="btn-read-more" style={{ color: "#47AD1D" }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="swc-btn">
                    <a href="javascript:void(0);" class="btn btn-current-color btn-block" style={{ color: "#47AD1D" ,minWidth:"100%" }}>
                      <span>View More Post</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="spacies-widget-row">
                <div class="species-content1 d-flex align-items-center">
                  <div class="sc-left">
                    <div class="sc-left-img">
                      <img src={species3} alt="" />
                    </div>
                  </div>
                  <div class="sc-center">
                    <div class="sc-text">
                      <h4>Aberdare Cisticola</h4>
                      <p>Cisticola aberdare</p>
                    </div>
                    <ul class="sc-origin-list d-flex flex-wrap">
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>Virginia, USA</p>
                        </div>
                      </li>
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>Serengeti, Tanzania</p>
                        </div>
                      </li>
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>Galápagos Islands, Ecuador</p>
                        </div>
                      </li>
                      <li>
                        <div class="sc-origin-box" style={{ color: "#47AD1D" }}>
                          <p>+5</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="sc-right ms-auto">
                    <div class="sc-right-img">
                      <img src={union} alt="" />
                    </div>
                    <p>Critically Endangered</p>
                  </div>
                </div>
                <div class="species-widget-content">
                  <ul class="swc-list">
                    <li class="swc-item">
                      <div class="swc-box d-flex flex-column">
                        <div class="swc-box-header">
                          <h4>The Aberdare Cisticola: A Tiny Treasure of Kenyas Highlands</h4>
                        </div>
                        <div class="swc-box-body flex-grow-1">
                          <p>
                            The Aberdare Cisticola is a rare and charming bird found only in the grasslands of Kenya's
                            Aberdare Mountain Range. Its distinct song and elusive nature make it a fascinating subject
                            for birdwatchers and conservationists alike.
                          </p>
                          <div class="swc-box-btn mt-auto">
                            <a href="javascript:void(0);" class="btn-read-more" style={{ color: "#47AD1D" }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="swc-item">
                      <div class="swc-box d-flex flex-column">
                        <div class="swc-box-header">
                          <h4>Why the Aberdare Cisticola Is at Risk of Extinction</h4>
                        </div>
                        <div class="swc-box-body flex-grow-1">
                          <p>
                            With its habitat in the endangered highland grasslands of central Kenya, the Aberdare
                            Cisticola faces numerous threats from habitat loss and climate change. Protecting these
                            unique ecosystems is crucial for ensuring the surv...
                          </p>
                          <div class="swc-box-btn mt-auto">
                            <a href="javascript:void(0);" class="btn-read-more" style={{ color: "#47AD1D" }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="swc-item">
                      <div class="swc-box d-flex flex-column">
                        <div class="swc-box-header">
                          <h4>Conservation Efforts for the Aberdare Cisticola: Preserving a Unique Ecosystem</h4>
                        </div>
                        <div class="swc-box-body flex-grow-1">
                          <p>
                            Conservation initiatives in Kenya are focused on protecting the Aberdare Cisticola by
                            preserving its fragile mountain grassland habitat.
                          </p>
                          <div class="swc-box-btn mt-auto">
                            <a href="javascript:void(0);" class="btn-read-more" style={{ color: "#47AD1D" }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="swc-btn">
                    <a href="javascript:void(0);" class="btn btn-current-color btn-block" style={{ color: "#47AD1D" ,minWidth:"100%" }}>
                      <span>View More Post</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sustainability-content-container post-container large-padd">
            <div class="container">
               <div class="row">
                  <div class="col-md-12">
                     <div class="page-title">
                        <h2>Posts</h2>
                     </div>
                     <div class="masonry">
                        <div class="masonry-item">
                           <div class="post-box d-flex">
                              <div class="post-img-box">
                                 <div class="post-img"><img src={icon1} alt="" /></div>
                              </div>
                              <div class="post-content-box">
                                 <div class="post-title-box d-flex align-items-center flex-wrap gap-2">
                                    <div class="post-name">
                                       <h5>Suelen Rodrigues</h5>
                                    </div>
                                    <div class="post-dot"><span></span></div>
                                    <div class="post-time">5 min</div>
                                 </div>
                                 <div class="post-text">
                                    <div class="post-text">
                                       <p>Seychelles, a tropical paradise of 115 islands in the Indian Ocean, is a biodiversity hotspot renowned for its pristine beaches, coral reefs, and lush forests. Home to rare and endemic species such as the giant Aldabra tortoise, Seychelles black parrot, and coco de mer palm, the islands boast some of the most unique ecosystems in the world. Despite their natural beauty, these ecosystems face th...</p>
                                    </div>
                                    <div class="post-btn"><a href="javascript:void(0);" class="link-btn-link">Read More</a></div>
                                    <div class="post-tags">
                                       <span><a href="javascript:void(0);">#Seychelles</a></span>
                                    </div>
                                 </div>
                                 <div class="post-bottom-img">
                                    <img src={postImg1} alt="" />
                                 </div>
                                 <div class="post-button-group d-flex align-items-center justify-content-around gap-2 mt-auto">
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={wIcon} alt="" />
                                          </div>
                                       </div>
                                    </div>
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={wIcon2} alt="" />
                                          </div>
                                          <div class="post-button-count">3</div>
                                       </div>
                                    </div>
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={wIcon3} alt="" />
                                          </div>
                                          <div class="post-button-count">16</div>
                                       </div>
                                    </div>
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={wIcon4} alt="" />
                                          </div>
                                          <div class="post-button-count">3</div>
                                       </div>
                                    </div>
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={wIcon5} alt="" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="masonry-item">
                           <div class="post-box d-flex">
                              <div class="post-img-box">
                                 <div class="post-img"><img src={icon1} alt=""/></div>
                              </div>
                              <div class="post-content-box">
                                 <div class="post-title-box d-flex align-items-center flex-wrap gap-2">
                                    <div class="post-name">
                                       <h5>Suelen Rodrigues</h5>
                                    </div>
                                    <div class="post-dot"><span></span></div>
                                    <div class="post-time">5 min</div>
                                 </div>
                                 <div class="post-text">
                                    <div class="post-text">
                                       <p>
                                          Preserving the coastal sand dunes and grasslands of Muskeget Island is vital. A great resource to find out more about the island is here: https://blogs.umb.edu/fiskecenter/2016/06/06/muskeget-island-through- time/?utm_source=chatgpt.com
                                       </p>
                                       <p>
                                          "The Fiske Center recently worked with Dr. Rob Stevenson of the UMass Boston Biology Department on his research into vole species on Muskeget Island, off th...
                                       </p>
                                    </div>
                                    <div class="post-btn"><a href="javascript:void(0);" class="link-btn-link">Read More</a></div>
                                    <div class="post-tags">
                                       <span><a href="javascript:void(0);">#Beach Vole</a></span>
                                    </div>
                                 </div>
                                 <div class="post-bottom-img">
                                    <img src={postImg2} alt=""/>
                                 </div>
                                 <div class="post-button-group d-flex align-items-center justify-content-around gap-2 mt-auto">
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={wIcon} alt=""/>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={wIcon2} alt=""/>
                                          </div>
                                          <div class="post-button-count">3</div>
                                       </div>
                                    </div>
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={liked} alt="" />
                                          </div>
                                          <div class="post-button-count">16</div>
                                       </div>
                                    </div>
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={wIcon4} alt="" />
                                          </div>
                                          <div class="post-button-count">3</div>
                                       </div>
                                    </div>
                                    <div class="post-button-item">
                                       <div class="post-button-box d-flex align-items-center gap-2">
                                          <div class="post-button-img">
                                             <img src={wIcon5} alt="" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                     </div>
                  </div>
               </div>
            </div>
         </div>

      {/* Footer */}
      <footer id="footerBar" className="font-family-poppins">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="footer-upper-bar d-flex flex-column align-items-center gap-3 gap-md-0 flex-md-row justify-content-between">
                <div class="footer-upper-bar-left">
                  <Link class="footer-bar-logo no-pointer">
                    <img src={logoBw} alt="" />
                  </Link>
                </div>
                <div class="footer-upper-bar-middle align-self-center">
                  <p>Address : P.O. Box G-3 Aspen, CO - 81611</p>
                </div>
                <div class="footer-upper-bar-right">
                  <ul class="social-media-list mt-0 d-flex flex-wrap">
                    <li class="social-media-item">
                      <Link class="social-media-box no-pointer">
                        <i class="fa-brands fa-x-twitter"></i>
                      </Link>
                    </li>
                    <li class="social-media-item">
                      <Link class="social-media-box no-pointer">
                        <i class="fab fa-instagram" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li class="social-media-item">
                      <Link class="social-media-box no-pointer">
                        <i class="fab fa-facebook-f" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li class="social-media-item">
                      <Link class="social-media-box no-pointer">
                        <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="footer-lower-bar">
                <div class="footer-lower-bar-content d-flex flex-column-reverse flex-md-row gap-3 align-items-center justify-content-between">
                  <div>
                    <div class="copyright">&copy; Environmental Inclusion. All Rights Reserved.</div>
                  </div>
                  <div>
                    <ul class="footer-nav d-flex align-items-center">
                      <li>
                        <Link class="no-pointer">Terms & Condition</Link>
                      </li>
                      <li>
                        <Link class="no-pointer">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SampleIndSustainability;
