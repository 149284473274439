import React from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import close from "../../../include/images/x-close-24x24.svg";
import downloadIcon from "../../../include/images/x-download.svg";

const ChatImageGallery = ({ show, handleClose, items, startIndex = 0, title }) => {
  if (!items || !items.files || items.files.length === 0) return null;
  const currentFile = items.files[startIndex];
  const fileUrl =
    currentFile.mediaType === "video" && !currentFile.original.startsWith("https")
      ? `${process.env.REACT_APP_MEDIA}${currentFile.original}`
      : currentFile.original;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      id="photosModal"
      backdrop={true}
      centered
      dialogClassName="custom-transparent-modal"
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: "100%",
          position: "relative",
          padding: "20px",
        }}
      >
        <div
          style={{
            maxWidth: "95vw",
            maxHeight: "90vh",
            position: "relative",
          }}
        >
          {currentFile.mediaType === "image" ? (
            <img
              src={`${process.env.REACT_APP_MEDIA}${currentFile.original}`}
              alt="gallery"
              style={{
                width: "auto",
                height: "auto",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          ) : currentFile.mediaType === "video" && currentFile.original.startsWith("https") ? (
            <ReactPlayer url={currentFile.original} controls width="100%" height="100%" />
          ) : currentFile.mediaType === "video" ? (
            <video controls style={{ maxWidth: "100%", maxHeight: "100%" }}>
              <source src={`${process.env.REACT_APP_MEDIA}${currentFile.original}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <p>Unsupported media type</p>
          )}
          {/* Close Button */}
          <div
            style={{
              position: "absolute",
              top: "-4px",
              right: "-80px",
              cursor: "pointer",
            }}
          >
            <a
              href={`${process.env.REACT_APP_MEDIA}${currentFile.original}`}
              download={currentFile.original.split("/").pop()}
              title="Download"
              className="me-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={downloadIcon} alt="Download" style={{ width: "24px", height: "24px" }} />
            </a>
            <img src={close} alt="close" onClick={handleClose} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChatImageGallery;
