import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  getEnvironmentalistDetails,
  environmentalistListings,
  getSpeciesEnv,
  updateSpeciesEnv,
  deleteSpeciesEnv,
} from "../../store/environmentalist";
import HTMLReactParser from "html-react-parser";
import { removeTags, capitalizeContentInBrackets, extractIds } from "../../utils/helperFunctions";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { deleteSpeciesOrg, getSpecies, organizationListing, updateSpeciesOrg } from "../../store/organization";
import { deleteSpeciesZoo, getSpeciesZoo, updateSpeciesZoo, zooListings } from "../../store/zoo";
import Pagination from "../common/Pagination/pagination";
import { getSpeciesList, sideBarApisListings } from "../../store/sidebarApis";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import Select from "react-select";
import trash from "../../include/images/trash-24x24.svg";
import AddModal from "../common/addModal/addModal";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import defaultImage from "../../include/images/default_image_species.svg";
import crustaceansImg from "../../include/images/Crustaceans.png";

const Species = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [selectedPreference, setSelectedPreference] = useState({
    species: "",
  });
  const [showAdd, setShowAdd] = useState(false);
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      species: selectedOptions,
    }));
  };
  const handleImageError = () => {
    setImageError(true);
  };

  const details =
    props.page === "organization"
      ? props.organizationListing && props.organizationListing.species
      : props.page === "zoo"
      ? props.zooListings && props.zooListings.species
      : props.environmentalistListings && props.environmentalistListings.species;
  const transformDropdownData = (data, labelKey = "scientificName", valueKey = "_id") => {
    return data
      .filter((item) => item[labelKey] || item.displayCommonName)
      .map((item) => ({
        label: capitalizeContentInBrackets(item.displayCommonName || item[labelKey]),
        value: item[valueKey],
      }));
  };

  const speciesListDropdown = transformDropdownData(props.sideBarApisListings?.speciesList?.data || []);

  useEffect(() => {
    if (!props.sideBarApisListings?.speciesList?.data) {
      props.getSpeciesList({}, (res) => {});
    }
  }, []);
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      if (props.page === "organization") {
        props.getSpecies(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      } else if (props.page === "zoo") {
        props.getSpeciesZoo(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      } else {
        props.getSpeciesEnv(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      if (description.includes("strong")) {
        description = description.substring(0, limit + 100) + dots;
      } else description = description.substring(0, limit) + dots;
    }
    return description;
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  const addMore = () => {
    setLoading(true);
    let payload = {
      speciesIds: extractIds(selectedPreference?.species),
    };

    // Conditionally calling the appropriate API for adding species
    const addSpeciesData = () => {
      if (props.page === "organization") {
        return props.updateSpeciesOrg(props.id, payload, handleResponse);
      } else if (props.page === "zoo") {
        return props.updateSpeciesZoo(props.id, payload, handleResponse);
      } else {
        return props.updateSpeciesEnv(props.id, payload, handleResponse);
      }
    };

    // Handle response after API call
    const handleResponse = (res) => {
      if (res.status === 200) {
        setSelectedPreference({
          species: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        // Fetch the updated species data
        fetchSpeciesData(params);
      }
    };

    // Fetch species data after add or delete operation
    const fetchSpeciesData = (params) => {
      if (props.page === "organization") {
        props.getSpecies(params, (res) => setLoading(false));
      } else if (props.page === "zoo") {
        props.getSpeciesZoo(params, (res) => setLoading(false));
      } else {
        props.getSpeciesEnv(params, (res) => setLoading(false));
      }
    };

    // Call the function to add species data
    addSpeciesData();
  };

  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      speciesIds: [ids],
    };

    // Conditionally calling the appropriate API for deleting species
    const deleteSpeciesData = () => {
      if (props.page === "organization") {
        return props.deleteSpeciesOrg(props.id, payload, handleResponse);
      } else if (props.page === "zoo") {
        return props.deleteSpeciesZoo(props.id, payload, handleResponse);
      } else {
        return props.deleteSpeciesEnv(props.id, payload, handleResponse);
      }
    };

    // Handle response after delete operation
    const handleResponse = (res) => {
      if (res.status === 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        // Fetch the updated species data
        fetchSpeciesData(params);
      }
    };

    // Fetch species data after add or delete operation
    const fetchSpeciesData = (params) => {
      if (props.page === "organization") {
        props.getSpecies(params, (res) => setLoading(false));
      } else if (props.page === "zoo") {
        props.getSpeciesZoo(params, (res) => setLoading(false));
      } else {
        props.getSpeciesEnv(params, (res) => setLoading(false));
      }
    };

    // Call the function to delete species data
    deleteSpeciesData();
  };
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  return (
    <>
      <div class="container" ref={topElementRef}>
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              {props.editMode && (
                <div className="d-flex justify-content-between mb-4">
                  <Link onClick={() => setShowAdd(true)} class="btn btn-default add-more-btn-edit-tab">
                    Add Species
                  </Link>
                  <div class="d-flex flex-wrap justify-content-end w-75">
                    <Select
                      className="basic-single w-50 z-2 text-capitalize"
                      classNamePrefix="select-search"
                      isMulti
                      placeholder="Select"
                      styles={customStyles1}
                      options={speciesListDropdown}
                      value={selectedPreference.species}
                      onChange={handleSelectChange}
                    />
                    <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                      Save
                    </Link>
                  </div>
                </div>
              )}
              <>
                {loading ? (
                  <ul class="tbc-grid-list d-flex flex-wrap ul-padding">
                    {Array.from({ length: 20 }, (item, index) => (
                      <li class="tbc-grid-item">
                        <div class="tbc-grid-box">
                          <h4>
                            <Skeleton borderRadius={"10px"} width="500px" height="220px" />
                          </h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    <ul class="card-list d-lg-flex flex-lg-wrap" style={{ marginBottom: "68px" }}>
                      {details && details.data && details?.data?.length
                        ? details?.data?.map((item) => {
                            return (
                              <li class="card-item position-relative">
                                {props.editMode && (
                                  <button
                                    type="button"
                                    class="trash-button-box z-1"
                                    onClick={(e) => {
                                      handleDelete(item._id);
                                    }}
                                  >
                                    <img src={trash} alt="w volumne" />
                                  </button>
                                )}
                                <Link
                                  to={{
                                    pathname: `/endangered-species/${item?.slug}`,
                                    state: { id: item._id },
                                  }}
                                  class="card-box w-100 h-100 d-flex flex-wrap"
                                >
                                  <div class="card-img-box overflow-hidden">
                                    {item?.coverImage?.original ? (
                                      <div class="card-img-inside">
                                        {!imageError ? (
                                          <img
                                            class="card-img"
                                            src={process.env.REACT_APP_MEDIA + item?.coverImage?.medium}
                                            onError={handleImageError}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            class="card-img"
                                            src={process.env.REACT_APP_MEDIA + item?.coverImage?.original}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <div class="card-img-inside">
                                        <img class="card-img" src={getDefaultImage(item?.speciesCategoryName)} alt="" />
                                      </div>
                                    )}
                                  </div>
                                  <div class="card-content-box">
                                    <h5
                                      className="text-transfrom"
                                      style={{
                                        fontSize: "20px",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {`${capitalizeContentInBrackets(
                                        item?.displayCommonName || item?.scientificName,
                                      )}`}
                                    </h5>
                                    <div class="card-content w-100">
                                      <p>
                                        {item.description
                                          ? dotDesc(HTMLReactParser(removeTags(item.description)), 200)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            );
                          })
                        : ""}
                    </ul>
                  </>
                )}
                {details && details?.totalCount > 20 && (
                  <Pagination
                    handlePageClick={handlePageClick}
                    currentPage={currentPage}
                    pageLinks={pageLinks}
                    totalPages={pageLinks.length}
                  />
                )}
              </>
            </div>
          </div>
        </div>
        <AddModal
          show={showAdd}
          onHide={() => setShowAdd(false)}
          heading={"Add Species"}
          page="zoo"
          type="species"
          id={props.id}
          alert="species"
        />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getEnvironmentalistDetails: (params, callback) => dispatch(getEnvironmentalistDetails(params, callback)),
  getSpecies: (params, callback) => dispatch(getSpecies(params, callback)),
  getSpeciesZoo: (params, callback) => dispatch(getSpeciesZoo(params, callback)),
  getSpeciesEnv: (params, callback) => dispatch(getSpeciesEnv(params, callback)),
  getSpeciesList: (params, callback) => dispatch(getSpeciesList(params, callback)),
  updateSpeciesEnv: (params, data, callback) => dispatch(updateSpeciesEnv(params, data, callback)),
  updateSpeciesZoo: (params, data, callback) => dispatch(updateSpeciesZoo(params, data, callback)),
  deleteSpeciesReg: (params, data, callback) => dispatch(deleteSpeciesOrg(params, data, callback)),
  deleteSpeciesZoo: (params, data, callback) => dispatch(deleteSpeciesZoo(params, data, callback)),
  deleteSpeciesEnv: (params, data, callback) => dispatch(deleteSpeciesEnv(params, data, callback)),
});

const mapStateToProps = (state) => ({
  environmentalistListings: environmentalistListings(state),
  organizationListing: organizationListing(state),
  sideBarApisListings: sideBarApisListings(state),
  zooListings: zooListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Species));
