import React, { useRef, useState } from "react";
import { deleteOrganizations, getOrganizations, speciesListings, updateOrganizations } from "../../store/species";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import noSponsor from "../../include/images/sponsor.svg";
import noSponsorSmall from "../../include/images/sponsorsmall.svg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetter,
  extractIds,
  transformDropdownData,
} from "../../utils/helperFunctions";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import { getsponsor, getsponsorListing, getstory, getSubscription } from "../../store/subscription";
import { getProfile } from "../../utils/localStorageServices";
import defaultImage from "../../include/images/default_image_species.svg";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SponsorsList = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const isMobile = useCheckMobileScreen();
  const sponsorData = props?.getSubscription?.sponsorList?.data;
  const storyData = props?.getSubscription?.story?.data;
  const profile = getProfile();
  const staticSpecies = ["66faa2afb126301e0344a422", "647fb22dd6091d1a635a36d3", "66fa9666b126301e03441d26"];
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [sort, setSort] = useState("sponsorName");

  useEffect(() => {
    const payload = {
      page: page,
      sort,
      order,
    };
    setLoading(true);
    props.getsponsorListing(payload, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
      }
    });
  }, [page]);

  const organizationDetail = props.speciesListings && props.speciesListings.organization;

  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };

  const pageLinks = Array.from(
    {
      length: Math.ceil(
        (props?.getSubscription?.sponsor?.totalCount ?? 0) / (props?.getSubscription?.sponsor?.recordsPerPage ?? 1),
      ),
    },
    (_, i) => i + 1,
  );

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }
    return description;
  };

  const totalPages = (props.getSubscription.sponsorList && props.getSubscription.sponsorList.totalCount) || 0;

  const dataPerPage = (props.getSubscription.sponsorList && props.getSubscription.sponsorList.recordsPerPage) || 0;

  const noOfPage = Math.ceil(totalPages / dataPerPage) || 0;
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };

  return (
    <>
      <div className="content-container corporate-sponcers-container" ref={topElementRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-title1 d-flex justify-content-between align-items-center">
                <h2>CORPORATE SPONSORS</h2>
                {sponsorData?.filter((item) => item.isCompany).length > 10 && (
                  <a
                    href="/sponsors"
                    className="btn btn-link"
                    style={{ fontSize: "20px", textDecoration: "underline" }}
                  >
                    View More
                  </a>
                )}
              </div>
              <ul className="sponcer-list">
                {sponsorData
                  ?.filter((item) => item.isCompany)
                  .slice(0, 10)
                  .map((item, index) => (
                    <li key={index} className="sponcer-item">
                      <div className="sponcer-box">
                        <div className="sponcer-box-left">
                          <div className="sbl-img">
                            <img
                              src={`${process.env.REACT_APP_MEDIA}${
                                item?.createdByDetail?.organizationLogo?.small
                                  ? item?.createdByDetail?.organizationLogo?.small
                                  : item?.createdByDetail?.profilePicture?.small
                              }`}
                              alt=""
                            />
                          </div>
                          <div className="sbl-text">
                            <h6 className="text-capitalize">{item?.createdByDetail?.organizationName}</h6>
                            <p>{dotDesc(item?.createdByDetail?.description, 200)}</p>
                          </div>
                        </div>
                        <div className="sponcer-box-right">
                          <div class="sbr-species">
                            <div class="sbr-species-head">
                              <h6>SPONSORED ANIMALS</h6>
                            </div>
                            <ul className="sbr-species-img-list">
                              {item?.species?.slice(0, 3).map((species, index) => (
                                <li key={index}>
                                  <div className="sbr-species-img-box" tabIndex="0">
                                    <img
                                      src={`${process.env.REACT_APP_MEDIA}${
                                        species?.coverImage?.small
                                          ? species.coverImage.small
                                          : getDefaultImage(species?.scientificName)
                                      }`}
                                      alt={species?.scientificName || "Species Image"}
                                    />
                                  </div>
                                </li>
                              ))}
                              {item?.species?.length > 3 && (
                                <li>
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id={`tooltip-extra-species`}>
                                        {item.species.slice(3).map((extraSpecies, index) => (
                                          <div className="text-capitalize" key={index}>
                                            {extraSpecies.displayCommonName}
                                          </div>
                                        ))}
                                      </Tooltip>
                                    }
                                  >
                                    <h6 style={{ cursor: "pointer" }}>+{item.species.length - 3}</h6>
                                  </OverlayTrigger>
                                </li>
                              )}
                            </ul>
                          </div>
                          <div class="sbr-cta">
                            <a
                              href={`/sustainability/${item?.createdByDetail?.slug}?isCompany=corporate`}
                              target="_blank"
                              rel="noopener noreferrer"
                              class="btn btn-default h-40 pt-10"
                            >
                              Sustainability Page
                            </a>
                            <a
                              href={`/profile/${item?.createdByDetail?.slug}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              class="btn btn-dark-green h-40 pt-10"
                            >
                              EI Profile Page
                            </a>
                            <a
                              href={
                                item?.createdByDetail?.website?.startsWith("http")
                                  ? item?.createdByDetail?.website
                                  : `https://${item?.createdByDetail?.website}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              class="btn btn-dark-green-outline h-40 pt-10"
                            >
                              Company Page
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="content-container individual-sponcers-container pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-title1 d-flex justify-content-between align-items-center">
                <h2>INDIVIDUAL SPONSORS</h2>
                {sponsorData?.filter((item) => item.isCompany).length > 10 && (
                  <a
                    href="/sponsors"
                    className="btn btn-link"
                    style={{ fontSize: "20px", textDecoration: "underline" }}
                  >
                    View More
                  </a>
                )}
              </div>
              <ul className="sponcer-list">
                {sponsorData
                  ?.filter((item) => !item.isCompany)
                  .slice(0, 10)
                  .map((item, index) => (
                    <li key={index} className="sponcer-item">
                      <div className="sponcer-box">
                        <div className="sponcer-box-left">
                          <div className="sbl-img">
                            <img
                              src={`${process.env.REACT_APP_MEDIA}${
                                item?.createdByDetail?.organizationLogo?.small
                                  ? item?.createdByDetail?.organizationLogo?.small
                                  : item?.createdByDetail?.profilePicture?.small
                              }`}
                              alt=""
                            />
                          </div>
                          <div className="sbl-text">
                            <h6 className="text-capitalize">
                              {item?.createdByDetail?.firstName} {item?.createdByDetail?.lastName}
                            </h6>
                            <p>{dotDesc(item?.createdByDetail?.individualDescription, 200)}</p>
                          </div>
                        </div>
                        <div className="sponcer-box-right">
                          <div class="sbr-species">
                            <div class="sbr-species-head">
                              <h6>SPONSORED ANIMALS</h6>
                            </div>
                            <ul className="sbr-species-img-list">
                              {item?.species?.slice(0, 3).map((species, index) => (
                                <li key={index}>
                                  <div className="sbr-species-img-box" tabIndex="0">
                                    <img
                                      src={`${process.env.REACT_APP_MEDIA}${
                                        species?.coverImage?.small
                                          ? species.coverImage.small
                                          : getDefaultImage(species?.scientificName)
                                      }`}
                                      alt={species?.scientificName || "Species Image"}
                                    />
                                  </div>
                                </li>
                              ))}
                              {item?.species?.length > 3 && (
                                <li>
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id={`tooltip-extra-species`}>
                                        {item.species.slice(3).map((extraSpecies, index) => (
                                          <div className="text-capitalize" key={index}>
                                            {extraSpecies.displayCommonName}
                                          </div>
                                        ))}
                                      </Tooltip>
                                    }
                                  >
                                    <h6 style={{ cursor: "pointer" }}>+{item.species.length - 3}</h6>
                                  </OverlayTrigger>
                                </li>
                              )}
                            </ul>
                          </div>
                          <div class="sbr-cta">
                            <a
                              href={`/sustainability/${item?.createdByDetail?.slug}?isCompany=individual`}
                              class="btn btn-dark-green h-40 pt-10"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Page
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getsponsor: (params, callback) => dispatch(getsponsor(params, callback)),
  getsponsorListing: (params, callback) => dispatch(getsponsorListing(params, callback)),
  getstory: (params, callback) => dispatch(getstory(params, callback)),
});

const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SponsorsList));
