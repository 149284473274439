import React, { useEffect, useState } from "react";
import noRecord from "../../include/images/nrb-img.svg";
import { Link } from "react-router-dom";
import paypalIcon from "../../include/images/paypal.svg";
import stripeIcon from "../../include/images/stripeIcon.svg";
import { connect } from "react-redux";
import { getTransaction, getUser } from "../../store/users";
import Skeleton from "react-loading-skeleton";

const Transaction = (props) => {
  const data = props?.getUser?.transaction?.data;
  const [page, setPage] = useState(1);

  useEffect(() => {
    const payload = {
      page: page,
    };
    if (page > 1) {
      props.getTransaction(payload, (res) => {
        if (res.status === 200) {
          // Handle success
        } else {
          // Handle failure
        }
      });
    }
  }, [page]);
  return (
    <div className="transaction-content-wrapper d-flex flex-column">
      {props.loading ? (
        <div className="ei-grid-box">
          <Skeleton height={150} width="100%" className="" />
          <Skeleton height={150} width="100%" className="mt-2" />
        </div>
      ) : (
        <>
          {data && data.length > 0 ? (
            data.map((transaction, index) => (
              <div key={transaction._id} className="transaction-content-head">
                <div className="transaction-head-box d-flex flex-column justify-content-between">
                  <div className="tcb-upper d-flex flex-wrap justify-content-between">
                    <div className="tcb-upper-left d-flex">
                      <div className="tcb-upper-icon" style={transaction?.type === "stripe" ? { border: "none" } : {}}>
                        {transaction?.type === "stripe" ? (
                          <img src={stripeIcon} alt="Stripe Icon" />
                        ) : (
                          <img src={paypalIcon} alt="Paypal Icon" />
                        )}
                      </div>
                      <div className="tcb-upper-text">
                        <div className="tcb-upper-head d-flex align-items-center">
                          <h6 className="mb-0">{transaction?.type === "stripe" ? "Stripe" : "PayPal"}</h6>
                          {/* <a
                        href={transaction?.billing_info?.links[1].href}
                        className="btn-edit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Edit
                      </a> */}
                        </div>
                        {transaction?.subscription_id && (
                          <div className="tcb-upper-body">Subscription ID : {transaction?.subscription_id}</div>
                        )}
                      </div>
                    </div>
                    {transaction?.planEnd && !transaction?.transaction?.isMarkedAsCanceled && (
                      <div className="tcb-upper-right">
                        <h6>Next Payment Date</h6>
                        <h5 className="mb-0">{new Date(transaction?.planEnd).toLocaleDateString()}</h5>
                      </div>
                    )}
                  </div>
                  <div className="tcb-lower d-flex flex-wrap">
                    <div className="tcb-lower-box text-center">
                      <h6>Amount</h6>
                      <h5 className="mb-0">${transaction.total}</h5>
                    </div>

                    <div className="tcb-lower-box text-center">
                      {transaction?.planStart ? (
                        <>
                          <h6>Sponsorship Start Date</h6>
                          <h5 className="mb-0">{new Date(transaction?.planStart).toLocaleDateString()}</h5>
                        </>
                      ) : (
                        <>
                          <h6>Transaction Date</h6>
                          <h5 className="mb-0">
                            {new Date(transaction?.transaction?.transactionDate).toLocaleDateString()}
                          </h5>
                        </>
                      )}
                    </div>
                    <div className="tcb-lower-box text-center">
                      <h6>Status</h6>
                      {!transaction?.transaction?.isMarkedAsCanceled ? (
                        <h5 className="mb-0">
                          {transaction?.type === "stripe"
                            ? transaction?.status === "succeeded"
                              ? "COMPLETED"
                              : transaction?.status === "requires_payment_method"
                              ? "PENDING"
                              : transaction?.status === "canceled"
                              ? "CANCELED"
                              : transaction?.status
                            : transaction?.status}
                        </h5>
                      ) : (
                        <h5 className="mb-0">CANCELED</h5>
                      )}
                    </div>
                  </div>
                </div>
                <div className="transaction-content-body">
                  {/* <div className="transaction-content-list d-flex flex-column">
                {transaction.billing_info.cycle_executions?.map((cycle, cycleIndex) => (
                  <div key={cycleIndex} className="transaction-content-item">
                    <div className="transaction-content-box d-flex flex-wrap align-items-center">
                      <div className="tcb-left flex-grow-1">
                        <h5>Transaction ID - {transaction?.billing_info.id}</h5>
                        <h6 className="mb-0">
                          {new Date(transaction?.billing_info?.last_payment.time).toLocaleDateString()}
                        </h6>
                      </div>
                      <div className="tcb-right flex-shrink-0">
                        - ${transaction.billing_info?.last_payment?.amount?.value}
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
                </div>
              </div>
            ))
          ) : (
            <div className="panel-empty-wrapper d-flex flex-column align-items-center text-center mx-auto pt-5">
              <img src={noRecord} alt="No Record" className="pew-icon icon-lg ms-0" />
              <h5>No Transactions Found</h5>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTransaction: (param, callback) => {
    dispatch(getTransaction(param, callback));
  },
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Transaction));
