import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "chat",
  initialState: {
    chats: {},
    unreadChatCount: null,
    chatMessage: {},
    loading: false,
  },
  reducers: {
    chatsRequested: (misc, action) => {
      misc.loading = true;
    },
    chatsReceived: (misc, action) => {
      misc.chats = action.payload;
      misc.loading = false;
    },
    chatsRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    unreadChatCountRequested: (misc, action) => {
      misc.loading = true;
    },
    unreadChatCountReceived: (misc, action) => {
      misc.unreadChatCount = action.payload;
      misc.loading = false;
    },
    unreadChatCountFailed: (misc, action) => {
      misc.loading = false;
    },

    chatMessageRequested: (misc, action) => {
      misc.loading = true;
    },
    chatMessageReceived: (misc, action) => {
      misc.chatMessage = action.payload;
      misc.loading = false;
    },
    chatMessageFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  
  chatsRequested,
  chatsReceived,
  chatsRequestFailed,

  unreadChatCountRequested,
  unreadChatCountReceived,
  unreadChatCountFailed,

  chatMessageRequested,
  chatMessageReceived,
  chatMessageFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "chat";


export const getChat = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}`,
      method: "get",
      params,
      callback,
      onStart: chatsRequested.type,
      onSuccess: chatsReceived.type,
      onError: chatsRequestFailed.type,
    })
  );
};

export const getUnreadChatCount = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/unreadCount`,
      method: "get",
      callback,
      onStart: unreadChatCountRequested.type,
      onSuccess: unreadChatCountReceived.type,
      onError: unreadChatCountFailed.type,
    })
  );
};
export const addChat = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}`,
      method: "post",
      data,
      callback,
    })
  );
};

export const getChatMessage = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/message/${params}`,
      method: "get",
      callback,
      onStart: chatMessageRequested.type,
      onSuccess: chatMessageReceived.type,
      onError: chatMessageFailed.type,
    })
  );
};
export const sendChatMessage = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/message`,
      method: "post",
      data,
      callback,
    })
  );
};
export const readChatMessage = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/message/read/${params}`,
      method: "put",
      data,
      callback,
    })
  );
};

export const userChat = createSelector(
  (state) => state.entities.chat,
  (chat) => chat
);
