import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import searchClose from "../../../include/images/x-close-24x24.svg";
import { useRef } from "react";
import { connect } from "react-redux";
import { postReports, sendCommentDM, sendPostDM } from "../../../store/post";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import { getUser, getUsersDropdownData } from "../../../store/users";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import { getProfile } from "../../../utils/localStorageServices";
import close1 from "../../../include/images/close-24x24.svg";
import close from "../../../include/images/x-close-24x24.svg";
import ImageHolder from "../../../common/customStyles/imageHolder";
import noRecord from "../../../include/images/nrb-img.svg";

const DmPosts = (props) => {
  var ref = useRef(null);
  const [textSearched, setTextSearched] = useState();
  const [userDropdown, setUserDropdown] = useState(null);
  const [sentUsers, setSentUsers] = useState([]);
  const profile = getProfile();
  let postId = props.selectedItem?._id;
  useEffect(() => {
    if (profile?.profile) {
      if (props?.getUser?.userDropdownData?.data) {
        setUserDropdown(props?.getUser?.userDropdownData?.data);
      } else {
        props.getUsersDropdownData({ keyword: "" }, (res) => {
          if (res && res?.status === 200) {
            setUserDropdown(res?.data?.data);
          } else {
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      }
    }
  }, []);
  useEffect(() => {
    if (textSearched) {
      const payload = {
        keyword: textSearched ? textSearched : "",
      };
      props.getUsersDropdownData(payload, (res) => {
        if (res && res.status === 200) {
          setUserDropdown(res?.data?.data);
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, [textSearched]);
  const handleSendRequest = (userId) => {
    if (!sentUsers.includes(userId)) {
      let payload = {
        users: [userId],
      };
      if (props?.comments) {
        props.sendCommentDM(postId, payload, (res) => {
          if (res && res.status === 200) {
            setSentUsers([...sentUsers, userId]);
          } else {
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        props.sendPostDM(postId, payload, (res) => {
          if (res && res.status === 200) {
            setSentUsers([...sentUsers, userId]);
          } else {
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      }
    } else {
      toast(<AlertError message={"Already Sent!"} />);
    }
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <Modal
        className="modal fade signup-modal font-family-poppins"
        id="reportPostModal"
        dialogClassName="modal-dialog modal-dialog-centered xs-modal"
        contentClassName="modal-content"
        show={props?.show}
        onHide={() => props?.onHide()}
      >
        <div class="signup-modal-parent d-flex flex-wrap">
          <div class="signup-modal-box">
            <div className="signup-modal-header">
              <div class="smh-insider position-relative">
                <h4>Send as DM</h4>
                {props.page == "viewpost" && (
                  <div
                    class="signup-modal-close ms-auto position-absolute top-0 end-0"
                    data-bs-dismiss="modal"
                    onClick={() => props?.onHide()}
                  >
                    <img src={close1} alt="x close icon" />
                  </div>
                )}
                <div class="modal_close-box d-lg-none">
                  <div class="signup-modal-close ms-auto" data-bs-dismiss="modal" onClick={() => props?.onHide()}>
                    <img src={close1} alt="x close icon" />
                  </div>
                </div>
              </div>
            </div>
            <Modal.Body className="signup-modal-body pt-0">
              <div class="tc-right-inside">
                <div class="modal-serch-box position-relative">
                  <input
                    type="text"
                    class="modal-search"
                    placeholder="Search"
                    value={textSearched}
                    onChange={(e) => setTextSearched(e.target.value)}
                  />
                  <button type="button" class="modal-search-close-icon" onClick={() => setTextSearched("")}>
                    <img src={searchClose} alt="alt close" />
                  </button>
                </div>
                <div class="tc-search-result">
                  {userDropdown && userDropdown.filter((user) => user?.isFollowing || user?.isFollowed).length > 0 ? (
                    userDropdown.map(
                      (user) =>
                        (user?.isFollowing || user?.isFollowed) && (
                          <div
                            class="tc-friends-row d-flex flex-wrap align-items-center justify-content-between"
                            key={user?._id}
                          >
                            <div class="friends-list d-flex flex-wrap align-items-center">
                              <div class="friends-img-box">
                                {user?.profilePicture?.original ? (
                                  <img
                                    src={
                                      user?.profilePicture?.original
                                        ? process.env.REACT_APP_MEDIA + user?.profilePicture?.original
                                        : ""
                                    }
                                    alt={user?.firstName}
                                  />
                                ) : (
                                  <ImageHolder image={null} name={user?.firstName?.slice(0, 1)} />
                                )}
                              </div>
                              <div class="friends-text-box text-capitalize">
                                {`${user?.firstName} ${user?.lastName}`}
                              </div>
                            </div>
                            <div className="tc-friends-btn">
                              <button
                                className="btn btn-default btn-block"
                                onClick={() => handleSendRequest(user._id)}
                                disabled={sentUsers.includes(user._id)}
                              >
                                {sentUsers.includes(user._id) ? "Sent" : "Send"}
                              </button>
                            </div>
                          </div>
                        ),
                    )
                  ) : (
                    <div
                      className="no-records-container d-flex align-items-center justify-content-center mt-4"
                      style={{ height: "400px" }}
                    >
                      <div className="no-records-box text-center">
                        <div className="nrb-image">
                          <img src={noRecord} alt="no records" />
                        </div>
                        <h6>No Users Found</h6>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
          </div>
          {props.page != "viewpost" && (
            <div class="signup-modal-close-box d-none d-lg-block">
              <div class="signup-modal-close ms-auto" data-bs-dismiss="modal">
                <img src={close} alt="x close icon" onClick={() => props?.onHide()} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  postReports: (id, parmas, callback) => dispatch(postReports(id, parmas, callback)),
  getUsersDropdownData: (params, callback) => dispatch(getUsersDropdownData(params, callback)),
  sendPostDM: (param, data, callback) => dispatch(sendPostDM(param, data, callback)),
  sendCommentDM: (param, data, callback) => dispatch(sendCommentDM(param, data, callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DmPosts));
