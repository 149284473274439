import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import close from "../../include/images/close-24x24.svg";

import RightFeedSidebar from "../../components/common/pageInfoSidebar/rightFeedSidebar";
import LeftFeedSidebar from "../../components/common/pageInfoSidebar/leftSideBar";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { getProfileData, getUser } from "../../store/users";
import { checkToken, getProfile } from "../../utils/localStorageServices";
import PageInfoSidebar from "../../components/common/pageInfoSidebar/pageInfoSidebar";
import {
  getEnvironmentalistList,
  getOrganizationList,
  getRegionList,
  getScienceAndEducationCategory,
  getSpeciesCategoryList,
  getSpeciesList,
  getWetMarketList,
  getZooList,
  sideBarApisListings,
} from "../../store/sidebarApis";
import { getFeaturedSponsors, getSubscription } from "../../store/subscription";
import ImageHolder from "../../common/customStyles/imageHolder";
import searchIcon from "../../include/images/searchIcon.svg";
import { getChat, userChat } from "../../store/chat";
import Skeleton from "react-loading-skeleton";

const ChatLayout = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showRightMenu, setShowRightMenu] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [filterRecordCount, setFilterRecordCount] = useState(0);
  const [selectSpeciesCategory, setSelectedSpeciesCategory] = useState();
  const [selectSpeciesCategoryName, setSelectedSpeciesCategoryName] = useState();
  const USRegion = props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country === "US");
  const otherRegion = props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country !== "US");
  const allRegions = props?.sideBarApisListings?.regionList?.data;
  const speciesCategories = props?.sideBarApisListings?.speciesCategory?.data;
  const zooAndParksList = props?.sideBarApisListings?.zoo?.data;
  const organizationList = props?.sideBarApisListings?.organizationList?.data;
  const environmentalistList = props?.sideBarApisListings?.environmentalist?.data;

  const wetmarketList = props?.sideBarApisListings?.wetMarket?.data;
  const scienceAndEducationCategory = props?.sideBarApisListings?.scienceAndEducationCategory?.data;
  const isFollowersPath = window.location.pathname.includes("/followers");
  const profile = getProfile();
  const history = useHistory();
  const sponsorFeature =
    props.getSubscription && props.getSubscription.sponsorFeature && props.getSubscription.sponsorFeature.data;
  const scrollRef = useRef(null);

  useEffect(() => {
    setLoadingMain(true);
    const fetchData = async () => {
      if (props.page !== "guestProfile" && profile?.profile) {
        await props.getProfileData((res) => {
          if (res && res.status === 200) {
            checkAllCallsComplete();
          }
        });
      }
      const fetchIfEmpty = async (list, fetchFunction) => {
        if (!list) {
          await fetchFunction({}, (res) => {
            if (res && res.status === 200) {
              checkAllCallsComplete();
            }
          });
        }
      };
      await fetchIfEmpty(speciesCategories, props.getSpeciesCategoryList);
      await fetchIfEmpty(zooAndParksList, props.getZooList);
      await fetchIfEmpty(organizationList, props.getOrganizationList);
      await fetchIfEmpty(environmentalistList, props.getEnvironmentalistList);
      await fetchIfEmpty(scienceAndEducationCategory, props.getScienceAndEducationCategory);
      await fetchIfEmpty(wetmarketList, props.getWetMarketList);
      await fetchIfEmpty(sponsorFeature, props.getFeaturedSponsors);
    };
    const checkAllCallsComplete = () => {
      if (
        (props.page === "guestProfile" || profile?.profile) &&
        speciesCategories !== undefined &&
        zooAndParksList !== undefined &&
        organizationList !== undefined &&
        environmentalistList !== undefined &&
        scienceAndEducationCategory !== undefined &&
        wetmarketList !== undefined
      ) {
        setLoadingMain(false);
      }
    };
    if (checkToken() || props.page === "guestProfile") {
      fetchData();
    } else {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add("filter-open");
    } else {
      document.body.classList.remove("filter-open");
    }
  }, [showMenu]);
  const handleSearch = (e) => {
    let keyword = e.target.value.toLowerCase();
    props.setKeyword(e.target.value);
    let tempData = [...props.chatUsers];
    const tempArray = tempData.filter((item) => {
      const firstName = item?.otherMember?.firstName?.toLowerCase() || "";
      const lastName = item?.otherMember?.lastName?.toLowerCase() || "";
      return firstName.includes(keyword) || lastName.includes(keyword);
    });
    props.setChatData(tempArray);
  };
  const handleScroll = () => {
    const element = scrollRef.current;
    if (element) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        props.setPage((prevPageCount) => prevPageCount + 1);
      }
    }
  };
  return (
    <>
      <PageLayout>
        <main id="main">
          <div class="page-panel-container font-family-poppins">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="ei-category-container d-flex flex-wrap">
                    <div class={`ei-category-left ${props.loading ? "mt-2" : ""}`}>
                      <div class={showMenu ? "panel-sidebar open" : "panel-sidebar"}>
                        <div class="panel-header d-lg-none">
                          <div class="panel-header-inside position-relative">
                            <div class="panel-header-title">Browse Category</div>
                            <div
                              class="panel-close-button d-flex align-items-center justify-content-center"
                              onClick={() => setShowMenu(false)}
                            >
                              <img src={close} alt="close__24x24" />
                            </div>
                          </div>
                        </div>
                        <PageInfoSidebar
                          page={page}
                          totalCount={totalCount}
                          filterRecordCount={filterRecordCount}
                          setFilterRecordCount={setFilterRecordCount}
                          setPage={setPage}
                          USRegions={USRegion}
                          otherRegion={otherRegion}
                          regions={allRegions}
                          setShowMenu={setShowMenu}
                          showMenu={showMenu}
                          speciesCategories={speciesCategories}
                          zooAndParksList={zooAndParksList}
                          organizationList={organizationList}
                          environmentalistList={environmentalistList}
                          wetmarketList={wetmarketList}
                          scienceAndEducationCategory={scienceAndEducationCategory}
                          selectSpeciesCategory={selectSpeciesCategory}
                          setSelectedSpeciesCategory={setSelectedSpeciesCategory}
                          setSelectedSpeciesCategoryName={setSelectedSpeciesCategoryName}
                          selectSpeciesCategoryName={selectSpeciesCategoryName}
                          sponsorFeature={sponsorFeature}
                          loading={props.loading}
                        />
                      </div>
                    </div>
                    {props?.children}
                    {props.selectUser && (
                      <div className={showRightMenu ? "panel-sidebar-right open" : "panel-sidebar-right"}>
                        <div className="recent-chat-pannel">
                          <div className="rcp-head d-flex flex-column gap-3">
                            <div className="rcp-heading-row d-flex align-items-center justify-content-between position-relative">
                              <div
                                className="panel-close-button d-flex d-xl-none align-items-center justify-content-center"
                                onClick={() => setShowRightMenu(!showRightMenu)}
                              >
                                <img src={close} alt="close__24x24" />
                              </div>
                              <div className="rcp-heading-label">Recent Chats</div>
                              <div className="rcp-heading-btn">
                                <Link
                                  type="button"
                                  className="btn-read-more font-semibold"
                                  data-bs-toggle="modal"
                                  data-bs-target="#mewChatModal"
                                  onClick={() => props?.setAddChat(true)}
                                >
                                  + Add Chat
                                </Link>
                              </div>
                            </div>
                            <div className="chat-search-pannel">
                              <span className="form-control-feedback">
                                <img src={searchIcon} alt="" />
                              </span>
                              <input
                                onChange={(e) => {
                                  handleSearch(e);
                                }}
                                value={props.keyword}
                                type="text"
                                className="chat-search-input"
                                placeholder="Search"
                              />
                            </div>
                          </div>
                          <div
                            className="rcp-body flex-grow-1"
                            // ref={scrollRef}
                            //  onScroll={handleScroll}
                          >
                            {props?.loading ? (
                              <ul className="lb-member-list">
                                {[0, 1, 2, 3, 4].map((d, i) => (
                                  <li key={i}>
                                    <div className="lb-member-box w-100 h-100">
                                      <Skeleton borderRadius={"10px"} height={100} width={"100%"} />
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <ul className="lb-member-list">
                                {props.chatData?.map((chat) => {
                                  const messageDate = new Date(chat.createdAt);
                                  const today = new Date();
                                  const yesterday = new Date();
                                  yesterday.setDate(today.getDate() - 1);
                                  let formattedDate;
                                  if (messageDate.toDateString() === today.toDateString()) {
                                    formattedDate = `${messageDate.toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: false, // Use 24-hour format
                                    })}`;
                                  } else if (messageDate.toDateString() === yesterday.toDateString()) {
                                    formattedDate = "Yesterday";
                                  } else {
                                    formattedDate = messageDate.toLocaleDateString("en-US", {
                                      month: "short",
                                      day: "numeric",
                                    });
                                  }

                                  return (
                                    <li
                                      key={chat._id}
                                      className="lb-member-item"
                                      onClick={() => {
                                        props.setSelectUser(chat);
                                        setShowRightMenu(!showRightMenu);
                                      }}
                                    >
                                      <div className="lb-member-box w-100 h-100 d-flex flex-wrap align-items-center">
                                        <div className="lb-member-img">
                                          {chat.otherMember?.profilePicture?.small ? (
                                            <img
                                              src={
                                                process.env.REACT_APP_MEDIA + chat.otherMember.profilePicture?.small ||
                                                "default-profile-picture.png"
                                              }
                                              alt={`${chat?.otherMember?.firstName} ${chat?.otherMember?.lastName}`}
                                            />
                                          ) : (
                                            <ImageHolder
                                              image={null}
                                              name={chat?.otherMember?.firstName?.slice(0, 1)}
                                            />
                                          )}
                                        </div>
                                        <div className="lb-member-text">
                                          <h5 className="text-capitalize">
                                            {chat?.otherMember?.firstName} {chat?.otherMember?.lastName}
                                          </h5>
                                          <div className="lb-member-intro d-flex align-items-center">
                                            <p>{chat?.lastMessage}</p>
                                            {chat?.unreadMessageCount > 0 && <span>{chat?.unreadMessageCount}</span>}
                                          </div>
                                          <div className="lb-member-status">{formattedDate}</div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </PageLayout>
      <div class="sticky-bottom-wrapper sticky-bottom d-xl-none">
        <div class="sbw-inside w-100 d-flex flex-wrap">
          <div class="sbw-item w-50 d-lg-none">
            <button
              type="button"
              class="sbw-btn sbw-btn-green"
              id="openLeftPanel"
              onClick={() => setShowMenu(!showMenu)}
            >
              Browse Category
            </button>
          </div>
          <div class="sbw-item">
            <button
              type="button"
              class="sbw-btn sbw-btn-light-green"
              id="openRightPanel"
              onClick={() => setShowRightMenu(!showRightMenu)}
            >
              Chat
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProfileData: (params, callback) => dispatch(getProfileData(params, callback)),
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  getSpeciesCategoryList: (params, callback) => dispatch(getSpeciesCategoryList(params, callback)),
  getSpeciesList: (params, callback) => dispatch(getSpeciesList(params, callback)),
  getZooList: (params, callback) => dispatch(getZooList(params, callback)),
  getOrganizationList: (params, callback) => dispatch(getOrganizationList(params, callback)),
  getEnvironmentalistList: (params, callback) => dispatch(getEnvironmentalistList(params, callback)),
  getWetMarketList: (params, callback) => dispatch(getWetMarketList(params, callback)),
  getScienceAndEducationCategory: (params, callback) => dispatch(getScienceAndEducationCategory(params, callback)),
  getFeaturedSponsors: (callback) => dispatch(getFeaturedSponsors(callback)),
  getChat: (params, callback) => {
    dispatch(getChat(params, callback));
  },
});

const mapStateToProps = (state) => ({
  getUser: getUser(state)?.profile,
  sideBarApisListings: sideBarApisListings(state),
  getSubscription: getSubscription(state),
  userChat: userChat(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ChatLayout));
