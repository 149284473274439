import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import successIcon from "../../include/images/check-icon.ec9704e601f898137e1324568528cfa2.svg";

import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { updateTransaction, updateTransactionStripe } from "../../store/subscription";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
//Education Page
const SponsorSuccessFull = (props) => {
  var ref = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const subscription_id = queryParams.get("subscription_id");
    const baToken = queryParams.get("ba_token");
    const token = queryParams.get("token");
    const type = queryParams.get("type");
    const paymentMode = queryParams.get("pay");
    const cancel = queryParams.get("cancel");
    const tempSelectSpecies = JSON.parse(sessionStorage.getItem("tempSelectSpecies") || "[]");
    const sessionId = JSON.parse(sessionStorage.getItem("session_id") || null);

    if ((subscription_id && tempSelectSpecies && token) || sessionId) {
      const speciesId = tempSelectSpecies;
      const params = {
        subscription_id,
        speciesId,
        token,
      };

      if (type === "individual" || type === "corporate") {
        params.isCompany = type === "corporate";
        if (paymentMode == "stripe") {
          const payload = {
            session_id: sessionId,
          };
          props.updateTransactionStripe(payload, (res) => {
            if (res && res.status === 200) {
              if (cancel) {
                toast(<AlertError message={res?.data?.data} />);
              } else {
                sessionStorage.removeItem("session_id");
                sessionStorage.removeItem("tempSelectSpecies");
              }
            } else {
              console.log("Error updating transaction", res);
            }
          });
        } else {
          props.updateTransaction(params, (res) => {
            if (res && res.status === 200) {
              if (cancel) {
              } else {
                sessionStorage.removeItem("tempSelectSpecies");
              }
            } else {
              console.log("Error updating transaction", res);
            }
          });
        }
      }
    }
  }, [location]);
  return (
    <PageLayout>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <main className="main-content">
        <div className="content-container">
          <div className="container text-center">
            <div className="error">
              <div className="mb-4" style={{ padding: "20px" }}>
                <img src={successIcon} alt="Error illustration" style={{ height: "120px" }} />
              </div>

              <h3 className="error-message w-75 mb-5">
                Thank you for sponsoring this species! Your generous contribution helps protect and preserve endangered
                species. We truly appreciate your support!
              </h3>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Link to={"/"} className="btn btn-default header-btn h-40 pt-10 cursor-pointer">
                Back To Home
              </Link>
            </div>
          </div>
        </div>
      </main>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateTransaction: (params, callback) => dispatch(updateTransaction(params, callback)),
  updateTransactionStripe: (params, callback) => dispatch(updateTransactionStripe(params, callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SponsorSuccessFull));
