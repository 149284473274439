import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { getProfile } from "../../utils/localStorageServices";
import arjunPic from "../../include/images/arjun.jpg";
import worldMap from "../../include/images/world-map.png";
import { connect } from "react-redux";
import { followUnfollowFriends, getGuestProfile, getMyFeedData, updateYourProfile } from "../../store/users";
import wIcon3 from "../../include/images/w-icon-3.svg";
import wIcon5 from "../../include/images/w-icon-5.svg";
import wIcon2 from "../../include/images/w-icon-2.svg";
import liked from "../../include/images/fill-heart-icon.svg";
import { getPublicPost, likeUnlikePost } from "../../store/post";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactPlayer from "react-player";
import HTMLReactParser from "html-react-parser";
import { getRelativeTime } from "../../utils/helperFunctions";
import { Dropdown } from "react-bootstrap";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import DmPosts from "../../components/feed/modals/dmPosts";
import Skeleton from "react-loading-skeleton";
import AlertSuccess from "../../common/alerts/alertSuccess";
import noRecord from "../../include/images/nrb-img.svg";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import LoadingBar from "react-top-loading-bar";
import DescriptionWithLink from "../../common/description/descriptionWithLink";
import MessageModal from "../../components/common/chatModal/messageModal";

//Enivronment Press corp Page
const EnvironmentalPressCorp = (props) => {
  var ref = useRef(null);
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [userData, setUserData] = useState("");
  const [follow, setFollow] = useState(false);
  const profile = getProfile();
  const [media, setMedia] = useState([]);
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDmPost, setShowDmPost] = useState(false);
  const isMobile = useCheckMobileScreen();
  const userSlug = process.env.REACT_APP_MODE === "development" ? "anjan-sundaram" : "anjan-s";
  const [showMessage,setShowMessage]=useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingDetail(true);
    props.getGuestProfile(userSlug, (res) => {
      if (res && res.status == 200) {
        props.getMyFeedData(userSlug, (res) => {
          if (res && res.status === 200) {
            setMedia(res.data?.data);
            setLoadingDetail(false);
          }
        });
        setUserData(res?.data?.data);
        if (profile?.profile?.preferredEnvironmentalists?.includes(res?.data?.data?._id)) {
          setFollow(true);
        }
      }
    });
  }, []);
  const likeAndUnlikePost = (type, id) => {
    const data = id;
    ref?.current?.continuousStart();
    props?.likeUnlikePost(type, data, (res) => {
      if (res && res.status == 200) {
        props.getMyFeedData(userSlug, (res) => {
          if (res && res.status === 200) {
            ref?.current?.complete();
            setMedia((prevMedia) => {
              return prevMedia.map((post) => {
                if (post._id === id) {
                  const isLiking = type === "like";
                  return {
                    ...post,
                    likeCount: isLiking ? post.likeCount + 1 : post.likeCount - 1,
                    isLiked: isLiking,
                  };
                }
                return post;
              });
            });
          }
        });
      } else {
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };
  const renderFiles = (files, item) => {
    if (files.length > 1) {
      const firstFile = files[0];
      const remainingCount = files.length - 1;
      const isVideo = /^(mp4|mpeg|webm|video)$/i.test(firstFile.mediaType);

      return (
        <>
          {isVideo ? (
            <video controls>
              <source src={process.env.REACT_APP_MEDIA + firstFile.original} />
            </video>
          ) : (
            <img src={process.env.REACT_APP_MEDIA + firstFile.original} alt="" />
          )}
          <div
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              background: "rgba(0,0,0,0.5)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            (+{remainingCount}) View More
          </div>
        </>
      );
    } else if (files.length === 1) {
      const file = files[0];
      const isVideo = /^(mp4|mpeg|webm|video)$/i.test(file.mediaType);

      return (
        <>
          {(file?.mediaType === "image" || file?.mediaType === "sketch" || file?.mediaType === "essay") && (
            <img className="w-100 h-100 object-fit-cover" src={process.env.REACT_APP_MEDIA + file?.original} alt="" />
          )}
          {file?.mediaType === "video" && (
            <>
              {file?.original && file?.original.startsWith("https") ? (
                <ReactPlayer className="w-100 react-player-iframe" url={file?.original} controls={true} />
              ) : (
                <video className="post-video-tag">
                  <source src={process.env.REACT_APP_MEDIA + file?.original} />
                </video>
              )}
            </>
          )}
          {file?.mediaType === "document" && file?.original && (
            <iframe
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                process.env.REACT_APP_MEDIA + file?.original,
              )}&embedded=true`}
              className="w-100 pdf-preview"
              title="PDF Preview"
            />
          )}
        </>
      );
    }
    return null;
  };
  const renderDescription = (description) => {
    if (!description) return "";
    const characterLimit = 400;
    if (description.length <= characterLimit) {
      return HTMLReactParser(description);
    }

    const shortDescription = description.substring(0, characterLimit) + "... ";
    return <>{HTMLReactParser(shortDescription)}</>;
  };
  const getShareUrl = (slug) => {
    const baseUrl =
      process.env.REACT_APP_MODE === "development"
        ? "https://www.dev.environmentalinclusion.com/posts"
        : "https://www.environmentalinclusion.com/posts";
    return `${baseUrl}/${encodeURIComponent(slug)}`;
  };
  const toggleDmPosts = () => {
    setShowDmPost(!showDmPost);
  };
  const handleEnvFollow = (e) => {
    e.preventDefault();
    const isCurrentlyFollowing = userData?.isFollowing;

    props.followUnfollowFriends(userData._id, (res) => {
      if (res && res.status === 200) {
        props.getGuestProfile(userSlug, (res) => {
          if (res && res.status === 200) {
            setUserData(res?.data?.data);
          }
        });

        if (isCurrentlyFollowing) {
          toast(<AlertSuccess message={`You have unfollowed Anjan Sundaram.`} />);
          setFollow(false);
        } else {
          toast(<AlertSuccess message={`You are now following Anjan Sundaram.`} />);
          setFollow(true);
        }
      } else {
        toast(<AlertError message="Something went wrong. Please try again." />);
      }
    });
  };

  return (
    <PageLayout>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <div class="heading-wrapper overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hw-title text-center">
                <h1>
                  <span>ENVIRONMENTAL PRESS CORP.</span>
                </h1>
              </div>
              <div class="hw-subtitle text-center">
                <h2>
                  <span>Stories of the Planet, Voices for Animals</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container pt-0">
        <div class="container container1">
          <div class="row">
            <div class="col-md-12">
              <div class="default-heading d-flex flex-wrap justify-content-between">
                <h2>Meet Anjan Sundaram</h2>
                {!loadingDetail && (
                  <div className={`default-cta-btn d-flex mt-2`}>
                    {profile?.profile ? (
                      <button onClick={()=>setShowMessage(true)} className={`btn btn-outline-green h-40 `}>
                        Message
                      </button>
                    ) : (
                      <Link to="/login" class="btn btn-default btn-xxl h-40">
                        Message
                      </Link>
                    )}
                    {profile?.profile ? (
                      <button onClick={handleEnvFollow} className={`btn btn-default h-40`}>
                        {userData?.isFollowing ? "Unfollow" : "Follow"}
                      </button>
                    ) : (
                      <Link to="/login" class="btn btn-default btn-xxl h-40">
                        Follow
                      </Link>
                    )}
                  </div>
                )}
              </div>
              <div class="default-cta-row d-flex flex-column flex-md-row gap-4 gap-md-5">
                <div class="default-cta-content">
                  <h3>Anjan Sundaram: Championing Environmental Justice and Giving Voice to Endangered Animals</h3>
                </div>
              </div>
              <div class="default-intro-row d-flex flex-column flex-md-row gap-4 gap-md-5">
                <div class="default-image-box flex-shrink-0">
                  <div class="dib-hold">
                    <img src={arjunPic} alt="" />
                  </div>
                </div>
                <div class="default-image-text flex-grow-1">
                  <p>
                    Sundaram leads the Environmental Press Corp for Environmental Inclusion, advocating for more
                    inclusive environmental reporting. Environmental Press Corp focuses on the environment has brought
                    to light the real war on the environmentalists. Endangered animals need a voice to bring awareness
                    and action to protecting them. Anjan and many others who are a part of the Environmental Inclusion
                    project bring journalistic expertise to telling the complicated scientific and personal stories
                    behind the scenes regarding the conservation efforts.
                  </p>
                  <p>
                    Anjan Sundaram is a journalist, author, artist, and war correspondent. He holds a degree in
                    mathematics from Yale and a Ph.D. in Journalism. His work covers conflict zones, environmental
                    issues, and authoritarianism. He is a TED speaker and the author of books such as Stringer and Bad
                    News, documenting his experiences in Africa. You can find more details about his career here.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container pt-0">
        <div class="container container1">
          <div class="row">
            <div class="col-md-12">
              <div class="default-heading">
                <h2>Journalists around the World</h2>
              </div>
            </div>
          </div>
          <div class="default-map-row">
            <div class="row">
              <div class="col-lg-5">
                <div class="dmr-text-box">
                  <p>
                    Environmental Press Corp. collaborates with Environmental Inclusion to shine a spotlight on
                    endangered species and the critical efforts to prevent their extinction. Our mission is supported by
                    a network of courageous journalists, scientists, and biologists who craft powerful stories that
                    reveal the ongoing battle against environmental destruction.
                  </p>
                  <p>
                    These dedicated individuals often face grave risks from cartels, gangs, poachers, militias, and
                    governments perpetrating atrocities against the planet's most vulnerable creatures. By sponsoring
                    these stories, you can help raise awareness of the daily threats these animals face and inspire
                    actionable steps toward conservation. We aim to honor and amplify the voices of environmental
                    heroes-the last line of defense standing between these species and complete extinction.
                  </p>
                  <div class="dmr-btn mb-3 mb-sm-0">
                    {profile?.profile ? (
                      <Link
                        to={{ pathname: "/addsponsor", state: "Environmental Press Corp" }}
                        class="btn btn-default btn-xxl h-40 pt-10"
                      >
                        Sponsor
                      </Link>
                    ) : (
                      <Link to={{ pathname: "/login" }} class="btn btn-default btn-xxl h-40 pt-10">
                        Sponsor
                      </Link>
                    )}
                    {profile?.profile ? (
                      <Link
                        to={{ pathname: "/story", state: "Environmental Press Corp" }}
                        class="btn btn-default btn-xxl h-40 pt-10"
                      >
                        Create a Story
                      </Link>
                    ) : (
                      <Link to={{ pathname: "/login" }} class="btn btn-default btn-xxl h-40 pt-10">
                        Create a Story
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="dmr-map-box">
                  <div class="dmr-map">
                    <img class="insider-map" src={worldMap} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container pt-0">
        <div class="container container1">
          <div class="row">
            <div class="col-md-12">
              <div class="default-heading d-flex justify-content-between">
                <h2>Journalists around the World</h2>
                <Link
                  className="btn-link w-25 mt-4"
                  to={{ pathname: `/profile/${userSlug}`, state: "Environmental Press Corp" }}
                >
                  View All Posts
                </Link>
              </div>
            </div>
          </div>
          <div className="default-card-list-row">
            <div className="default-card-list d-block d-sm-flex flex-wrap">
              {loadingDetail || !media ? (
                Array.from({ length: 6 }).map((_, index) => (
                  <div className="default-card-item" key={index}>
                    <div className="default-card-box d-flex flex-column overflow-hidden">
                      <Skeleton height={300} />
                      <div className="default-card-content">
                        <Skeleton count={2} height={20} style={{ marginBottom: 10 }} />
                        <Skeleton width={100} height={15} />
                      </div>

                      <div className="default-card-bottom d-flex align-items-center mt-auto">
                        <Skeleton width={60} height={30} style={{ marginRight: 10 }} />
                        <Skeleton width={60} height={30} style={{ marginRight: 10 }} />
                        <Skeleton width={60} height={30} />
                      </div>
                    </div>
                  </div>
                ))
              ) : media.length > 0 ? (
                media.slice(0, 6).map((item, index) => (
                  <div className={`default-card-item ${isMobile ? "w-100" : ""}`} key={index}>
                    <div className="default-card-box d-flex flex-column overflow-hidden">
                      <div className="default-card-img">{renderFiles(item.files, item)}</div>
                      <div className="default-card-content">
                        <p>
                          <DescriptionWithLink description={renderDescription(item?.description)} />
                        </p>
                        <div className="default-card-date">{getRelativeTime(item && item.createdAt)}</div>
                        <div className="default-card-btn">
                          <Link
                            to={
                              profile?.profile
                                ? { pathname: `/posts/${item.slug}`, state: "environmental-press-corp" }
                                : { pathname: "/login" }
                            }
                            className="readmore-link"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                      <div className="default-card-bottom d-flex align-items-center mt-auto">
                        <div className="d-button-link-wrap w-100 d-flex align-items-center justify-content-between">
                          <div className="d-button-link-item">
                            <div className="d-button-link-box w-100 h-100 d-flex gap-2">
                              <div className="d-button-link-icon flex-shrink-0 cursor-pointer">
                                <img
                                  src={item?.isLiked ? liked : wIcon3}
                                  alt="w icon 3"
                                  onClick={() => {
                                    if (!profile?.profile) {
                                      history.push("/login");
                                    } else {
                                      likeAndUnlikePost(item?.isLiked ? "unlike" : "like", item?._id);
                                    }
                                  }}
                                />
                              </div>
                              <div className="d-button-link-label">{item?.likeCount == 0 ? "" : item?.likeCount}</div>
                            </div>
                          </div>
                          <div className="v-line"></div>
                          <div className="d-button-link-item">
                            <div className="d-button-link-box w-100 h-100 d-flex gap-2">
                              <div className="d-button-link-icon flex-shrink-0">
                                <Dropdown className="btn-group d-flex align-items-center justify-content-center cursor-pointer">
                                  <Dropdown.Toggle
                                    as="span"
                                    id="dropdown-basic"
                                    className="w-iconl-box dropdown-toggle"
                                  >
                                    <img
                                      src={wIcon5}
                                      alt="w icon 5"
                                      onClick={(e) => {
                                        if (!profile?.profile) {
                                          history.push("/login");
                                        }
                                      }}
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropdown-menu share-post custom-dropdown-menu">
                                    <FacebookShareButton
                                      url={getShareUrl(item?.slug)}
                                      description={item?.description}
                                      className="custom-share-post"
                                    >
                                      <Dropdown.Item className="dropdown-item" eventKey={"1"}>
                                        <Link
                                          to="#"
                                          className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center"
                                        >
                                          <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                                            <svg
                                              width="20"
                                              height="21"
                                              viewBox="0 0 20 21"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.7974 16.1444V10.7915H12.5942L12.8637 8.70543H10.7974V7.37352C10.7974 6.76958 10.9652 6.35797 11.8313 6.35797H12.936V4.49269C12.4013 4.43612 11.8639 4.40872 11.3263 4.41061C9.73351 4.41061 8.64314 5.38274 8.64314 7.16813V8.70543H6.8418V10.7915H8.64314V16.1444H10.7974Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </div>

                                          <div className="dropdown-item-text custom-share-dropdown-text">Facebook</div>
                                        </Link>
                                      </Dropdown.Item>
                                    </FacebookShareButton>
                                    <TwitterShareButton url={getShareUrl(item?.slug)} className="custom-share-post">
                                      <Dropdown.Item className="dropdown-item" eventKey={"2"}>
                                        <Link
                                          to="#"
                                          className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center"
                                        >
                                          <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                                            <svg
                                              width="20"
                                              height="21"
                                              viewBox="0 0 20 21"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M3.0317 5L8.05085 11.6187L3 17H4.13674L8.55875 12.2886L12.1316 17H16L10.6984 10.009L15.3997 5H14.263L10.1905 9.33911L6.90008 5H3.0317ZM4.70336 5.8258H6.48051L14.3281 16.1741H12.5509L4.70336 5.8258Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </div>

                                          <div className="dropdown-item-text custom-share-dropdown-text">Twitter</div>
                                        </Link>
                                      </Dropdown.Item>
                                    </TwitterShareButton>
                                    <Dropdown.Item className="dropdown-item" eventKey={"3"}>
                                      <Link
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectedItem(item);
                                          toggleDmPosts();
                                        }}
                                        className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center"
                                      >
                                        <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                                          <svg
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14 7.83325C15.1046 7.83325 16 6.93782 16 5.83325C16 4.72868 15.1046 3.83325 14 3.83325C12.8954 3.83325 12 4.72868 12 5.83325C12 6.93782 12.8954 7.83325 14 7.83325Z"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M6 12.5C7.10457 12.5 8 11.6046 8 10.5C8 9.39543 7.10457 8.5 6 8.5C4.89543 8.5 4 9.39543 4 10.5C4 11.6046 4.89543 12.5 6 12.5Z"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M14 17.1666C15.1046 17.1666 16 16.2712 16 15.1666C16 14.0621 15.1046 13.1666 14 13.1666C12.8954 13.1666 12 14.0621 12 15.1666C12 16.2712 12.8954 17.1666 14 17.1666Z"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M7.72656 11.5066L12.2799 14.1599"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M12.2732 6.83997L7.72656 9.4933"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </div>
                                        <div className="dropdown-item-text">Direct</div>
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          <div className="v-line"></div>
                          <div className="d-button-link-item">
                            <div className="d-button-link-box w-100 h-100 d-flex justify-content-end gap-2">
                              <Link
                                className="d-button-link-icon flex-shrink-0"
                                to={
                                  profile?.profile
                                    ? { pathname: `/posts/${item.slug}`, state: "environmental-press-corp" }
                                    : { pathname: "/login" }
                                }
                              >
                                <img src={wIcon2} alt="w icon 2" />
                              </Link>
                              <div className="d-button-link-label">
                                {item?.commentCount == 0 ? "" : item?.commentCount}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="no-records-container w-100 d-flex align-items-center justify-content-center mt-4"
                  style={{ height: "auto" }}
                >
                  <div className="no-records-box text-center">
                    <div className="nrb-image">
                      <img src={noRecord} alt="" />
                    </div>
                    <h6>No Posts Found</h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="content-container default-bottom-container">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mx-auto">
              <div class="default-bottom-content d-flex flex-column gap-4 justify-content-center align-items-center text-center">
                <div class="default-bottom-button-group d-flex flex-column flex-sm-row align-items-center gap-4">
                  {!profile?.profile && (
                    <Link to="/signup?type=journalist" class="btn btn-default h-40 pt-10">
                      Join the Movement
                    </Link>
                  )}
                </div>
                <h4>
                  Follow us for the latest updates, volunteer with the Environmental Press Corp, or share your story.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DmPosts show={showDmPost} onHide={toggleDmPosts} selectedItem={selectedItem} />
      <MessageModal show={showMessage} onHide={()=>setShowMessage(!showMessage)}  userData={userData} />
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getGuestProfile: (params, callback) => dispatch(getGuestProfile(params, callback)),
  getMyFeedData: (params, callback) => dispatch(getPublicPost(params, callback)),
  likeUnlikePost: (type, id, callback) => dispatch(likeUnlikePost(type, id, callback)), // getMyFeedData
  followUnfollowFriends: (data, callback) => {
    dispatch(followUnfollowFriends(data, callback));
  },
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EnvironmentalPressCorp));
