import React, { useEffect, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import close from "../../include/images/close.svg";
import { getProfileInfo, getUser, getUsersPreferences, updateYourProfile } from "../../store/users";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import rawImage from "../../include/images/row-icon-0.png";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import RightFeedSidebar from "../../components/common/pageInfoSidebar/rightFeedSidebar";
import LeftSideBar from "../../components/common/pageInfoSidebar/leftSideBar";
import editIcon from "../../include/images/edit-2.svg";
import { getMisc, getCountry, getUsState, getUsCitiesDropdown } from "../../store/misc";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Storage, Auth } from "aws-amplify";
import Select from "react-select";
import Joi from "joi";
import { validateErrorMessage } from "../../common/validation/validation";
import { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import AlertSuccess from "../../common/alerts/alertSuccess";
import Skeleton from "react-loading-skeleton";
import { getProfile, setProfile } from "../../utils/localStorageServices";
import ProfileLayout from "../../layout/profileLayout/profileLayout";
import { Cropper } from "react-cropper";
import PostLayout from "../../layout/profileLayout/postLayout";
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const EditUserProfile = (props) => {
  var ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showRightMenu, setShowRightMenu] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  //   signup
  const [countryDropdown, setCountryDropdown] = useState([]);
  const [stateDropdown, setStateDropdown] = useState([]);
  const [cityDropdown, setCityDropdown] = useState([]);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    organizationName: "",
    website: "",
    country: {},
    state: {},
    city: {},
    preferredRegions: [],
    preferredSpecies: [],
    preferredSpeciesCategories: [],
    preferredOrganizations: [],
    preferredWetMarkets: [],
    preferredEnvironmentalists: [],
    preferredScienceAndEducations: [],
    preferredZoos: [],
    profilePicture: "",
    description: "",
  });
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [key, setKey] = useState(0);
  const profile = getProfile();
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [imageForProfile, setImageForProfile] = useState(null); // For Profile Picture cropping
  const [imageForOrganization, setImageForOrganization] = useState(null); // For Organization Logo cropping
  const [cropper, setCropper] = useState(null);

  const schemaDoc = Joi.object({
    firstName: Joi.string()
      .label("First Name")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    lastName: Joi.string()
      .label("Last Name")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    organizationName: Joi.string()
      .label("Organization Name")
      .error((errors) => validateErrorMessage(errors)),
    website: Joi.string()
      .label("Website")
      .error((errors) => validateErrorMessage(errors)),
    country: Joi.object()
      .label("Country")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    state: Joi.object()
      .label("State")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    city: Joi.object()
      .label("City")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    preferredRegions: Joi.array()
      .label("preferredRegions")
      .error((errors) => validateErrorMessage(errors)),
    preferredSpecies: Joi.array()
      .label("preferredSpecies")
      .error((errors) => validateErrorMessage(errors)),
    preferredSpeciesCategories: Joi.array()
      .label("preferredSpeciesCategories")
      .error((errors) => validateErrorMessage(errors)),
    preferredOrganizations: Joi.array()
      .label("preferredOrganizations")
      .error((errors) => validateErrorMessage(errors)),
    preferredWetMarkets: Joi.array()
      .label("preferredWetMarkets")
      .error((errors) => validateErrorMessage(errors)),
    preferredEnvironmentalists: Joi.array()
      .label("preferredEnvironmentalists")
      .error((errors) => validateErrorMessage(errors)),
    preferredScienceAndEducations: Joi.array()
      .label("preferredScienceAndEducations")
      .error((errors) => validateErrorMessage(errors)),
    preferredZoos: Joi.array()
      .label("preferredZoos")
      .error((errors) => validateErrorMessage(errors)),
    profilePicture: Joi.string()
      .required()
      .label("Profile Picture")
      .error((errors) => validateErrorMessage(errors)),
  });
  const validateDoc = (type = false) => {
    const filteredUserData = _.omit(userData, ["organizationLogo","description"]);

    const error = schemaDoc.validate(_.pickBy(filteredUserData, _.identity), {
      abortEarly: type,
    });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setError(errors);
      return false;
    }
    setError(null);
    return true;
  };

  useEffect(() => {
    setLoadingMain(true);
    props.getCountry((res) => {
      if (res && res.status === 200) {
        setLoadingMain(false);
      } else {
        setLoadingMain(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  useEffect(() => {
    if (countryDropdown && stateDropdown && _.isEmpty(userProfile) && !loadingMain) {
      window.scrollTo(0, 0);
      setLoadingMain(true);
      props.getProfileInfo((res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
          setUserProfile(res?.data?.data);
          const data = { ...userData };
          data.firstName = res?.data?.data?.firstName;
          data.lastName = res?.data?.data?.lastName;
          data.website = res?.data?.data?.website;
          data.organizationName = res?.data?.data?.organizationName;
          data.country = {
            name: res?.data?.data?.location?.countryName,
            code: res?.data?.data?.location?.country,
            id: res?.data?.data?.location?._id,
          };
          data.state =
            res?.data?.data?.location?.countryName == "United States"
              ? res?.data?.data?.location?.stateID
                ? {
                    id: res?.data?.data?.location?.stateID,
                    name: res?.data?.data?.location?.stateName,
                  }
                : res?.data?.data?.location?.stateName
                ? { name: res?.data?.data?.location?.stateName }
                : null
              : res?.data?.data?.location?.stateName
              ? { name: res?.data?.data?.location?.stateName }
              : null;
          data.city =
            res?.data?.data?.location?.countryName == "United States"
              ? res?.data?.data?.location?.cityID
                ? {
                    id: res?.data?.data?.location?.cityID,
                    name: res?.data?.data?.location?.cityName,
                  }
                : res?.data?.data?.location?.cityName
                ? { name: res?.data?.data?.location?.cityName }
                : null
              : res?.data?.data?.location?.cityName
              ? { name: res?.data?.data?.location?.cityName }
              : null;
          data.profilePicture = res?.data?.data?.profilePicture?.original;
          data.organizationLogo = res?.data?.data?.organizationLogo?.original;
          data.description = res?.data?.data?.description;
          setUserData(data);
        } else {
          setLoadingMain(false);
        }
      });
    }
  }, [countryDropdown, stateDropdown]);

  useEffect(() => {
    setLoadingMain(true);
    props.getUsState((res) => {
      if (res && res.status === 200) {
        setLoadingMain(false);
      } else {
        setLoadingMain(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  useEffect(() => {
    if (userData?.state?.id) {
      setLoadingCity(true);
      const payload = {
        id: userData?.state?.id,
      };
      props.getUsCitiesDropdown(payload, (res) => {
        if (res && res.status === 200) {
          setLoadingCity(false);
        } else {
          setLoadingCity(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, [userData?.state?.id]);

  const country = (props.getMisc && props.getMisc.Countries && props.getMisc.Countries.data) || [];

  const usState = (props.getMisc && props.getMisc.usState && props.getMisc.usState.data) || [];
  const usCities = (props.getMisc && props.getMisc.usCitiesItems && props.getMisc.usCitiesItems.data) || [];

  useEffect(() => {
    if (country) {
      const cData =
        country && country.length > 0
          ? country.map((item) => ({
              name: item.name,
              code: item.code,
              _id: item._id,
            }))
          : [];
      setCountryDropdown(cData);
    }
  }, [country]);

  useEffect(() => {
    if (usState) {
      const stateData =
        usState && usState.length > 0
          ? usState.map((item) => ({
              id: item.id,
              name: item.name,
            }))
          : [];
      setStateDropdown(stateData);
    }
  }, [usState]);

  useEffect(() => {
    if (usCities && !_.isEmpty(usCities)) {
      const cityData =
        usCities && usCities.length > 0
          ? usCities.map((item) => ({
              id: item.id,
              name: item.name,
            }))
          : [];
      setCityDropdown(cityData);
    }
  }, [usCities]);

  const customStyles1 = {
    indicatorSeparator: (styles) => ({ display: "none" }),

    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#47ad1d",
        color: "#F5F5F5",
      },
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
      maxHeight: "84px",
    }),
    control: (base, state) => ({
      ...base,
      height: 42,
      minHeight: 42,
      background: "##f5f5f5",
      borderColor: state.isFocused ? "#e4e4e4" : "#e4e4e4",
      boxShadow: state.isFocused ? "0 0 0 0px #e4e4e4" : "0",
      border: state.isHovered ? "0" : "0px solid #e4e4e4",

      "&:hover": {
        borderColor: state.isFocused ? "#e4e4e4" : "0",
        boxShadow: state.isFocused ? "0 0 0 0px #e4e4e4" : "0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };
  const onSaveHandler = async () => {
    if (validateDoc()) {
      setLoadingData(true);
      ref?.current?.continuousStart();

      const uploadFile = async (file, type) => {
        const fSize = Math.round(file.size / 1048576);
        const fType = file.type;
        const ext = file.name.split(".").pop();

        if (fSize > 25) {
          toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
          setLoadingData(false);
          return null;
        }

        if (!["image/jpeg", "image/jpg", "image/png", "image/webp"].includes(fType)) {
          toast(<AlertError message="Invalid format. Allowed formats are jpg, jpeg, png, and webp." />);
          setLoadingData(false);
          return null;
        }

        const fileName = `${uuidv4()}.${ext}`;

        try {
          const result = await Storage.put(fileName, file, {
            completeCallback: (event) => {},
            progressCallback: (progress) => {},
            errorCallback: (err) => {
              toast(<AlertError message="Something went wrong with the upload." />);
              setLoadingData(false);
            },
          });
          return `public/${result.key}`;
        } catch (error) {
          toast(<AlertError message="Something went wrong with the upload." />);
          setLoadingData(false);
          return null;
        }
      };

      let profilePictureUrl = userData?.profilePicture;
      // let organizationLogoUrl = userData?.organizationLogo;

      if (newProfilePic) {
        profilePictureUrl = await uploadFile(newProfilePic, "profilePicture");
        if (!profilePictureUrl) return;
      }

      // if (newLogo) {
      //   organizationLogoUrl = await uploadFile(newLogo, "organizationLogo");
      //   if (!organizationLogoUrl) return;
      // }

      const payload = {
        profilePicture: profilePictureUrl,
        // organizationLogo: organizationLogoUrl,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        website: userData?.website || "",
        organizationName: userData?.organizationName || "",
        country: userData?.country?.code || "",
        state: userData?.state?.name || "",
        city: userData?.city?.name || "",
        // description:userData?.description
      };

      props.updateYourProfile(payload, (res) => {
        if (res && res.status === 200) {
          props.getProfileInfo((res) => {
            if (res && res.status === 200) {
              setUserProfile(res?.data?.data);
              setNewProfilePic(null);
              setNewLogo(null);
              let tempData = { ...profile };
              tempData.profile = res?.data?.data;
              setProfile(tempData);
              const data = {
                ...userData,
                firstName: res?.data?.data?.firstName,
                lastName: res?.data?.data?.lastName,
                website: res?.data?.data?.website,
                organizationName: res?.data?.data?.organizationName,
                country: {
                  name: res?.data?.data?.location?.countryName,
                  code: res?.data?.data?.location?.country,
                  id: res?.data?.data?.location?._id,
                },
                state:
                  res?.data?.data?.location?.countryName === "United States"
                    ? res?.data?.data?.location?.stateID
                      ? { id: res?.data?.data?.location?.stateID, name: res?.data?.data?.location?.stateName }
                      : res?.data?.data?.location?.stateName
                      ? { name: res?.data?.data?.location?.stateName }
                      : null
                    : res?.data?.data?.location?.stateName
                    ? { name: res?.data?.data?.location?.stateName }
                    : null,
                city:
                  res?.data?.data?.location?.countryName === "United States"
                    ? res?.data?.data?.location?.cityID
                      ? { id: res?.data?.data?.location?.cityID, name: res?.data?.data?.location?.cityName }
                      : res?.data?.data?.location?.cityName
                      ? { name: res?.data?.data?.location?.cityName }
                      : null
                    : res?.data?.data?.location?.cityName
                    ? { name: res?.data?.data?.location?.cityName }
                    : null,
                profilePicture: res?.data?.data?.profilePicture?.original,
                // organizationLogo: res?.data?.data?.organizationLogo?.original,
                // description: res?.data?.data?.description,
              };
              setUserData(data);
              setLoadingData(false);
              ref?.current?.complete();
              toast(<AlertSuccess message="Information Saved" />);
            }
          });
        } else {
          setLoadingData(false);
          ref?.current?.complete();
          toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
        }
      });
    }
  };

  let reorderedCountryDropdown = [];

  if (Array.isArray(countryDropdown)) {
    reorderedCountryDropdown = [...countryDropdown];
    const usIndex = reorderedCountryDropdown.findIndex((country) => country.name === "United States");

    if (usIndex > -1) {
      const usOption = reorderedCountryDropdown.splice(usIndex, 1)[0];
      reorderedCountryDropdown.unshift(usOption);
    }
  }
  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (type === "profilePicture") {
          setImageForProfile(reader.result);
        } else if (type === "organizationLogo") {
          setImageForOrganization(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropProfilePicture = () => {
    if (cropper) {
      const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
      const croppedImageBlob = dataURLToBlob(croppedImageDataUrl);
      const croppedImageFile = new File([croppedImageBlob], "profile-picture.png", { type: "image/png" });

      const updatedData = { ...userData, profilePicture: URL.createObjectURL(croppedImageBlob) };
      setUserData(updatedData);
      setImageForProfile(null);
      setNewProfilePic(croppedImageFile);
    }
  };

  const handleCropOrganizationLogo = () => {
    if (cropper) {
      const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
      const croppedImageBlob = dataURLToBlob(croppedImageDataUrl);
      const croppedImageFile = new File([croppedImageBlob], "organization-logo.png", { type: "image/png" });

      const updatedData = { ...userData, organizationLogo: URL.createObjectURL(croppedImageBlob) };
      setUserData(updatedData);
      setImageForOrganization(null);
      setNewLogo(croppedImageFile);
    }
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([uintArray], { type: mimeString });
  };
  return (
    <PostLayout page={"editProfile"}>
      {loadingMain || loadingData ? (
        <div class="panel-main-content d-flex flex-column">
          {" "}
          <Skeleton borderRadius={"10px"} height={"100vh"} />{" "}
        </div>
      ) : (
        <div class="panel-main-content d-flex flex-column">
          <div class="panel-head">
            <div class="breadcrumb-row d-flex flex-wrap"></div>
            <h1>Edit Profile</h1>
          </div>
          <div class="panel-body flex-grow-1">
            <div class="form-floating-row d-block d-sm-flex flex-wrap">
              <div className="ff-column w-25">
                <div className="ff-label-box">Profile Picture</div>
                <div className="ff-image-box d-flex">
                  {imageForProfile ? (
                    <div className="cropper-overlay">
                      <div className="cropper-container">
                        <Cropper
                          src={imageForProfile}
                          style={{ height: "70vh", width: "100%" }}
                          aspectRatio={1}
                          guides={true}
                          viewMode={1}
                          autoCropArea={1}
                          background={false}
                          responsive={true}
                          checkOrientation={false}
                          onInitialized={(instance) => setCropper(instance)}
                        />
                        <div className="cropper-actions">
                          <button onClick={handleCropProfilePicture} className="btn btn-default h-40">
                            Crop
                          </button>
                          <button onClick={() => setImageForProfile(null)} className="btn btn-secondary h-40">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <img
                        src={
                          newProfilePic
                            ? userData?.profilePicture
                            : process.env.REACT_APP_MEDIA + userData?.profilePicture
                        }
                        alt=""
                      />
                      <button
                        type="button"
                        className="ff-close-img"
                        onClick={() => {
                          fileInputRef.current?.click();
                        }}
                      >
                        <img src={editIcon} alt="edit icon" />
                      </button>
                    </>
                  )}
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="form-control"
                    id="profilePicture"
                    name="profilePicture"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "profilePicture")}
                  />
                </div>
              </div>
              {/* <div className="ff-column w-25">
                <div className="ff-label-box">Organization Logo</div>
                <div className="ff-image-box d-flex">
                  {imageForOrganization ? (
                    <div className="cropper-overlay">
                      <div className="cropper-container">
                        <Cropper
                          src={imageForOrganization}
                          style={{ height: "70vh", width: "100%" }}
                          aspectRatio={1}
                          guides={true}
                          viewMode={1}
                          autoCropArea={1}
                          background={false}
                          responsive={true}
                          checkOrientation={false}
                          onInitialized={(instance) => setCropper(instance)}
                        />
                        <div className="cropper-actions">
                          <button onClick={handleCropOrganizationLogo} className="btn btn-default h-40">
                            Crop
                          </button>
                          <button onClick={() => setImageForOrganization(null)} className="btn btn-secondary h-40">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {userData?.organizationLogo ? (
                        <img
                          src={
                            newLogo
                              ? userData?.organizationLogo
                              : process.env.REACT_APP_MEDIA + userData?.organizationLogo
                          }
                          alt="Organization Logo"
                        />
                      ) : (
                        <></>
                      )}
                      {(userData?.organizationLogo || imageForOrganization) && (
                        <button
                          type="button"
                          className="ff-close-img"
                          onClick={() => {
                            fileInputRef1.current?.click();
                          }}
                        >
                          <img src={editIcon} alt="edit icon" />
                        </button>
                      )}
                    </>
                  )}
                  <input
                    ref={fileInputRef1}
                    type="file"
                    className="form-control"
                    id="organizationLogo"
                    name="organizationLogo"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "organizationLogo")}
                  />
                  {!userData?.organizationLogo && !imageForOrganization && (
                    <button type="button" className={"ff-update-link mt-auto position-relative"}>
                      <input
                        type="file"
                        className="form-control"
                        id="fileInput"
                        accept=".jpg, .jpeg, .png, .webp"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => setImageForOrganization(reader.result);
                          }
                        }}
                      />
                      Upload
                    </button>
                  )}
                </div>
              </div> */}

              <div className="edit-pfl-modal" style={{ marginTop: "10%" }}>
                <Link to="/change-password" className="view-all-btn me-3 mb-3">
                  Change Password
                </Link>
                <div className="wpw-content-head-btn me-3">
                  <Link className="btn btn-default btn-block" to="/profile">
                    View Profile
                  </Link>
                </div>
              </div>
            </div>
            <div
              class={
                error && error.firstName
                  ? "is-invalid form-floating-row d-flex flex-wrap"
                  : "form-floating-row d-flex flex-wrap"
              }
            >
              <div class="ff-column">
                <div class="form-floating custom-form-floating">
                  <input
                    type="text"
                    class={
                      error && error.firstName
                        ? "is-invalid form-control text-capitalize"
                        : "form-control text-capitalize"
                    }
                    id="firstName"
                    name="firstName"
                    value={userData?.firstName}
                    placeholder="First name"
                    onChange={(e) => {
                      const data = { ...userData };
                      data.firstName = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                      setUserData(data);
                    }}
                  />
                  <label for="firstName">First Name</label>
                </div>
                {error && error?.firstName ? <p className="invalid">{error?.firstName}</p> : ""}
              </div>
            </div>
            <div
              class={
                error && error.lastName
                  ? "is-invalid form-floating-row d-flex flex-wrap"
                  : "form-floating-row d-flex flex-wrap"
              }
            >
              <div class="ff-column">
                <div class={"form-floating custom-form-floating"}>
                  <input
                    type="text"
                    class={
                      error && error.lastName
                        ? "is-invalid form-control text-capitalize"
                        : "form-control text-capitalize "
                    }
                    id="lastName"
                    name="lastName"
                    placeholder="Last name"
                    value={userData?.lastName}
                    onChange={(e) => {
                      const data = { ...userData };
                      data.lastName = e.target.value;
                      setUserData(data);
                    }}
                  />
                  <label for="lastName">Last Name</label>
                </div>
                {error && error?.lastName ? <p className="invalid">{error?.lastName}</p> : ""}
              </div>
            </div>
            <div
              class={
                error && error.email
                  ? "is-invalid form-floating-row d-flex flex-wrap"
                  : "form-floating-row d-flex flex-wrap"
              }
            >
              <div class="ff-column">
                <div class="form-floating custom-form-floating">
                  <input
                    type="email"
                    class={error && error.email ? "is-invalid form-control" : "form-control"}
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={userProfile?.email}
                    disabled
                  />
                  <label for="emailAddress">Email Address</label>
                </div>
              </div>
            </div>
            <div class="form-floating-row d-flex flex-wrap">
              <div class="ff-column">
                <div
                  class={
                    error && error.country ? "form-floating custom-form-floating" : "form-floating custom-form-floating"
                  }
                >
                  <Select
                    className={
                      error && error.country
                        ? " form-select c-select signup-dropdown-fix "
                        : "form-select c-select signup-dropdown-fix"
                    }
                    id="cuntrySelect"
                    placeholder={false}
                    styles={customStyles1}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    options={reorderedCountryDropdown}
                    name={countryDropdown}
                    onChange={(e) => {
                      const data = { ...userData };
                      data.country = e;
                      data.state = null;
                      data.city = null;
                      setUserData(data);
                    }}
                    value={userData?.country}
                  />
                  <label for="floatingSelect">Country</label>
                </div>
                {error && error?.country ? <p className="invalid">{error?.country}</p> : ""}
              </div>
            </div>

            <div class="form-floating-row d-flex flex-wrap">
              <div class="ff-column">
                <div
                  class={
                    error && error.state ? " form-floating custom-form-floating" : "form-floating custom-form-floating"
                  }
                >
                  {userData?.country?.code == "US" ? (
                    <Select
                      className={
                        error && error.state
                          ? " form-select c-select signup-dropdown-fix "
                          : "form-select c-select signup-dropdown-fix"
                      }
                      id="satetSelect"
                      styles={customStyles1}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={stateDropdown}
                      name={"state"}
                      onChange={(e) => {
                        const data = { ...userData };
                        data.state = e;
                        data.city = null;
                        setUserData(data);
                      }}
                      value={userData?.state}
                    />
                  ) : (
                    <input
                      class={error && error.state ? "is-invalid form-control" : "form-control "}
                      id="state"
                      name="state"
                      placeholder="State"
                      value={userData?.state?.name ? userData?.state?.name : ""}
                      onChange={(e) => {
                        const data = { ...userData };
                        data.state = { name: e.target.value };

                        setUserData(data);
                      }}
                    />
                  )}
                  <label for="floatingSelect">State</label>
                </div>
                {error && error?.state ? <p className="invalid">{error?.state}</p> : ""}
              </div>
              <div class="ff-column">
                <div
                  class={
                    error && error.city ? " form-floating custom-form-floating" : "form-floating custom-form-floating"
                  }
                >
                  {userData?.country?.code == "US" && (cityDropdown?.length != 0 || loadingCity) && userData?.state ? (
                    <Select
                      className={
                        error && error.city
                          ? " form-select c-select signup-dropdown-fix "
                          : "form-select c-select signup-dropdown-fix"
                      }
                      id="cuntrySelect"
                      placeholder={false}
                      styles={customStyles1}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={cityDropdown}
                      name={"city"}
                      onChange={(e) => {
                        const data = { ...userData };
                        data.city = e;
                        setUserData(data);
                      }}
                      isLoading={loadingCity}
                      loadingMessage={() => "Loading..."}
                      value={userData?.city}
                    />
                  ) : (
                    <input
                      class={error && error.city ? "is-invalid form-control" : "form-control "}
                      id="city"
                      name={"city"}
                      placeholder="city"
                      value={userData?.city?.name ? userData?.city?.name : ""}
                      onChange={(e) => {
                        const data = { ...userData };
                        data.city = { name: e.target.value };
                        setUserData(data);
                      }}
                    />
                  )}
                  <label for="floatingSelect">City</label>
                </div>
                {error && error?.city ? <p className="invalid">{error?.city}</p> : ""}
              </div>
            </div>

            <div class="form-floating-row d-flex flex-wrap">
              <div class="ff-column">
                <div class="form-floating custom-form-floating">
                  <input
                    type="text"
                    class="form-control "
                    id="website"
                    placeholder="Website"
                    value={userData?.website}
                    onChange={(e) => {
                      const data = { ...userData };
                      data.website = e.target.value;
                      setUserData(data);
                    }}
                  />
                  <label for="website">Website</label>
                </div>
              </div>
            </div>
            {/* <div class="form-floating-row d-flex flex-wrap">
              <div class="ff-column">
                <div class="form-floating custom-form-floating">
                  <input
                    type="text"
                    class="form-control text-capitalize"
                    id="organizationName"
                    placeholder="Organization Name"
                    value={userData?.organizationName}
                    onChange={(e) => {
                      const data = { ...userData };
                      data.organizationName = e.target.value;
                      setUserData(data);
                    }}
                  />
                  <label for="website">Organization Name</label>
                </div>
              </div>
            </div> */}
            {/* <div class="form-floating-row d-flex flex-wrap">
              <div class="ff-column">
                <div class="form-floating custom-form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    id="floatingTextarea2"
                    style={{ height: "115px" }}
                    value={userData.description}
                    name="description"
                    onChange={(e) => {
                      const data = { ...userData };
                      data.description = e.target.value;
                      setUserData(data);
                    }}
                  />
                  <label for="floatingTextarea2">Description</label>
                </div>
              </div>
            </div> */}
            <div class="p-p-btn d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0);"
                class={loadingMain ? "btn btn-default btn-sm disabled" : "btn btn-default btn-sm"}
                onClick={() => onSaveHandler()}
              >
                Save
              </a>
            </div>
          </div>
        </div>
      )}
    </PostLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCountry: (callback) => dispatch(getCountry(callback)),
  getUsState: (callback) => dispatch(getUsState(callback)),
  getUsCitiesDropdown: (data, callback) => dispatch(getUsCitiesDropdown(data, callback)),
  updateYourProfile: (data, callback) => dispatch(updateYourProfile(data, callback)),
  getProfileInfo: (callback) => dispatch(getProfileInfo(callback)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EditUserProfile));
