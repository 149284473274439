import React, { useEffect, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import close from "../../include/images/close-24x24.svg";

import RightFeedSidebar from "../../components/common/pageInfoSidebar/rightFeedSidebar";
import LeftFeedSidebar from "../../components/common/pageInfoSidebar/leftSideBar";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { getProfileData, getUser } from "../../store/users";
import { checkToken, getProfile } from "../../utils/localStorageServices";
import Skeleton from "react-loading-skeleton";
import PageInfoSidebar from "../../components/common/pageInfoSidebar/pageInfoSidebar";
import {
  getEnvironmentalistList,
  getOrganizationList,
  getRegionList,
  getScienceAndEducationCategory,
  getSpeciesCategoryList,
  getSpeciesList,
  getWetMarketList,
  getZooList,
  sideBarApisListings,
} from "../../store/sidebarApis";
import { getFeaturedSponsors, getSubscription } from "../../store/subscription";
import ImageHolder from "../../common/customStyles/imageHolder";
const ProfileLayout = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showRightMenu, setShowRightMenu] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [filterRecordCount, setFilterRecordCount] = useState(0);
  const [selectSpeciesCategory, setSelectedSpeciesCategory] = useState();
  const [selectSpeciesCategoryName, setSelectedSpeciesCategoryName] = useState();
  const USRegion = props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country === "US");
  const otherRegion = props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country !== "US");
  const allRegions = props?.sideBarApisListings?.regionList?.data;
  const speciesCategories = props?.sideBarApisListings?.speciesCategory?.data;
  const zooAndParksList = props?.sideBarApisListings?.zoo?.data;
  const organizationList = props?.sideBarApisListings?.organizationList?.data;
  const environmentalistList = props?.sideBarApisListings?.environmentalist?.data;

  const wetmarketList = props?.sideBarApisListings?.wetMarket?.data;
  const scienceAndEducationCategory = props?.sideBarApisListings?.scienceAndEducationCategory?.data;
  const isFollowersPath = window.location.pathname.includes("/followers");
  const profile = getProfile();
  const history = useHistory();
  const sponsorFeature =
    props.getSubscription && props.getSubscription.sponsorFeature && props.getSubscription.sponsorFeature.data;
  useEffect(() => {
    setLoadingMain(true);
    const fetchData = async () => {
      if (props.page !== "guestProfile" && profile?.profile) {
        await props.getProfileData((res) => {
          if (res && res.status === 200) {
            checkAllCallsComplete();
          }
        });
      }
      const fetchIfEmpty = async (list, fetchFunction) => {
        if (!list) {
          await fetchFunction({}, (res) => {
            if (res && res.status === 200) {
              checkAllCallsComplete();
            }
          });
        }
      };
      await fetchIfEmpty(speciesCategories, props.getSpeciesCategoryList);
      await fetchIfEmpty(zooAndParksList, props.getZooList);
      await fetchIfEmpty(organizationList, props.getOrganizationList);
      await fetchIfEmpty(environmentalistList, props.getEnvironmentalistList);
      await fetchIfEmpty(scienceAndEducationCategory, props.getScienceAndEducationCategory);
      await fetchIfEmpty(wetmarketList, props.getWetMarketList);
      await fetchIfEmpty(sponsorFeature, props.getFeaturedSponsors);
    };
    const checkAllCallsComplete = () => {
      if (
        (props.page === "guestProfile" || profile?.profile) &&
        speciesCategories !== undefined &&
        zooAndParksList !== undefined &&
        organizationList !== undefined &&
        environmentalistList !== undefined &&
        scienceAndEducationCategory !== undefined &&
        wetmarketList !== undefined
      ) {
        setLoadingMain(false);
      }
    };
    if (checkToken() || props.page === "guestProfile") {
      fetchData();
    } else {
      history.push("/login");
    }
  }, []);

  const userProfile = props?.getUser?.data;
  useEffect(() => {
    if (showMenu) {
      document.body.classList.add("filter-open");
    } else {
      document.body.classList.remove("filter-open");
    }
  }, [showMenu]);
  return (
    <>
      <PageLayout>
        <main id="main">
          <div class="page-panel-container font-family-poppins">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="ei-category-container d-flex flex-wrap">
                    <div class="ei-category-left profile-layout-left">
                      <div class={showMenu ? "panel-sidebar open" : "panel-sidebar"}>
                        <div class="panel-header d-lg-none">
                          <div class="panel-header-inside position-relative">
                            <div class="panel-header-title">Browse Category</div>
                            <div
                              class="panel-close-button d-flex align-items-center justify-content-center"
                              onClick={() => setShowMenu(false)}
                            >
                              <img src={close} alt="close__24x24" />
                            </div>
                          </div>
                        </div>
                        <PageInfoSidebar
                          page={page}
                          totalCount={totalCount}
                          filterRecordCount={filterRecordCount}
                          setFilterRecordCount={setFilterRecordCount}
                          setPage={setPage}
                          USRegions={USRegion}
                          otherRegion={otherRegion}
                          regions={allRegions}
                          setShowMenu={setShowMenu}
                          showMenu={showMenu}
                          speciesCategories={speciesCategories}
                          zooAndParksList={zooAndParksList}
                          organizationList={organizationList}
                          environmentalistList={environmentalistList}
                          wetmarketList={wetmarketList}
                          scienceAndEducationCategory={scienceAndEducationCategory}
                          selectSpeciesCategory={selectSpeciesCategory}
                          setSelectedSpeciesCategory={setSelectedSpeciesCategory}
                          setSelectedSpeciesCategoryName={setSelectedSpeciesCategoryName}
                          selectSpeciesCategoryName={selectSpeciesCategoryName}
                          sponsorFeature={sponsorFeature}
                          loading={props.loading}
                        />
                      </div>
                    </div>
                    {props?.children}
                    <div class={showRightMenu ? " panel-sidebar-right open " : "panel-sidebar-right "}>
                      <div
                        class={
                          showRightMenu
                            ? " panel-sidebar-right open profile-layout-right"
                            : "panel-sidebar-right  profile-layout-right"
                        }
                      >
                        <div class="panel-header d-lg-none">
                          <div class="panel-header-inside position-relative">
                            <div class="panel-header-title">Selected Topics</div>
                            <div
                              class="panel-close-button d-flex align-items-center justify-content-center"
                              onClick={() => setShowRightMenu(false)}
                            >
                              <img src={close} alt="close__24x24" />
                            </div>
                          </div>
                        </div>
                        {props.loading ? (
                          <Skeleton borderRadius={"10px"} height={"100vh"} />
                        ) : (
                          <div class="sticky-lg-top">
                            {profile?.profile && props.page != "profile" && (
                              <div class="sticky-widget sticky-widget-wrap">
                                <div class="sw-text-wrap d-flex flex-wrap align-items-center">
                                  <div class="sw-text-img">
                                    <div class="sw-img d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
                                      {profile?.profile?.profilePicture?.original ? (
                                        <img
                                          src={
                                            profile?.profile?.profilePicture?.original
                                              ? process.env.REACT_APP_MEDIA + profile?.profile?.profilePicture?.small
                                              : ""
                                          }
                                          alt=""
                                          className="view-profile-section-img"
                                        />
                                      ) : (
                                        <ImageHolder
                                          image={null}
                                          name={profile?.profile?.firstName?.slice(0, 1)}
                                          page="post"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div class="sw-text-label text-capitalize">
                                    {profile?.profile?.firstName} {profile?.profile?.lastName}
                                  </div>
                                </div>
                                <div class="sw-followers-box d-flex flex-wrap align-items-center ">
                                  <Link
                                    to={isFollowersPath ? "#" : `${profile?.profile?.slug}/followers`}
                                    class="link-btn cursor-pointer sw-followers-item"
                                  >
                                    <span>{profile?.profile && profile?.profile.followerCount}</span> Followers
                                  </Link>
                                  <Link
                                    to={isFollowersPath ? "#" : `${profile?.profile?.slug}/followers`}
                                    class="link-btn cursor-pointer sw-followers-item"
                                  >
                                    <span>{profile?.profile && profile?.profile.followingCount}</span> Following
                                  </Link>
                                </div>
                                {props.page !== "editProfile" && (
                                  <div class="sw-btn-wrap">
                                    <Link className="view-text-btn" to="/profile">
                                      View Profile
                                    </Link>
                                  </div>
                                )}
                              </div>
                            )}
                            {props.page == "guestProfile" && !profile?.profile && (
                              <div class="sticky-widget sticky-label-widget">
                                <div>
                                  <h6>Discover More by Signing Up! </h6>
                                  <p>
                                    It looks like you're having a great time on our site. Why not enhance your
                                    experience even further? By registering, you'll unlock additional features and be
                                    able to follow more users, and explore more amazing species that interest you.
                                    Signing up takes just a few minutes. Join us today and make the most of your
                                    experience!
                                  </p>
                                  <Link to="/register/user-type" className="btn btn-default">
                                    Sign Up Now
                                  </Link>
                                </div>
                              </div>
                            )}
                            {/* <><div class="sticky-widget sticky-label-widget">
                          <p>
                            Select All Species and Regions Select All Species, Regions, Organizations, Zoos &
                            Wildlife, Science & Education, Global Threats,
                            Biologists and Friends That You Want to Follow. *
                            That You Want to Follow.
                          </p>
                        </div>

                          <div class="sticky-widget">
                            <RightFeedSidebar
                              showRightMenu={showRightMenu}
                              setShowRightMenu={setShowRightMenu}
                              loading={props.loading}
                            />
                          </div></> */}
                            {profile?.profile && props.page !== "guestProfile" && (
                              <div class="sticky-widget">
                                <RightFeedSidebar
                                  showRightMenu={showRightMenu}
                                  setShowRightMenu={setShowRightMenu}
                                  loading={props.loading}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </PageLayout>
      <div class="sticky-bottom-wrapper sticky-bottom d-lg-none">
        <div class="sbw-inside w-100 d-flex flex-wrap">
          <div class="sbw-item w-50">
            <button
              type="button"
              class="sbw-btn sbw-btn-green"
              id="openLeftPanel"
              onClick={() => setShowMenu(!showMenu)}
            >
              Browse Category
            </button>
          </div>
          <div class="sbw-item w-50">
            <button
              type="button"
              class="sbw-btn sbw-btn-light-green"
              id="openRightPanel"
              onClick={() => setShowRightMenu(!showRightMenu)}
            >
              Selected Topics
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProfileData: (params, callback) => dispatch(getProfileData(params, callback)),
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  getSpeciesCategoryList: (params, callback) => dispatch(getSpeciesCategoryList(params, callback)),
  getSpeciesList: (params, callback) => dispatch(getSpeciesList(params, callback)),
  getZooList: (params, callback) => dispatch(getZooList(params, callback)),
  getOrganizationList: (params, callback) => dispatch(getOrganizationList(params, callback)),
  getEnvironmentalistList: (params, callback) => dispatch(getEnvironmentalistList(params, callback)),
  getWetMarketList: (params, callback) => dispatch(getWetMarketList(params, callback)),
  getScienceAndEducationCategory: (params, callback) => dispatch(getScienceAndEducationCategory(params, callback)),
  getFeaturedSponsors: (callback) => dispatch(getFeaturedSponsors(callback)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state)?.profile,
  sideBarApisListings: sideBarApisListings(state),
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ProfileLayout));
