import React, { useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { useEffect } from "react";
import leftArrow from "../../include/images/pagination-left-arrow.svg";
import rightArrow from "../../include/images/pagination-right-arrow.svg";
import leftArrowGreen from "../../include/images/pagination-left-arrow-green.svg";
import rightArrowGreen from "../../include/images/pagination-right-arrow-green.svg";
import closeIcon from "../../include/images/close.svg";
import { Link } from "react-router-dom";
import { sideBarApisListings, getRegionList, getSpeciesList, getEnvironmentalistList } from "../../store/sidebarApis";
import ShowMoreModal from "../../components/common/showMoreModal/showMoreModal";
import noRecord from "../../include/images/nrb-img.svg";
import OrganizationListCard from "../../components/common/listingCard/organizationListCard";
import ShowMoreRegionsModal from "../../components/common/showMoreModal/showMoreRegionsModal";
import SideBarFilterList from "../../components/common/sideBarFilter/sideBarFilterList";
import AlertError from "../../common/alerts/alertError";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import DonationViaEI from "../../components/speciesDetailsPages/donationViaEI";
import { Amplify, Storage, Auth } from "aws-amplify";
import { addDonationDetails, speciesListings } from "../../store/species";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import ListingPagination from "../../components/common/Pagination/listingPagination";
import SmallScreenPaginations from "../../components/common/Pagination/smallScreenPagination";
import ListingPaginations from "../../components/common/Pagination/listingPaginations";
import { getsponsorListing, getSubscription } from "../../store/subscription";
import SponsorsListCard from "../../components/common/listingCard/sponsorsListcard";
import { capitalizeContentInBrackets } from "../../utils/helperFunctions";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import defaultImage from "../../include/images/default_image_species.svg";
import Skeleton from "react-loading-skeleton";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const SponsorsListing = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [sort, setSort] = useState("sponsorName");
  const [loadingMain, setLoadingMain] = useState(true);

  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedRegionsName, setSelectedRegionsName] = useState([]);
  const [selectEnvironmentalist, setSelectedEnvironmentalist] = useState([]);
  const [selectedEnvironmentalistName, setSelectedEnvironmentalistName] = useState([]);
  const [selectedSpecies, setSelectedSpecies] = useState([]);
  const [selectedSpeciesName, setSelectedSpeciesName] = useState([]);

  const [USRegion, setUSRegion] = useState([]);
  const [otherRegion, setOtherRegion] = useState([]);
  //Modal
  const [showViewAll, setShowViewAll] = useState(false);
  const [showSpeciesAll, setShowSpeciesAll] = useState(false);
  const [showEnvironmentalistViewAll, setShowEnvironmentalistViewAll] = useState(false);

  // for donation
  const [showDonate, setshowDonate] = useState(false);
  const [showDirectDonate, setshowDirectDonate] = useState(false);
  const [error, setError] = useState({});
  const [speciesDropdown, setspeciesDropdown] = useState(null);
  const [selectDonation, setSelectDonation] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isCheck, setIsCheck] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSpecies, setLoadingSpecies] = useState(false);
  const [loadingEnv, setLoadingEnv] = useState(false);

  const toggleViewAll = () => {
    setShowViewAll(!showViewAll);
  };

  const toggleViewAllSpecies = () => {
    setShowSpeciesAll(!showSpeciesAll);
  };

  const toggleViewAllEnvironmentalist = () => {
    setShowEnvironmentalistViewAll(!showEnvironmentalistViewAll);
  };

  const sideBarSpecies = props.sideBarApisListings && props.sideBarApisListings.speciesList;

  const environmentalist = props.sideBarApisListings && props.sideBarApisListings.environmentalist;
  const regions = props.sideBarApisListings && props.sideBarApisListings.regionList;
  useEffect(() => {
    setLoadingMain(true);
    window.scrollTo(0, 0);
    const species = selectedSpecies.map((item) => item).join(",");
    const payload = {
      keyword,
      page: page,
      sort,
      order,
    };
    if (species) {
      payload.speciesId = species;
    }
    props.getsponsorListing(payload, (res) => {
      if (res && res.status === 200) {
        setLoadingMain(false);
      }
    });
  }, [page, sort, order, keyword, selectEnvironmentalist, selectedRegions, selectedSpecies]);
  Useauthredirect();
  useEffect(() => {
    if (sideBarSpecies && !sideBarSpecies.data) {
      setLoadingSpecies(true);
      props.getSpeciesList({}, (res) => {
        if (res && res.status === 200) {
          setLoadingSpecies(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (loadingEnv || loadingSpecies) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loadingEnv, loadingSpecies]);

  useEffect(() => {
    //For Regions Dropdown
    if (regions && regions.data) {
      const usRegions = regions.data && regions.data.length > 0 && regions.data.filter((item) => item.country === "US");
      const otherRegions =
        regions.data && regions.data.length > 0 && regions.data.filter((item) => item.country !== "US");
      setUSRegion(usRegions);
      setOtherRegion(otherRegions);
    }
  }, [regions]);

  const sponsorsList = props.getSubscription.sponsorList && props.getSubscription.sponsorList.data;

  const totalPages = (props.getSubscription.sponsorList && props.getSubscription.sponsorList.totalCount) || 0;

  const dataPerPage = (props.getSubscription.sponsorList && props.getSubscription.sponsorList.recordsPerPage) || 0;

  const noOfPage = Math.ceil(totalPages / dataPerPage) || 0;

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add("filter-open");
    } else {
      document.body.classList.remove("filter-open");
    }
  }, [showMenu]);

  useEffect(() => {
    const Data = sideBarSpecies && sideBarSpecies.data;
    setspeciesDropdown(Data);
  }, [showDonate, sideBarSpecies]);
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }

    return description;
  };
  return (
    <>
      <PageLayout>
        <main id="main">
          <div class="listing-container">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="listing-container-inner d-flex flex-wrap">
                    <div class="filter-sidebar">
                      <div class="filter-header-mobile d-block d-lg-none">
                        <h6>Filters</h6>
                        <div class="close-btn">
                          <img src={closeIcon} alt="" onClick={() => setShowMenu(false)} />
                        </div>
                      </div>
                      <SideBarFilterList
                        page={"Sponsors"}
                        USRegion={USRegion}
                        otherRegion={otherRegion}
                        selectRegion={selectedRegions}
                        setSelectRegion={setSelectedRegions}
                        setSelectRegionName={setSelectedRegionsName}
                        selectRegionName={selectedRegionsName}
                        toggleRegionsViewAll={toggleViewAll}
                        environmentalist={environmentalist}
                        selectEnvironmentalist={selectEnvironmentalist}
                        setSelectEnvironmentalist={setSelectedEnvironmentalist}
                        selectEnvironmentalistName={selectedEnvironmentalistName}
                        setSelectEnvironmentalistName={setSelectedEnvironmentalistName}
                        toggleEnvironmentalistViewAll={toggleViewAllEnvironmentalist}
                        species={sideBarSpecies}
                        selectSpecies={selectedSpecies}
                        setSelectSpecies={setSelectedSpecies}
                        setSelectSpeciesName={setSelectedSpeciesName}
                        selectSpeciesName={selectedSpeciesName}
                        toggleSpeciesViewAll={toggleViewAllSpecies}
                        setShowMenu={setShowMenu}
                        showMenu={showMenu}
                        setPage={setPage}
                      />
                      <div class="filter-footer-mob-btn d-flex d-lg-none">
                        <Link
                          class="btn btn-grey"
                          onClick={() => {
                            if (selectedSpeciesName && selectedSpeciesName.length > 0) {
                              const data = [];
                              setSelectedSpeciesName(data);
                              setSelectedSpecies(data);
                            }

                            if (selectedRegionsName && selectedRegionsName.length > 0) {
                              const data = [];
                              setSelectedRegions(data);
                              setSelectedRegionsName(data);
                            }

                            if (selectedEnvironmentalistName && selectedEnvironmentalistName.length > 0) {
                              const data = [];
                              setSelectedEnvironmentalist(data);
                              setSelectedEnvironmentalistName(data);
                            }
                          }}
                        >
                          Clear All Filters
                        </Link>
                        <Link class="btn btn-dark-green" onClick={() => setShowMenu(!showMenu)}>
                          Apply
                        </Link>
                      </div>
                    </div>
                    <div class="listing-content-box">
                      <div class="breadcrumb-row d-flex flex-wrap">
                        <div class="breadcrumb-box">
                          <Link to="/">Home</Link>
                        </div>
                        <div class="breadcrumb-box">
                          <Link to="/organizations">Sponsors</Link>
                        </div>
                      </div>
                      <div class="ei-heading listing-content-heading-mob">
                        <h4>All Sponsors ({totalPages || 0})</h4>
                        <div className="d-lg-none custom-sort-filter d-flex align-items-center justify-content-between">
                          <div class="ei-sort-filter position-relative d-flex w-100 mt-3">
                            <a href="javascript:void(0);">Sort By</a>

                            <ul class="sorting-list d-flex flex-wrap" style={{ marginLeft: "15px" }}>
                              <li class="sorting-item ">
                                <div
                                  class="sorting-box active cursor-pointer"
                                  onClick={() => {
                                    if (order === "asc") {
                                      setOrder("desc");
                                    } else {
                                      setOrder("asc");
                                    }
                                  }}
                                >
                                  A to Z
                                  <svg
                                    className={order === "desc" && "down-arrow "}
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g>
                                      <path
                                        d="M7 11.0827V2.91602"
                                        stroke="black"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M2.9165 6.99935L6.99984 2.91602L11.0832 6.99935"
                                        stroke="black"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="pagination-row ms-5 w-100 d-lg-none d-flex justify-content-end">
                            {sponsorsList && noOfPage > 1 && (
                              <SmallScreenPaginations
                                noOfPage={noOfPage}
                                totalPages={noOfPage}
                                page={page}
                                setPage={setPage}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-lg-flex flex-wrap d-none space-between border-bottom-1"
                        style={{ marginBottom: "10px", paddingBottom: "10px" }}
                      >
                        <div class="sorting-row d-lg-flex flex-wrap d-none border-0 sort-region-listing ">
                          <p>Sort By :</p>
                          <ul class="sorting-list d-flex flex-wrap">
                            <li class="sorting-item ">
                              <div
                                class="sorting-box active cursor-pointer"
                                onClick={() => {
                                  if (order === "asc") {
                                    setOrder("desc");
                                  } else {
                                    setOrder("asc");
                                  }
                                }}
                              >
                                A to Z
                                <svg
                                  className={order === "desc" && "down-arrow "}
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g>
                                    <path
                                      d="M7 11.0827V2.91602"
                                      stroke="black"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M2.9165 6.99935L6.99984 2.91602L11.0832 6.99935"
                                      stroke="black"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                </svg>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="pagination-row mt-0 d-flex justify-content-between">
                          {sponsorsList && noOfPage > 1 && (
                            <ListingPaginations
                              noOfPage={noOfPage}
                              totalPages={noOfPage}
                              page={page}
                              setPage={setPage}
                            />
                          )}
                        </div>
                        {/* <input
                          type="text"
                          className="form-control region-search"
                          placeholder="Search"
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value)}
                        /> */}
                        {/* <img
                          src={close}
                          className="search-region-close"
                          alt=""
                        /> */}
                      </div>
                      <ul class="sponcer-list">
                        {loadingMain
                          ? Array.from({ length: 48 }, (item, index) => (
                              <li key={index} className="sponcer-item sponsor-list-item">
                                <Skeleton width={"100%"} height={"220px"} className="pt-2" />
                              </li>
                            ))
                          : sponsorsList &&
                            sponsorsList.length > 0 &&
                            sponsorsList.map((item, idx) => (
                              <li key={idx} className="sponcer-item sponsor-list-item">
                                <div className="sponcer-box">
                                  <div className="sponcer-box-left">
                                    <div class="sbl-img">
                                      <img
                                        src={`${process.env.REACT_APP_MEDIA}${
                                          item?.isCompany
                                            ? item?.createdByDetail?.organizationLogo?.small
                                            : item?.createdByDetail?.profilePicture?.small
                                        }`}
                                        alt=""
                                      />
                                    </div>
                                    <div className="sbl-text">
                                      {item?.isCompany ? (
                                        <h6 className="text-capitalize">{item?.createdByDetail?.organizationName}</h6>
                                      ) : (
                                        <h6 className="text-capitalize">
                                          {item?.createdByDetail?.firstName} {item?.createdByDetail?.lastName}
                                        </h6>
                                      )}
                                      <p>
                                        {item?.isCompany
                                          ? dotDesc(item?.createdByDetail?.description, 200)
                                          : dotDesc(item?.createdByDetail?.individualDescription, 200)}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="sponcer-box-right">
                                    <div class="sbr-species">
                                      <div class="sbr-species-head">
                                        <h6>SPONSORED ANIMALS</h6>
                                      </div>
                                      <ul className="sbr-species-img-list">
                                        {item?.species?.slice(0, 3).map((species, index) => (
                                          <li key={index}>
                                            <div
                                              className="sbr-species-img-box"
                                              data-bs-toggle="popover"
                                              data-bs-placement="top"
                                              data-bs-content-id={`popover${index + 1}`}
                                              tabIndex="0"
                                            >
                                              <img
                                                src={
                                                  species?.coverImage?.small
                                                    ? `${process.env.REACT_APP_MEDIA}${species.coverImage.small}`
                                                    : getDefaultImage(species?.scientificName)
                                                }
                                                alt={species?.scientificName || "Species Image"}
                                              />
                                            </div>
                                            <div id={`popover${index + 1}`} className="d-none">
                                              <div className="custom-popover-content">
                                                <div className="custom-popover-title">{species?.commonName}</div>
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                        {item?.species?.length > 3 && (
                                          <li>
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={
                                                <Tooltip id={`tooltip-extra-species`}>
                                                  {item.species.slice(3).map((extraSpecies, index) => (
                                                    <div className="text-capitalize" key={index}>
                                                      {extraSpecies.displayCommonName}
                                                    </div>
                                                  ))}
                                                </Tooltip>
                                              }
                                            >
                                              <h6 style={{ cursor: "pointer" }}>+{item.species.length - 3}</h6>
                                            </OverlayTrigger>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                    {item?.isCompany ? (
                                      <div class="sbr-cta">
                                        <a
                                          href={`/sustainability/${item?.createdByDetail?.slug}?isCompany=corporate`}
                                          class="btn btn-default h-40 pt-10"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Sustainability Page
                                        </a>
                                        <a
                                          href={`/profile/${item?.createdByDetail?.slug}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          class="btn btn-dark-green h-40 pt-10"
                                        >
                                          EI Profile Page
                                        </a>
                                        <a
                                          href={
                                            item?.createdByDetail?.website?.startsWith("http")
                                              ? item?.createdByDetail?.website
                                              : `https://${item?.createdByDetail?.website}`
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          class="btn btn-dark-green-outline h-40 pt-10"
                                        >
                                          Company Page
                                        </a>
                                      </div>
                                    ) : (
                                      <div class="sbr-cta">
                                        <Link
                                          to={`/sustainability/${item?.createdByDetail?.slug}?isCompany=individual`}
                                          class="btn btn-dark-green h-40 pt-10"
                                        >
                                          View Page
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                      </ul>
                      <div class="pagination-row d-flex justify-content-between">
                        {sponsorsList && sponsorsList.length === 0 ? (
                          <ul class="blog-list d-flex flex-wrap justify-content-center">
                            <div class="no-records-container d-flex align-items-center justify-content-center">
                              <div class="no-records-box text-center">
                                <div class="nrb-image">
                                  <img src={noRecord} alt="" />
                                </div>
                                <h6>No Records Found</h6>
                              </div>
                            </div>
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ShowMoreModal
          show={showSpeciesAll}
          onHide={toggleViewAllSpecies}
          heading="Endangered Species"
          listData={sideBarSpecies && sideBarSpecies.data}
          selectedData={selectedSpecies}
          selectedDataName={selectedSpeciesName}
          setSelectedData={setSelectedSpecies}
          setSelectedDataName={setSelectedSpeciesName}
        />
      </PageLayout>
      {!loadingMain && (
        <div class="filter-sort-btn d-block d-lg-none">
          <Link class="btn btn-block btn-dark-green" onClick={() => setShowMenu(!showMenu)}>
            Filters
          </Link>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getsponsorListing: (params, callback) => dispatch(getsponsorListing(params, callback)),
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  getSpeciesList: (params, callback) => dispatch(getSpeciesList(params, callback)),
  getEnvironmentalistList: (params, callback) => dispatch(getEnvironmentalistList(params, callback)),
  addDonationDetails: (data, callback) => dispatch(addDonationDetails(data, callback)),
});

const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
  sideBarApisListings: sideBarApisListings(state),
  speciesListings: speciesListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SponsorsListing));
