import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import programImage from "../../include/images/program-box.jpg";
import internImage from "../../include/images/intern-program.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
//Education Page
const Education = (props) => {
  var ref = useRef(null);

  return (
    <PageLayout>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <div class="program-container">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="program-heading d-flex flex-wrap">
                <div class="program-heading-main">
                  <h1>
                    Environmental Inclusion <span>Education</span> Page
                  </h1>
                </div>
                <div class="program-heading-text">
                  <p>
                    <strong className="d-inline">
                      Welcome to the K-12 and University Intern Environmental Education Programs.
                    </strong>{" "}
                    This activity is designed to help students learn about and connect with the natural world by
                    exploring fascinating species. Follow the instructions on each page to complete the activity.
                  </p>
                </div>
              </div>
              <div class="program-list d-flex flex-wrap">
                <div class="program-item">
                  <div class="program-box position-relative d-flex align-items-center">
                    <div class="program-box-bg">
                      <img src={programImage} alt="" />
                    </div>
                    <div class="program-box-text">
                      <h2>
                        <strong>K-12</strong> PROGRAM
                      </h2>
                      <Link class="btn btn-green-white btn-xxl h-40 pt-10">Learn more</Link>
                    </div>
                  </div>
                </div>
                <div class="program-item">
                  <div class="program-box position-relative d-flex align-items-center">
                    <div class="program-box-bg">
                      <img src={internImage} alt="" />
                    </div>
                    <div class="program-box-text">
                      <h2>
                        <strong>Intern</strong> PROGRAM
                      </h2>
                      <Link to="/intern-report" class="btn btn-green-white btn-xxl h-40 pt-10">
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="species-description-container py-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="activity-instructions-box p-0">
                <h4 class="fw-bold mb-4">Inspiring Awareness Through Education: Endangered Animals</h4>
                <p class="mb-4">
                  At Environmental Inclusion, we believe that educating young people about endangered animals and the
                  importance of protecting them is crucial for fostering a lifelong commitment to conservation. By
                  exploring the beauty and uniqueness of these species, students can develop a deep appreciation for the
                  natural world and the challenges it faces.
                </p>
                <p class="mb-4">
                  Animal Art is a creative and impactful way to engage young minds. By drawing endangered animals,
                  students are encouraged to observe and capture every detail—from the distinctive patterns of fur and
                  feathers to the unique features shaped by evolution. This artistic exercise not only hones creativity
                  but also builds a profound connection with the species they depict.
                </p>
                <p class="mb-4">
                  Writing Essays about endangered animals provides another powerful avenue for education. Through
                  research and storytelling, students delve into the specific challenges each species faces, such as
                  habitat loss, climate change, and human-wildlife conflict. This exercise encourages critical thinking
                  and fosters empathy, inspiring young writers to understand the urgency of conservation efforts. Many
                  students carry these experiences into adulthood, often becoming advocates for environmental
                  stewardship.
                </p>
                <p class="mb-4">
                  The essays and artwork created by students are showcased on our Environmental Inclusion Species Page.
                  By publishing their creations, we amplify their voices and highlight the care and concern young people
                  have for endangered species. This initiative not only spreads awareness but also inspires others to
                  take action. Together, through art, writing, and education, we can empower the next generation to be
                  passionate protectors of our planet’s incredible biodiversity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Education));
