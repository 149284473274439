import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { followUnfollowFriends, getGuestProfile, getMyFeedData, updateYourProfile } from "../../store/users";
import wIcon3 from "../../include/images/w-icon-3.svg";
import introImage from "../../include/images/contact-intro-img.jpg";
import emailIcon from "../../include/images/e-mail-icon.svg";
import locationIcon from "../../include/images/location-icon.svg";
import { getPublicPost, likeUnlikePost } from "../../store/post";
import contactHero from "../../include/images/contact-hero.png";
import LoadingBar from "react-top-loading-bar";
import { contactUs } from "../../store/misc";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { getProfile } from "../../utils/localStorageServices";
import ChatLayout from "../../layout/profileLayout/chatLayout";
import ChatUser from "../../components/common/chatUserModal/chatUser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import sendIcon from "../../include/images/send.svg";
import attachIcon from "../../include/images/attach-file.svg";
import {
  addChat,
  getChat,
  getChatMessage,
  getUnreadChatCount,
  readChatMessage,
  sendChatMessage,
  unreadChatCountReceived,
  userChat,
} from "../../store/chat";
import ImageHolder from "../../common/customStyles/imageHolder";
import ChatModal from "../../components/common/chatModal/chatModal";
import { Amplify, Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
//Enivronment Press corp Page
const ChatPage = (props) => {
  var ref = useRef(null);
  const profile = getProfile();
  const [addChat, setAddChat] = useState(false);
  const [selectUser, setSelectUser] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingRight, setLoadingRight] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [fileType, setFileType] = useState("");
  const [chatUsers, setChatUsers] = useState([]);
  const [attachedFile, setAttachedFile] = React.useState(null);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [chatData, setChatData] = useState([]);
  const isMobile = useCheckMobileScreen();
  useEffect(() => {
    const payload = {
      keyword,
      page,
      sort: "createdAt",
      order: "desc",
    };
    setLoadingRight(true);
    props.getChat(payload, (res) => {
      if (res && res.status === 200) {
        setLoadingRight(false);
        setLoading(false);
        if (res?.data?.data?.length > 0) {
          setChatUsers([...chatUsers, ...res?.data?.data]);
          setChatData([...chatUsers, ...res?.data?.data]);
          if (page == 1) {
            setSelectUser(res?.data?.data[0]);
          }
        }
      }
    });
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    if (selectUser?.otherMember?._id || selectUser?._id) {
      const userId = selectUser?.otherMember?._id || selectUser?._id;
      const payload = {
        receiver: userId,
      };
      setLoading(true);
      props.addChat(payload, (res) => {
        if (res && res.status === 200) {
          setChatId(res?.data?.data?.chat);
          let chatId = res?.data?.data?.chat;
          props.getChatMessage(chatId, (res1) => {
            if (res1 && res1.status === 200) {
              setLoading(false);
              setChatMessages(res1?.data?.data);
              props.readChatMessage(chatId, { chatMessages: [] }, (res) => {
                const payload = {
                  page,
                  sort: "createdAt",
                  order: "desc",
                };
                props.getChat(payload, (res) => {
                  if (res && res.status === 200) {
                    if (res?.data?.data?.length > 0) {
                      setChatUsers([...res?.data?.data]);
                      setChatData([...res?.data?.data]);
                    }
                    props.getUnreadChatCount((res) => {
                      if (res.status == 200) {
                      }
                    });
                  }
                });
              });
            }
          });
        }
      });
    }
  }, [selectUser]);

  const handleSendMessage = async () => {
    let message = typeof messageContent === "string" ? messageContent : "";
    if (!message.trim() && (!attachedFile || attachedFile.length === 0)) {
      toast(<AlertError message="Please enter a message or attach a file before sending." />);
      return;
    }
    setMessageContent("");
    ref && ref.current && ref.current.continuousStart();

    const uploadFile = async (file) => {
      const fSize = Math.round(file.size / 1048576); // Convert bytes to MB
      const fType = file.type;
      const ext = file.name.split(".").pop();

      if (fSize > 25) {
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        ref?.current?.complete();
        return null;
      }

      if (
        ![
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp", // Allowed image formats
          "video/mp4",
          "video/mov",
          "video/avi", // Allowed video formats
          "application/pdf", // PDF format
          "application/msword", // DOC format
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX format
        ].includes(fType)
      ) {
        toast(
          <AlertError message="Invalid format. Allowed formats are jpg, jpeg, png, webp for images; mp4, mov, avi for videos; and pdf, doc, docx for documents." />,
        );
        ref?.current?.complete();
        return null;
      }

      const fileName = `${uuidv4()}.${ext}`;
      try {
        const result = await Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            ref?.current?.complete();
          },
        });
        return `public/${result.key}`;
      } catch (error) {
        ref?.current?.complete();
        toast(<AlertError message="Something went wrong with the upload." />);
        return null;
      }
    };

    let uploadedFiles = [];

    if (attachedFile && attachedFile.length > 0) {
      for (let i = 0; i < attachedFile.length; i++) {
        const file = attachedFile[i];
        const uploadedFileUrl = await uploadFile(file);
        if (!uploadedFileUrl) return;

        const mediaType = file.type.startsWith("image/")
          ? "image"
          : file.type.startsWith("video/")
          ? "video"
          : "document";

        uploadedFiles.push({
          media: uploadedFileUrl,
          mediaType,
          name: file.name,
          mediaSource: "s3",
        });
      }

      const filePayload = {
        chat: chatId,
        receiver: selectUser?.otherMember?._id ? selectUser?.otherMember?._id : selectUser?._id,
        files: uploadedFiles,
      };

      const fileSendResponse = await new Promise((resolve) =>
        props.sendChatMessage(filePayload, (res) => resolve(res)),
      );

      if (fileSendResponse && fileSendResponse.status === 200) {
        props.getChatMessage(chatId, (res1) => {
          if (res1 && res1.status === 200) {
            setLoading(false);
            setChatMessages(res1?.data?.data);
          }
        });
      } else {
        ref?.current?.complete();
        return;
      }
    }

    if (message.trim()) {
      const textPayload = {
        chat: chatId,
        receiver: selectUser?.otherMember?._id ? selectUser?.otherMember?._id : selectUser?._id,
        content: message,
      };

      props.sendChatMessage(textPayload, (res) => {
        if (res && res.status === 200) {
          ref?.current?.complete();
          props.getChatMessage(chatId, (res1) => {
            if (res1 && res1.status === 200) {
              setLoading(false);
              setChatMessages(res1?.data?.data);
            }
          });
          const payload = {
            keyword,
            page,
            sort: "createdAt",
            order: "desc",
          };
          props.getChat(payload, (res) => {
            if (res && res.status === 200) {
              if (res?.data?.data?.length > 0) {
                setChatUsers([...res?.data?.data]);
                setChatData([...res?.data?.data]);
              }
            }
          });
        }
      });
    }
    setAttachedFile([]);
    ref?.current?.complete();
  };

  return (
    <ChatLayout
      selectUser={selectUser || chatUsers?.length > 0}
      setSelectUser={setSelectUser}
      setAddChat={setAddChat}
      setKeyword={setKeyword}
      keyword={keyword}
      loading={loadingRight}
      page={page}
      setPage={setPage}
      chatUsers={chatUsers}
      chatData={chatData}
      setChatData={setChatData}
    >
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      {(selectUser || chatUsers?.length > 0) && (
        <ChatModal
          chatMessages={chatMessages}
          setChatMessages={setChatMessages}
          setMessageContent={setMessageContent}
          profile={profile}
          handleSendMessage={handleSendMessage}
          messageContent={messageContent}
          selectUser={selectUser}
          setAttachedFile={setAttachedFile}
          attachedFile={attachedFile}
          loading={loading}
        />
      )}
      {/* {!selectUser && chatUsers?.length > 0 && (
        <div class="panel-main-content">
          {loading && loadingRight ? (
            <div class="chatbot-wrapper d-flex flex-column skeleton-cutom">
              <Skeleton borderRadius={10} width={"100%"} height={"80vh"} />
            </div>
          ) : (
            <div class="chatbot-wrapper d-flex flex-column">
              <div class="d-flex align-items-center justify-content-center m-auto">
                <div className="text-center">
                  <svg width="71" height="65" viewBox="0 0 71 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.15"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M21.8746 53.1184C21.923 53.1355 21.9715 53.1525 22.02 53.1693V64.7193L32.48 54.2793H50.48C55.7795 54.2635 60.8574 52.1513 64.6047 48.4039C68.352 44.6566 70.4642 39.5788 70.48 34.2793V28.2793C70.4643 23.0001 68.3682 17.9405 64.6472 14.1971C65.1893 16.1588 65.4739 18.1999 65.48 20.2704L65.48 20.2793L65.48 26.2883C65.4618 32.3803 63.0337 38.2176 58.726 42.5253C54.4183 46.833 48.581 49.2611 42.489 49.2793L42.48 49.2793L25.721 49.2793L21.8746 53.1184ZM10.971 43.8211C9.37964 40.9048 8.53122 37.6268 8.52002 34.2793V28.2793C8.53585 22.9798 10.6481 17.9019 14.3954 14.1546C18.1427 10.4073 23.2206 8.29512 28.52 8.2793H50.52C53.8736 8.296 57.1369 9.15206 60.029 10.7301C61.6136 13.6342 62.47 16.9121 62.48 20.2793V26.2793C62.4642 31.5788 60.352 36.6567 56.6047 40.404C52.8574 44.1513 47.7795 46.2635 42.48 46.2793H24.48L18.943 51.8058C17.116 50.805 15.4547 49.525 14.02 48.0113V45.1693C12.963 44.8035 11.9436 44.3519 10.971 43.8211Z"
                      fill="#47AD1D"
                    />
                    <path
                      d="M42.52 0.279297H20.52C15.2206 0.295124 10.1427 2.40734 6.39537 6.15464C2.64807 9.90195 0.535847 14.9798 0.52002 20.2793V26.2793C0.533933 30.439 1.84067 34.4916 4.25933 37.8759C6.678 41.2603 10.089 43.809 14.02 45.1693V56.7193L24.48 46.2793H42.48C47.7795 46.2635 52.8574 44.1513 56.6047 40.4039C60.352 36.6566 62.4642 31.5788 62.48 26.2793V20.2793C62.4643 14.9867 60.3575 9.91486 56.6189 6.16869C52.8802 2.42252 47.8125 0.305657 42.52 0.279297Z"
                      fill="#98C387"
                    />
                  </svg>
                  <div class="add-new-chat-intro text-center">
                    <h1>Start a Conversation</h1>
                    <p className="w-75 m-auto">Click on a chat to begin messaging.</p>
                  </div>
                  <div class="add-new-chat-btn">
                    <Link
                      onClick={() => setAddChat(true)}
                      class="btn btn-default"
                      data-bs-toggle="modal"
                      data-bs-target="#mewChatModal"
                    >
                      Chat
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )} */}
      {!selectUser && !chatUsers?.length > 0 && (
        <div class="panel-main-content d-flex flex-column panel-main-content-chat">
          {loading ? (
            <div class="panel-body flex-grow-1 ">
              <Skeleton borderRadius={10} width={`${isMobile ? "100%" : "105.5%"}`} height={"100vh"} />
            </div>
          ) : (
            <div class="panel-body flex-grow-1 ">
              <div class="chat-widget-holder">
                <div class="add-new-chat-widget">
                  <div>
                    <svg width="71" height="65" viewBox="0 0 71 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.15"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21.8746 53.1184C21.923 53.1355 21.9715 53.1525 22.02 53.1693V64.7193L32.48 54.2793H50.48C55.7795 54.2635 60.8574 52.1513 64.6047 48.4039C68.352 44.6566 70.4642 39.5788 70.48 34.2793V28.2793C70.4643 23.0001 68.3682 17.9405 64.6472 14.1971C65.1893 16.1588 65.4739 18.1999 65.48 20.2704L65.48 20.2793L65.48 26.2883C65.4618 32.3803 63.0337 38.2176 58.726 42.5253C54.4183 46.833 48.581 49.2611 42.489 49.2793L42.48 49.2793L25.721 49.2793L21.8746 53.1184ZM10.971 43.8211C9.37964 40.9048 8.53122 37.6268 8.52002 34.2793V28.2793C8.53585 22.9798 10.6481 17.9019 14.3954 14.1546C18.1427 10.4073 23.2206 8.29512 28.52 8.2793H50.52C53.8736 8.296 57.1369 9.15206 60.029 10.7301C61.6136 13.6342 62.47 16.9121 62.48 20.2793V26.2793C62.4642 31.5788 60.352 36.6567 56.6047 40.404C52.8574 44.1513 47.7795 46.2635 42.48 46.2793H24.48L18.943 51.8058C17.116 50.805 15.4547 49.525 14.02 48.0113V45.1693C12.963 44.8035 11.9436 44.3519 10.971 43.8211Z"
                        fill="#47AD1D"
                      />
                      <path
                        d="M42.52 0.279297H20.52C15.2206 0.295124 10.1427 2.40734 6.39537 6.15464C2.64807 9.90195 0.535847 14.9798 0.52002 20.2793V26.2793C0.533933 30.439 1.84067 34.4916 4.25933 37.8759C6.678 41.2603 10.089 43.809 14.02 45.1693V56.7193L24.48 46.2793H42.48C47.7795 46.2635 52.8574 44.1513 56.6047 40.4039C60.352 36.6566 62.4642 31.5788 62.48 26.2793V20.2793C62.4643 14.9867 60.3575 9.91486 56.6189 6.16869C52.8802 2.42252 47.8125 0.305657 42.52 0.279297Z"
                        fill="#98C387"
                      />
                    </svg>
                    <div class="add-new-chat-intro text-center">
                      <h1>Welcome to your inbox!</h1>
                      <p className="w-75 m-auto">
                        Drop a line, share posts and more with private conversations between you and others.
                      </p>
                    </div>
                    <div class="add-new-chat-btn">
                      <Link
                        onClick={() => setAddChat(true)}
                        class="btn btn-default"
                        data-bs-toggle="modal"
                        data-bs-target="#mewChatModal"
                      >
                        Add New Chat
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <ChatUser show={addChat} onHide={() => setAddChat(!addChat)} setSelectUser={setSelectUser} />
    </ChatLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getChat: (params, callback) => {
    dispatch(getChat(params, callback));
  },
  addChat: (data, callback) => {
    dispatch(addChat(data, callback));
  },
  getChatMessage: (params, callback) => {
    dispatch(getChatMessage(params, callback));
  },
  sendChatMessage: (data, callback) => {
    dispatch(sendChatMessage(data, callback));
  },
  readChatMessage: (params, data, callback) => {
    dispatch(readChatMessage(params, data, callback));
  },
  getUnreadChatCount: (payload) => dispatch(getUnreadChatCount(payload)),
  unreadChatCountReceived: (payload) => dispatch(unreadChatCountReceived(payload)),
});

const mapStateToProps = (state) => ({
  userChat: userChat(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ChatPage));
