import React, { useState, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import SpeciesAndCategoryEditModal from "../feedEditModals/speciesAndCategoryEditModal";
import RegionEditModal from "../feedEditModals/regionEditModal";
import WhoToFollowEditModal from "../feedEditModals/whoToFollowEditModal";
import ScienceAndEducationEditModal from "../feedEditModals/scienceAndEducationEditModal";
import OrganizationEditModal from "../feedEditModals/organizationEditModal";
import WetMarketEditModal from "../feedEditModals/wetMarketEditModal";
import {
  getUsersPreferences,
  getUser,
  getMyFeedData,
  getUsersDropdownData,
  followUnfollowMultipleFriends,
  updateYourProfile,
} from "../../../store/users";
import { connect } from "react-redux";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import { getProfile } from "../../../utils/localStorageServices";
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions";
import { getEnvironmentalistList, sideBarApisListings } from "../../../store/sidebarApis";
import defaultEnvImg from "../../../include/images/defaultEnvImg.png";

const RightFeedSidebar = (props) => {
  const [isOrgMenu, setIsOrgMenu] = useState(true);
  const [isZooMenu, setIsZooMenu] = useState(false);
  const [isEnvMenu, setIsEnvMenu] = useState(true);
  const [isFriendMenu, setIsFriendMenu] = useState(false);

  // for edit modals
  const [showSpeciesAndCategories, setshowSpeciesAndCategories] = useState(false);
  const [showRegion, setShowRegion] = useState(false);
  const [showWetMarket, setShowWetMarket] = useState(false);
  const [showWhoToFollow, setShowWhoToFollow] = useState(false);
  const [showScienceAndEducation, setShowScienceAndEducation] = useState(false);
  const [showOrganizationAndZoo, setShowOrganizationAndZoo] = useState(false);

  // temp state for selection
  const [tempSelectSpeciesCategory, setTempSelectSpeciesCategory] = useState([]);
  const [tempSelectSpecies, setTempSelectSpecies] = useState([]);
  const [tempSelectSpeciesName, setTempSelectSpeciesName] = useState([]);
  const [tempSelectScience, setTempSelectScience] = useState([]);
  const [tempSelectScienceName, setTempSelectScienceName] = useState([]);
  const [tempSelectRegion, setTempSelectRegion] = useState([]);
  const [tempSelectRegionName, setTempSelectRegionName] = useState([]);
  const [tempSelectOrg, setTempSelectOrg] = useState([]);
  const [tempSelectOrgName, setTempSelectOrgName] = useState([]);
  const [tempSelectZoo, setTempSelectZoo] = useState([]);
  const [tempSelectZooName, setTempSelectZooName] = useState([]);
  const [tempSelectEnv, setTempSelectEnv] = useState([]);
  const [tempSelectEnvName, setTempSelectEnvName] = useState([]);
  const [tempSelectUser, setTempSelectUser] = useState([]);
  const [tempSelectUserName, setTempSelectUserName] = useState([]);
  const [tempSelectWetMarket, setTempSelectWetMarket] = useState([]);
  const [tempSelectWetMarketName, setTempSelectWetMarketName] = useState([]);

  const togglerZoo = () => {
    setIsZooMenu(!isZooMenu);
    if (isOrgMenu) {
      setIsOrgMenu(!isOrgMenu);
    }
  };

  const togglerOrg = () => {
    setIsOrgMenu(!isOrgMenu);
    if (isZooMenu) {
      setIsZooMenu(!isZooMenu);
    }
  };

  const togglerEnv = () => {
    setIsEnvMenu(!isEnvMenu);
    if (isFriendMenu) {
      setIsFriendMenu(!isFriendMenu);
    }
  };
  const togglerFriend = () => {
    setIsFriendMenu(!isFriendMenu);
    if (isEnvMenu) {
      setIsEnvMenu(!isEnvMenu);
    }
  };

  //  for toggle modals
  const toggleSpeciesAndCategories = () => {
    setshowSpeciesAndCategories(!showSpeciesAndCategories);
  };
  const toggleRegion = () => {
    setShowRegion(!showRegion);
  };
  const toggleWetMarket = () => {
    setShowWetMarket(!showWetMarket);
  };

  const toggleWhoToFollow = () => {
    setShowWhoToFollow(!showWhoToFollow);
  };

  const toggleScience = () => {
    setShowScienceAndEducation(!showScienceAndEducation);
  };

  const toggleOrgAndZoo = () => {
    setShowOrganizationAndZoo(!showOrganizationAndZoo);
  };

  const [isEdit, setIsEdit] = useState(true);
  const [isViewAll, setIsViewAll] = useState(false);

  // for preference list data
  const [loadingMain, setLoadingMain] = useState(false);

  const regionData = props?.getUser?.region?.data;
  const speciesData = props?.getUser?.species?.data;
  const speciesCatData = props?.getUser?.speciesCategory?.data;
  const envData = props?.getUser?.environmentalist?.data;
  const orgData = props?.getUser?.organization?.data;
  const wetMarketData = props?.getUser?.wetMarket?.data;
  const zooData = props?.getUser?.zoo?.data;
  const scienceAndEducationData = props?.getUser?.science?.data;
  const userData = props?.getUser?.userPreferences?.data;
  const profile = getProfile();
  const isInitialRender = useRef(true);
  const envDropdown = props?.getUser?.environmentalist?.data;
  const [userDropdown, setUserDropdown] = useState(null);
  const [onePreferenceSelected, setonePreferenceSelected] = useState({
    regionData: 0,
    speciesData: 0,
    envData: 0,
    orgData: 0,
    wetMarketData: 0,
    zooData: 0,
    scienceAndEducationData: 0,
    userData: 0,
  });

  const checkIfAnyPreferenceSelected = (name, preferenceSelected) => {
    let newUpdatedPrefernce;
    if (name === "whotofollow") {
      setonePreferenceSelected({
        ...onePreferenceSelected,
        envData: preferenceSelected.envData.length,
        userData: preferenceSelected.userData.length,
      });
      newUpdatedPrefernce = {
        ...onePreferenceSelected,
        envData: preferenceSelected.envData.length,
        userData: preferenceSelected.userData.length,
      };
    } else if (name === "organization") {
      setonePreferenceSelected({
        ...onePreferenceSelected,
        orgData: preferenceSelected.orgData.length,
        zooData: preferenceSelected.zooData.length,
      });
      newUpdatedPrefernce = {
        ...onePreferenceSelected,
        orgData: preferenceSelected.orgData.length,
        zooData: preferenceSelected.zooData.length,
      };
    } else {
      setonePreferenceSelected({
        ...onePreferenceSelected,
        [name]: preferenceSelected.length,
      });
      newUpdatedPrefernce = {
        ...onePreferenceSelected,
        [name]: preferenceSelected.length,
      };
    }
    for (const dataKey in newUpdatedPrefernce) {
      if (newUpdatedPrefernce[dataKey] > 0) {
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    if (regionData) return;
    if (profile?.profile) {
      setLoadingMain(true);
      props.getUsersPreferences("region", (res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (speciesData) return;
    if (profile?.profile) {
      setLoadingMain(true);
      props.getUsersPreferences("species", (res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (speciesCatData) return;
    if (profile?.profile) {
      setLoadingMain(true);
      props.getUsersPreferences("speciesCategory", (res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (orgData) return;
    if (!showOrganizationAndZoo && profile?.profile) {
      setLoadingMain(true);
      props.getUsersPreferences("organization", (res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      return;
    }
  }, [showOrganizationAndZoo]);

  useEffect(() => {
    if (envData) return;
    if (!showWhoToFollow && profile?.profile) {
      setLoadingMain(true);
      props.getUsersPreferences("environmentalist", (res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      return;
    }
  }, [showWhoToFollow]);

  useEffect(() => {
    if (wetMarketData) return;
    if (profile?.profile) {
      setLoadingMain(true);
      props.getUsersPreferences("wetMarket", (res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (scienceAndEducationData) return;
    if (profile?.profile) {
      setLoadingMain(true);
      props.getUsersPreferences("scienceAndEducation", (res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, []);
  useEffect(() => {
    setLoadingMain(true);
    const payload = {
      keyword: "",
    };
    props.getUsersDropdownData(payload, (res) => {
      if (res && res.status === 200) {
        //Logic that current user should not be in the list
        setUserDropdown(res?.data?.data.filter((item) => item._id !== profile?.profile?._id));
        setLoadingMain(false);
      } else {
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);
  useEffect(() => {
    if (zooData) return;
    if (!showOrganizationAndZoo && profile?.profile) {
      setLoadingMain(true);
      props.getUsersPreferences("zoo", (res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      return;
    }
  }, [showOrganizationAndZoo]);

  useEffect(() => {
    if (userData) return;
    if (!showWhoToFollow && profile?.profile) {
      setLoadingMain(true);
      props.getUsersPreferences("user", (res) => {
        if (res && res.status === 200) {
          setLoadingMain(false);
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      return;
    }
  }, [showWhoToFollow]);
  useEffect(() => {
    if (profile?.profile) {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }
      const payload = {
        page: 1,
        sort: "createdAt",
        order: "desc",
      };
      if (
        !showRegion ||
        !showWetMarket ||
        !showWhoToFollow ||
        !showScienceAndEducation ||
        !showOrganizationAndZoo ||
        !showSpeciesAndCategories
      ) {
        props.getMyFeedData(payload, (res) => {});
      }
    }
  }, [
    showRegion,
    showWetMarket,
    showWhoToFollow,
    showScienceAndEducation,
    showOrganizationAndZoo,
    showSpeciesAndCategories,
  ]);
  useEffect(() => {
    if (regionData) {
      setonePreferenceSelected((prevState) => ({
        ...prevState,
        regionData: regionData.length,
      }));
    }
    if (speciesData) {
      setonePreferenceSelected((prevState) => ({
        ...prevState,
        speciesData: speciesData.length,
      }));
    }
    if (envData) {
      setonePreferenceSelected((prevState) => ({
        ...prevState,
        envData: envData.length,
      }));
    }
    if (orgData) {
      setonePreferenceSelected((prevState) => ({
        ...prevState,
        orgData: orgData.length,
      }));
    }
    if (wetMarketData) {
      setonePreferenceSelected((prevState) => ({
        ...prevState,
        wetMarketData: wetMarketData.length,
      }));
    }
    if (zooData) {
      setonePreferenceSelected((prevState) => ({
        ...prevState,
        zooData: zooData.length,
      }));
    }
    if (scienceAndEducationData) {
      setonePreferenceSelected((prevState) => ({
        ...prevState,
        scienceAndEducationData: scienceAndEducationData.length,
      }));
    }
    if (userData) {
      setonePreferenceSelected((prevState) => ({
        ...prevState,
        userData: userData.length,
      }));
    }
  }, [regionData, speciesData, envData, orgData, wetMarketData, zooData, scienceAndEducationData, userData]);
  const handleFollow = (item, type) => {
    setLoadingMain(true);
    if (type !== "env") {
      const payload = {
        userIds: [item],
      };
      props.followUnfollowMultipleFriends(payload, (res) => {
        if (res && res.status === 200) {
          const param = {
            keyword: "",
          };
          props.getUsersDropdownData(param, (res) => {
            if (res && res.status === 200) {
              setUserDropdown(res?.data?.data.filter((item) => item._id !== profile?.profile?._id));
              setLoadingMain(false);
            } else {
              setLoadingMain(false);
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
            }
          });
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      const ids = envData.map((i) => i._id);
      const itemIndex = ids.indexOf(item);
      let preferredEnvironmentalists;
      if (itemIndex !== -1) {
        preferredEnvironmentalists = ids.filter((id) => id !== item);
      } else {
        preferredEnvironmentalists = [...ids, item];
      }
      const payload = {
        preferredEnvironmentalists,
      };

      props.updateYourProfile(payload, (res) => {
        if (res && res.status === 200) {
          props.getUsersPreferences("environmentalist", (res) => {
            if (res && res.status === 200) {
              setLoadingMain(false);
            } else {
              setLoadingMain(false);
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
            }
          });
        } else {
          setLoadingMain(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  };
  return (
    <Accordion
      className="border-accordion accordion accordion-flush"
      id="accordionCategoryWidget"
      defaultActiveKey={["1"]}
    >
      <Accordion.Item className="accordion-item" id="two-tab-pane" eventKey="1">
        <Accordion.Button
          className="accordion-button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseWidgetOne"
          aria-expanded="true"
        >
          {profile?.profile ? (
            <div className="accordion-button-label">
              Major Categories of Species <br /> to Follow
              <span> ({speciesData ? speciesData.length : 0})</span>
            </div>
          ) : (
            <div className="accordion-button-label">
              Major Categories of Species to Follow
              <br />
            </div>
          )}
        </Accordion.Button>
        <div id="collapseWidgetOne" className="accordion-collapse collapse show">
          <Accordion.Body className="accordion-body">
            {profile?.profile && (
              <div className="accor-edit-btn">
                <div
                  className="edit-green-btn cursor-pointer"
                  onClick={() => {
                    if (isViewAll) {
                      setIsViewAll(false);
                    }
                    setIsEdit(true);
                    toggleSpeciesAndCategories();
                    props.setShowRightMenu(false);
                  }}
                >
                  Edit
                </div>
              </div>
            )}
            <ul className="border-accor-list">
              {speciesData && speciesData.length > 0
                ? speciesData.map((item, idx) => {
                    return (
                      idx < 5 && (
                        <li>
                          <Link
                            to={{
                              pathname: `/endangered-species/${item?.slug}`,
                              state: { id: item?._id },
                            }}
                          >
                            {capitalizeContentInBrackets(item?.displayCommonName || item?.scientificName)}
                          </Link>
                        </li>
                      )
                    );
                  })
                : profile?.profile
                ? "Select Major Categories of Species to Follow"
                : "Please Register/Login to select the preferences for Major Categories of Species to Follow"}
            </ul>
            {speciesData && speciesData.length > 0 ? (
              <div
                className="accor-view-all-btn"
                data-bs-toggle="collapse"
                data-bs-target=".multi-collapse"
                aria-expanded="false"
              >
                <button
                  type="button"
                  className="view-all-btn"
                  onClick={() => {
                    if (isEdit) {
                      setIsEdit(false);
                    }
                    setIsViewAll(true);
                    toggleSpeciesAndCategories();
                    props.setShowRightMenu(false);
                  }}
                >
                  View All
                </button>
              </div>
            ) : (
              ""
            )}
          </Accordion.Body>
        </div>
      </Accordion.Item>
      {/* <Accordion.Item className="accordion-item" eventKey="2">
        <Accordion.Button
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseWidgetTwo"
          aria-expanded="false"
        >
          {profile?.profile ? <div className="accordion-button-label">
            Science & Education to Follow
            <span>
              {" "}( {scienceAndEducationData ? scienceAndEducationData.length : 0} )
            </span>
          </div> : <div className="accordion-button-label">
            Science & Education to Follow
          </div>}
        </Accordion.Button>
        <div
          id="collapseWidgetTwo"
          className="accordion-collapse collapse show"
        >
          <Accordion.Body className="accordion-body">
            {profile?.profile && <div className="accor-edit-btn">
              <div
                className="edit-green-btn cursor-pointer"
                onClick={() => {
                  setIsEdit(true);
                  if (isViewAll) {
                    setIsViewAll(false);
                  }
                  toggleScience();
                  props.setShowRightMenu(false);
                }}
              >
                Edit
              </div>
            </div>}
            <ul className="border-accor-list">
              {scienceAndEducationData && scienceAndEducationData.length > 0
                ? scienceAndEducationData.map(
                  (item, idx) =>
                    idx < 5 && (
                      <li>
                        <a href="#">{capitalizeContentInBrackets(item?.name)}</a>
                      </li>
                    )
                )
                : profile?.profile ? "Select Science & Education to Follow" : "Please Register/Login to select the preferences for Science & Education to Follow"}
            </ul>
            {scienceAndEducationData && scienceAndEducationData.length > 0 ? (
              <div
                className="accor-view-all-btn"
                data-bs-toggle="collapse"
                data-bs-target=".multi-collapse2"
                aria-expanded="false"
              >
                <button
                  type="button"
                  className="view-all-btn"
                  onClick={() => {
                    setIsViewAll(true);
                    if (isEdit) {
                      setIsEdit(false);
                    }
                    toggleScience();
                    props.setShowRightMenu(false);
                  }}
                >
                  View All
                </button>
              </div>
            ) : (
              ""
            )}
          </Accordion.Body>
        </div>
      </Accordion.Item> */}
      <Accordion.Item className="accordion-item" eventKey="3">
        <Accordion.Button
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseWidgetThree"
          aria-expanded="false"
        >
          {profile?.profile ? (
            <div className="accordion-button-label">
              Organizations, Zoos and <br /> Wildlife Reserves to Follow
              <span> ( {(orgData?.length ?? 0) + (zooData?.length ?? 0)} )</span>
            </div>
          ) : (
            <div className="accordion-button-label">
              Organizations, Zoos and <br /> Wildlife Reserves to Follow
            </div>
          )}
        </Accordion.Button>
        <div id="collapseWidgetThree" className="accordion-collapse collapse show">
          <Accordion.Body className="accordion-body">
            {profile?.profile && (
              <div className="nav nav-tabs step-tabs" id="myTab1">
                <div className="nav-item">
                  <div
                    onClick={togglerOrg}
                    className={isOrgMenu ? "nav-link active" : "nav-link"}
                    id="tabA-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tabA-tab-pane"
                    aria-selected="true"
                  >
                    Organizations
                  </div>
                </div>
                <div className="nav-item">
                  <div
                    onClick={togglerZoo}
                    className={isZooMenu ? "nav-link active" : "nav-link"}
                    id="tabB-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tabB-tab-pane"
                    aria-selected="false"
                  >
                    Zoos & Wildlife Reserves
                  </div>
                </div>
              </div>
            )}
            <div className="tab-content" id="myTabContent">
              {isOrgMenu && (
                <div className="tab-pane fade show active" id="tabA-tab-pane">
                  {profile?.profile && (
                    <div className="accor-edit-btn">
                      <div
                        className="edit-green-btn cursor-pointer"
                        onClick={() => {
                          setIsEdit(true);
                          if (isViewAll) {
                            setIsViewAll(false);
                          }
                          toggleOrgAndZoo();
                          props.setShowRightMenu(false);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  )}
                  <div className="accor-d-area">
                    {orgData && orgData.length > 0
                      ? orgData.map(
                          (item, idx) =>
                            idx < 5 && (
                              <div className="d-item">
                                <div className="accor-d-title">
                                  <Link
                                    to={{
                                      pathname: `/organizations/${item?.slug}`,
                                      state: { id: item?._id },
                                    }}
                                  >
                                    {capitalizeContentInBrackets(item?.name)}
                                  </Link>
                                </div>
                                <div className="accor-d-subtitle">
                                  {item?.headQuarter
                                    ? `${item?.headQuarter?.cityName} ${item?.headQuarter?.stateName}, ${item?.headQuarter?.countryName}`
                                    : ""}
                                </div>
                              </div>
                            ),
                        )
                      : profile?.profile
                      ? "Select Organizations, Zoos and Wildlife Reserves to Follow"
                      : "Please Register/Login to select the preferences for Organizations, Zoos and Wildlife Reserves to Follow"}
                  </div>
                  {orgData && orgData.length > 0 ? (
                    <div
                      className="accor-view-all-btn"
                      data-bs-toggle="collapse"
                      data-bs-target=".multi-collapse3"
                      aria-expanded="false"
                    >
                      <button
                        type="button"
                        className="view-all-btn"
                        onClick={() => {
                          setIsViewAll(true);
                          if (isEdit) {
                            setIsEdit(false);
                          }
                          toggleOrgAndZoo();
                          props.setShowRightMenu(false);
                        }}
                      >
                        View All
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {isZooMenu && (
                <div className="tab-pane fade show active" id="tabB-tab-pane">
                  <div className="accor-edit-btn">
                    <div
                      className="edit-green-btn cursor-pointer"
                      onClick={() => {
                        setIsEdit(true);
                        if (isViewAll) {
                          setIsViewAll(false);
                        }
                        toggleOrgAndZoo();
                        props.setShowRightMenu(false);
                      }}
                    >
                      Edit
                    </div>
                  </div>
                  <div className="accor-d-area">
                    {zooData && zooData.length > 0
                      ? zooData.map(
                          (item, idx) =>
                            idx < 5 && (
                              <div className="d-item">
                                <div className="accor-d-title">
                                  <Link
                                    to={{
                                      pathname: `/zoos-and-wildlife-reserves/${item?.slug}`,
                                      state: { id: item?._id },
                                    }}
                                  >
                                    {capitalizeContentInBrackets(item?.name)}
                                  </Link>
                                </div>
                                <div className="accor-d-subtitle">
                                  {item?.headQuarter
                                    ? `${item?.headQuarter?.cityName} ${item?.headQuarter?.stateName}, ${item?.headQuarter?.countryName}`
                                    : ""}
                                </div>
                              </div>
                            ),
                        )
                      : profile?.profile
                      ? "Select Organizations, Zoos and Wildlife Reserves to Follow"
                      : "Please Register/Login to select the preferences for Organizations, Zoos and Wildlife Reserves to Follow"}
                  </div>
                  {zooData && zooData.length > 0 ? (
                    <div
                      className="accor-view-all-btn"
                      data-bs-toggle="collapse"
                      data-bs-target=".multi-collapse4"
                      aria-expanded="false"
                    >
                      <button
                        type="button"
                        className="view-all-btn"
                        onClick={() => {
                          setIsViewAll(true);
                          if (isEdit) {
                            setIsEdit(false);
                          }
                          toggleOrgAndZoo();
                          props.setShowRightMenu(false);
                        }}
                      >
                        View All
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </Accordion.Body>
        </div>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className="accordion-item">
        <Accordion.Button
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseWidgetFour"
          aria-expanded="false"
        >
          {profile?.profile ? (
            <div className="accordion-button-label">
              War and Threats to <br /> Environment to Follow
              <span> ( {wetMarketData ? wetMarketData.length : 0} )</span>
            </div>
          ) : (
            <div className="accordion-button-label">
              War and Threats to <br /> Environment to Follow
            </div>
          )}
        </Accordion.Button>
        <div id="collapseWidgetFour" className="accordion-collapse collapse show">
          <Accordion.Body className="accordion-body">
            {profile?.profile && (
              <div className="accor-edit-btn">
                <div
                  className="edit-green-btn cursor-pointer"
                  onClick={() => {
                    if (isViewAll) {
                      setIsViewAll(false);
                    }
                    setIsEdit(true);
                    toggleWetMarket();
                    props.setShowRightMenu(false);
                  }}
                >
                  Edit
                </div>
              </div>
            )}
            <ul className="border-accor-list">
              {wetMarketData && wetMarketData.length > 0
                ? wetMarketData.map(
                    (item, idx) =>
                      idx < 5 && (
                        <li>
                          <Link
                            to={{
                              pathname: `/war-on-the-environment-threats/${item?.slug}`,
                              state: { id: item?._id },
                            }}
                          >
                            {capitalizeContentInBrackets(item?.name)}
                          </Link>
                        </li>
                      ),
                  )
                : profile?.profile
                ? "Select War and Threats to Environment to Follow"
                : "Please Register/Login to select the preferences for War and Threats to Environment to Follow"}
            </ul>
            {wetMarketData && wetMarketData.length > 0 ? (
              <div
                className="accor-view-all-btn"
                data-bs-toggle="collapse"
                data-bs-target=".multi-collapse5"
                aria-expanded="false"
              >
                <button
                  type="button"
                  className="view-all-btn"
                  onClick={() => {
                    setIsViewAll(true);
                    if (isEdit) {
                      setIsEdit(false);
                    }
                    toggleWetMarket();
                    props.setShowRightMenu(false);
                  }}
                >
                  View All
                </button>
              </div>
            ) : (
              ""
            )}
          </Accordion.Body>
        </div>
      </Accordion.Item>
      <Accordion.Item eventKey="5" className="accordion-item">
        <Accordion.Button
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseWidgetFive"
          aria-expanded="false"
        >
          {profile?.profile ? (
            <div className="accordion-button-label">
              Regions <br /> to Follow
              <span> ({regionData ? regionData.length : 0})</span>
            </div>
          ) : (
            <div className="accordion-button-label">Regions to Follow</div>
          )}
        </Accordion.Button>
        <div id="collapseWidgetFive" className="accordion-collapse collapse show">
          <Accordion.Body className="accordion-body">
            {profile?.profile && (
              <div className="accor-edit-btn">
                <div
                  className="edit-green-btn cursor-pointer"
                  onClick={() => {
                    if (isViewAll) {
                      setIsViewAll(false);
                    }
                    setIsEdit(true);
                    toggleRegion();
                    props.setShowRightMenu(false);
                  }}
                >
                  Edit
                </div>
              </div>
            )}
            <ul className="border-accor-list">
              {regionData && regionData.length > 0
                ? regionData.map(
                    (item, idx) =>
                      idx < 5 && (
                        <li>
                          <Link
                            to={{
                              pathname:
                                item?.country === "US"
                                  ? `/regions/united-states/${item?.slug}`
                                  : `/regions/${item?.slug}`,
                              state: { id: item?._id },
                            }}
                          >
                            {item?.country === "US" ? `${item.name}, ${item?.countryName}` : item?.name}
                          </Link>
                        </li>
                      ),
                  )
                : profile?.profile
                ? "Select Regions to Follow"
                : "Please Register/Login to select the preferences for Select Regions to Follow"}
            </ul>
            {regionData && regionData.length > 0 ? (
              <div
                className="accor-view-all-btn"
                data-bs-toggle="collapse"
                data-bs-target=".multi-collapse"
                aria-expanded="false"
              >
                <button
                  type="button"
                  className="view-all-btn"
                  onClick={() => {
                    setIsViewAll(true);
                    if (isEdit) {
                      setIsEdit(false);
                    }
                    toggleRegion();
                    props.setShowRightMenu(false);
                  }}
                >
                  View All
                </button>
              </div>
            ) : (
              ""
            )}
          </Accordion.Body>
        </div>
      </Accordion.Item>
      {/* For Biologists and Freinds */}
      <Accordion.Item eventKey="6" className="accordion-item">
        <Accordion.Button
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseWidgetSix"
          aria-expanded="false"
        >
          {profile?.profile ? (
            <div className="accordion-button-label">
              Environmentalists and Friends <br /> to Follow
              <span> ( {(envData?.length ?? 0) + (userData?.length ?? 0)} )</span>
            </div>
          ) : (
            <div className="accordion-button-label">
              Environmentalists and Friends <br />
              to Follow
            </div>
          )}
        </Accordion.Button>
        <div id="collapseWidgetSix" className="accordion-collapse collapse show">
          <Accordion.Body className="accordion-body">
            {profile?.profile && (
              <div className="nav nav-tabs step-tabs" id="myTab2">
                <div className="nav-item">
                  <div
                    onClick={togglerEnv}
                    className={isEnvMenu ? "nav-link active" : "nav-link"}
                    id="tab1"
                    data-bs-toggle="tab"
                    data-bs-target="#tab1-pane"
                    aria-selected="true"
                  >
                    Environmentalists
                  </div>
                </div>
                <div className="nav-item">
                  <div
                    onClick={togglerFriend}
                    className={isFriendMenu ? "nav-link active" : "nav-link"}
                    id="tab2"
                    data-bs-toggle="tab"
                    data-bs-target="#tab2-pane"
                    aria-selected="false"
                  >
                    Friends
                  </div>
                </div>
              </div>
            )}
            <div className="tab-content" id="my2TabContent">
              {isEnvMenu && (
                <div className="tab-pane fade show active" id="tab1-pane">
                  {profile?.profile && (
                    <div className="accor-edit-btn">
                      <div
                        className="edit-green-btn cursor-pointer"
                        onClick={() => {
                          setIsEdit(true);
                          if (isViewAll) {
                            setIsViewAll(false);
                          }
                          toggleWhoToFollow();
                          props.setShowRightMenu(false);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  )}
                  <div className="tc-friends-list">
                    {envDropdown && envDropdown.length
                      ? envDropdown.map(
                          (item, idx) =>
                            idx < 3 && (
                              <div className="tc-friends-item">
                                <div className="tc-friends-row d-flex flex-wrap align-items-center justify-content-between">
                                  <div className="friends-list d-flex flex-wrap align-items-center">
                                    <div className="friends-img-box">
                                      <img
                                        src={
                                          item?.detailTabImage?.original
                                            ? process.env.REACT_APP_MEDIA + item?.detailTabImage?.original
                                            : item?.coverImage?.original
                                            ? process.env.REACT_APP_MEDIA + item?.coverImage?.original
                                            : defaultEnvImg
                                        }
                                        alt="frand img 03"
                                      />
                                    </div>
                                    <Link
                                      to={{
                                        pathname: `/environmentalists/${item?.slug}`,
                                        state: { id: item?._id },
                                      }}
                                      className="friends-text-box text-capitalize"
                                    >
                                      {capitalizeContentInBrackets(item?.name)}
                                    </Link>
                                  </div>
                                  <div class="tc-friends-btn">
                                    <Link
                                      onClick={() => handleFollow(item?._id, "env")}
                                      class="btn btn-default btn-block btn-follow"
                                    >
                                      {envData?.map((item) => item?._id)?.includes(item?._id) ? "Unfollow" : "Follow"}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ),
                        )
                      : profile?.profile
                      ? "Select Environmentalists and Friends to Follow"
                      : "Please Register/Login to select the preferences for Biologists and Friends to Follow"}
                  </div>
                  {envDropdown && envDropdown.length > 0 ? (
                    <div
                      className="accor-view-all-btn"
                      data-bs-toggle="collapse"
                      data-bs-target=".multi-collapse"
                      aria-expanded="false"
                    >
                      <button
                        type="button"
                        className="view-all-btn"
                        onClick={() => {
                          setIsViewAll(true);
                          if (isEdit) {
                            setIsEdit(false);
                          }
                          toggleWhoToFollow();
                          props.setShowRightMenu(false);
                        }}
                      >
                        Show more
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}

              {isFriendMenu && (
                <div className="tab-pane fade show active" id="tab2-pane">
                  {profile?.profile && (
                    <div className="accor-edit-btn">
                      <div
                        className="edit-green-btn cursor-pointer"
                        onClick={() => {
                          setIsEdit(true);
                          if (isViewAll) {
                            setIsViewAll(false);
                          }
                          toggleWhoToFollow();
                          props.setShowRightMenu(false);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  )}
                  <div className="tc-friends-list">
                    {userDropdown && userDropdown.length > 0
                      ? userDropdown.map(
                          (item, idx) =>
                            idx < 3 && (
                              <div className="tc-friends-item">
                                <div className="tc-friends-row d-flex flex-wrap align-items-center justify-content-between">
                                  <div className="friends-list d-flex flex-wrap align-items-center">
                                    <div className="friends-img-box">
                                      <img
                                        src={
                                          item?.profilePicture?.small
                                            ? process.env.REACT_APP_MEDIA + item?.profilePicture?.small
                                            : process.env.REACT_APP_MEDIA + item?.profilePicture?.original
                                        }
                                        alt="frand img 03"
                                      />
                                    </div>
                                    <Link
                                      to={{
                                        pathname: `profile/${item?.slug}`,
                                        state: { id: item?._id },
                                      }}
                                      className="friends-text-box caps-text"
                                    >
                                      {item && item?.firstName} {item && item?.lastName}
                                    </Link>
                                  </div>
                                  <div class="tc-friends-btn">
                                    <Link
                                      onClick={() => handleFollow(item?._id, "friends")}
                                      class="btn btn-default btn-block btn-follow"
                                    >
                                      {item?.isFollowing ? "Unfollow" : "Follow"}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ),
                        )
                      : profile?.profile
                      ? "Select Biologists and Friend to Follow"
                      : "Please Register/Login to select the preferences for Biologists and Friend to Follow"}
                  </div>
                  {userDropdown && userDropdown.length > 0 ? (
                    <div
                      className="accor-view-all-btn"
                      data-bs-toggle="collapse"
                      data-bs-target=".multi-collapse6"
                      aria-expanded="false"
                    >
                      <button
                        type="button"
                        className="view-all-btn"
                        onClick={() => {
                          setIsViewAll(true);
                          if (isEdit) {
                            setIsEdit(false);
                          }
                          toggleWhoToFollow();
                          props.setShowRightMenu(false);
                        }}
                      >
                        Show more
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </Accordion.Body>
        </div>
      </Accordion.Item>

      {/* <Accordion.Item eventKey="7" className="accordion-item">
        <Accordion.Button
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseWidgetSeven"
          aria-expanded="false"
        >
          <div className="accordion-button-label">
            Suggested Users<span>( 15 )</span>
          </div>
        </Accordion.Button>
        <div
          id="collapseWidgetSeven"
          className="accordion-collapse collapse show"
        >
          <Accordion.Body className="accordion-body">
            <div className="accor-edit-btn">
              <a href="#" className="edit-green-btn">
                Edit
              </a>
            </div>
            <div className="tc-friends-list">
              <div className="tc-friends-item">
                <div className="tc-friends-row d-flex flex-wrap align-items-center justify-content-between">
                  <div className="friends-list d-flex flex-wrap align-items-center">
                    <div className="friends-img-box">
                      <img
                        src="include/images/image-u-1.jpg"
                        alt="frand img 03"
                      />
                    </div>
                    <div className="friends-text-box">Tatiana Aminoff</div>
                  </div>
                  <div className="tc-friends-btn">
                    <a href="#" className="btn btn-default btn-block">
                      Follow
                    </a>
                  </div>
                </div>
              </div>
              <div className="tc-friends-item">
                <div className="tc-friends-row d-flex flex-wrap align-items-center justify-content-between">
                  <div className="friends-list d-flex flex-wrap align-items-center">
                    <div className="friends-img-box">
                      <img
                        src="include/images/image-u-2.jpg"
                        alt="frand img 03"
                      />
                    </div>
                    <div className="friends-text-box">Zain Siphron</div>
                  </div>
                  <div className="tc-friends-btn">
                    <a href="#" className="btn btn-default btn-block">
                      Follow
                    </a>
                  </div>
                </div>
              </div>
              <div className="tc-friends-item">
                <div className="tc-friends-row d-flex flex-wrap align-items-center justify-content-between">
                  <div className="friends-list d-flex flex-wrap align-items-center">
                    <div className="friends-img-box">
                      <img
                        src="include/images/image-u-3.jpg"
                        alt="frand img 03"
                      />
                    </div>
                    <div className="friends-text-box">Tiana Curtis</div>
                  </div>
                  <div className="tc-friends-btn">
                    <a href="#" className="btn btn-default btn-block">
                      Follow
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </div>
      </Accordion.Item> */}
      {profile?.profile && (
        <>
          <SpeciesAndCategoryEditModal
            show={showSpeciesAndCategories}
            onHide={toggleSpeciesAndCategories}
            loading={loadingMain}
            setLoading={setLoadingMain}
            speciesData={speciesData}
            isEdit={true}
            setIsEdit={setIsEdit}
            isViewAll={false}
            setIsViewAll={setIsViewAll}
            speciesCatData={speciesCatData}
            tempSelectSpeciesCategory={tempSelectSpeciesCategory}
            setTempSelectSpeciesCategory={setTempSelectSpeciesCategory}
            tempSelectSpecies={tempSelectSpecies}
            setTempSelectSpecies={setTempSelectSpecies}
            tempSelectSpeciesName={tempSelectSpeciesName}
            setTempSelectSpeciesName={setTempSelectSpeciesName}
            onePreferenceSelected={onePreferenceSelected}
            setonePreferenceSelected={setonePreferenceSelected}
            checkIfAnyPreferenceSelected={checkIfAnyPreferenceSelected}
          />
          <RegionEditModal
            show={showRegion}
            onHide={toggleRegion}
            loading={loadingMain}
            regionData={regionData}
            setLoading={setLoadingMain}
            isEdit={true}
            isViewAll={false}
            setIsViewAll={setIsViewAll}
            setIsEdit={setIsEdit}
            tempSelectRegion={tempSelectRegion}
            setTempSelectRegion={setTempSelectRegion}
            tempSelectRegionName={tempSelectRegionName}
            setTempSelectRegionName={setTempSelectRegionName}
            onePreferenceSelected={onePreferenceSelected}
            setonePreferenceSelected={setonePreferenceSelected}
            checkIfAnyPreferenceSelected={checkIfAnyPreferenceSelected}
          />

          <WhoToFollowEditModal
            show={showWhoToFollow}
            onHide={toggleWhoToFollow}
            loading={loadingMain}
            setLoading={setLoadingMain}
            envData={envData}
            userData={userData}
            isEdit={true}
            setIsEdit={setIsEdit}
            isViewAll={false}
            setIsViewAll={setIsViewAll}
            isEnvMenu={isEnvMenu}
            setIsEnvMenu={setIsEnvMenu}
            isFriendMenu={isFriendMenu}
            setIsFriendMenu={setIsFriendMenu}
            togglerEnv={togglerEnv}
            togglerFriend={togglerFriend}
            tempSelectEnv={tempSelectEnv}
            tempSelectEnvName={tempSelectEnvName}
            setTempSelectEnv={setTempSelectEnv}
            setTempSelectEnvName={setTempSelectEnvName}
            tempSelectUser={tempSelectUser}
            tempSelectUserName={tempSelectUserName}
            setTempSelectUser={setTempSelectUser}
            setTempSelectUserName={setTempSelectUserName}
            onePreferenceSelected={onePreferenceSelected}
            setonePreferenceSelected={setonePreferenceSelected}
            checkIfAnyPreferenceSelected={checkIfAnyPreferenceSelected}
          />
          <ScienceAndEducationEditModal
            show={showScienceAndEducation}
            onHide={toggleScience}
            loading={loadingMain}
            scienceAndEducationData={scienceAndEducationData}
            setLoading={setLoadingMain}
            isEdit={true}
            setIsEdit={setIsEdit}
            isViewAll={false}
            setIsViewAll={setIsViewAll}
            tempSelectScience={tempSelectScience}
            tempSelectScienceName={tempSelectScienceName}
            setTempSelectScience={setTempSelectScience}
            setTempSelectScienceName={setTempSelectScienceName}
            onePreferenceSelected={onePreferenceSelected}
            setonePreferenceSelected={setonePreferenceSelected}
            checkIfAnyPreferenceSelected={checkIfAnyPreferenceSelected}
          />
          <OrganizationEditModal
            show={showOrganizationAndZoo}
            onHide={toggleOrgAndZoo}
            loading={loadingMain}
            setLoading={setLoadingMain}
            orgData={orgData}
            zooData={zooData}
            isEdit={true}
            setIsEdit={setIsEdit}
            isViewAll={false}
            setIsViewAll={setIsViewAll}
            isOrgMenu={isOrgMenu}
            setIsOrgMenu={setIsOrgMenu}
            isZooMenu={isZooMenu}
            setIsZooMenu={setIsZooMenu}
            togglerOrg={togglerOrg}
            togglerZoo={togglerZoo}
            tempSelectOrg={tempSelectOrg}
            tempSelectOrgName={tempSelectOrgName}
            setTempSelectOrg={setTempSelectOrg}
            setTempSelectOrgName={setTempSelectOrgName}
            tempSelectZoo={tempSelectZoo}
            tempSelectZooName={tempSelectZooName}
            setTempSelectZoo={setTempSelectZoo}
            setTempSelectZooName={setTempSelectZooName}
            onePreferenceSelected={onePreferenceSelected}
            setonePreferenceSelected={setonePreferenceSelected}
            checkIfAnyPreferenceSelected={checkIfAnyPreferenceSelected}
          />

          <WetMarketEditModal
            show={showWetMarket}
            onHide={toggleWetMarket}
            loading={loadingMain}
            setLoading={setLoadingMain}
            isEdit={true}
            setIsEdit={setIsEdit}
            isViewAll={false}
            setIsViewAll={setIsViewAll}
            wetMarketData={wetMarketData}
            tempSelectWetMarket={tempSelectWetMarket}
            tempSelectWetMarketName={tempSelectWetMarketName}
            setTempSelectWetMarket={setTempSelectWetMarket}
            setTempSelectWetMarketName={setTempSelectWetMarketName}
            onePreferenceSelected={onePreferenceSelected}
            setonePreferenceSelected={setonePreferenceSelected}
            checkIfAnyPreferenceSelected={checkIfAnyPreferenceSelected}
          />
        </>
      )}
    </Accordion>
  );
};

// export default RightFeedSidebar;
const mapDispatchToProps = (dispatch) => ({
  getUsersPreferences: (params, callback) => dispatch(getUsersPreferences(params, callback)),
  getMyFeedData: (params, callback) => dispatch(getMyFeedData(params, callback)),
  getUsersDropdownData: (params, callback) => dispatch(getUsersDropdownData(params, callback)),
  followUnfollowMultipleFriends: (data, callback) => dispatch(followUnfollowMultipleFriends(data, callback)),
  getEnvironmentalistList: (params, callback) => {
    dispatch(getEnvironmentalistList(params, callback));
  },
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(RightFeedSidebar));
