import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { getProfile } from "../../utils/localStorageServices";
import gcIntroImg from "../../include/images/gc-intro-img.png";
import gcDetailImg from "../../include/images/gc-detail-img.png";
import { connect } from "react-redux";
import { followUnfollowFriends, getGuestProfile, getMyFeedData, updateYourProfile } from "../../store/users";
import logoImg1 from "../../include/images/logo-img-1.jpg";
import logoImg2 from "../../include/images/logo-img-2.jpg";
import logoImg3 from "../../include/images/logo-img-3.jpg";
import logoImg4 from "../../include/images/logo-img-4.jpg";
import logoImg5 from "../../include/images/logo-img-5.jpg";
import logoImg6 from "../../include/images/logo-img-6.jpg";
import logoImg7 from "../../include/images/logo-img-7.jpg";
import memberImg1 from "../../include/images/member-img-1.jpg";
import memberImg2 from "../../include/images/member-img-2.jpg";
import memberImg3 from "../../include/images/member-img-3.jpg";
import memberImg4 from "../../include/images/member-img-4.jpg";
import memberImg5 from "../../include/images/member-img-5.jpg";
import memberImg6 from "../../include/images/member-img-6.jpg";
import gcCardImg2 from "../../include/images/gc-card-img-2.jpg";
import gcCardImg1 from "../../include/images/gc-card-img-1.jpg";

import { getPublicPost, likeUnlikePost } from "../../store/post";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactPlayer from "react-player";
import HTMLReactParser from "html-react-parser";
import { getRelativeTime } from "../../utils/helperFunctions";
import { Dropdown } from "react-bootstrap";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import DmPosts from "../../components/feed/modals/dmPosts";
import Skeleton from "react-loading-skeleton";
import AlertSuccess from "../../common/alerts/alertSuccess";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import LoadingBar from "react-top-loading-bar";

//Enivronment Press corp Page
const InternReport = (props) => {
  var ref = useRef(null);

  return (
    <PageLayout>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <div class="heading-wrapper intern-program-container overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hw-title text-center">
                <h1>
                  <span>
                    INTERN <em>PROGRAM OVERVIEW</em>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container professor-details-container p-0">
        <div class="container">
          <div class="row">
            <div class="col-md-11 mx-auto">
              <div class="professor-details-box grey-bg">
                <p>
                  We are introducing a new intern program designed for university students to be involved in the
                  development of the first social media site dedicated to endangered species.
                </p>
                <br />
                <p>
                  We have over 14,301 species from the animal kingdom including mammals, reptiles, birds, crustations,
                  amphibians, sharks & rays, reef corals, and insect classes that are in danger of extinction. We are
                  building pages for each animal including photos, videos, science papers, zoo reports, conservation
                  reports, animal art, organizations, biologist and scientists to bring awareness about the programs and
                  threats to each species. Here is a summary of the program:{" "}
                </p>
                <ol>
                  <li>
                    Register on{" "}
                    <a href="/signup?type=volunteer" target="_blank" rel="noopener noreferrer">
                      Environmental Inclusion
                    </a>{" "}
                    and select species of interest. Let us know the university, department and professors involved.
                  </li>
                  <li>
                    The student will then have full access to develop and build one species page with the most current
                    information available.
                  </li>
                  <li>
                    Student will engage scientists, biologists, environmentalist, zoos, non-profits, preservations, and
                    advocates to collect and collaborate on EnvironmentalInclusion.com.
                  </li>
                  <li>
                    The goal is to organize, develop and promote the conservation efforts to save this animal through
                    research and journalism.
                  </li>
                  <li>
                    After the internship completion, Environmental Inclusion will supply the professor with a detailed
                    report showing the results and comments regarding the project.{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container grid-content-list">
        <div class="container">
          <div class="row">
            <div class="col-md-11 mx-auto">
              <h3 className="text-center" style={{fontWeight:"500"}}>EXAMPLE INTERN REPORT</h3>
              <div className="custom-intern-container">
                <div class="grid-content-row">
                  <div class="gc-body">
                    <div class="gc-intro-content d-flex justify-content-between flex-column flex-md-row">
                      <div class="gc-intro-left">
                        <div class="gc-title">
                          <h4>
                            Student Name : <span>Paris Wilson</span>
                          </h4>
                        </div>
                        <div class="gc-intro-details-list d-flex flex-column">
                          <div class="gc-intro-details-item">
                            <strong>Professor Name :</strong> Sam Segal
                          </div>
                          <div class="gc-intro-details-item">
                            <strong>Department :</strong> Biology & Natural Studies
                          </div>
                        </div>
                      </div>
                      <div class="gc-intro-right d-flex align-items-center">
                        <div class="gc-intro-img">
                          <img class="w-100 h-100 object-fit-cover" src={gcIntroImg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid-content-row">
                  <div class="gc-body">
                    <div class="gc-detail-wrapper">
                      <div class="gc-detail-left">
                        <div class="gc-detail-left-img">
                          <img src={gcDetailImg} alt="" />
                        </div>
                      </div>
                      <div class="gc-detail-right">
                        <div class="gc-detail-right-upper">
                          <div class="gc-detail-right-head">
                            <a
                              href="https://www.environmentalinclusion.com/endangered-species/mustela-nigripes-38"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h4>Black-Footed Ferret</h4>
                            </a>
                          </div>
                        </div>
                        <div class="gc-detail-right-lower d-flex">
                          <div class="gc-detail-lower-left">
                            <div class="gc-detail-text">
                              <h6>Mustela Nigripes</h6>
                              <p>ESA Categories -Mammals</p>
                            </div>
                            <div class="gc-detail-text">
                              <h6>National Parks/ Sanctuaries</h6>
                              <p>Cheyenne Mountain Zoo</p>
                              <p>National Black-Footed Ferret Conservation Center</p>
                            </div>
                          </div>
                          <div class="gc-detail-lower-right d-flex">
                            <div class="gc-detail-lower-col">
                              <div class="gc-detail-text">
                                <h6>Common Names</h6>
                                <p>Black-Footed Ferret</p>
                              </div>
                              <div class="gc-detail-text">
                                <h6>Habitat</h6>
                                <p>Open Grasslands and Prairies</p>
                              </div>
                            </div>
                            <div class="gc-detail-lower-col">
                              <div class="gc-detail-text">
                                <h6>Regions</h6>
                                <p>Arizona, United States,</p>
                                <p>Colorado, United States,</p>
                                <p>Montana, United States</p>
                              </div>
                            </div>
                            <div class="gc-detail-lower-col">
                              <div class="gc-detail-text">
                                <h6>Population</h6>
                                <p>300</p>
                              </div>
                              <div class="gc-detail-text">
                                <h6>Weight(in lbs) and Life Span</h6>
                                <p>1.5 to 2.5 pounds and 3-4 years</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid-content-row intern-program-custom">
                  <div class="gc-header-row d-flex">
                    <div class="gc-heading">
                      <h3>Organizations & Donating</h3>
                    </div>
                  </div>
                  <div class="gc-body">
                    <div class="grid-logo-list d-flex flex-wrap">
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={logoImg1} alt="logoImg1" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/organizations/association-of-zoos-and-aquariums-aza"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Association Of Zoos And Aquariums (AZA)
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={logoImg2} alt="logoImg2" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/organizations/audubon-of-kansas"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Audubon Of Kansas
                              </a>
                            </h5>

                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={logoImg3} alt="logoImg3" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/organizations/defenders-of-wildlife"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Defenders of Wildlife
                              </a>
                            </h5>

                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={logoImg4} alt="logoImg4" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/organizations/prairie-wildlife-research"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prairie Wildlife Research
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={logoImg5} alt="logoImg5" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/organizations/blackfooted-ferret-friends-bfffriends"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Black-Footed Ferret Frieds (BFFFRIENDS)
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={logoImg6} alt="logoImg6" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/organizations/center-for-biological-diversity"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Center For Biological Diversity
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={logoImg7} alt="logoImg7" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/organizations/colorado-parks-wildlife-cpw"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Colorado Parks & Wildlife (CPW)
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid-content-row intern-program-custom">
                  <div class="gc-header-row d-flex">
                    <div class="gc-heading">
                      <h3>Scientists & Activists</h3>
                    </div>
                  </div>
                  <div class="gc-body">
                    <div class="grid-logo-list member-list d-flex flex-wrap">
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={memberImg1} alt="memberImg1" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/environmentalists/alexandria-decandia"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Alexandria Decandia
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={memberImg2} alt="memberImg2" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/environmentalists/courtney-j-conway"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Courtney J. Conway
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={memberImg3} alt="memberImg3" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/environmentalists/dean-biggins"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Dean Biggins
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={memberImg4} alt="memberImg4" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/environmentalists/robyn-bortner"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Robyn Bortner
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={memberImg5} alt="memberImg5" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/environmentalists/john-emmerich"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                John Emmerich
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="grid-logo-item">
                        <div class="grid-logo-box d-flex align-items-center">
                          <div class="glb-img">
                            <img class="w-100 h-100 object-fit-contain" src={memberImg6} alt="memberImg6" />
                          </div>
                          <div class="glb-content">
                            <h5>
                              {" "}
                              <a
                                href="https://www.environmentalinclusion.com/environmentalists/pete-gober"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Pete Gober
                              </a>
                            </h5>
                            <div class="glb-btn d-flex align-items-start">
                              {/* <a href="#" class="read-btn">Read More</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid-content-row intern-program-custom">
                  <div class="gc-header-row">
                    <div class="gc-heading gc-heading-line">
                      <h3>Regions</h3>
                    </div>
                  </div>
                  <div class="gc-body">
                    <div class="green-dot-list d-flex flex-wrap">
                      <div class="green-dot-item position-relative">
                        <a href="/regions/arizona" target="_blank" rel="noopener noreferrer">
                          <span>Arizona,</span> United States
                        </a>
                      </div>
                      <div class="green-dot-item position-relative">
                        <a href="/regions/colorado" target="_blank" rel="noopener noreferrer">
                          <span>Colorado,</span> United States
                        </a>
                      </div>
                      <div class="green-dot-item position-relative">
                        <span>Montana,</span> United States
                      </div>
                      <div class="green-dot-item position-relative">
                        <a href="/regions/colorado" target="_blank" rel="noopener noreferrer">
                          <span>Nebraska,</span> United States
                        </a>
                      </div>
                      <div class="green-dot-item position-relative">
                        <a href="/regions/new-mexico" target="_blank" rel="noopener noreferrer">
                          <span>New Mexico,</span> United States
                        </a>
                      </div>
                      <div class="green-dot-item position-relative">
                        <a href="/regions/north-dakota" target="_blank" rel="noopener noreferrer">
                          <span>North Dakota,</span> United States
                        </a>
                      </div>
                      <div class="green-dot-item position-relative">
                        <a href="/regions/oklahoma" target="_blank" rel="noopener noreferrer">
                          <span>Oklahoma,</span> United States
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid-content-row">
                  <div class="gc-header-row">
                    <div class="gc-heading">
                      <h3>War on the Environment – Threats</h3>
                    </div>
                  </div>
                  <div class="gc-body">
                    <div class="gc-card-list d-flex flex-wrap">
                      <div class="gc-card-item">
                        <div class="gc-card-box w-100 h-100">
                          <div class="gc-img-box">
                            <img class="w-100 h-100 object-fit-cover" src={gcCardImg1} alt="" />
                          </div>
                          <div class="gc-content-box">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/war-on-the-environment-threats/agriculture-aquaculture"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Agriculture & Aquaculture
                              </a>
                            </h5>
                            <p>
                              Descrition: Intensive farming practices and the expansion of aquaculture can have adverse
                              effects on wildlife. Using pesticides, herbicides, and fertilizers in agriculture leads to
                              wat
                            </p>
                            <div class="gc-link-group d-flex flex-wrap align-items-center">
                              {/* <div class="gc-link-item"><a href="#" class="readmore-link">Read More</a></div> */}
                              <div class="gc-link-item">
                                <a href="/endangered-species" class="gc-link" target="_blank" rel="noopener noreferrer">
                                  Endangeres Species
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="gc-card-item">
                        <div class="gc-card-box w-100 h-100">
                          <div class="gc-img-box">
                            <img class="w-100 h-100 object-fit-cover" src={gcCardImg2} alt="" />
                          </div>
                          <div class="gc-content-box">
                            <h5>
                              <a
                                href="https://www.environmentalinclusion.com/war-on-the-environment-threats/biological-resource-use"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Biological Resource Use
                              </a>
                            </h5>
                            <p>
                              Descrition: The unsustainable exploitation of biological resources, such as overfishing,
                              poaching, and illegal wildlife trade, threatens endangered a
                            </p>
                            <div class="gc-link-group d-flex flex-wrap align-items-center">
                              {/* <div class="gc-link-item"><a href="#" class="readmore-link">Read More</a></div> */}
                              <div class="gc-link-item">
                                <a href="/endangered-species" class="gc-link" target="_blank" rel="noopener noreferrer">
                                  Endangeres Species
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid-content-row">
                  <div class="tc-heading">
                    <h4>Threats</h4>
                  </div>
                  <div class="tc-content-box">
                    <div class="tc-list d-flex flex-wrap">
                      <div class="tc-item">
                        <div class="tc-box">
                          <h5>Natural Threats</h5>
                          <ul class="small-dot-list d-flex flex-column">
                            <div class="small-dot-item position-relative">Predators</div>
                            <div class="small-dot-item position-relative">Diseases Like Sylvatic Plague</div>
                            <div class="small-dot-item position-relative">Availability Of Prey (Black-Prairie Dog)</div>
                          </ul>
                        </div>
                      </div>
                      <div class="tc-item">
                        <div class="tc-box">
                          <h5>Human Threats</h5>
                          <ul class="small-dot-list d-flex flex-column">
                            <div class="small-dot-item position-relative">
                              Habitat Loss Due To Agricultural And Urban Development
                            </div>
                            <div class="small-dot-item position-relative">Poisoning And Hunting ‘Pest’ Campaings</div>
                          </ul>
                        </div>
                      </div>
                      <div class="tc-item">
                        <div class="tc-box">
                          <h5>Climate Change Threats</h5>
                          <ul class="small-dot-list d-flex flex-column">
                            <div class="small-dot-item position-relative">
                              Altering The Distribution And Abudance Of Their Prey
                            </div>
                            <div class="small-dot-item position-relative">
                              Affectation Of Overall Ecosystem Dynamics
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container pt-0 gc-bottom-container">
        <div class="container">
          <div class="row">
            <div class="col-md-11 mx-auto">
              <div class="gc-bottom-wrapper">
                <div class="gc-title">
                  <h4>
                    Environmental Inclusion Student Report : <span>Dedication and Excellence in Action</span>
                  </h4>
                </div>
                <p>
                  <strong>Paris Wilson</strong> has demonstrated remarkable dedication and passion throughout her
                  involvement in this program. Her hard work shines in every aspect of her contributions, from
                  meticulously researching environmental challenges to creatively sharing the stories of unsung
                  environmental heroes. <strong>Paris</strong> brought unique talent and a tireless commitment to
                  raising awareness and driving change. The impact of her efforts is not only evident in the quality of
                  her work but also in the inspiration she has provided to peers and mentors alike. Well done!
                </p>
                <div class="gc-btn-group d-flex flex-wrap justify-content-center">
                  <a href="javascript:void(0);" class="btn btn-default">
                    Contribution
                  </a>
                  <a href="javascript:void(0);" class="btn btn-outline-green">
                    Environmental Inclusion Manager Comments
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getGuestProfile: (params, callback) => dispatch(getGuestProfile(params, callback)),
  getMyFeedData: (params, callback) => dispatch(getPublicPost(params, callback)),
  likeUnlikePost: (type, id, callback) => dispatch(likeUnlikePost(type, id, callback)), // getMyFeedData
  followUnfollowFriends: (data, callback) => {
    dispatch(followUnfollowFriends(data, callback));
  },
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(InternReport));
