import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { getProfileInfo, updateYourProfile } from "../../store/users";
import Select from "react-select";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import { getCountry, getMisc, getUsCitiesDropdown, getUsState } from "../../store/misc";
import editIcon from "../../include/images/edit-2.svg";
import { Cropper } from "react-cropper";
import { getProfile, setProfile } from "../../utils/localStorageServices";
import banner from "../../include/images/banner-hero.jpg";
import { getSpeciesList, sideBarApisListings } from "../../store/sidebarApis";
import SponsorSpeciesModal from "../../components/common/subscription/sponsorSpeciesModal";
import SpeciesMultiSelectModal from "../../components/common/subscription/speciesMultiSelectModal";
import defaultImage from "../../include/images/default_image_species.svg";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import stripeIcon from "../../include/images/stripeIcon.svg";
import paypalIcon from "../../include/images/paypal.svg";
import { createsponsor, createStripeSponsor } from "../../store/subscription";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Auth, Storage } from "aws-amplify";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import LoadingBar from "react-top-loading-bar";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
const SustainabilityDetails = (props) => {
  // State Management
  const profile = getProfile();
  const [selectedSponsor, setSelectedSponsor] = useState("corporate");
  const [formState, setFormState] = useState({
    logoOrPhoto: selectedSponsor == "corporate" ? profile?.profile?.organizationLogo : profile?.profile?.profilePicture,
    bannerPicture:
      selectedSponsor == "corporate" ? profile?.profile?.bannerPicture : profile?.profile?.individualBannerPicture,
    primaryColor:
      selectedSponsor === "corporate"
        ? profile?.profile?.primaryColor || "#47ad1d"
        : profile?.profile?.individualPrimaryColor || "#47ad1d",
    secondaryColor:
      selectedSponsor === "corporate"
        ? profile?.profile?.secondaryColor || "#47ad1d"
        : profile?.profile?.individualSecondaryColor || "#47ad1d",
    companyName: profile?.profile?.organizationName,
    email: profile?.profile?.email,
    website: profile?.profile?.website,
    country: profile?.profile?.location?.country,
    state: profile?.profile?.location?.state,
    city: profile?.profile?.location?.cityName,
    firstName: profile?.profile?.firstName,
    lastName: profile?.profile?.lastName,
    tagLine: selectedSponsor == "corporate" ? profile?.profile?.tagLine : profile?.profile?.individualTagLine,
    description:
      selectedSponsor == "corporate" ? profile?.profile?.description : profile?.profile?.individualDescription,
  });
  const [error, setError] = useState(null);
  const [selectLocation, setSelectLocation] = useState({
    countryName: profile?.profile?.location?.countryName,
    country: profile?.profile?.location?.country,
    state: profile?.profile?.location?.stateName,
    code: profile?.profile?.location?.country,
  });
  const [countryDropdown, setCountryDropdown] = useState(null);
  const [usStateDropdown, setUsStateDropdown] = useState(null);
  const [usCityDropdown, setUsCityDropdown] = useState(null);
  const [loadingCity, setLoadingCity] = useState(false);
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  var ref = useRef(null);
  const location = useLocation();
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [cropper, setCropper] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [coverImage, setCoverImage] = useState(
    profile?.profile?.bannerPicture?.original
      ? process.env.REACT_APP_MEDIA + profile?.profile?.bannerPicture?.original
      : banner,
  );
  const [imageForOrganization, setImageForOrganization] = useState(null);
  const [tempSelectSpeciesName, setTempSelectSpeciesName] = useState([location.state?.data]);
  const [showSpeciesAndCategories, setshowSpeciesAndCategories] = useState(false);
  const [tempSelectSpeciesCategory, setTempSelectSpeciesCategory] = useState([]);
  const [tempSelectSpecies, setTempSelectSpecies] = useState([location.state?.data?._id]);
  const [imageForBanner, setImageForBanner] = useState(null);
  const [image, setImage] = useState(null);
  const speciesDropdownDataList = props?.sideBarApisListings?.speciesList?.data;
  const [selectedSpecies, setSelectedSpecies] = useState([]);
  const isMobile = useCheckMobileScreen();
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);

  let reorderedCountryDropdown = [];
  if (Array.isArray(countryDropdown)) {
    reorderedCountryDropdown = [...countryDropdown];
    const usIndex = reorderedCountryDropdown.findIndex((country) => country.name === "United States");

    if (usIndex > -1) {
      const usOption = reorderedCountryDropdown.splice(usIndex, 1)[0];
      reorderedCountryDropdown.unshift(usOption);
    }
  }
  const [selectedAnimals, setSelectedAnimals] = useState([
    { id: 1, name: "Black-Footed Ferret", img: "include/images/search-anim-img-1.jpg" },
    { id: 2, name: "Giant Panda", img: "include/images/search-anim-img-2.jpg" },
  ]);
  useEffect(() => {
    props.getSpeciesList({}, (res) => {
      if (res && res.status === 200) {
      }
    });
  }, []);
  useEffect(() => {
    ref && ref.current && ref.current.continuousStart();
    setLoadingCountry(true);
    props.getCountry((res) => {
      if (res && res.status === 200) {
        ref && ref.current && ref.current.complete();
        setLoadingCountry(false);
      } else {
        setLoadingCountry(false);
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);
  const handleCrop = () => {
    if (cropper) {
      const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
      const croppedImageBlob = dataURLToBlob(croppedImageDataUrl);
      const croppedImageFile = new File([croppedImageBlob], "banner-image.png", { type: "image/png" });
      const updatedData = { ...formState, bannerPicture: URL.createObjectURL(croppedImageBlob) };
      setFormState(updatedData);
      setCoverImage(URL.createObjectURL(croppedImageBlob));
      setImage(null);
      setImageForBanner(croppedImageFile);
      const type = selectedSponsor == "corporate" ? "bannerPicture" : "individualBannerPicture";
      uploadFile(croppedImageFile, type);
    }
  };
  const handleCropOrganizationLogo = () => {
    if (cropper) {
      const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
      const croppedImageBlob = dataURLToBlob(croppedImageDataUrl);
      const croppedImageFile = new File([croppedImageBlob], "organization-logo.png", { type: "image/png" });
      const updatedData = { ...formState, logoOrPhoto: URL.createObjectURL(croppedImageBlob) };
      setFormState(updatedData);
      setImageForOrganization(null);
      setNewLogo(croppedImageFile);
      const type = selectedSponsor == "corporate" ? "organizationLogo" : "profilePicture";
      uploadFile(croppedImageFile, type);
    }
  };
  useEffect(() => {
    ref && ref.current && ref.current.continuousStart();
    setLoadingState(true);
    props.getUsState((res) => {
      if (res && res.status === 200) {
        ref && ref.current && ref.current.complete();
        setLoadingState(false);
      } else {
        setLoadingState(false);
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  const country = (props.getMisc && props.getMisc.Countries && props.getMisc.Countries.data) || {};

  const state = (props.getMisc && props.getMisc.usState && props.getMisc.usState.data) || {};

  const cities = props.getMisc && props.getMisc.usCitiesItems && props.getMisc.usCitiesItems.data;

  useEffect(() => {
    if (country) {
      const data =
        country && country?.length > 0
          ? country?.map((item) => ({
              name: item.name,
              code: item.code,
              _id: item._id,
            }))
          : [];
      setCountryDropdown(data);
    }

    if (state) {
      const sData =
        state && state?.length > 0
          ? state?.map((item) => ({
              id: item.id,
              name: item.name,
            }))
          : [];
      setUsStateDropdown(sData);
    }

    if (cities) {
      const cData =
        cities && cities.length > 0
          ? cities?.map((item) => ({
              id: item.id,
              name: item.name,
            }))
          : [];
      setUsCityDropdown(cData);
    }
  }, [country, state, cities]);
  const handleInputChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleAnimalRemove = (id) => {
    setSelectedAnimals(selectedAnimals.filter((animal) => animal.id !== id));
  };

  const toggleSpeciesAndCategories = () => {
    setshowSpeciesAndCategories(!showSpeciesAndCategories);
  };
  const handleSponsorChange = (e) => {
    setSelectedSponsor(e.target.value);
    setFormState({
      logoOrPhoto:
        e.target.value == "corporate" ? profile?.profile?.organizationLogo : profile?.profile?.profilePicture,
      bannerPicture:
        e.target.value == "corporate" ? profile?.profile?.bannerPicture : profile?.profile?.individualBannerPicture,
      primaryColor:
        e.target.value === "corporate"
          ? profile?.profile?.primaryColor || "#47ad1d"
          : profile?.profile?.individualPrimaryColor || "#47ad1d",
      secondaryColor:
        e.target.value === "corporate"
          ? profile?.profile?.secondaryColor || "#47ad1d"
          : profile?.profile?.individualSecondaryColor || "#47ad1d",
      companyName: profile?.profile?.organizationName,
      email: profile?.profile?.email,
      website: profile?.profile?.website,
      country: profile?.profile?.location?.country,
      state: profile?.profile?.location?.state,
      city: profile?.profile?.location?.cityName,
      firstName: profile?.profile?.firstName,
      lastName: profile?.profile?.lastName,
      tagLine: e.target.value == "corporate" ? profile?.profile?.tagLine : profile?.profile?.individualTagLine,
      description:
        e.target.value == "corporate" ? profile?.profile?.description : profile?.profile?.individualDescription,
    });
    setCoverImage(
      e.target.value === "corporate"
        ? profile?.profile?.bannerPicture?.original
          ? process.env.REACT_APP_MEDIA + profile?.profile?.bannerPicture?.original
          : banner
        : profile?.profile?.individualBannerPicture?.original
        ? process.env.REACT_APP_MEDIA + profile?.profile?.individualBannerPicture?.original
        : banner,
    );
  };
  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([uintArray], { type: mimeString });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageForOrganization(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  const validateHandler = () => {
    const errors = {};

    if (!formState.tagLine || formState.tagLine.trim() === "") {
      errors.tagLine = "Tagline is required";
    }
    if (!formState.description || formState.description.trim() === "") {
      errors.description = "Write something About your Business";
    }

    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      setError(errors);
      return false;
    } else {
      setError({});
      return true;
    }
  };
  const uploadFile = async (file, type) => {
    ref?.current?.continuousStart();
    const fSize = Math.round(file.size / 1048576);
    const fType = file.type;
    const ext = file.name.split(".").pop();

    if (fSize > 25) {
      ref?.current?.complete();
      toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
      return null;
    }

    if (!["image/jpeg", "image/jpg", "image/png", "image/webp"].includes(fType)) {
      ref?.current?.complete();
      toast(<AlertError message="Invalid format. Allowed formats are jpg, jpeg, png, and webp." />);
      return null;
    }

    const fileName = `${uuidv4()}.${ext}`;

    try {
      const result = await Storage.put(fileName, file, {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {
          ref?.current?.complete();
          toast(<AlertError message="Something went wrong with the upload." />);
        },
      });
      let payload = { [type]: `public/${result.key}` };
      props.updateYourProfile(payload, (res) => {
        if (res && res.status === 200) {
          ref?.current?.complete();
          const data = {
            ...formState,
            logoOrPhoto:
              selectedSponsor == "corporate" ? res?.data?.data?.organizationLogo : res?.data?.data?.profilePicture,
            bannerPicture:
              selectedSponsor == "corporate"
                ? res?.data?.data?.bannerPicture
                : res?.data?.data?.individualBannerPicture,
            primaryColor:
              selectedSponsor == "corporate" ? res?.data?.data?.primaryColor : res?.data?.data?.individualPrimaryColor,
            secondaryColor:
              selectedSponsor == "corporate"
                ? res?.data?.data?.secondaryColor
                : res?.data?.data?.individualSecondaryColor,
            companyName: res?.data?.data?.organizationName,
            email: res?.data?.data?.email,
            website: res?.data?.data?.website,
            country: res?.data?.data?.location?.country,
            state: res?.data?.data?.location?.state,
            city: res?.data?.data?.location?.cityName,
            firstName: res?.data?.data?.firstName,
            lastName: res?.data?.data?.lastName,
            tagLine: selectedSponsor == "corporate" ? res?.data?.data?.tagLine : res?.data?.data?.individualTagLine,
            description:
              selectedSponsor == "corporate" ? res?.data?.data?.description : res?.data?.data?.individualDescription,
          };
          setCoverImage(
            selectedSponsor == "corporate"
              ? process.env.REACT_APP_MEDIA + res?.data?.data?.bannerPicture.original
              : process.env.REACT_APP_MEDIA + res?.data?.data?.individualBannerPicture.original,
          );
          setSelectLocation({
            countryName: res?.data?.data?.location?.countryName,
            country: res?.data?.data?.location?.country,
            state: res?.data?.data?.location?.stateName,
            code: res?.data?.data?.location?.country,
          });
          props.getProfileInfo((res) => {
            let tempData = { ...profile };
            tempData.profile = res?.data?.data;
            setProfile(tempData);
          });
          setFormState(data);
          setNewLogo(null);
        }
      });
    } catch (error) {
      ref?.current?.complete();
      toast(<AlertError message="Something went wrong with the upload." />);
      return null;
    }
  };
  const onSaveHandler = async (type1) => {
    setLoadingPayment(true);
    if (!validateHandler()) {
      setLoadingPayment(false);
      return null;
    }
    ref?.current?.continuousStart();

    let payload;

    if (selectedSponsor == "corporate") {
      payload = {
        tagLine: formState?.tagLine,
        description: formState?.description,
        organizationName: formState?.organizationName,
        primaryColor: formState?.primaryColor,
        secondaryColor: formState?.secondaryColor,
        country: selectLocation.country ? selectLocation.code : "",
        website: formState.website ? formState.website : "",
        state: selectLocation.state ? selectLocation.state : "",
        city: formState.city ? formState.city : "",
      };
    } else {
      payload = {
        individualTagLine: formState?.tagLine,
        individualDescription: formState?.description,
        individualPrimaryColor: formState?.primaryColor,
        individualSecondaryColor: formState?.secondaryColor,
        country: selectLocation.country ? selectLocation.code : "",
        state: selectLocation.state ? selectLocation.state : "",
        city: formState.city ? formState.city : "",
      };
    }
    props.updateYourProfile(payload, (res) => {
      if (res && res.status === 200) {
        if (location?.state?.data?._id) {
          const type = selectedSponsor == "corporate" ? "corporate" : "individual";
          let payload1;
          if (type1 == "paypal") {
            payload1 = {
              speciesId: [...tempSelectSpecies],
              isCompany: selectedSponsor == "corporate",
              return_url: location.state.data._id
                ? `${window.location.origin}/sponsor-successfull/${location.state.data._id}?type=${type}`
                : `${window.location.origin}/sponsor-successfull?type=${type}`,
              cancel_url: location.state.data._id
                ? `${window.location.origin}/endangered-species/${location.state.data._id}?type=${type}&cancel=true`
                : `${window.location.origin}/profile?type=${type}&cancel=true`,
            };
            sessionStorage.setItem("tempSelectSpecies", JSON.stringify(tempSelectSpecies));
            props
              .createsponsor(payload1, (res) => {
                if (res && res.status === 200) {
                  setLoadingPayment(false);
                  const redirectUrl = res.data.data?.url;
                  if (redirectUrl) {
                    window.location.href = redirectUrl;
                  } else {
                    toast(<AlertError message="No redirection URL provided" />);
                  }
                } else {
                  setLoadingPayment(false);
                  toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
                }
              })
              .catch((err) => {
                setLoadingPayment(false);
                toast(<AlertError message="Something Went Wrong" />);
              });
          } else {
            payload1 = {
              speciesId: [...tempSelectSpecies],
              isCompany: selectedSponsor == "corporate",
              return_url: location.state.data._id
                ? `${window.location.origin}/sponsor-successfull/${location.state.data._id}?type=${type}&pay=stripe`
                : `${window.location.origin}/sponsor-successfull?type=${type}&pay=stripe`,
              cancel_url: location.state.data._id
                ? `${window.location.origin}/endangered-species/${location.state.data._id}?type=${type}&cancel=true&pay=stripe`
                : `${window.location.origin}/profile?type=${type}&cancel=true&pay=stripe`,
            };
            props
              .createStripeSponsor(payload1, (res) => {
                if (res && res.status === 200) {
                  const redirectUrl = res.data.data?.session?.url;
                  const sessionId = res.data.data?.session?.id;
                  sessionStorage.setItem("session_id", JSON.stringify(sessionId));
                  ref?.current?.complete();
                  setLoadingPayment(false);
                  if (redirectUrl) {
                    window.location.href = redirectUrl;
                  } else {
                    toast(<AlertError message="No redirection URL provided" />);
                  }
                } else {
                  setLoadingPayment(false);
                  ref?.current?.complete();
                  toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
                }
              })
              .catch((err) => {
                ref?.current?.complete();
                setLoadingPayment(false);
                toast(<AlertError message="Something Went Wrong" />);
              });
          }
        } else {
          props.getProfileInfo((res) => {
            if (res && res.status == 200) {
              setLoadingPayment(false);
              ref?.current?.complete();
              const data = {
                ...formState,
                logoOrPhoto:
                  selectedSponsor == "corporate" ? res?.data?.data?.organizationLogo : res?.data?.data?.profilePicture,
                bannerPicture:
                  selectedSponsor == "corporate"
                    ? res?.data?.data?.bannerPicture
                    : res?.data?.data?.individualBannerPicture,
                primaryColor:
                  selectedSponsor == "corporate"
                    ? res?.data?.data?.primaryColor
                    : res?.data?.data?.individualPrimaryColor,
                secondaryColor:
                  selectedSponsor == "corporate"
                    ? res?.data?.data?.secondaryColor
                    : res?.data?.data?.individualSecondaryColor,
                companyName: res?.data?.data?.organizationName,
                email: res?.data?.data?.email,
                website: res?.data?.data?.website,
                country: res?.data?.data?.location?.country,
                state: res?.data?.data?.location?.state,
                city: res?.data?.data?.location?.cityName,
                firstName: res?.data?.data?.firstName,
                lastName: res?.data?.data?.lastName,
                tagLine: selectedSponsor == "corporate" ? res?.data?.data?.tagLine : res?.data?.data?.individualTagLine,
                description:
                  selectedSponsor == "corporate"
                    ? res?.data?.data?.description
                    : res?.data?.data?.individualDescription,
              };
              setCoverImage(
                selectedSponsor == "corporate"
                  ? process.env.REACT_APP_MEDIA + res?.data?.data?.bannerPicture.original
                  : process.env.REACT_APP_MEDIA + res?.data?.data?.individualBannerPicture.original,
              );
              setSelectLocation({
                countryName: res?.data?.data?.location?.countryName,
                country: res?.data?.data?.location?.country,
                state: res?.data?.data?.location?.stateName,
                code: res?.data?.data?.location?.country,
              });
              setFormState(data);
              setNewLogo(null);
              let tempData = { ...profile };
              tempData.profile = res?.data?.data;
              setProfile(tempData);
            }
          });
        }
      } else {
        ref?.current?.complete();
        setLoadingPayment(false);
        toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
      }
    });
  };
  const handlePreview = async () => {
    setLoadingPreview(true);
    if (!validateHandler()) {
      setLoadingPreview(false);
      return null;
    }
    ref?.current?.continuousStart();
    let payload;
    if (selectedSponsor == "corporate") {
      payload = {
        tempSpeciesToBeSponsored: [...tempSelectSpecies],
        tagLine: formState?.tagLine,
        description: formState?.description,
        organizationName: formState?.organizationName,
        primaryColor: formState?.primaryColor,
        secondaryColor: formState?.secondaryColor,
        country: selectLocation.country ? selectLocation.code : "",
        website: formState.website ? formState.website : "",
        state: selectLocation.state ? selectLocation.state : "",
        city: formState.city ? formState.city : "",
      };
    } else {
      payload = {
        tempSpeciesToBeSponsored: [...tempSelectSpecies],
        individualTagLine: formState?.tagLine,
        individualDescription: formState?.description,
        individualPrimaryColor: formState?.primaryColor,
        individualSecondaryColor: formState?.secondaryColor,
        country: selectLocation.country ? selectLocation.code : "",
        state: selectLocation.state ? selectLocation.state : "",
        city: formState.city ? formState.city : "",
      };
    }
    props.updateYourProfile(payload, (res) => {
      if (res && res.status === 200) {
        const type = selectedSponsor == "corporate" ? "corporate" : "individual";
        const redirectUrl = `/sustainability-preview/${profile?.profile?.slug}?isCompany=${type}&isPreview=true`;
        setLoadingPreview(false);
        window.open(redirectUrl, "_blank");
        props.getProfileInfo((res) => {
          let tempData = { ...profile };
          tempData.profile = res?.data?.data;
          setProfile(tempData);
        });
      } else {
        ref?.current?.complete();
        setLoadingPreview(false);
        toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
      }
    });
  };
  return (
    <PageLayout>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <div class="content-container sponsor-form-container">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="sf-info-content">
                <div class="page-title1 sponsor-form-title">
                  <h1>Sponsor Form</h1>
                </div>
                <p>
                  One of the key benefits of sponsoring an animal is gaining access to your personalized company or
                  sustainability page. To get started, simply complete the form below and click the Preview button to
                  see how your page will appear. While we will initially use the selections from your profile page, you
                  have the option to customize them specifically for your unique Sustainability Page.
                </p>
                <p>
                  If your company already has a sustainability page, you can link to it and label this as your
                  Environmental Outreach page, highlighting your ongoing efforts in sustainability. This additional page
                  is a great way to complement your current sustainability initiatives and showcase your commitment to
                  environmental stewardship.
                  <br class="d-inline-block d-xl-block" /> Your Sustainability Page can be updated anytime in the future
                  to reflect new changes or achievements.{" "}
                </p>
                <p>
                  For assistance, please contact our customer service team at{" "}
                  <a className="view-all" href="mailto:communications@environmentalinclusion.com">
                    communications@environmentalinclusion.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container sponsor-form-container pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form>
                {/* Sponsor Type */}
                <div className="sf-form-widget-row d-flex flex-column gap-40">
                  <div className="sf-raio-group d-flex flex-column flex-lg-row flex-wrap">
                    <div className="sf-raio-item">
                      <div class="sf-radio-box">
                        <div class="form-check rounded-form-check">
                          <input
                            type="radio"
                            name="sponsorCheck"
                            value="corporate"
                            class="form-check-input"
                            checked={selectedSponsor === "corporate"}
                            onChange={handleSponsorChange}
                          />
                          <label class="form-check-label" for="sponsorCheck1">
                            <div class="fc-title-box font-family-poppins fw-medium">Corporate Sustainability Page</div>
                            <div class="fc-subtitle">for Corporate Sponsors</div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="sf-raio-item">
                      <div class="sf-radio-box">
                        <div class="form-check rounded-form-check">
                          <input
                            type="radio"
                            name="sponsorCheck"
                            id="sponsorCheck2"
                            class="form-check-input"
                            value="personal"
                            checked={selectedSponsor === "personal"}
                            onChange={handleSponsorChange}
                          />
                          <label class="form-check-label" for="sponsorCheck2">
                            <div class="fc-title-box font-family-poppins fw-medium">Personal Sustainability Page</div>
                            <div class="fc-subtitle">for Personal Sponsors</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Form Inputs */}
                <div className="sf-form-widget-row d-flex flex-column gap-40">
                  <div class="file-upload-group d-flex flex-column flex-md-row flex-wrap">
                    <div class="file-upload-left">
                      <div class="file-upload-box d-flex flex-column">
                        <div class="file-upload-label font-family-poppins fw-medium">Upload Logo or Photo</div>
                        <div class="file-uploader-button">
                          <div class="fu-img-box">
                            {imageForOrganization ? (
                              <div className="cropper-overlay">
                                <div className="cropper-container">
                                  <Cropper
                                    src={imageForOrganization}
                                    style={{ height: "70vh", width: "100%" }}
                                    aspectRatio={1}
                                    guides={true}
                                    viewMode={1}
                                    autoCropArea={1}
                                    background={false}
                                    responsive={true}
                                    checkOrientation={false}
                                    onInitialized={(instance) => setCropper(instance)}
                                  />
                                  <div className="cropper-actions">
                                    <button onClick={handleCropOrganizationLogo} className="btn btn-default h-40">
                                      Crop and Save
                                    </button>
                                    <button
                                      onClick={() => setImageForOrganization(null)}
                                      className="btn btn-secondary h-40"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <img
                                src={
                                  newLogo
                                    ? formState?.logoOrPhoto
                                    : process.env.REACT_APP_MEDIA + formState?.logoOrPhoto?.small
                                }
                                alt="Logo"
                              />
                            )}
                          </div>
                          <div class="file-upload d-flex align-items-center justify-content-center">
                            <input
                              className="d-none"
                              type="file"
                              id="fileInput1"
                              ref={fileInputRef1}
                              onChange={handleFileChange}
                            />
                            <img
                              onClick={() => {
                                fileInputRef1.current?.click();
                              }}
                              src={editIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="file-upload-right">
                      <div class="file-upload-box d-flex flex-column">
                        <div class="file-upload-label font-family-poppins fw-medium">Banner Picture</div>
                        <div class="file-uploader-button">
                          <div class="fu-img-box">
                            {image ? (
                              <div className="cropper-overlay">
                                <div className="cropper-container">
                                  <Cropper
                                    src={image}
                                    style={{ height: "70vh", width: "100%" }}
                                    aspectRatio={16 / 9}
                                    guides={true}
                                    viewMode={1}
                                    autoCropArea={1}
                                    background={false}
                                    responsive={true}
                                    checkOrientation={false}
                                    onInitialized={(instance) => setCropper(instance)}
                                  />
                                  <div className="cropper-actions">
                                    <button onClick={handleCrop} className="btn btn-default h-40">
                                      Crop and Save
                                    </button>
                                    <button onClick={() => setImage(null)} className="btn btn-secondary h-40">
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <img src={coverImage} alt="profile cover" className="rounded-3" />
                            )}
                          </div>
                          <div class="file-upload d-flex align-items-center justify-content-center">
                            <input type="file" id="fileInput" ref={fileInputRef} onChange={handleFileChange1} />
                            <img
                              src={editIcon}
                              alt=""
                              onClick={() => {
                                fileInputRef.current.click();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="color-picker-wrapper d-flex flex-column">
                    <div class="color-picker-label font-family-poppins fw-medium">Choose your color theme</div>
                    <div class="color-picker-row d-flex flex-column flex-md-row">
                      <div class="color-picker-item">
                        <div class="color-picker-box d-flex flex-column">
                          <div class="picker-hold d-flex flex-column gap-2">
                            <div class="color-picker-label font-family-poppins">Primary color</div>
                            <div class="picker d-flex">
                              {" "}
                              <div class="picker-input flex-grow-1">
                                <input
                                  type="text"
                                  id="textInput1"
                                  value={formState.primaryColor}
                                  onChange={(e) => handleInputChange("primaryColor", e.target.value)}
                                  class="w-100"
                                />
                              </div>
                              <div class="picker-trigger flex-shrink-0">
                                <input
                                  type="color"
                                  class="form-control form-control-color"
                                  id="colorInput1"
                                  value={formState.primaryColor}
                                  title="Choose your color"
                                  onChange={(e) => handleInputChange("primaryColor", e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="color-picker-item">
                        <div class="color-picker-box d-flex flex-column">
                          <div class="picker-hold d-flex flex-column gap-2">
                            <div class="color-picker-label font-family-poppins">Secondary color</div>
                            <div class="picker d-flex">
                              <div class="picker-input flex-grow-1">
                                <input
                                  type="text"
                                  id="textInput2"
                                  value={formState.secondaryColor}
                                  onChange={(e) => handleInputChange("secondaryColor", e.target.value)}
                                  class="w-100"
                                />
                              </div>
                              <div class="picker-trigger flex-shrink-0">
                                <input
                                  type="color"
                                  class="form-control form-control-color"
                                  id="colorInput2"
                                  value={formState.secondaryColor}
                                  title="Choose your color"
                                  onChange={(e) => handleInputChange("secondaryColor", e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sf-form-widget-row d-flex flex-column gap-40 font-family-poppins">
                  <div class="form-col-holder">
                    <div class="sf-w-form-list d-flex flex-wrap">
                      <div class="sf-w-form-item">
                        <div class="sf-w-form-box">
                          {selectedSponsor === "corporate" ? (
                            <div class="form-floating custom-form-floating xl-size">
                              <input
                                type="text"
                                class="form-control text-capitalize"
                                id="companyName"
                                name="companyName"
                                value={formState?.companyName}
                                onChange={(e) => {
                                  const data = { ...formState };
                                  data.companyName = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                                  setFormState(data);
                                }}
                              />
                              <label for="companyName">Company Name</label>
                            </div>
                          ) : (
                            <div class="form-floating custom-form-floating xl-size">
                              <input
                                type="text"
                                class="form-control text-capitalize"
                                id="companyName"
                                name="companyName"
                                value={formState?.firstName}
                                readOnly
                              />
                              <label for="companyName">First Name</label>
                            </div>
                          )}
                        </div>
                      </div>
                      {selectedSponsor != "corporate" && (
                        <div class="sf-w-form-item">
                          <div class="sf-w-form-box">
                            <div class="form-floating custom-form-floating xl-size">
                              <input
                                type="email"
                                class="form-control text-capitalize"
                                id="email"
                                name="email"
                                value={formState?.lastName}
                                readOnly
                              />
                              <label for="email">Last Name</label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div class="sf-w-form-item">
                        <div class="sf-w-form-box">
                          <div class="form-floating custom-form-floating xl-size">
                            <input
                              type="email"
                              class="form-control "
                              id="email"
                              name="email"
                              value={formState?.email}
                              readOnly
                            />
                            <label for="email">Email Address</label>
                          </div>
                        </div>
                      </div>
                      {selectedSponsor === "corporate" && (
                        <div class="sf-w-form-item">
                          <div class="sf-w-form-box">
                            <div class="form-floating custom-form-floating xl-size">
                              <input
                                type="text"
                                class="form-control "
                                id="website"
                                name="website"
                                value={formState?.website}
                                onChange={(e) => {
                                  const data = { ...formState };
                                  data.website = e.target.value;
                                  setFormState(data);
                                }}
                              />
                              <label for="website">Website</label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form-col-holder">
                    <div class="sf-w-form-list d-flex flex-wrap">
                      <div class="sf-w-form-item">
                        <div class="sf-w-form-box">
                          <div className="form-floating custom-form-floating xl-size">
                            <select
                              className="form-select"
                              id="countrySelect"
                              value={
                                reorderedCountryDropdown.find((option) => option._id === selectLocation?.country)
                                  ? selectLocation?.country
                                  : reorderedCountryDropdown.find(
                                      (option) => option.name === selectLocation?.countryName,
                                    )?._id || ""
                              }
                              onChange={(event) => {
                                const selectedOption = reorderedCountryDropdown?.find(
                                  (option) => option._id === event.target.value,
                                );
                                const data = { ...selectLocation };
                                data.countryName = selectedOption ? selectedOption.name : "";
                                data.country = selectedOption ? selectedOption._id : "";
                                data.code = selectedOption ? selectedOption.code : "";
                                setSelectLocation(data);
                              }}
                            >
                              <option value="">Please select country</option>
                              {reorderedCountryDropdown?.map((option) => (
                                <option key={option._id} value={option._id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            <label htmlFor="countrySelect">Country</label>
                          </div>
                        </div>
                      </div>
                      <div class="sf-w-form-item">
                        <div class="sf-w-form-box">
                          {(selectLocation && selectLocation?.country && selectLocation?.country === "US") ||
                          (selectLocation &&
                            selectLocation?.countryName &&
                            selectLocation?.countryName === "United States") ? (
                            <div class="form-floating custom-form-floating xl-size">
                              <select
                                className="form-select"
                                id="stateSelect"
                                value={
                                  selectLocation.state
                                    ? usStateDropdown?.find((option) => option.name === selectLocation.state)?.id || ""
                                    : ""
                                }
                                onChange={(event) => {
                                  const selectedOption = usStateDropdown.find(
                                    (option) => option.id === event.target.value,
                                  );
                                  const data = { ...selectLocation };
                                  data.state = selectedOption ? selectedOption.name : "";
                                  data.value = selectedOption ? selectedOption.id : "";
                                  setSelectLocation(data);
                                }}
                              >
                                <option value="">Select</option>
                                {usStateDropdown?.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                              <label for="state">State</label>
                            </div>
                          ) : (
                            <div class="form-floating custom-form-floating xl-size">
                              <input
                                type="text"
                                class="form-control text-capitalize"
                                id="state"
                                value={selectLocation && selectLocation.state ? selectLocation?.state : ""}
                                onChange={(e) => {
                                  const data = { ...selectLocation };
                                  data.state = e.target.value;
                                  setSelectLocation(data);
                                }}
                              />
                              <label for="state">State</label>
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="sf-w-form-item">
                        <div class="sf-w-form-box">
                          <div class="form-floating custom-form-floating xl-size">
                            <input
                              type="text"
                              class="form-control text-capitalize"
                              id="city"
                              name="city"
                              value={formState?.city}
                              onChange={(e) => {
                                const data = { ...formState };
                                data.city = e.target.value;
                                setFormState(data);
                              }}
                            />
                            <label for="city">City</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-col-holder">
                    <div class="sf-w-form-list d-flex flex-wrap">
                      <div class="sf-w-form-item">
                        <div class="sf-w-form-box">
                          <div class="form-floating custom-form-floating xl-size">
                            <input
                              type="text"
                              class="form-control"
                              id="tagline"
                              name="tagline"
                              value={formState.tagLine || ""}
                              onChange={(e) => {
                                const data = { ...formState };
                                data.tagLine = e.target.value;
                                setFormState(data);
                              }}
                            />
                            <label for="tagline">Tagline</label>
                            <div class="invalid-feedback">{error && error.tagLine ? <p>{error.tagLine}</p> : ""}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-col-holder">
                    <div class="sf-w-form-list d-flex flex-wrap">
                      <div class="sf-w-form-item">
                        <div class="sf-w-form-box">
                          <div class="form-floating custom-form-floating xl-size">
                            <textarea
                              class="form-control"
                              placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              name="description"
                              value={formState.description || ""}
                              onChange={(e) => {
                                const data = { ...formState };
                                data.description = e.target.value;
                                setFormState(data);
                              }}
                            ></textarea>
                            <label for="floatingTextarea2">About your Business</label>
                            <div class="invalid-feedback">
                              {error && error.description ? <p>{error.description}</p> : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {location?.state?.data?._id && (
                    <div class="form-col-holder">
                      <div class="pick-animals-wrapper">
                        <div class="pick-animals-select">
                          <div class="form-floating custom-form-floating xl-size">
                            <select class="form-select" id="countrySelect" onClick={toggleSpeciesAndCategories}>
                              <option selected="">Please select Animal</option>
                            </select>
                            <label for="floatingSelect">Pick Animal</label>
                          </div>
                        </div>
                        <div class="selected-animals-block">
                          {tempSelectSpeciesName?.length > 0 && <div class="selected-label">Selected Animals</div>}
                          <div className="selected-animals-list d-flex flex-wrap">
                            {tempSelectSpeciesName?.map((species, index) => (
                              <div key={species._id} className="selected-animals-item flex-grow-0">
                                <div className="selected-animals-box w-100 h-100 d-flex">
                                  {/* Image Section */}
                                  <div className="selected-animals-img ">
                                    <img
                                      src={
                                        species.coverImage?.small
                                          ? process.env.REACT_APP_MEDIA + species.coverImage.small
                                          : getDefaultImage(species?.speciesCategoryName)
                                      }
                                      alt={species.displayCommonName || "Species"}
                                    />
                                  </div>
                                  {/* Name Section */}
                                  <div className="selected-animals-name text-capitalize">
                                    {species.displayCommonName || species.scientificName || "Unknown"}
                                  </div>
                                  <button
                                    type="button"
                                    className="close-sf-btn flex-shrink-0"
                                    onClick={() => {
                                      // Remove the species from the selection
                                      const updatedTempSelectSpecies = props.tempSelectSpecies.filter(
                                        (id) => id !== species._id,
                                      );
                                      const updatedTempSelectSpeciesName = props.tempSelectSpeciesName.filter(
                                        (item) => item._id !== species._id,
                                      );
                                      props.setTempSelectSpecies(updatedTempSelectSpecies);
                                      props.setTempSelectSpeciesName(updatedTempSelectSpeciesName);
                                    }}
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Submit Buttons */}
                <div className="sf-btn-wrap d-flex">
                  {location?.state?.data?._id && (
                    <button
                      type="button"
                      className={`btn btn-default ${isMobile ? "w-100 h-40 pt-10" : "w-25"} `}
                      onClick={handlePreview}
                      style={{ borderRadius: "10px" }}
                    >
                      {loadingPreview ? "Loading..." : "Preview"}
                    </button>
                  )}
                  {location?.state?.data?._id ? (
                    <>
                      <div
                        className={`btn payment-btn ${
                          isMobile ? "w-100 h-40" : "w-25"
                        } d-flex align-items-center cursor-pointer`}
                        onClick={() => onSaveHandler("stripe")}
                      >
                        <img
                          src={stripeIcon}
                          alt="Stripe Icon"
                          className="me-2"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <div>{loadingPayment ? "Loading..." : "Pay via Stripe"}</div>
                      </div>
                      <div
                        className={`btn payment-btn ${
                          isMobile ? "w-100 h-40" : "w-25"
                        } d-flex align-items-center cursor-pointer ${tempSelectSpecies.length > 1 ? "disabled" : ""}`}
                        onClick={() => onSaveHandler("paypal")}
                      >
                        <img
                          src={paypalIcon}
                          alt="Stripe Icon"
                          className="me-2"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <div>{loadingPayment ? "Loading..." : "Pay via PayPal"}</div>
                      </div>
                    </>
                  ) : (
                    <button type="button" className="btn btn-default h-40 pt-10" onClick={onSaveHandler}>
                      {loadingPayment ? "Loading..." : "Submit"}
                    </button>
                  )}
                </div>
                {tempSelectSpecies.length > 1 && (
                  <p className="text-red ps-1 pt-2">Please select only one species to proceed with PayPal payment.</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <SpeciesMultiSelectModal
        show={showSpeciesAndCategories}
        onHide={toggleSpeciesAndCategories}
        loading={false}
        setLoading={() => {}}
        isEdit={true}
        isViewAll={false}
        tempSelectSpeciesCategory={tempSelectSpeciesCategory}
        setTempSelectSpeciesCategory={setTempSelectSpeciesCategory}
        tempSelectSpecies={tempSelectSpecies}
        setTempSelectSpecies={setTempSelectSpecies}
        tempSelectSpeciesName={tempSelectSpeciesName}
        setTempSelectSpeciesName={setTempSelectSpeciesName}
        selectedSpecies={selectedSpecies}
        setSelectedSpecies={setSelectedSpecies}
        isPost={true}
        postType={props?.postType}
      />
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProfileInfo: (callback) => dispatch(getProfileInfo(callback)),
  getCountry: (callback) => dispatch(getCountry(callback)),
  getUsState: (callback) => dispatch(getUsState(callback)),
  getUsCitiesDropdown: (data, callback) => dispatch(getUsCitiesDropdown(data, callback)),
  getSpeciesList: (params, callback) => {
    dispatch(getSpeciesList(params, callback));
  },
  updateYourProfile: (data, callback) => dispatch(updateYourProfile(data, callback)),
  createStripeSponsor: (data, callback) => {
    dispatch(createStripeSponsor(data, callback));
  },
  createsponsor: (data, callback) => {
    dispatch(createsponsor(data, callback));
  },
});
const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  sideBarApisListings: sideBarApisListings(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SustainabilityDetails));
