import React, { useEffect, useState, useCallback } from "react";
import Layout from "../../components/forgotPassword/layout";
import Select from "react-select";
import {
  getEnvironmentalistList,
  getOrganizationList,
  getRegionList,
  getScienceAndEducationList,
  getSpeciesList,
  getWetMarketList,
  getZooList,
  sideBarApisListings,
} from "../../store/sidebarApis";
import { connect } from "react-redux";
import NextButton from "../../common/form/nextButton";
import { getProfile, removeKey, setProfile, setVolunteerPreference } from "../../utils/localStorageServices";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { capitalizeContentInBrackets, transformDropdownData } from "../../utils/helperFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./header";
import { volunteerPreference } from "../../store/users";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import reportingImg1 from "../../include/images/reporting-img-1.png";
import reportingImg2 from "../../include/images/reporting-img-2.png";

const customStyles1 = {
  indicatorSeparator: () => ({ display: "none" }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#fff",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 42,
    borderColor: "transparent",
    borderBottom: state.isFocused ? "2px solid #47AD1D" : "1px solid #e4e4e4",
    borderRadius: 0,
    boxShadow: "none",
    "&:hover": {
      borderBottom: "2px solid #47AD1D",
    },
  }),
  multiValueRemove: (base) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    borderRadius: 0,
  }),
};

//Volunteer Preference Modal while signup
const VolunteerProfile = (props) => {
  const [selectedPreference, setSelectedPreference] = useState({
    species: [],
    organization: [],
    region: [],
    zoo: [],
    wetMarket: [],
    environmentalist: [],
    science: [],
  });
  const [allContentChecked, setAllContentChecked] = useState(false);
  const profile = getProfile();
  const history = useHistory();
  const isLoggedIn = !!localStorage.getItem("data");
  const transformDropdownDataSpecies = (data, labelKey = "scientificName", valueKey = "_id") => {
    return data
      .filter((item) => item[labelKey] || item.displayCommonName)
      .map((item) => ({
        label: capitalizeContentInBrackets(item.displayCommonName || item[labelKey]),
        value: item[valueKey],
      }));
  };
  const speciesDropdown = transformDropdownDataSpecies(props.sideBarApisListings?.speciesList?.data || []);
  const organizationDropdown = transformDropdownData(props.sideBarApisListings?.organizationList?.data || []);
  const regionsDropdown = transformDropdownData(props.sideBarApisListings?.regionList?.data || []);
  const zooDropdown = transformDropdownData(props.sideBarApisListings?.zoo?.data || []);
  const wetMarketDropdown = transformDropdownData(props.sideBarApisListings?.wetMarket?.data || []);
  const environmentalistDropdown = transformDropdownData(props.sideBarApisListings?.environmentalist?.data || []);
  const isMobile = useCheckMobileScreen();
  const [selectedValues, setSelectedValues] = useState([]);

  const options = [
    { id: "flexCheck__1", label: "Animal Lover", value: "animalLover" },
    { id: "flexCheck__2", label: "Student/Graduates", value: "studentOrGraduates" },
    { id: "flexCheck__3", label: "Scientists & Biologists", value: "scientistsAndBiologists" },
    { id: "flexCheck__4", label: "Professor", value: "professor" },
    { id: "flexCheck__5", label: "Activist", value: "activists" },
  ];

  const handleCheckboxChangeType = (value) => {
    setSelectedValues((prevSelected) =>
      prevSelected.includes(value) ? prevSelected.filter((item) => item !== value) : [...prevSelected, value],
    );
  };
  const extractLabel = useCallback((interestedArray, dropdownArray) => {
    return interestedArray
      ?.map((id) => {
        const foundObject = dropdownArray?.find((obj) => obj?.value === id);
        return foundObject ? { value: foundObject.value, label: foundObject.label } : null;
      })
      .filter((item) => item);
  }, []);
  const areAllArraysEmpty = (obj) => {
    const keys = [
      "interestedEnvironmentalists",
      "interestedOrganizations",
      "interestedRegions",
      "interestedScienceAndEducations",
      "interestedSpecies",
      "interestedWetMarkets",
      "interestedZoos",
    ];

    for (const key of keys) {
      if (!Array.isArray(obj[key]) || obj[key]?.length > 0) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (!props.sideBarApisListings?.regionList?.data) {
      props.getRegionList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.organizationList?.data) {
      props.getOrganizationList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.zoo?.data) {
      props.getZooList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.wetMarket?.data) {
      props.getWetMarketList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.environmentalist?.data) {
      props.getEnvironmentalistList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.speciesList?.data) {
      props.getSpeciesList({}, (res) => {});
    }
  }, []);
  useEffect(() => {
    if (isLoggedIn && profile?.profile?.isVolunteer) {
      if (areAllArraysEmpty(profile?.profile)) {
        setAllContentChecked((prevState) => !prevState);
        if (!allContentChecked) {
          setSelectedPreference({
            species: [],
            organization: [],
            region: [],
            zoo: [],
            wetMarket: [],
            environmentalist: [],
            science: [],
          });
        }
      } else {
        const {
          interestedSpecies,
          interestedOrganizations,
          interestedRegions,
          interestedZoos,
          interestedWetMarkets,
          interestedEnvironmentalists,
        } = profile.profile;
        setSelectedPreference({
          species: extractLabel(interestedSpecies, speciesDropdown),
          organization: extractLabel(interestedOrganizations, organizationDropdown),
          region: extractLabel(interestedRegions, regionsDropdown),
          zoo: extractLabel(interestedZoos, zooDropdown),
          wetMarket: extractLabel(interestedWetMarkets, wetMarketDropdown),
          environmentalist: extractLabel(interestedEnvironmentalists, environmentalistDropdown),
        });
      }
    }
  }, []);

  const handleSelectChange = useCallback(
    (type, selectedOptions) => {
      if (!allContentChecked) {
        setSelectedPreference((prevState) => ({
          ...prevState,
          [type]: selectedOptions,
        }));
      }
    },
    [allContentChecked],
  );

  const handleCheckboxChange = () => {
    setAllContentChecked((prevState) => !prevState);
    if (!allContentChecked) {
      setSelectedPreference({
        species: [],
        organization: [],
        region: [],
        zoo: [],
        wetMarket: [],
        environmentalist: [],
        science: [],
      });
    }
  };

  const extractIds = (selectedOptions) => {
    return selectedOptions.map((option) => option.value);
  };

  const handleSubmit = () => {
    let payload = {
      species: [],
      organizations: [],
      regions: [],
      zoos: [],
      wetMarkets: [],
      environmentalists: [],
      volunteerSubTypes: selectedValues,
    };

    if (!allContentChecked) {
      payload = {
        species: extractIds(selectedPreference.species),
        organizations: extractIds(selectedPreference.organization),
        regions: extractIds(selectedPreference.region),
        zoos: extractIds(selectedPreference.zoo),
        wetMarkets: extractIds(selectedPreference.wetMarket),
        environmentalists: extractIds(selectedPreference.environmentalist),
        volunteerSubTypes: selectedValues,
      };
    }

    props.volunteerPreference(props.steps.data.id, payload, (res) => {
      if (res && res.status === 200) {
        removeKey("preferences");
        history.push("/greeting");
      } else {
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleNext = () => {
    setVolunteerPreference(selectedPreference);
    history.push("/signup");
  };

  return (
    <div class="page-outer-wrapper font-family-poppins grey-bg min-vh-100 min-vh-90">
      <Header data={props?.steps?.data} step="step-3" type={props?.type} />
      <div className="page-outer-wrapper font-family-poppins grey-bg min-vh-100">
        <div className="registration-flow-container fw-medium">
          <div class="volunteer-page-wrapper">
            <div class="content-container volunteer-container">
              <div class="container">
                <div class="row">
                  <div class="col-lg-11 mx-auto">
                    <div class="volunteer-detail">
                      <div class="volunteer-detail-upper">
                        <p>
                          We are excited to invite you to participate in an impactful initiative through 
                          <a href="/" target="_blank" rel="noopener noreferrer">
                            Environmental Inclusion.
                          </a>{" "}
                          By registering on our platform, you can select a species of interest and contribute to its
                          conservation by helping to build a dedicated species page with the most up-to-date information
                          available. You will also be notified and included in field activities involving your species
                          and region of interest. 
                        </p>
                        <div class="vd-separator"></div>
                        <p>
                          To get started, simply register{" "}
                          <a href="/signup?type=volunteer" target="_blank" rel="noopener noreferrer">
                            here
                          </a>{" "}
                          and provide us with the details of your university, department, and the professors involved.
                          As part of this program, students will:
                        </p>
                      </div>

                      <div class="volunteer-detail-lower">
                        <ul class="tick-icon-list">
                          <li class="ti-item">
                            Collaborate with scientists, biologists, environmentalists, zoos, nonprofits,
                            preservationists, and advocates.
                          </li>
                          <li class="ti-item">
                            Collect and organize valuable data to build a comprehensive species page.
                          </li>
                          <li class="ti-item">
                            Use research and journalism to promote and develop conservation efforts for the chosen
                            species.
                          </li>
                          <li class="ti-item">Site visits and volunteer work in the field</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-container">
              <div class="container">
                <div class="row">
                  <div class="col-xl-11 mx-auto">
                    <div class="reporting-content">
                      <div class="reporting-content-head text-center">
                        <h4>
                           Content you are interested in includes researching, communicating, writing scientific papers,
                          making observations, and reporting findings
                        </h4>
                      </div>
                      <div className="rc-check-list d-flex flex-wrap justify-content-center">
                        {options.map((option) => (
                          <div key={option.id} className="rc-check-item">
                            <div className="custom-form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={option.id}
                                checked={selectedValues.includes(option.value)}
                                onChange={() => handleCheckboxChangeType(option.value)}
                              />
                              <label className="form-check-label" htmlFor={option.id}>
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div class="rc-form d-flex">
                        <div class="rc-form-left">
                          <div class="rc-form-img position-relative">
                            <div class="rc-holder rc-holder-1">
                              <img class="w-100 h-100 object-fit-cover" src={reportingImg1} alt="" />
                            </div>
                            <div class="rc-holder rc-holder-2">
                              <img class="w-100 h-100 object-fit-cover" src={reportingImg2} alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="rc-form-right">
                          <form class="rc-form-row-group">
                            <div class="form-floating-row d-flex flex-wrap">
                              <div class="ff-column">
                                <label htmlFor="species" className="form-label">
                                  Species
                                </label>
                                <Select
                                  className="basic-single text-capitalize"
                                  classNamePrefix="select-search"
                                  isMulti
                                  placeholder="Select"
                                  styles={customStyles1}
                                  options={speciesDropdown}
                                  value={selectedPreference.species}
                                  onChange={(selectedOptions) => handleSelectChange("species", selectedOptions)}
                                  isDisabled={allContentChecked}
                                />
                              </div>
                            </div>
                            <div class="form-floating-row d-flex flex-wrap">
                              <div class="ff-column">
                                <label htmlFor="region" className="form-label">
                                  Regions
                                </label>
                                <Select
                                  className="basic-single text-capitalize"
                                  classNamePrefix="select-search"
                                  isMulti
                                  placeholder="Select"
                                  styles={customStyles1}
                                  options={regionsDropdown}
                                  value={selectedPreference.region}
                                  onChange={(selectedOptions) => handleSelectChange("region", selectedOptions)}
                                  isDisabled={allContentChecked}
                                />
                              </div>
                            </div>
                            {/* <div className="ff-column w-100">
                        <label htmlFor="zoo" className="form-label">
                          Zoos
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={zooDropdown}
                          value={selectedPreference.zoo}
                          onChange={(selectedOptions) => handleSelectChange("zoo", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div>
                      <div className="ff-column w-100">
                        <label htmlFor="wetMarket" className="form-label">
                          War on the Environment - Threats
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={wetMarketDropdown}
                          value={selectedPreference.wetMarket}
                          onChange={(selectedOptions) => handleSelectChange("wetMarket", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div>
                      <div className="ff-column w-100">
                        <label htmlFor="environmentalist" className="form-label">
                          Environmentalists
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={environmentalistDropdown}
                          value={selectedPreference.environmentalist}
                          onChange={(selectedOptions) => handleSelectChange("environmentalist", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div>
                      <div className="ff-column w-100">
                        <label htmlFor="organization" className="form-label">
                          Organizations
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={organizationDropdown}
                          value={selectedPreference.organization}
                          onChange={(selectedOptions) => handleSelectChange("organization", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div>  */}
                            <div class="rc-from-btn">
                              <Link onClick={handleSubmit} class="btn btn-default btn-block btn-xxl h-40 pt-10">
                                Register
                              </Link>
                            </div>
                            <div class="rc-from-btn">
                              <a
                                href="/intern-report"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="btn btn-outline-green btn-block btn-xxl h-40 pt-10"
                              >
                                University Intern Page
                              </a>
                            </div>
                            <div class="rc-from-btn d-flex justify-content-center">
                              <a href="/" target="_blank" rel="noopener noreferrer" class="btn btn-read-more btn-xxl">
                                Back to Home
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  getSpeciesList: (params, callback) => dispatch(getSpeciesList(params, callback)),
  getZooList: (params, callback) => dispatch(getZooList(params, callback)),
  getOrganizationList: (params, callback) => dispatch(getOrganizationList(params, callback)),
  getEnvironmentalistList: (params, callback) => dispatch(getEnvironmentalistList(params, callback)),
  getWetMarketList: (params, callback) => dispatch(getWetMarketList(params, callback)),
  getScienceAndEducationList: (params, callback) => dispatch(getScienceAndEducationList(params, callback)),
  volunteerPreference: (params, data, callback) => dispatch(volunteerPreference(params, data, callback)),
});

const mapStateToProps = (state) => ({
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(VolunteerProfile));
