import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/x-close-24x24.svg";
import Mclose from "../../../include/images/close-24x24.svg";
import searchClose from "../../../include/images/search-close-18x18.svg";
import { getSpeciesCategoryList, sideBarApisListings, getSpeciesList } from "../../../store/sidebarApis";
import { getUser, updateYourProfile, getUsersPreferences } from "../../../store/users";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import { useRef } from "react";
import NextButton from "../../../common/form/nextButton";
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions";
import defaultImage from "../../../include/images/default_image_species.svg";
import amphibiansImg from "../../../include/images/Amphibians.png";
import birdsImg from "../../../include/images/Birds.png";
import fishImg from "../../../include/images/Fish.png";
import insectsImg from "../../../include/images/Insects.png";
import mammalsImg from "../../../include/images/Mammals.png";
import reefCoralsImg from "../../../include/images/Reef Corals.png";
import reptilessImg from "../../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../../include/images/Crustaceans.png";
import SmallScreenPaginations from "../Pagination/smallScreenPagination";
import Skeleton from "react-loading-skeleton";

const SpeciesAndCategoryEditModal = (props) => {
  var ref = useRef();
  const isMobile = useCheckMobileScreen();
  const [speciesDropdownData, setSpeciesDropdonData] = useState(null);
  const [speciesCategoryData, setSpeciesCategoryData] = useState(null);
  const [textSearched, setTextSearched] = useState("");
  const [selectSpecies, setSelectSpecies] = useState([]);
  const [selectSpeciesName, setSelectSpeciesName] = useState([]);
  const [selectSpeciesCategory, setSelectSpeciesCategory] = useState([]);
  const speciesCategories = props?.sideBarApisListings?.speciesCategory?.data;
  const speciesDropdownDataList = props?.sideBarApisListings?.speciesList?.data;
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(false);
  useEffect(() => {
    const a = props?.speciesData && props?.speciesData.length > 0 ? props?.speciesData.map((item) => item?._id) : [];
    setSelectSpecies(a);

    const b = props?.speciesData ? props.speciesData : [];
    setSelectSpeciesName(b);

    const c =
      props.speciesCatData && props.speciesCatData.length > 0 ? props.speciesCatData.map((item) => item?._id) : [];

    setSelectSpeciesCategory(c);
  }, [props?.speciesData, props.speciesCatData]);

  useEffect(() => {
    if (props?.show && !props?.isPost) {
      // When the modal opens, will initialize the temporary state with the current selected categories.
      props.setTempSelectSpeciesCategory([...selectSpeciesCategory]);
      props.setTempSelectSpecies([...selectSpecies]);
      props.setTempSelectSpeciesName([...selectSpeciesName]);
    }
  }, [props.show]);
  useEffect(() => {
    props.setLoading(true);
    setSpeciesDropdonData([]);
    props.getSpeciesList({ page }, (res) => {
      if (res && res.status === 200) {
        setSpeciesDropdonData(res?.data?.data);
        ref && ref.current && ref.current.complete();
        props.setLoading(false);
      }
    });
  }, [page]);
  useEffect(() => {
    const categories = props.tempSelectSpeciesCategory && props.tempSelectSpeciesCategory.map((item) => item).join(",");
    if (categories || textSearched) {
      ref && ref.current && ref.current.continuousStart();
      props.setLoading(true);
      const payload = {
        keyword: textSearched ? textSearched : "",
        page,
      };
      if (categories) {
        payload.categories = categories;
      }
      setSpeciesDropdonData([]);
      props.getSpeciesList(payload, (res) => {
        if (res && res.status === 200) {
          setSpeciesDropdonData(res?.data?.data);
          ref && ref.current && ref.current.complete();
          props.setLoading(false);
        } else {
          ref && ref.current && ref.current.complete();
          props.setLoading(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, [props.tempSelectSpeciesCategory]);

  useEffect(() => {
    if (!speciesCategories) {
      ref && ref.current && ref.current.continuousStart();
      props.setLoading(true);
      props.getSpeciesCategoryList({}, (res) => {
        if (res && res.status === 200) {
          setSpeciesCategoryData(res?.data?.data);
          ref && ref.current && ref.current.complete();
          props.setLoading(false);
        } else {
          ref && ref.current && ref.current.complete();
          props.setLoading(false);
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      setSpeciesCategoryData(speciesCategories);
    }
  }, []);
  const handleSelectAllSpecies = () => {
    const allIds = speciesDropdownData && speciesDropdownData.map((item) => item?._id);
    props.setTempSelectSpecies(allIds);
    props.setTempSelectSpeciesName(speciesDropdownData);
  };

  const handleSpeciesUpdateProfile = (e) => {
    if (props?.isPost) {
      props?.onHide();
    } else {
      if (props.checkIfAnyPreferenceSelected("speciesData", props.tempSelectSpecies)) {
        e.preventDefault();
        ref && ref.current && ref.current.continuousStart();
        props.setLoading(true);
        const payload = {
          preferredSpecies: props.tempSelectSpecies ? props.tempSelectSpecies : [],
          preferredSpeciesCategories: props.tempSelectSpeciesCategory ? props.tempSelectSpeciesCategory : [],
        };
        props.updateYourProfile(payload, (res) => {
          if (res && res.status === 200) {
            props.getUsersPreferences("species", (res) => {
              if (res && res.status === 200) {
                props.getUsersPreferences("speciesCategory", (res) => {
                  if (res && res.status === 200) {
                    ref && ref.current && ref.current.complete();
                    props.setLoading(false);
                    toast(<AlertSuccess message="Information Saved" />);
                    props.onHide();
                  }
                });
              }
            });
          } else {
            ref && ref.current && ref.current.complete();
            props.setLoading(false);
            props.onHide();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        toast(<AlertError message={"Please Select any one Preferences"} />);
      }
    }
  };
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  const handleSearch = () => {
    setSpeciesDropdonData([]);
    const payload = {
      keyword: textSearched || "",
      page: page,
    };

    if (props.tempSelectSpeciesCategory?.length > 0) {
      payload.categories = props.tempSelectSpeciesCategory.join(",");
    }

    props.getSpeciesList(payload, (res) => {
      if (res?.status === 200) {
        setSpeciesDropdonData(res?.data?.data);
      } else {
        toast(<AlertError message="Something Went Wrong" />);
      }
      ref.current?.complete();
      props.setLoading(false);
    });
  };
  const totalPages =
    (props?.sideBarApisListings?.speciesList && props?.sideBarApisListings?.speciesList?.totalCount) || 0;

  const dataPerPage =
    (props?.sideBarApisListings?.speciesList && props?.sideBarApisListings?.speciesList?.recordsPerPage) || 0;

  const noOfPage = Math.ceil(totalPages / dataPerPage) || 0;
  return (
    <>
      <Modal
        className={`modal fade signup-modal font-family-poppins `}
        id="speciesModal"
        show={props.show}
        onHide={() => props.onHide()}
        style={isMobile ? {} : { marginTop: "4%" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="signup-modal-parent d-flex flex-wrap">
              <div className="signup-modal-box">
                <div className="signup-modal-header">
                  <div className="smh-insider position-relative d-flex justify-content-between">
                    <h4 className={isMobile ? "mt-2" : ""}>Major Categories of Species to Follow</h4>
                    {!isMobile && props.isViewAll && (
                      <div className="accor-edit-btn">
                        <Link
                          className={`edit-green-btn `}
                          onClick={() => {
                            props.setIsEdit(!props.isEdit);
                            if (props.isViewAll) {
                              props.setIsViewAll(!props.isViewAll);
                            }
                          }}
                        >
                          Edit
                        </Link>
                      </div>
                    )}
                    <div className="modal_close-box d-lg-none ">
                      <div
                        className={`signup-modal-close ms-auto ${isMobile ? "mt-1" : ""}`}
                        data-bs-dismiss="modal"
                        onClick={() => props.onHide()}
                      >
                        <img src={Mclose} alt="x close icon" />
                      </div>
                    </div>
                  </div>
                  <p>
                    Select a group of individual species that you want to follow. You will receive a notification if
                    anything is posted on these species pages. You can adjust what you see in your feed below.
                  </p>
                  {isMobile && props.isViewAll && (
                    <div className="accor-edit-btn">
                      <Link
                        className={`edit-green-btn mb-4 `}
                        onClick={() => {
                          props.setIsEdit(!props.isEdit);
                          if (props.isViewAll) {
                            props.setIsViewAll(!props.isViewAll);
                          }
                        }}
                      >
                        Edit
                      </Link>
                    </div>
                  )}
                  <div className="grey-content-box">
                    <div className="signup-modal-title fw-medium">Narrow List to Major Species Categories</div>
                    <div className="check-group-list row">
                      {" "}
                      {props?.isEdit ? (
                        <>
                          {speciesCategoryData?.length > 0 ? (
                            speciesCategoryData?.map((item) => {
                              const isActive = props.tempSelectSpeciesCategory?.includes(item._id);
                              return (
                                <div className="col-4 mb-3" key={item._id}>
                                  {" "}
                                  <button
                                    className={`btn h-40 ${
                                      isActive ? "btn-outline-green" : "btn-outline-secondary"
                                    } w-100`}
                                    onClick={() => {
                                      const updatedTempSelectSpeciesCategory = [...props.tempSelectSpeciesCategory];
                                      if (isActive) {
                                        const filtered = updatedTempSelectSpeciesCategory.filter(
                                          (it) => it !== item._id,
                                        );
                                        props.setTempSelectSpeciesCategory(filtered);
                                      } else {
                                        updatedTempSelectSpeciesCategory.push(item._id);
                                        props.setTempSelectSpeciesCategory(updatedTempSelectSpeciesCategory);
                                      }
                                    }}
                                  >
                                    {capitalizeContentInBrackets(item?.name)}
                                  </button>
                                </div>
                              );
                            })
                          ) : (
                            <div className="row w-100 ms-1 mb-1">
                              {Array.from({ length: 9 }).map((_, idx) => (
                                <div key={idx} className="col-4 mb-2">
                                  <Skeleton width={"100%"} height={40} />
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {speciesCategoryData &&
                            speciesCategoryData.length > 0 &&
                            speciesCategoryData.map((item) => (
                              <div className="col-4 mb-3" key={item._id}>
                                {" "}
                                <button className="btn btn-outline-secondary w-100" disabled>
                                  {capitalizeContentInBrackets(item?.name)}
                                </button>
                              </div>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="signup-modal-body preference-modal-adjust pt-0">
                  <div className="two-column-row d-block d-sm-flex flex-wrap">
                    <div className={`tc-left ${isMobile ? "" : "w-25"}`}>
                      {props.tempSelectSpecies.length > 0 && (
                        <div className="signup-modal-title fw-medium d-flex align-items-center">
                          Selected
                          {isMobile && props.tempSelectSpeciesName.length > 10 && (
                            <div
                              className="clear__all__btn ms-2"
                              onClick={() => setShowMore((prev) => !prev)}
                            >
                              {showMore ? "View Less" : "View More"}
                            </div>
                          )}
                        </div>
                      )}
                      {props?.isEdit && props.tempSelectSpecies && props.tempSelectSpecies.length > 0 && (
                        <div className="tc-btn-box">
                          <Link
                            className="clear__all__btn"
                            onClick={() => {
                              const data = [];
                              props.setTempSelectSpecies(data);
                              props.setTempSelectSpeciesName(data);
                            }}
                          >
                            Clear All
                          </Link>
                        </div>
                      )}
                      <div className="check-group-list row">
                        {props.tempSelectSpeciesName &&
                          props.tempSelectSpeciesName.length > 0 &&
                          props.tempSelectSpeciesName
                            .slice(0, isMobile && !showMore ? 10 : props.tempSelectSpeciesName.length) // Limit to 10 if isMobile and showMore is false
                            .map((item, index) => {
                              return (
                                <div className={`col-6 col-md-12 ${isMobile ? "" : "mb-2"}`} key={index}>
                                  <div className="check-group-item list-with-bullets">
                                    <div className="custom-form-check ps-0">
                                      <label className="form-check-label text-capitalize">
                                        {capitalizeContentInBrackets(
                                          item?.displayCommonName || item?.scientificName || item?.name,
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    <div className={`tc-right ${isMobile ? "" : "w-75"}`}>
                      <div className="signup-modal-title fw-medium">Species Under Selected Categories</div>
                      <div className="modal-serch-box d-block d-sm-flex flex-wrap justify-content-between">
                        <div className={`position-relative ${isMobile ? "" : "w-50"} d-flex`}>
                          <input
                            type="text"
                            className={`modal-search ${isMobile ? "" : "w-75"}`}
                            placeholder="Search"
                            value={textSearched}
                            onChange={(e) => setTextSearched(e.target.value)}
                          />
                          <button type="button" class="btn btn-outline-green ms-2" onClick={() => handleSearch()}>
                            Search
                          </button>
                        </div>
                        <div className={` ${isMobile ? "mt-3" : "w-50"} `}>
                          {speciesDropdownData && noOfPage > 1 && (
                            <SmallScreenPaginations
                              noOfPage={noOfPage}
                              totalPages={noOfPage}
                              page={page}
                              setPage={setPage}
                            />
                          )}
                        </div>
                      </div>
                      {props.isEdit && (
                        <div className="tc-btn-box">
                          <Link className="clear__all__btn" onClick={handleSelectAllSpecies}>
                            Select All
                          </Link>
                        </div>
                      )}
                      <div className="tc-search-result-row mt-0">
                        <ul class="filter-content-list d-flex flex-wrap">
                          {props.isEdit ? (
                            <>
                              {speciesDropdownData?.length > 0 ? (
                                speciesDropdownData?.map((item, idx) => {
                                  let firstLetter = item?.displayCommonName?.match(/[A-Za-z]/);
                                  firstLetter = firstLetter ? firstLetter[0].toUpperCase() : "";
                                  return (
                                    <div key={item._id} className="col-12 col-lg-6 tc-search-result">
                                      <div className="check-group-list" style={{ marginLeft: "0px" }}>
                                        <div
                                          style={{ width: "90%" }}
                                          className={`species-item mt-2 d-flex ${
                                            props.tempSelectSpecies?.includes(item._id) ? "green-background" : ""
                                          }`}
                                          onClick={() => {
                                            const updatedTempSelectSpecies = [...props.tempSelectSpecies];
                                            const updatedTempSelectSpeciesName = [...props.tempSelectSpeciesName];

                                            if (updatedTempSelectSpecies.includes(item._id)) {
                                              props.setTempSelectSpecies(
                                                updatedTempSelectSpecies.filter((it) => it !== item._id),
                                              );
                                              props.setTempSelectSpeciesName(
                                                updatedTempSelectSpeciesName.filter((it) => it._id !== item._id),
                                              );
                                            } else {
                                              updatedTempSelectSpecies.push(item._id);
                                              updatedTempSelectSpeciesName.push(item);
                                              props.setTempSelectSpecies(updatedTempSelectSpecies);
                                              props.setTempSelectSpeciesName(updatedTempSelectSpeciesName);
                                            }
                                          }}
                                        >
                                          <div className="species-image">
                                            <img
                                              src={
                                                item?.coverImage?.small
                                                  ? process.env.REACT_APP_MEDIA + item?.coverImage?.small
                                                  : getDefaultImage(item?.speciesCategoryName)
                                              }
                                              alt="Species"
                                              className="img-fluid"
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = getDefaultImage(item?.speciesCategoryName);
                                              }}
                                            />
                                          </div>
                                          <div className="species-content">
                                            <div className="species-name text-capitalize">
                                              {item?.displayCommonName || item?.scientificName || item?.name}
                                            </div>
                                            <div className="species-scientific-name caps-text">
                                              {item?.scientificName || item?.name}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="row">
                                  {Array.from({ length: 6 }).map((_, idx) => (
                                    <div key={idx} className="col-lg-6 col-md-6 col-sm-12 tc-search-result">
                                      <div className="check-group-list" style={{ marginLeft: "0px" }}>
                                        <div className="species-item mt-2 d-flex">
                                          <div className="species-image">
                                            <Skeleton width={"300px"} height={"80px"} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {speciesDropdownData &&
                                speciesDropdownData.length > 0 &&
                                speciesDropdownData.map((item, idx) => (
                                  <div
                                    key={item._id}
                                    className="col-lg-4 col-md-6 col-sm-12 tc-search-result d-flex align-items-center"
                                  >
                                    <div className="species-item mt-2 d-flex" style={{ width: "90%" }}>
                                      <div className="species-image">
                                        <img
                                          src={item?.coverImage?.small || "/default-placeholder.png"}
                                          alt={item?.displayCommonName || "Species"}
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="species-content">
                                        <div className="species-name text-capitalize">
                                          {capitalizeContentInBrackets(
                                            item?.displayCommonName || item?.scientificName || item?.name,
                                          )}
                                        </div>
                                        <div className="species-scientific-name caps-text">
                                          {item?.scientificName || item?.name}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {props.isEdit && (
                  <div className="signup-modal-footer  d-flex align-items-start justify-content-end">
                    <NextButton
                      classData={"btn btn-default btn-md"}
                      label="Save"
                      page={"edit-feed"}
                      customizeMessgae={"Saving"}
                      loading={props.loading}
                      handleSubmit={(e) => handleSpeciesUpdateProfile(e)}
                    />
                  </div>
                )}
              </div>
              <div className="signup-modal-close-box d-none d-lg-block">
                <div className="signup-modal-close ms-auto" data-bs-dismiss="modal" onClick={() => props.onHide()}>
                  <img src={close} alt="x close icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSpeciesCategoryList: (params, callback) => dispatch(getSpeciesCategoryList(params, callback)),
  getSpeciesList: (params, callback) => {
    dispatch(getSpeciesList(params, callback));
  },
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
  getUsersPreferences: (params, callback) => dispatch(getUsersPreferences(params, callback)),
});

const mapStateToProps = (state) => ({
  sideBarApisListings: sideBarApisListings(state),
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SpeciesAndCategoryEditModal));
