import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import close from "../../../include/images/close.svg";
import { connect } from "react-redux";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { sharePage, verifyRecaptcha } from "../../../store/misc";
import ReCAPTCHA from 'react-google-recaptcha';

const ShareModal = ({ show, onHide, category, id, name, sharePage, verifyRecaptcha }) => {
    const ref = useRef(null);
    const captchaRef = useRef(null);
    const [emails, setEmails] = useState("");
    const [loading, setLoading] = useState(false);
    const [captchaToken, setCaptchaToken] = useState("");
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);

    useEffect(() => {
        if (show && captchaRef.current) {
            captchaRef.current.execute();
        }
    }, [show]);

    useEffect(() => {
        if (captchaToken && show && !isVerifying) {
            setIsVerifying(true);
            const captchaPayload = { recaptchaToken: captchaToken };
            verifyRecaptcha(captchaPayload, (res) => {
                if (res && res.status === 200) {
                    setIsCaptchaVerified(true);
                } else {
                    setIsCaptchaVerified(false);
                }
                setIsVerifying(false);
            });
        }
    }, [captchaToken, show]);

    const handleChange = (token) => {
        setCaptchaToken(token);
    };

    const handleShare = (e) => {
        e.preventDefault();
        if (!emails.trim()) {
            toast(<AlertError message="Please enter at least one email address" />);
            return;
        }

        const emailArray = emails.split(',').map(email => email.trim());
        if (emailArray.length > 15) {
            toast(<AlertError message="You can enter up to 15 email addresses" />);
            return;
        }

        const payload = {
            section: category,
            [category]: id,
            emails: emailArray
        };

        setLoading(true);
        ref.current.continuousStart();
        sharePage(payload, (res) => {
            setLoading(false);
            ref.current.complete();
            if (res?.status === 200) {
                setEmails("");
                toast(<AlertSuccess message="Page Shared Successfully" />);
                onHide();
            } else {
                toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
            }
        });
    };

    return (
        <>
            <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0}/>
            <Modal
                className="modal fade signup-modal font-family-poppins"
                id="reportPostModal"
                dialogClassName="modal-dialog modal-dialog-centered xs-modal post-comment-modal"
                contentClassName="modal-content"
                show={show}
                onHide={onHide}
            >
                <div className="signup-modal-parent d-flex flex-wrap">
                    <div className="signup-modal-box">
                        <div className="signup-modal-header">
                            <div className="smh-insider position-relative d-flex">
                                <h4>Share this page</h4>
                                <div className="d-none d-lg-block position-absolute end-0">
                                    <div className="signup-modal-close w-auto" data-bs-dismiss="modal">
                                        <img
                                            src={close}
                                            alt="x close icon"
                                            onClick={onHide}
                                        />
                                    </div>
                                </div>
                                <div className="modal_close-box d-lg-none">
                                    <div
                                        className="signup-modal-close ms-auto"
                                        data-bs-dismiss="modal"
                                        onClick={onHide}
                                    >
                                        <img src={close} alt="x close icon" />
                                    </div>
                                </div>
                            </div>
                            <p className="smh-insider-text">
                                Help raise awareness by sharing this page with others. Together, we can spread the word about {name} and make a meaningful impact.
                            </p>
                        </div>
                        <Modal.Body className="signup-modal-body pt-0">
                            <div className="page-modal-check-group">
                                <div className="page-modal-check-item">
                                    <div className="custom-form-check pl-0 pe-2">
                                        <input
                                            type="email"
                                            className="form-control w-100 placeholder-small"
                                            placeholder="Enter multiple email addresses separated by commas."
                                            value={emails}
                                            onChange={(e) => setEmails(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                onChange={handleChange}
                                size="invisible"
                                ref={captchaRef}
                            />
                        </Modal.Body>
                        <div className="signup-modal-footer d-flex align-items-start justify-content-end border-top-0">
                            <Link
                                className={`btn btn-default btn-md ${loading || !isCaptchaVerified ? "disabled" : ""}`}
                                onClick={handleShare}
                                disabled={loading || !isCaptchaVerified}
                            >
                                Share
                            </Link>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    sharePage: (data, callback) => dispatch(sharePage(data, callback)),
    verifyRecaptcha: (data, callback) => dispatch(verifyRecaptcha(data, callback)),
});

export default connect(
    null,
    mapDispatchToProps
)(React.memo(ShareModal));
