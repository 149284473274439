import React from "react";
import { Modal } from "react-bootstrap";
import Editor from "../../common/editor/editor";
import close from "../../include/images/close.svg";
import close1 from "../../include/images/x-close-24x24.svg";
//Delete Modal for Post which Reason Textarea
const DeletePost = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup "
      show={props.show}
      onHide={() => props.onHide(null)}
      enforceFocus={true}
      dialogClassName="modal-400w"
      backdropClassName={"delete-backdrop"}
      //   backdrop={uploadImageModal ? false : true}
      centered
    >
      <div
        className="w-100 ms-auto d-block d-lg-none d-flex align-items-center justify-content-end "
        onClick={() => props.onHide(null)}
      >
        <img src={close} alt="" className="custom-close-btn cursor-pointer" />
      </div>
      <div
        className="signup-modal-close ms-auto d-lg-block d-sm-none"
        style={{ position: "absolute", right: "-40px", top: "0px" }}
        data-bs-dismiss="modal"
        onClick={props.onHide}
      >
        <img src={close1} alt="x close icon" />
      </div>
      <Modal.Body>
        <div class="modal-alert-box">
          <h4>Alert! </h4>
          <p>Are you sure you want to Cancel Sponsorship?</p>
        </div>
        <div className="mt-3">
          <textarea
            type="text"
            className="form-control w-100 text-in"
            onChange={(e) => {
              props.setReason(e.target.value);
            }}
            placeholder="Reason for cancelation"
            value={props.reason}
          />
        </div>
        <div class="modal-action-btn">
          <a
            href="javascript:void(0);"
            class={`btn btn-delete btn-md delete-btn-bg  ${props.loadingDelete && "disabled"}`}
            onClick={() => !props.loadingDelete && props.deleteHandler()}
          >
            Confirm
          </a>
          <a
            href="javascript:void(0);"
            style={{ background: "#fff" }}
            class="btn btn-cancel global-cancel-button"
            onClick={() => props.onHide(null)}
          >
            Cancel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeletePost;
