import React, { useRef, useState } from "react";
import logo from "../../include/images/logo.svg";
import LoadingBar from "react-top-loading-bar";
import NextButton from "../../common/form/nextButton";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";

const RegisterUserType = () => {
  const ref = useRef(null);
  const history = useHistory();
  const isMobile = useCheckMobileScreen();
  const [selectedOption, setSelectedOption] = useState("");

  Useauthredirect();

  const handleRedirect = () => {
    if (selectedOption?.length == 0) return;

    if (selectedOption === "1") {
      history.push("/signup?type=normal");
    } else if (selectedOption === "2") {
      history.push("/signup?type=volunteer");
    } else if (selectedOption === "3") {
      history.push("/signup?type=impactInvestor");
    } else if (selectedOption === "4") {
      history.push("/signup?type=journalist");
    } else if (selectedOption === "5") {
      history.push("/signup?type=scientistAndEducator");
    }
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0}/>
      <div className="page-outer-wrapper font-family-poppins grey-bg">
        <header id="header">
          <nav className="navbar navbar-expand-xl">
            <div className="container">
              <div className="nav-inside d-flex align-items-center justify-content-center">
                <Link to={"/"} className="navbar-brand">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div>
          </nav>
        </header>
        <div className="login-container fw-medium">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-content-holder mx-auto">
                  <div className="login-head-row">
                    <h1>Select Registration Type</h1>
                  </div>
                  <div className="form-group register-user-form fw-normal">
                    <p className="mb-3">
                      To personalize your experience, please choose the category that best fits you. This will enable us
                      to offer you the most relevant content and features.
                    </p>
                    <div className="d-flex register-user-container flex-column">
                      <div className="form-check ps-4">
                        <input
                          type="radio"
                          className="form-check-input"
                          checked={selectedOption === "1"}
                          onChange={(e) => setSelectedOption("1")}
                          id="reg-1"
                        />
                        <label htmlFor="reg-1" className="form-check-label">
                          Register for the Launch
                        </label>
                      </div>
                      <div className="form-check ps-4">
                        <input
                          type="radio"
                          className="form-check-input"
                          checked={selectedOption === "2"}
                          onChange={(e) => setSelectedOption("2")}
                          id="reg-2"
                        />
                        <label htmlFor="reg-2" className="form-check-label">
                          Register as Volunteer & Interns
                        </label>
                      </div>
                      <div className="form-check ps-4">
                        <input
                          type="radio"
                          className="form-check-input"
                          checked={selectedOption === "3"}
                          onChange={(e) => setSelectedOption("3")}
                          id="reg-3"
                        />
                        <label htmlFor="reg-3" className="form-check-label">
                          Register as Impact Investor
                        </label>
                      </div>
                      <div className="form-check ps-4">
                        <input
                          type="radio"
                          className="form-check-input"
                          checked={selectedOption === "4"}
                          onChange={(e) => setSelectedOption("4")}
                          id="reg-4"
                        />
                        <label htmlFor="reg-4" className="form-check-label">
                          Register for Environmental Press Corp
                        </label>
                      </div>
                      <div className="form-check ps-4">
                        <input
                          type="radio"
                          className="form-check-input"
                          checked={selectedOption === "5"}
                          onChange={(e) => setSelectedOption("5")}
                          id="reg-5"
                        />
                        <label htmlFor="reg-5" className="form-check-label">
                          Register as Scientist & Educator
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="login-form-btn d-flex align-items-center justify-content-end">
                    <NextButton
                      handleSubmit={handleRedirect}
                      classData={`btn btn-default ${selectedOption?.length > 0 ? "" : "disabled"}`}
                      label="Continue"
                    />
                  </div>

                  <div className="login-link">
                    <Link to="/">Back to Home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer2" className="d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-md-flex align-items-md-center justify-content-md-center">
              <ul className="footer-nav-list d-flex justify-content-center justify-content-md-start align-items-center">
                <li>
                  <a href="javascript:void(0);" >Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="javascript:void(0);" >Privacy Policy</a>
                </li>
              </ul>
              <div className="copyright-box">
                <img src="include/images/copyright.svg" alt="" />
                &copy; Environmental Inclusion. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(RegisterUserType);
